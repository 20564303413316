import { Tab,Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTabContainer = styled(Tabs)(({ theme, myColor }) => ({
  borderBottom: null,
  "& .MuiTabs-indicator": {
    backgroundColor: myColor,
    borderRadius: "12px 12px 0px 0px",
  },
}));

  const StyledIndividualTab = styled((props) => <Tab {...props}/>)(({ theme, myColor }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    width: "max-content",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    color: "#000000",
    opacity:1,
    "&:hover": {
      color: "rgba(0, 0, 0,0.7)",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: myColor,
      backgroundColor: "white",
      borderRadius: "12px 12px 0px 0px",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }))

export { StyledTabContainer,StyledIndividualTab }