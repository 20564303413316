import moment from "moment";
import momentTZ from "moment-timezone";
import Cookies from "universal-cookie";

const cookies = new Cookies();
var ist_offset = -5.5 * 60;
const timeZone = cookies.get("timeZone");

function UTCtoISTConverter(date) {
  var d = new Date(date);

  d.setMinutes(d.getMinutes() - ist_offset);

  return d;
}

function convertUTCtoZone(date, seconds) {
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: timeZone }
    )
  );

  if (seconds) {
    var finalDate = moment(g).format("DD MMM'YY, hh:mm:ss A");
    if (finalDate === "Invalid date") return date;
    else return finalDate;
  } else {
    var finalDate2 = moment(g).format("DD MMM'YY, hh:mm A");
    if (finalDate2 === "Invalid date") return date;
    else return finalDate2;
  }
}

function convertUTCtoZoneYYYYMMDD(date) {
  console.log(momentTZ)
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: timeZone }
    )
  );
  var finalDate = moment(g).format("YYYY-MM-DD HH:mm:ss");
  if (finalDate === "Invalid date") return date;
    else return finalDate;
}

function convertZonetoUTCStart(date) {
  var dString = date + " 00:00:00";

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = timeZone;

  var m = moment.tz(dString, format, zone);
  return m.utc().format(format);
}

function convertZonetoUTCEnd(date) {
  var dString = date + " 00:00:00";
  var d = new Date(dString);
  d.setMinutes(d.getMinutes() + 24 * 60);

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = timeZone;

  var m = moment.tz(d, format, zone);
  return m.utc().format(format);
}

export {
  UTCtoISTConverter,
  convertUTCtoZone,
  convertUTCtoZoneYYYYMMDD,
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
};
