import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Cookies from "universal-cookie";

import Appbar from "./Features/Appbar/Appbar";
import Landing from "./Pages/Landing";
import SpecificBattery from "./Pages/SpecificBattery/SpecificBattery";
import AllBatteries from "./Pages/AllBatteries/AllBatteries";
import AlertAnalytics from "./PagesContent/Reports/ComponentDrillDown/AlertAnalytics/AlertAnalytics";
import DataQuality from "./PagesContent/Reports/ComponentDrillDown/DataQuality/DataQuality";

import Components from "./Static/Data/_Common/Components";

import theme from "./Theme/BaseTheme";
import Login from "./Auth/Login";
import PasswordReset from "./Auth/PasswordReset";
import PasswordReset2 from "./Auth/PasswordReset2";

import SessionExpired from "./Features/Dialog/SessionExpired";

import { useSelector } from "react-redux";


function hui() {
  let result, p;
  if (window.performance.getEntriesByType("navigation")) {
    p = window.performance.getEntriesByType("navigation")[0].type;

    if (p === "navigate") {
      result = "navigate";
    }
    if (p === "reload") {
      result = "reloady";
    }
    if (p === "back_forward") {
      result = "back_forward";
    }
    if (p === "prerender") {
      result = "prerender";
    } //3 is my invention!
  }
  return result;
}

function App() {
  const cookies = new Cookies();
  
  const [LoggedIn, setLoggedIn] = useState(false);
  const isSessionExpired = useSelector((state) => state.SessionExpired.value);

  if (isSessionExpired) {
    const data = hui();
    if (data === "navigate") {
      cookies.remove("name", { path: "/" });
      cookies.remove("sessionId", { path: "/" });
      cookies.remove("access_token", { path: "/" });
      cookies.remove("pages", { path: "/" });
      cookies.remove("pagesMeta", { path: "/" });
      cookies.remove("navigationCard", { path: "/" });
      cookies.remove("orgName", { path: "/" });
      cookies.remove("amplitudeID", { path: "/" });
      cookies.remove("timeZone", { path: "/" });
      cookies.remove("finalCall", { path: "/" });
      window.location.reload();
    }
  }

  useEffect(() => {
    if (isSessionExpired) {
      cookies.set("finalCall", true, { path: "/" });
      const data = hui();
      if (data === "navigate") {
        cookies.remove("name", { path: "/" });
        cookies.remove("sessionId", { path: "/" });
        cookies.remove("access_token", { path: "/" });
        cookies.remove("pages", { path: "/" });
        cookies.remove("pagesMeta", { path: "/" });
        cookies.remove("navigationCard", { path: "/" });
        cookies.remove("orgName", { path: "/" });
        cookies.remove("amplitudeID", { path: "/" });
        cookies.remove("timeZone", { path: "/" });
        cookies.remove("finalCall", { path: "/" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionExpired,window.performance.getEntriesByType("navigation")[0].type]);

  if (
    (!cookies.get("access_token") && !cookies.get("name")) ||
    cookies.get("pagesMeta") === undefined ||
    cookies.get("finalCall")
  ) {
    
    if (cookies.get("finalCall")) {
      cookies.remove("name", { path: "/" });
      cookies.remove("sessionId", { path: "/" });
      cookies.remove("access_token", { path: "/" });
      cookies.remove("pages", { path: "/" });
      cookies.remove("pagesMeta", { path: "/" });
      cookies.remove("navigationCard", { path: "/" });
      cookies.remove("orgName", { path: "/" });
      cookies.remove("amplitudeID", { path: "/" });
      cookies.remove("timeZone", { path: "/" });
      cookies.remove("finalCall", { path: "/" });
    }
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/password_reset_2" element={<PasswordReset2 />} />

          <Route path="/" exact element={ <Login setLoggedIn={setLoggedIn} />}/>
          <Route
            path="*"
            element={
              <Login setLoggedIn={setLoggedIn} LoggedIn={LoggedIn}/>
            }
          />
        </Routes>
        </ThemeProvider>
      </React.Fragment>
    );
  }
 // deviceGlobalSearch - added
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Appbar appbarLinks={cookies.get("pagesMeta")} />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Landing
                pagesLinks={cookies.get("navigationCard")}
                routeNotFound={false}
              />
            }
          />
          <Route path="/specificBattery" element={<SpecificBattery />} />
          <Route path="/AllBatteries" element={<AllBatteries />} />
          <Route path="/_Reports/DataQuality" element={<DataQuality />} />
          <Route path="/_Reports/AlertAnalytics" element={<AlertAnalytics />} />



          {/* eslint-disable-next-line array-callback-return */}
          {cookies.get("pagesMeta").map((item, index) => {
            if (cookies.get("pages").includes(item["mykey"])) {
              if (
                item["queryParam"] &&
                Object.keys(item["queryParam"]).length !== 0
              ) {
                const queryParam = Object.entries(item["queryParam"]);
                const path =
                  item["to"] + "/" + queryParam[0][0] + "=" + queryParam[0][1];
                const Component = Components[item["mykey"]];
                return (
                  <Route
                    path={path}
                    exact
                    strict
                    element={<Component batteryType={queryParam[0][1]} />}
                    key={index}
                  />
                );
              } else {
                const path = item["to"];
                const Component = Components[item["mykey"]];
                return (
                  <Route
                    path={path}
                    exact
                    element={<Component />}
                    key={index}
                  />
                );
              }
            }
          })}
          <Route
            path="*"
            element={
              <Landing
                pagesLinks={cookies.get("navigationCard")}
                routeNotFound={true}
              />
            }
          />
        </Routes>
        {isSessionExpired && (
          <SessionExpired isSessionExpired={isSessionExpired} />
        )}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
