import React from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

function NoDataChart({ text }) {
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          position: "absolute",
          left: "45%",
          top: "42%",
        }}
      >
        <div
          style={{
            backgroundColor: "#ececec",
            padding: "10px",
            borderRadius: "20px",
          }}
        >
          <Typography variant="textSubtitle">{text}</Typography>
        </div>
      </div>
      <Chart
        options={{
          chart: {
            type: "bar",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: [ThemeProperties.grey],
          plotOptions: {
            bar: {
              distributed: true, // this line is mandatory
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"],
              },
              // offsetX: 100
            },
          },
          dataLabels: {
            enabled: true,
            distributed: true,
            offsetX: 30,
            style: {
              fontSize: ThemeProperties.c_axis_labels,
              fontWeight: ThemeProperties.c_axis_labels_weight,
              colors: [ThemeProperties.c_axis_labels_color],
              fontFamily: "Roboto",
            },
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: [""],
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: true,
              datetimeUTC: false,
              rotateAlways: false,
              // align: "right",
              style: {
                colors: [ThemeProperties.c_axis_labels_color],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },

              offsetY: 0,
            },
          },
          legend: {
            show: false,
          },
        }}
        series={[
          {
            name: "Count",
            data: [null],
          },
        ]}
        type="bar"
        height="100%"
        width="100%"
      />
    </div>
  );
}

export default NoDataChart;
