import React, { useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";

import { OuterBox, InnerBox } from "../../Components/CustomBox";
import { PaperWithBottomRadius } from "../../Components/CustomPaper";

import BatteryHealthTabs from "../../Static/Data/BatteryHealth/BatteryHealthTabs";
import BasicTabs from "../../Features/Tabs/BasicTabs";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";
import GenericSelectTest from "../../Features/Menu/GenericSelectKeyValue";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";

import Filters from "../../Static/Data/_Common/Filters";

import {
  SetBatteryHealthEqCycle,
  SetBatteryHealthSOH,
  SetBatteryHealthCell,
  SetBatteryHealthVolt,
  SetBatteryHealthBusiness,
  SetBatteryHealthIOT,
  SetBatteryHealthPack,
  SetBatteryHealthRUL,
  SetBatteryHealthBatteryPartnerSelect,
  SetBatteryHealthBatteryID,
  SetBatteryHealthDeviceID,
  SetBatteryHealthPartner,
} from "../../Actions/BatteryHealth";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Events from "../../Analytics/Events";
import LandingOverview from "../../PagesContent/BatteryHealth/Overview/LandingOverview";
import {
  decryptTheParams,
  encryptTheParams,
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import BatteryOverview from "../../PagesContent/BatteryHealth/Overview/BatteryOverview";
import { notification } from "antd";
import { getConnectedBatteries } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import "../../Features/Toast/NewNotification.css";
import { MapNonClickablekpis } from "../../Static/Data/Map/MapKPIS";
const Context = React.createContext({
  name: "Default",
});

function BatteryHealth(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [tabvalue, setTabValue, refTabValue] = useState(0);

  const toggleTable = useSelector(
    (state) => state.BatteryHealthToggleTable.value
  );

  const [resetButton, setResetButton] = useState(true);

  const select = useSelector(
    (state) => state.BatteryHealthBatteryPartnerSelect.value
  );
  const batteryID = useSelector((state) => state.BatteryHealthBatteryID.value);
  const deviceID = useSelector((state) => state.BatteryHealthDeviceID.value);
  const partner = useSelector((state) => state.BatteryHealthPartner.value);

  const eqCycle = useSelector((state) => state.BatteryHealthEqCycle.value);
  const sohLevel = useSelector((state) => state.BatteryHealthSOH.value);
  const cell = useSelector((state) => state.BatteryHealthCell.value);
  const volt = useSelector((state) => state.BatteryHealthVolt.value);
  const business = useSelector((state) => state.BatteryHealthBusiness.value);
  const iotVendor = useSelector((state) => state.BatteryHealthIOT.value);
  const packVendor = useSelector((state) => state.BatteryHealthPack.value);
  const rul = useSelector((state)=>state.BatteryHealthRUL.value)

  const [connectedBatteries, setConnectedBatteries] = useState(0);

  const filters = {
    "Eq. Cycle": eqCycle,
    "SOH Level": sohLevel,
    Cell: cell,
    Business: business,
    Volt: volt,
    IOT: iotVendor,
    Pack: packVendor,
    RUL: rul,
    Select: select,
  };

  function ChangeTheTab(newValue) {
    setTabValue(newValue);
  }

  function resetTheFilters() {
    dispatch(SetBatteryHealthEqCycle(""));
    dispatch(SetBatteryHealthSOH(""));
    dispatch(SetBatteryHealthCell(""));
    dispatch(SetBatteryHealthVolt(""));
    dispatch(SetBatteryHealthBusiness(""));
    dispatch(SetBatteryHealthIOT(""));
    dispatch(SetBatteryHealthPack(""));
    dispatch(SetBatteryHealthRUL(""))
    resetTheFilterValues(props.pagesContent.filteredArray, navigate);

    Events("Clicked Battery Health Reset Filter");
  }

  function showResetButton() {
    if (
      eqCycle !== "" ||
      cell !== "" ||
      volt !== "" ||
      business !== "" ||
      iotVendor !== "" ||
      rul!=="" ||
      packVendor !== ""
    ) {
      setResetButton(true);
    } else {
      let temp;
      const queryParams = decryptTheParams();

      setResetButton(false);
      if (batteryID !== "")
        openNotification("bottomLeft", "Cleared filters if selected");
      else if (deviceID !== "")
        openNotification("bottomLeft", "Cleared filters if selected");
      else if (partner !== "")
        openNotification("bottomLeft", "Cleared filters if selected");
      else
        openNotification(
          "bottomLeft",
          "All filters are removed. Page might become heavy to function. Consider selecting some relevant filters for smooth experience"
        );
      temp = {
        ...queryParams,
        SOH: "",
        IOT: "",
        Pack: "",
        RUL:""
      };
      encryptTheParams(temp, navigate);
    }
  }

  function handleSetSelect(name, value) {
    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      batteryPartnerSelect: value,
      batteryID: "",
      deviceID: "",
      partner: "",
      IOT: "Wynncom",
    };
    dispatch(SetBatteryHealthBatteryPartnerSelect(value));
    dispatch(SetBatteryHealthIOT("Wynncom"));
    dispatch(SetBatteryHealthBatteryID(""));
    dispatch(SetBatteryHealthDeviceID(""));
    dispatch(SetBatteryHealthPartner(""));

    if (batteryID !== "" || partner !== "" || deviceID !== "")
      openNotification("bottomLeft", `Setting IOT filter to Wynncom`);

    encryptTheParams(temp, navigate);
  }

  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);

    if (filterName === Filters["Eq. Cycle"]) {
      dispatch(SetBatteryHealthEqCycle(value));
    } else if (filterName === Filters["SOH Level"]) {
      dispatch(SetBatteryHealthSOH(""));
    } else if (filterName === Filters.Cell) {
      dispatch(SetBatteryHealthCell(value));
    } else if (filterName === Filters.Volt) {
      dispatch(SetBatteryHealthVolt(value));
    } else if (filterName === Filters.Business) {
      dispatch(SetBatteryHealthBusiness(value));
    } else if (filterName === "IOT") {
      dispatch(SetBatteryHealthIOT(value));
    } else if (filterName === "Pack") {
      dispatch(SetBatteryHealthPack(value));
    } else if (filterName === "RUL") {
      dispatch(SetBatteryHealthRUL(value));
    }

    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      SOH: "",
    };
    encryptTheParams(temp, navigate);
  }

  function FilterBox() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <GenericSelectRedux
            filter={{
              itemName: "Select",
              items: ["Battery ID", "Device ID", "Occupant"],
            }}
            handleSetFilter={handleSetSelect}
            selected={filters.Select}
            maxWidth={"max-content"}
            showClearButton={false}
            id="batteryState"
          />
          <AutocompleteDashboard
            width={300}
            resetTheFilters={resetTheFilters}
            tabValue={0}
            page="BatteryHealth"
            openNotification={openNotification}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {resetButton && (
            <Button
              sx={{ fontSize: "12px", height: "30px", mt: 0, mr: 0.5 }}
              onClick={() => {
                Events("clicked reset button fleet battery health");
                resetTheFilters();
                // openNotification("bottomLeft","All filters are removed. Page might become heavy to function. Consider selecting some relevant filters for smooth experience")
              }}
            >
              Reset
            </Button>
          )}
          {props.pagesContent.filtersWithData.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  pointerEvents:
                    batteryID !== "" || deviceID !== "" ? "none" : "auto",
                  opacity: batteryID !== "" || deviceID !== "" ? 0.5 : 1,
                }}
              >
                <GenericSelectTest
                  filter={item}
                  handleSetFilter={handleSetFilter}
                  selected={filters[item["itemName"]]} // All,48V,60V
                  maxWidth={"100px"}
                  showClearButton={true}
                  id="overflow-text-1"
                  pageName={"Battery Health"}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  function SwitchTheTab(tabvalue) {
    switch (tabvalue) {
      case 0:
        return (
          <>
            {!batteryID && !deviceID ? (
              <LandingOverview
                toggleTable={toggleTable}
                filtersWithData={
                  props.pagesContent.filtersWithData.filter((obj) => {
                    return obj.itemName === "Volt";
                  })[0]
                }
                eqCycle={eqCycle}
                sohLevel={sohLevel}
                cell={cell}
                volt={volt}
                partner={partner}
                business={business}
                iotVendor={iotVendor}
                rul={rul}
                packVendor={packVendor}
                showResetButton={showResetButton}
              />
            ) : (
              <>
                {deviceID ? (
                  <BatteryOverview batteryID={deviceID} searched={"Device"} />
                ) : (
                  <BatteryOverview batteryID={batteryID} searched={"Battery"} />
                )}
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  }
  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    showResetButton();
    SwitchTheTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batteryID, deviceID, partner, iotVendor, packVendor, rul]);

  return (
    <OuterBox>
      <InnerBox>
        <Context.Provider value={contextValue}>
          {contextHolder}
          <Box sx={{ mt: 0.5 }}></Box>
          <PageHeaderKPI
            name={"Battery Health Assessment"}
            nonClickableKpis={MapNonClickablekpis}
            connectedBatteries={connectedBatteries}
          />
          <Box sx={{ mt: -1 }}></Box>
          <BasicTabs
            ChangeTheTab={ChangeTheTab}
            tabs={BatteryHealthTabs}
            tabValue={refTabValue}
          />
          <BasicTabPanel value={tabvalue} index={tabvalue} key={tabvalue}>
            <PaperWithBottomRadius
              elevation={0}
              sx={{ p: 1, border: "1px solid #ececec" }}
            >
              {FilterBox()}
              <hr style={{ borderTop: "1px solid #ececec" }}></hr>
              <br></br>
              <>{SwitchTheTab(tabvalue)}</>
            </PaperWithBottomRadius>
          </BasicTabPanel>
        </Context.Provider>
      </InnerBox>
    </OuterBox>
  );
}

export default BatteryHealth;
