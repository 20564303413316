import qs from "qs";
import { GetData } from "./Functions/SettingData";

async function getBatteryHealth1(
  volt,
  range,
  batteryType,
  partner,
  eqCycle,
  cellType,
  iotVendor,
  packVendor,
  rul
) {
  let newRangeStart =
    range === ""
      ? 0
      : range === "SOH >98%"
      ? 99
      : range === "SOH 95% to 98%"
      ? 95
      : range === "SOH 90% to 95%"
      ? 90
      : range === "SOH 85% to 90%"
      ? 85
      : range === "SOH 80% to 85%"
      ? 80
      : range === "SOH <80%"
      ? 0
      : 0;

  let newRangeEnd =
    range === ""
      ? ""
      : range === "SOH >98%"
      ? ""
      : range === "SOH 95% to 98%"
      ? 98
      : range === "SOH 90% to 95%"
      ? 95
      : range === "SOH 85% to 90%"
      ? 90
      : range === "SOH 80% to 85%"
      ? 85
      : range === "SOH <80%"
      ? 80
      : "";
   let rulRange = rul.split(":");

  var rawJson = {
    voltConf: volt,
    rangeStart: newRangeStart,
    rangeEnd: newRangeEnd,
    batteryType: batteryType,
    partner: partner === "" ? [] : [partner],
    location: "",
    cellType: cellType,
    cycleRangeStart: "",
    cycleRangeEnd: "",
    iotVendor: iotVendor,
    batteryVendor: packVendor,
    rulRangeStart: rulRange[0],
    rulRangeEnd: rulRange[1] === "-1" ? ""  : rulRange[1]
  };

  var config = {
    method: "get",
    url: "/api/fleet_battery_health",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getBatteryHealthDistribution(
  volt,
  range,
  batteryType,
  partner,
  eqCycle,
  cellType,
  iotVendor,
  packVendor,
  rul
) {
  let rulRange = rul.split(":");

  var rawJson = {
    voltConf: volt,
    rangeStart: "",
    rangeEnd: "",
    batteryType: batteryType,
    partner: partner === "" ? [] : partner,
    location: "",
    cellType: cellType,
    cycleRangeStart: "",
    cycleRangeEnd: "",
    iotVendor: iotVendor,
    batteryVendor: packVendor,
    rulRangeStart: rulRange[0],
    rulRangeEnd: rulRange[1] === "-1" ? ""  : rulRange[1]
  };

  var config = {
    method: "get",
    url: "/api/fleet_battery_health_group",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryHealthBatteryPartner(columnName, search, limit) {
  let newColumnName =
    columnName === "Battery ID"
      ? "assetID"
      : columnName === "Device ID"
      ? "batteryID"
      : "partner";

  var rawJson = {
    columnName: newColumnName,
    columnSearch: search,
    columnLimit: limit,
  };

  var config = {
    method: "get",
    url: "/api/filter_health_dashboard",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

export {
  getBatteryHealth1,
  getBatteryHealthDistribution,
  getBatteryHealthBatteryPartner,
};
