import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { BasicSelect } from "../../Components/CustomSelect";
import { Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Events from "../../Analytics/Events";
import { DateDiffInDays } from "../../Helper/DatePicker/DateFormatters";
export default function GenericSelectKeyValue(props) {
  const mynewColor =
    props.selected === "" || props.selected === "Last 7 Days"
      ? "white"
      : ThemeProperties.purpleSelected;

  const dateDiff =
    props.date?.[0] && props.date?.[1]
      ? DateDiffInDays(props.date[0], props.date[1])
      : "Invalid";
  // const isDisabled =

  // const [select, setSelect] = React.useState(props.filter.items.indexOf(props.selected));
  const ChangeTheSelect = (event) => {
    // console.log(event)
    // console.log(props.filter.items[event.target.value].value)
    // setSelect(event.target.value);
    props.handleSetFilter(
      props.filter.itemName,
      props.filter.items[event.target.value].value
    );
    // props.setFilter(props.filter.items[event.target.value]);
    Events(
      "clicked " + props.pageName + " " + props.filter.itemName + " filter"
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid rgb(189,189,189)",
          borderRadius: "4px",
          width: props.maxWidth,
          mr: 2,
          mt: 1,
          mb: 1,
          height: "35px",
          bgcolor: mynewColor,
          maxWidth: props.maxWidth,
        }}
      >
        <FormControl size="small" variant="outlined">
          <InputLabel id="demo-select-small" sx={{ maxWidth: "100%" }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 500,
                fontFamily: "Roboto",
                mt: 0.4,
                pr: 0.5,
                pl: 0.5,
                bgcolor:
                  props.selected === "" || props.selected === "Last 7 Days"
                    ? "white"
                    : ThemeProperties.backgroundPurple,
              }}
            >
              {props.filter.itemName}
            </Typography>
          </InputLabel>
          {/* {console.log(
            "the value selected in BasicSelect",
            props.filter.items,//["All","48V","60V"]
                                // [{key:"All",value:""},{key:"48V",value:48},{key:"60V",value:60}]

            props.selected,//All,48V
            // props.filter.items.indexOf(props.selected)//1,
            props.filter.items.map((o)=>o.value).indexOf(props.selected)
          )} */}
          <BasicSelect
            variant="outlined"
            labelId="demo-select-small"
            MenuProps={{
              style: { zIndex: 10001 },
            }}
            // id="overflow-text"
            value={props.filter.items
              .map((o) => o.value)
              .indexOf(props.selected)}
            label="Age"
            name={props.filter.itemName}
            onChange={ChangeTheSelect}
            renderValue={(selected) => {
              return (
                <Typography
                  sx={{ fontWeight: 500, fontSize: "12px" }}
                  id={props.id}
                >
                  {props.filter.items[selected] !== undefined &&
                    props.filter.items[selected].key}
                </Typography>
              );
            }}
            myColor={mynewColor}
            sx={{
              "&:hover": {
                "&& fieldset": {
                  border: `1px solid ${mynewColor}`,
                },
              },
            }}
          >
            {props.filter.items.map((item, index) => (
              <MenuItem
                value={index}
                key={index}
                disabled={
                  props.filter.itemName === "Granularity" &&
                  ((item.key === "Daily" && (dateDiff > 60)) ||
                    (item.key === "Weekly" && dateDiff > 182))
                }
              >
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                >
                  {item.key}
                </Typography>
              </MenuItem>
            ))}
          </BasicSelect>
        </FormControl>
        {props.showClearButton && (
          <Box sx={{ ml: "-8px", mr: "1px" }}>
            <IconButton
              size="small"
              onClick={(event) => {
                if (props.selected !== "") {
                  props.filter.itemName === "Time"
                    ? props.handleSetFilter(
                        props.filter.itemName,
                        "Last 7 Days"
                      )
                    : props.handleSetFilter(props.filter.itemName, "");

                  if (event.target.name === undefined)
                    Events("Cancelled filter selection");
                  else Events("clicked " + event.target.name + " filter");
                }
              }}
            >
              <HighlightOffIcon sx={{ fontSize: "19px", mt: "-1px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
