import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItemText,
  Grid,
  Avatar,
} from "@mui/material";
import { StyledListItemButton } from "../../Components/CustomListItemButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Events from "../../Analytics/Events";
import {
  SetDashboardAlertTypeCode,
  SetDashboardAlertTypeIndex,
  SetDashboardTotal,
  SetDashboardPageNumber,
} from "../../Actions/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";
import AlertType from "../Menu/AlertType";

import CODE from "../../Static/Constants/StatusCodes";

export default function ListWithSelectionDashboard(props) {
  const dispatch = useDispatch();

  const tabvalue = useSelector((state) => state.DashboardTabValue.value);
  const alertTypeIndex = useSelector(
    (state) => state.DashboardAlertTypeIndex.value
  );

  const handleListItemClick = (alertCode, alertCount, index) => {
    dispatch(SetDashboardAlertTypeIndex(index));
    dispatch(SetDashboardAlertTypeCode(alertCode));
    dispatch(SetDashboardTotal(alertCount));
    dispatch(SetDashboardPageNumber(1));
    Events("clicked alert type");
  };

  const [list, setList] = useState({
    type: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  useEffect(() => {
    if (props.data && props.data.list.length > 0) {
      setList({
        type: props.data.list,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        type: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#FBFBFA",
        borderRadius: "8px",
        border: "1px solid #f0f0f0",
      }}
    >
      <Box
        sx={{
          height: "32px",
          lineHeight: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #ececec",
          textAlign: "center",
          p: 0.9,
        }}
      >
        <Typography variant="tableHeadBold">
          Alert Type &nbsp;&nbsp;- &nbsp;&nbsp;
        </Typography>
        <AlertType
          filter={{
            itemName: "Alert Type",
            items: [
              {
                key: "All",
                value: "",
              },
              {
                key: "Safety Risk",
                value: "Safety Risk",
              },
              {
                key: "Operational Issue",
                value: "Operational Issue",
              },

              {
                key: "Violations",
                value: "Violation",//this is equal to the itemName of props.alertType
              },
            ],
          }}
          handleSetFilter={props.handleSetFilter}
          selected={props.category} // All,48V,60V
          maxWidth={"200px"}
          showClearButton={true}
          id="overflow-text-1"
          pageName={"Dashboard"}
          alertType={props.alertType}
        />
      </Box>
      <Box
        sx={{
          height: 565,
          maxHeight: 565,
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "-10px",
        }}
      >
        {list.responseStatus.code === CODE.SUCCESS ? (
          <List
            sx={{
              pt: 0,
            }}
          >
            {list.type.map((item, index) => (
              <div style={{ position: "relative" }} key={index}>
                <StyledListItemButton
                  selected={alertTypeIndex === index}
                  onClick={(event) =>
                    handleListItemClick(
                      item["alertCode"],
                      item["alertCount"],
                      index
                    )
                  }
                  key={index}
                  sx={{ borderBottom: "1px solid #ececec" }}
                  myColor={
                    tabvalue === 0
                      ? ThemeProperties.red_100
                      : tabvalue === 1
                      ? ThemeProperties.blue_100
                      : tabvalue === 2
                      ? ThemeProperties.green_100
                      : tabvalue === 3
                      ? ThemeProperties.pink_100
                      : ThemeProperties.blueGrey_100
                  }
                >
                  <Grid container spacing={1} sx={{ alignItems: "center" }}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <Avatar
                        sx={{
                          bgcolor: item["color"],
                          border: `1px solid ${ThemeProperties.grey}`,
                          width: { xs: 12, sm: 16, md: 20, lg: 20 },
                          height: { xs: 12, sm: 16, md: 20, lg: 20 },
                        }}
                      >
                        <Typography
                          variant={"textDescription"}
                          sx={{ color: item["textColor"] }}
                        >
                          {item["type"].charAt(0)}
                        </Typography>
                      </Avatar>
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                      <ListItemText sx={{ ml: 1 }}>
                        <Typography variant="tableRow" >
                          {item["alertDescription"]}
                        </Typography>
                      </ListItemText>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant={"tableRow"}
                        sx={{
                          // ml: -1,
                          bgcolor: ThemeProperties.light_yellow,
                          p: 0.5,
                          borderRadius: "10px",
                          fontWeight:400
                        }}
                      >
                        {item["alertCount"]}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledListItemButton>
                <Box
                  sx={{
                    position: "absolute",
                    top: {
                      xs: 0,
                      sm: -13,
                      md: -10,
                      lg: -10,
                      xl: -10,
                    },
                    right: 40,
                  }}
                >
                  {alertTypeIndex === index && (
                    <ArrowRightIcon
                      sx={{
                        color:
                          tabvalue === 0
                            ? ThemeProperties.red_100
                            : tabvalue === 1
                            ? ThemeProperties.blue_100
                            : tabvalue === 2
                            ? ThemeProperties.green_100
                            : tabvalue === 3
                            ? ThemeProperties.pink_100
                            : ThemeProperties.blueGrey_100,
                        fontSize: "65px",
                        pl: 0,
                        position: "absolute",
                      }}
                    />
                  )}
                </Box>
              </div>
            ))}
          </List>
        ) : (
          <>
            {list.responseStatus.code === CODE.LOADING ? (
              <Loader />
            ) : (
              <NoData text="No alerts found" />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
