import React from "react";
import { Button } from "antd";
import { Dialog, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ThemeProperties } from "../../Theme/ThemeProperties";

function NoInternet() {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "50%",
          height: 150,
        },
      }}
    >
      <div
        style={{
          padding: "16px",
          display: "inline",
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        <InfoIcon sx={{ fontSize: "20px", color: ThemeProperties.purple }} />
        <br />
        <br />
        <Typography variant="paperHeader">
          No Internet. Please check your internet connection and try again.
        </Typography>
        <div
          className="dropDownBottom"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            type="primary"
            onClick={() => window.location.reload()}
            style={{ backgroundColor: ThemeProperties.purple }}
          >
            Retry
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default NoInternet;
