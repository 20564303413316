
import { formatDateNumeric } from "../Helper/DatePicker/DateFormatters"
import { newCity, newIOT, newPack, newSeverity, newStatus, newZone } from "../Static/Data/Dashboard/DashboardFilters"
const sessionExpired = 
{
  value:false
}

const refreshAPI = 
{
  value:false
}

const initialDashboardType = {
  value: "",
}

const inititalColumns = {
  value:[],
}

const inititalHiddenColumns = {
  value:[],
}

const initialIsBatteryIsPartnerIsNull = {
  value: null,
};
const initialBatteryPartnerSelect = {
  value: "Battery ID"
}
const initialBatteryID = {
  value: "",
};
const initialDeviceID = {
  value: "",
};
const initialPartner = {
  value: "",
};

const initialVolt = {
  value: "",
};

const initialCategory = {
  value: "",
};
const initialCity = {
  value: newCity
};
const initialStatus = {
  value: newStatus,
};
const initialSeverity = {
  value: newSeverity
};

const initialIot = {
  value: newIOT,
};

const initialPack = {
  value: newPack,
};

const initialZone = {
  value: newZone,
};

const initialTab = {
  value: 0,
};
const initialDashboardAlertTypeIndex = {
  value: 0,
};
const initialDashboardAlertTypeCode = {
  value: "",
};
const initialTotal = {
  value:0
}
const initialStartDate = {
    value:formatDateNumeric(new Date(new Date().setDate(new Date().getDate() - 45)))
}
const initialEndDate = {
    value:formatDateNumeric(new Date())
}
const initialDashboardAlertTypeList = {
    value:[]
}
const initialDashboardListOfBatteries = {
    value:[]
}

const initialUserState = {
    arr: [],
  };

  const initialDashboardExisting = {
    arr: [],
  };

  const initialDashboardColumnSort = {
    arr: [
      {
        sortBy: "alertTime",
      },
      {
        sortBy: "actionTime",
      },
      {
        sortBy: "statusChangedTime",
      },
      {
        sortBy: "statusChangedTime",
      },
      {
        sortBy: "statusChangedTime",
      },
    ],
    // orderBy: "asc"
  };

  const initialPageNumber = {
    value: 1,
  };

  const initialRowsPerPage = {
    value: 20,
  };

  const initialOpenSelectedBatteries = {
    value: false,
  };
  const initialSelectedBatteriesAction = {
    value: "",
  };

  const SessionExpired = (
  state = sessionExpired,
  action
) => {
  switch (action.type) {
    case "SessionExpired":
      return {
        value: action.isExpired,
      };
    default:
      return state;
  }
};

const RefreshAPICalled = (
  state = refreshAPI,
  action
) => {
  switch (action.type) {
    case "RefreshAPICalled":
      return {
        value: action.refresh,
      };
    default:
      return state;
  }
};

const DashboardType = (
  state = initialDashboardType,
  action
) => {
  switch (action.type) {
    case "DashboardType":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardColumns = (
  state = inititalColumns,
  action
) => {
  switch (action.type) {
    case "DashboardColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardHiddenColumns = (
  state = inititalHiddenColumns,
  action
) => {
  switch (action.type) {
    case "DashboardHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const IsBatteryIsPartnerIsNull = (
  state = initialIsBatteryIsPartnerIsNull,
  action
) => {
  switch (action.type) {
    case "IsBatteryIsPartnerIsNull":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "DashboardBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};


const DashboardBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "DashboardBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const DashboardDeviceID = (state = initialDeviceID, action) => {
  switch (action.type) {
    case "DashboardDeviceID":
      return {
        value: action.deviceID,
      };
    default:
      return state;
  }
};

const DashboardPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "DashboardPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const DashboardVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "DashboardVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};

const DashboardCategory = (state = initialCategory, action) => {
  switch (action.type) {
    case "DashboardCategory":
      return {
        value: action.category,
      };
    default:
      return state;
  }
};
const DashboardCity = (state = initialCity, action) => {
  switch (action.type) {
    case "DashboardCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const DashboardZone = (state = initialZone, action) => {
  switch (action.type) {
    case "DashboardZone":
      return {
        value: action.zone,
      };
    default:
      return state;
  }
};
const DashboardStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "DashboardStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};

const DashboardSeverity = (state = initialSeverity, action) => {
  switch (action.type) {
    case "DashboardSeverity":
      return {
        value: action.severity,
      };
    default:
      return state;
  }
};

const DashboardIOT = (state = initialIot, action) => {
  switch (action.type) {
    case "DashboardIOT":
      return {
        value: action.Iot,
      };
    default:
      return state;
  }
};

const DashboardPack = (state = initialPack, action) => {
  switch (action.type) {
    case "DashboardPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const DashboardTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "DashboardTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const DashboardAlertTypeIndex = (
  state = initialDashboardAlertTypeIndex,
  action
) => {
  switch (action.type) {
    case "DashboardAlertTypeIndex":
      return {
        value: action.index,
      };
    default:
      return state;
  }
};

const DashboardAlertTypeCode = (
  state = initialDashboardAlertTypeCode,
  action
) => {
  switch (action.type) {
    case "DashboardAlertTypeCode":
      return {
        value: action.code,
      };
    default:
      return state;
  }
};

const DashboardTotal = (state = initialTotal, action) => {
    switch (action.type) {
      case "DashboardTotal":
        return {
          value: action.total,
        };
      default:
        return state;
    }
  };
const DashboardStartDate  = (state = initialStartDate, action) => {
    switch (action.type) {
      case "DashboardStartDate":
        return {
          value: action.start,
        };
      default:
        return state;
    }
  };
  const DashboardEndDate  = (state = initialEndDate, action) => {
    switch (action.type) {
      case "DashboardEndDate":
        return {
          value: action.end,
        };
      default:
        return state;
    }
  };
  const DashboardAlertTypeList  = (state = initialDashboardAlertTypeList, action) => {
    switch (action.type) {
      case "DashboardAlertTypeList":
        return {
          value: action.list,
        };
      default:
        return state;
    }
  };
  const DashboardListOfBatteries  = (state = initialDashboardListOfBatteries, action) => {
    switch (action.type) {
      case "DashboardListOfBatteries":
        return {
          value: action.list,
        };
      default:
        return state;
    }
  };

    
  const DashboardStack = (state = initialUserState, action) => {
      
    switch (action.type) {
      case "DashboardStack":
        return {
          ...state,
          arr: action.batteries,
      };
      default:
        return state;
    }
  };

  const DashboardExistingBatteries = (state = initialDashboardExisting, action) => {
      
    switch (action.type) {
      case "DashboardExistingBatteries":
        return {
          ...state,
          arr: action.batteries,
      };
      default:
        return state;
    }
  };

   const DashboardColumnSort = (state = initialDashboardColumnSort, action) => {
      
    switch (action.type) {
      case "DashboardColumnSort":
        return {
          ...state,
          arr: action.sort,
      };
      default:
        return state;
    }
  };

  const DashboardPageNumber = (state = initialPageNumber, action) => {
    switch (action.type) {
      case "DashboardPageNumber":
        return {
          value: action.page,
        };
      default:
        return state;
    }
  };

  const DashboardRowsPerPage = (state = initialRowsPerPage, action) => {
    switch (action.type) {
      case "DashboardRowsPerPage":
        return {
          value: action.rows,
        };
      default:
        return state;
    }
  };

  const DashboardOpenAddSelectedBatteries = (state = initialOpenSelectedBatteries, action) => {
    switch (action.type) {
      case "DashboardOpenAddSelectedBatteries":
        return {
          value: action.addBatteries,
        };
      default:
        return state;
    }
  };

  const DashboardSelectedBatteriesAction = (state = initialSelectedBatteriesAction, action) => {
    switch (action.type) {
      case "DashboardSelectedBatteriesAction":
        return {
          value: action.performAction,
        };
      default:
        return state;
    }
  };
  

export {
  SessionExpired,
  RefreshAPICalled,
  DashboardType,
  DashboardColumns,
  DashboardHiddenColumns,
  IsBatteryIsPartnerIsNull,
  DashboardBatteryPartnerSelect,
  DashboardBatteryID,
  DashboardDeviceID,
  DashboardPartner,
  DashboardVolt,
  DashboardCategory,
  DashboardSeverity,
  DashboardIOT,
  DashboardPack,
  DashboardCity,
  DashboardZone,
  DashboardStatus,
  DashboardTabValue,
  DashboardAlertTypeIndex,
  DashboardAlertTypeCode,
  DashboardTotal,
  DashboardStartDate,
  DashboardEndDate,
  DashboardAlertTypeList,
  DashboardListOfBatteries,
  DashboardStack,
  DashboardExistingBatteries,
  DashboardColumnSort,
  DashboardPageNumber,
  DashboardRowsPerPage,
  DashboardOpenAddSelectedBatteries,
  DashboardSelectedBatteriesAction
};
