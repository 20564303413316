import React, { useState, useEffect } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { PaperHighlight } from "../../../Components/CustomPaper";
import Header from "../../../Features/Header/Header";
import { ThemeProperties } from "../../../Theme/ThemeProperties";

import ApexBasicMultiLine from "../../../Charts/Line/ApexBasicMultiLine";
import {
  getRealTimeStatus,
  getSOHDegradation,
  getSohRUL,
} from "../../../Api/SpecificBatteryApi";
import { SimpleBox } from "../../../Components/CustomBox";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { SetSessionExpired } from "../../../Actions";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Events from "../../../Analytics/Events";
import LaunchIcon from "@mui/icons-material/Launch";

import ScrollToTheElement from "../../../Helper/Operations/ScrollToTheElement";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import CODE from "../../../Static/Constants/StatusCodes";
import { formatDate } from "../../../Helper/DatePicker/DateFormatters";

function BatteryOverview(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();

  const [cycleAge, showCycleAge] = React.useState(false);

  const [sohDegradation, setSohDegradation] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [rul, setRUL] = useState({
    data: [],
    csvData: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [finalData, setFinalData] = useState({
    data: [],
    data2: [],
    csvData: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryInfo, setBatteryInfo] = useState({
    soh: 0,
    IOT: "",
    pack: "",
    batteryID: "",
    deviceID: "",
    responseStatus: { code: -1, msg: "" },
  });

  const typoStyle = {
    fontFamily: ThemeProperties.primaryFont,
    fontSize: "1.5rem",
    fontWeight: 300,
    color: "#000",
  };

  const handleToggleCycleAge = (event) => {
    Events("SB Toggled Show Eq. Cycle Degradation Curve");
    showCycleAge(event.target.checked);
  };

  function getMarkerColor(soh) {
    if (soh !== undefined)
      if (soh >= 98) {
        return "#00B159";
      } else if (soh < 98 && soh >= 95) {
        return "#7AC36A";
      } else if (soh < 95 && soh >= 90) {
        return "#B9E769";
      } else if (soh < 90 && soh >= 85) {
        return "#F2A54B";
      } else if (soh < 85 && soh >= 80) {
        return "#F9724C";
      } else {
        return "#D22B2B";
      }
  }

  // function returnColors(data) {
  //   if (sohDegradation.data.length > 0) {
  //     return getMarkerColor(
  //       sohDegradation.data[sohDegradation.data.length - 1]["y"]
  //     );
  //   } else {
  //     return "#fff";
  //   }
  // }

  useEffect(() => {
    ScrollToTheElement();

    setSohDegradation({
      data: [],

      responseStatus: {
        code: -1,
        msg: "",
      },
    });
    setRUL({
      data: [],
      csvData: [],
      responseStatus: { code: -1, msg: "" },
    });

    getSOHDegradation(props.searched, props.batteryID).then((res) => {
      if (res.responseStatus.code === 200) {
        let tempData = [],
          tempCSV = [],
          cart = [];

        const nullish = res.response.specificBatteryHealth.every(
          (item) => item.sohEst === null
        );
        if (!nullish) {
          res.response.specificBatteryHealth.forEach((item) => {
            tempCSV.push({
              "Eq. Cycle": item.eqCycleEst?.toFixed(2),
              "SOH (%)": item.sohEst,
              "Age (months)": item.age ? (item.age / 30).toFixed(2) : 0,
              Date: formatDate(new Date(item.date)),
              Forecasted: false,
            });
          });

          if (cycleAge) {
            res.response.specificBatteryHealth.forEach((item) => {
              item.eqCycleEst &&
                item.sohEst &&
                cart.push({
                  x: item.eqCycleEst,
                  y: item.sohEst,
                  secondaryX: item.age / 30,
                  date: item.date,
                });
            });
            cart = cart.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.x === value.x && t.y === value.y)
            );
            tempData = cart;
          } else {
            res.response.specificBatteryHealth.forEach((item) => {
              item.age &&
                item.sohEst &&
                cart.push({
                  x: item.age / 30,
                  y: item.sohEst,
                  secondaryX: item.eqCycleEst,
                  date: item.date,
                });
            });
            cart = cart.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.x === value.x && t.y === value.y)
            );
            tempData = cart;
          }
        } else {
          setSohDegradation({
            data: [],
            csvData: [
              {
                "Eq. Cycle": "",
                "SOH (%)": "",
                "Age (months)": "",
                Date: "",
              },
            ],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data Found",
            },
          });
        }

        setSohDegradation({
          data: tempData,
          csvData: tempCSV,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setSohDegradation({
          data: [],
          csvData: [
            {
              "Eq. Cycle": "",
              "SOH (%)": "",
              "Age (months)": "",
              Date: "",
            },
          ],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    getSohRUL(props.searched, props.batteryID).then((res) => {
      // console.log(res.response)
      if (res.responseStatus.code === 200) {
        let tempData = [],
          tempCSV = [],
          cart = [];

        res.response.specificBatteryRUL.forEach((item) => {
          tempCSV.push({
            "Eq. Cycle": item.eqCycleEst?.toFixed(2),
            "SOH (%)": item.sohForecast,
            "Age (months)": item.calendarAgeInDays
              ? (item.calendarAgeInDays / 30).toFixed(2)
              : 0,
            Date: "",
            Forecasted: true,
          });
        });

        if (cycleAge) {
          res.response.specificBatteryRUL.forEach((item) => {
            item.eqCycleEst &&
              item.sohForecast &&
              cart.push({
                x: item.eqCycleEst,
                y: item.sohForecast,
                secondaryX: item.calendarAgeInDays / 30,
              });
          });
          tempData = cart;
        } else {
          res.response.specificBatteryRUL.forEach((item) => {
            item.calendarAgeInDays &&
              item.sohForecast &&
              cart.push({
                x: item.calendarAgeInDays / 30,
                y: item.sohForecast,
                secondaryX: item.eqCycleEst,
              });
          });
          tempData = cart;
        }
        setRUL({
          data: tempData,
          csvData: tempCSV,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        setRUL({
          data: [],
          csvData: [
            {
              "Eq. Cycle": "",
              "SOH (%)": "",
              "Age (months)": "",
              Date: "",
            },
          ],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID, cycleAge]);

  useEffect(() => {
    setBatteryInfo({
      soh: "...",
      IOT: "...",
      pack: "...",
      batteryID: "...",
      deviceID: "...",
    });
    getRealTimeStatus(props.searched, props.batteryID).then((res) => {
      if (res.responseStatus.code === 200) {
        setBatteryInfo({
          soh: res.response.batteryData.soh
            ? res.response.batteryData.soh?.toFixed(1)
            : "Cannot be computed",
          IOT: res.response.batteryData.iotVendor
            ? res.response.batteryData.iotVendor
            : "Not found",
          pack: res.response.batteryData.batteryVendor
            ? res.response.batteryData.batteryVendor
            : "Not found",
          batteryID: res.response.batteryData.assetID
            ? res.response.batteryData.assetID
            : "Not found",
          deviceID: res.response.batteryData.batteryID
            ? res.response.batteryData.batteryID
            : "Not found",
        });

        let temp = {
          ...queryParams,
          deviceID: res.response.batteryData.batteryID,
          type: "Device",
        };
        encryptTheParams(temp, navigate);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setBatteryInfo({
          soh: "Not found",
          IOT: "Not found",
          pack: "Not found",
          batteryID: "Not found",
          deviceID: "Not found",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID]);

  useEffect(() => {
    setFinalData({
      data: [],
      data2: [],
      csvData: [],
      responseStatus: { code: -1, msg: "" },
    });
    if (
      sohDegradation.responseStatus.code === CODE.SUCCESS &&
      rul.responseStatus.code === CODE.SUCCESS
    ) {
      const newCSV = sohDegradation.csvData.concat(rul.csvData);
      setFinalData({
        data: sohDegradation.data,
        data2: rul.data,
        csvData: newCSV,
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    } else if (
      sohDegradation.responseStatus.code === CODE.SUCCESS &&
      rul.responseStatus.code !== CODE.SUCCESS  &&
      rul.responseStatus.code !== CODE.LOADING
    ) {
      console.log(rul.responseStatus)
      const newCSV = sohDegradation.csvData;
      setFinalData({
        data: sohDegradation.data,
        data2: [],
        csvData: newCSV,
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    } else if (
      sohDegradation.responseStatus.code === CODE.NODATA &&
      rul.responseStatus.code === CODE.NODATA
    ) {
      setFinalData({
        data: [],
        data2: [],
        csvData: [...sohDegradation.csvData, ...rul.csvData],
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    }
  }, [sohDegradation.responseStatus.code, rul.responseStatus.code]);

  useEffect(
    () => () => {
      setSohDegradation({
        data: [],

        responseStatus: {
          code: -1,
          msg: "",
        },
      });
    },
    []
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
          <PaperHighlight
            elevation={0}
            sx={{ height: "480px" }}
            onMouseEnter={() => Events("SB Hovered Alerts Table")}
          >
            <Header
              heading="Current SOH"
              description="Current SOH"
              showIButton={true}
              showThreeDots={false}
              id="Current_SOH"
              page="BatteryHealth"
            />
            <div
              className="section"
              id="Current_SOH"
              style={{ height: "400px", paddingTop: "50px" }}
            >
              {/* {sohDegradation.data.length > 0 && ( */}
              <>
                <div
                  style={{
                    display: "inline",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: ThemeProperties.primaryFont,
                        fontSize:
                          batteryInfo.soh === "Not found" ||
                          batteryInfo.soh === "Cannot be computed"
                            ? "1.5rem"
                            : "4.8rem",
                        fontWeight: 300,
                        color:
                          batteryInfo.soh === "Not found" ||
                          batteryInfo.soh === "Cannot be computed"
                            ? "#000"
                            : getMarkerColor(batteryInfo.soh),
                      }}
                    >
                      {batteryInfo.soh}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="tableHeadBold">SOH (%)</Typography>
                  </Box>
                </div>
              </>
              <Box sx={{ mt: 8 }}>
                <Grid container spacing={1} sx={{ ml: 5, mr: 10 }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="tableHeadBold">Battery ID</Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={typoStyle}>
                        {batteryInfo.batteryID}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="tableHeadBold">Device ID</Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={typoStyle}>
                      {batteryInfo.deviceID}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="tableHeadBold">
                      IOT Provider
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={typoStyle}>{batteryInfo.IOT}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="tableHeadBold">
                      Pack Manufacturer
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={typoStyle}>{batteryInfo.pack}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 8,
                  justifyContent: "center",
                }}
              >
                <Typography variant="textSubtitle">
                  Go to battery page
                </Typography>
                <LaunchIcon
                  sx={{
                    color: ThemeProperties.purple,
                    ml: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Events(
                      "Battery Health clicked go to specific battery page"
                    );
                    let temp;
                    temp = {
                      ...queryParams,
                      batteryID: batteryInfo.deviceID,
                      batterySearchedGlobal: "false",
                      tabValue: 0,
                      tabName: "Health",
                      prevLink: location.pathname + location.search,
                    };
                    encryptTheParams(temp, navigate, true);
                  }}
                />
              </Box>
            </div>
          </PaperHighlight>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
          <PaperHighlight
            elevation={0}
            sx={{ height: "480px" }}
            onMouseEnter={() => Events("SB Hovered Degradation Curve")}
          >
            <Header
              heading="Degradation Curve"
              description="Degradation of battery over its Age (months)/Eq. Cycle"
              showIButton={true}
              showThreeDots={false}
              id="Degradation_Curve"
              page="BatteryHealth"
            />
            <div className="section" id="Degradation_Curve">
              <FormGroup>
                <FormControlLabel
                  sx={{
                    ml: 0,
                    mr: 0,
                    width: "max-content",
                    opacity: finalData.responseStatus.code === -1 ? "0.5" : 1,
                    pointerEvents:
                      finalData.responseStatus.code === -1 ? "none" : "auto",
                  }}
                  control={
                    <Switch
                      checked={cycleAge}
                      onChange={handleToggleCycleAge}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="textSubtitle">
                      Show Eq. Cycle
                    </Typography>
                  }
                />
              </FormGroup>
              <SimpleBox sx={{ height: "480px" }}>
                <ApexBasicMultiLine
                  data={finalData}
                  setData={setFinalData}
                  cycleAge={cycleAge}
                  colors={[getMarkerColor(batteryInfo.soh)]}
                  xTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
                  yTitle={"SOH (%)"}
                  yTitleColor={getMarkerColor(batteryInfo.soh)}
                />
              </SimpleBox>
            </div>
          </PaperHighlight>
        </Grid>
      </Grid>
    </>
  );
}

export default BatteryOverview;
