import React from "react";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { KeyboardBackspace } from "@mui/icons-material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useNavigate } from "react-router-dom";

function DisplayHeading(name) {
  return (
    <Box sx={{ mt: 0.5 }}>
      <Typography variant="textSubtitleBold">{name}</Typography>
    </Box>
  );
}
function DisplayNonClickableKPI(kpi, index, props) {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        width: "100%",
        cursor: "default",
      }}
    >
      <Box sx={{ borderRight: index === 2 ? "none" : "none" }}>
        <Box sx={{ pr: index === 2 ? 0 : 1 }}>
          <Box>
            <Typography variant="textSubtitleSmall">{kpi}</Typography>
          </Box>
          <Box sx={{ mt: 0.2 }}>
            <Typography variant="textSubtitle">
              <b>{props.connectedBatteries}</b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function PageHeaderKPI(props) {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{display:"flex"}}>
      {props.showBackButton && <Box>
      <IconButton fontSize="small" 
      onClick={()=>navigate("/Reports")} 
      // sx={{ ml: -1 }}
      sx={{mt:-0.2,mr:1}}
      >
        <KeyboardBackspace
          fontSize="small"
          cursor="pointer"
          sx={{
            fontWeight: 200,
            color: ThemeProperties.purple,
            fontSize: "18px",
          }}
        />
      </IconButton>
      </Box>}
      {DisplayHeading(props.name)}
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: ThemeProperties.white,
          borderRadius: ThemeProperties.borderRadius,
          border: "1px solid #ececec",
          cursor: "default",
          p: 1,
          // mr: 2
        }}
      >
        {props.nonClickableKpis.map((item, index) => (
          <Box key={index} sx={{ p: 0.5 }}>
            {DisplayNonClickableKPI(item.name, index, props)}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PageHeaderKPI;

// function DisplayClickableKPI(kpi, props) {
//   // const navigate = useNavigate();
//   // const dispatch = useDispatch();

//   return (
//     <>
//       <KPIBox
//       >
//         <Box sx={{ display: "inline" }}>
//           <Box>
//             <Typography variant="textSubtitle">{kpi}</Typography>
//           </Box>
//           <Box sx={{ mt: 1 }}>
//             <Typography variant="textSubtitleBold">
//               {props.clickableValues === undefined ? 0 : props.clickableValues}
//             </Typography>
//           </Box>
//         </Box>
//       </KPIBox>
//     </>
//   );
// }
// <Grid container spacing={1} sx={{ alignItems: "center" }}>
// <Grid container spacing={2} sx={{ alignItems: "top" }}>
// <Grid item xs={12} sm={4} md={5} lg={7} xl={8}>
// </Grid>
// <Grid item xs={12} sm={8} md={7} lg={5} xl={4}>
// </Grid>
// </Grid>
// </Grid>
