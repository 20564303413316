const CODE = {
    LOADING : -1,
    SUCCESS : 200,
    NODATA : 404,
    UNAUTHORIZED : 401,
    FORBIDDEN: 403,

    //Original response from URL

    URL_NODATA : 404,
    URL_CREATED : 201


}

export default CODE