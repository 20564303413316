import Dashboard from '../../../Pages/Dashboard/Dashboard';
import AllBatteries from '../../../Pages/AllBatteries/AllBatteries';
import Diagnostics from '../../../Pages/Diagnostics/Diagnostics';
import SpecificBattery from '../../../Pages/SpecificBattery/SpecificBattery';
import BatteryHealth from '../../../Pages/BatteryHealth/BatteryHealth';
import Anomaly from "../../../Pages/Anomaly/Anomaly"
import Warranty from '../../../Pages/Warranty/Warranty';
import Map from '../../../Pages/Map/Map';
import Reports from "../../../Pages/Reports"

const Components = {
  'Alerts': Dashboard,
  'AllBatteries': AllBatteries,
  "Diagnostics":Diagnostics,
  "SpecificBattery":SpecificBattery,
  "BatteryHealth":BatteryHealth,
  "Anomaly":Anomaly,
  "Warranty":Warranty,
  "Reports":Reports,
  "Map":Map
}

export default Components;