import React, { useEffect, useState, useRef } from "react";
import BatteryHealthMain from "./BatteryHealthMain";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { Box, Typography, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";

import {
  SetBatteryHealthBatteryID,
  SetBatteryHealthDeviceID,
  SetBatteryHealthBatteryPartnerSelect,
  SetBatteryHealthIOT,
  SetBatteryHealthPack,
  SetBatteryHealthPartner,
  SetBatteryHealthSOH,
  SetBatteryHealthColumns,
  SetBatteryHealthRUL,
} from "../../Actions/BatteryHealth";

import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import { Button, Input, Space, Checkbox } from "antd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { socFilter,age,partner } from "../../Static/Data/_Common/TempFilters";
import { Tooltip } from "antd";
import copy from "copy-to-clipboard";
import Events from "../../Analytics/Events";

function BatteryHealth(props) {
  const dispatch = useDispatch();

  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });

  const searchInput = useRef(null);
  const tempSocFilter = [...socFilter];
  tempSocFilter.map((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });

  const tempPartnerFilter = [...partner];
tempPartnerFilter.map((item) => {
  if (item.isChecked) {
    item.isChecked = false;
  }
});

  function renderBatteryIDCopy(item, name) {
    return (
      <>
        {item && (
          <>
            <Typography variant="tableRow">{item}</Typography>
            <Tooltip title={"Click to copy"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  copy(item);
                  Events(`Copied ${name}`);
                }}
              >
                <ContentCopyIcon
                  sx={{
                    fontSize: "13px",
                    cursor: "pointer",
                    "&:hover": {
                      color: ThemeProperties.purple,
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  useEffect(() => {
    setTimeout(() => {
     
      const filters = [
        {
          itemName: "IOT",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Wynncom",
              value: "Wynncom",
            },
            {
              key: "Intellicar",
              value: "INTELLICAR",
            },
          ],
        },
        {
          itemName: "Pack",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Livguard",
              value: "Livguard",
            },
          ],
        },
        {
          itemName: "RUL",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "<6 months",
              value: "0:182",
            },
            {
              key: "6 to 12 months",
              value: "182:365",
            },
            {
              key: "12 to 18 months",
              value: "365:548",
            },
            {
              key: "> 18 months",
              value: "548:-1",
            },
          ],
        },
      ];
      let newFilterWithData = [];
      filters.map((item) => newFilterWithData.push(item["itemName"]));
      const filteredArray = newFilterWithData;

      const queryParams = decryptTheParams();

      // dispatch(SetBatteryHealthIOT("Wynncom"));
      for (var value of Object.keys(queryParams)) {
        if (value === "IOT") {
          dispatch(SetBatteryHealthIOT(queryParams[value]));
        } else if (value === "Pack") {
          dispatch(SetBatteryHealthPack(queryParams[value]));
        } else if (value === "RUL") {
          dispatch(SetBatteryHealthRUL(queryParams[value]));
        }

        if (value === "batteryPartnerSelect") {
          dispatch(SetBatteryHealthBatteryPartnerSelect(queryParams[value]));
        }
        if (value === "batteryID") {
          dispatch(SetBatteryHealthBatteryID(queryParams[value]));
        }
        if (value === "deviceID") {
          dispatch(SetBatteryHealthDeviceID(queryParams[value]));
        }
        if (value === "partner") {
          dispatch(SetBatteryHealthPartner(queryParams[value]));
        }
        if (value === "SOH") {
          dispatch(SetBatteryHealthSOH(queryParams[value]));
        }
      }

      setPagesContent({
        ...pagesContent,
        pageHeader: "Battery Health",
        filtersWithData: filters, //res.response.response.filter.filtersWithData
        filteredArray: filteredArray,
        batteryType: "",
        mounted: true,
      });

      const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
        tabvalue,
        close
      ) => {
        const tempColumns = [...columns];
        tempColumns[tabvalue]["columns"].forEach((col) => {
          if (col.dataIndex === dataIndex) {
            col.checkedOptions = [...selectedKeys];
          }
        });
        dispatch(SetBatteryHealthColumns([...tempColumns]));
        close();
      };
      const handleReset = (
        setSelectedKeys,
        confirm,
        filters,
        dataIndex,
        tabvalue,
        close
      ) => {
        const tempColumns = [...columns];
        filters.forEach((ele) => {
          ele.isChecked = false;
        });
        tempColumns[tabvalue]["columns"].forEach((col) => {
          if (col.dataIndex === dataIndex) {
            col.checkedOptions = [];
          }
        });
        setSelectedKeys([]);
        dispatch(SetBatteryHealthColumns([...tempColumns]));
        close();
      };
      const onChangeFilter = (
        ele,
        dataIndex,
        selectedKeys,
        setSelectedKeys,
        filters
      ) => {
        if (ele.isChecked) {
          filters.forEach((element) => {
            if (element == ele) {
              ele.isChecked = false;
            }
          });

          const arr =
            selectedKeys.length > 0
              ? selectedKeys.filter((item) => item != ele.value)
              : [];

          setSelectedKeys(arr);
        } else {
          filters.forEach((element) => {
            if (element == ele) {
              ele.isChecked = true;
            }
          });

          if (selectedKeys[0] == undefined) {
            setSelectedKeys([(selectedKeys[0] = "")]);
          }
          setSelectedKeys([...selectedKeys, ele.value]);
        }
        var isFilterEmpty = true;
        filters.forEach((ele) => {
          if (ele.isChecked == true) {
            isFilterEmpty = false;
          }
        });

        if (isFilterEmpty) {
          setSelectedKeys([]);
        }
      };

      const handleSelectAllFilters = (
        e,
        filters,
        selectedKeys,
        setSelectedKeys,
        dataIndex
      ) => {
        if (e.target.checked) {
          const arr = [];

          if (selectedKeys[0] == undefined) {
            setSelectedKeys([(selectedKeys[0] = "")]);
          }
          filters
            .filter((ele) => ele.value.includes(selectedKeys[0]))
            .forEach((ele, key) => {
              ele.isChecked = true;
              arr.push(ele.value);
            });

          setSelectedKeys([...selectedKeys, ...arr]);
        } else {
          filters.forEach((ele) => {
            ele.isChecked = false;
          });
          setSelectedKeys([]);
        }
      };

      let checker = (arr) => arr.every((v) => v === true);

      const getColumnSearchProps = (dataIndex, tabvalue) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
          filters,
        }) => (
          <div
            style={{
              padding: 8,
            }}
            // onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e?.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys([
                  (selectedKeys[0] = e.target.value),
                  ...selectedKeys.splice(1),
                ])
              }
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />

            {filters.filter((ele) =>
              ele.value.includes(
                selectedKeys[0] === undefined ? "" : selectedKeys[0]
              )
            ).length != 0 ? (
              <div>
                <Checkbox
                  onClick={(e) =>
                    handleSelectAllFilters(
                      e,
                      filters,
                      selectedKeys,
                      setSelectedKeys,
                      dataIndex
                    )
                  }
                  checked={
                    filters.filter((ele) =>
                      ele.value.includes(
                        selectedKeys[0] === undefined ? "" : selectedKeys[0]
                      )
                    ).length != 0
                      ? checker(
                          filters
                            .filter((ele) =>
                              ele.value.includes(
                                selectedKeys[0] === undefined
                                  ? ""
                                  : selectedKeys[0]
                              )
                            )
                            .map((ele) => ele.isChecked == true)
                        )
                      : false
                  }
                >
                  Select all Items
                </Checkbox>

                <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {filters
                    .filter((ele) =>
                      ele.value.includes(
                        selectedKeys[0] === undefined ? "" : selectedKeys[0]
                      )
                    )
                    .map((ele, key) => {
                      return (
                        <>
                          <div>
                            <Checkbox
                              key={key}
                              checked={ele.isChecked}
                              onChange={(e) =>
                                onChangeFilter(
                                  ele,
                                  dataIndex,
                                  selectedKeys,
                                  setSelectedKeys,
                                  filters
                                )
                              }
                            >
                              {ele.value}
                            </Checkbox>
                            <br></br>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="dropDownBottom">
                  <Button
                    onClick={() =>
                      handleReset(
                        setSelectedKeys,
                        confirm,
                        filters,
                        dataIndex,
                        tabvalue,
                        close
                      )
                    }
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    // disabled={selectedKeys.length>1?false:true}
                    onClick={() =>
                      handleSearch(
                        selectedKeys,
                        confirm,
                        dataIndex,
                        tabvalue,
                        close
                      )
                    }
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : (
              <p style={{ textAlign: "center" }}>No Options Found</p>
            )}
          </div>
        ),

        filterMultiple: true,

        onFilter: (value, record) => {
          if (value.length != 0) {
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },

        onFilterDropdownOpenChange: (visible) => {
          const tempColumns = [...columns];
          if (!visible) {
            tempColumns[tabvalue]["columns"].forEach((col) => {
              if (col.dataIndex === dataIndex) {
                col.filters.forEach((item) => {
                  if (col.checkedOptions.includes(item.text)) {
                    item.isChecked = true;
                  } else {
                    item.isChecked = false;
                  }
                });
              }
            });
            dispatch(SetBatteryHealthColumns([...tempColumns]));
          } else {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        filterResetToDefaultFilteredValue: true,
        filterIcon: (filtered) => (
          <FilterAltIcon
            style={{
              color:
                columns[tabvalue]["columns"].filter(
                  (element) => element.dataIndex === dataIndex
                )[0]["checkedOptions"].length > 0
                  ? ThemeProperties.purple
                  : undefined,
              paddingTop:"5px"
            }}
          />
        ),
        render: (text) => text,
      });

      const columns = [
        {
          name: "Active Columns",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 200,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 150,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "sohEst", "SOH (%)"),
              name: "SOH (%)",
              dataIndex: "sohEst",
              key: "sohEst",
        
              align: "center",
        
              sorter: (a, b) => a.sohEst - b.sohEst,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "descend",
              showSorterTooltip: false,
        
        
              filterSearch: false,
              // filters: tempSocFilter,
              // ...getColumnSearchProps("sohEst", 0),
              // checkedOptions: [],
              width: 160,
              uniqueKey: "sohEst",
              render: (sohEst) => (
                <Typography variant="tableRow">{Math.round(sohEst)}</Typography>
              ),
            },
        
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "age", "Age (months)"),
              name: "age",
              dataIndex: "age",
              key: "age",
        
              align: "center",
              
              sorter: (a, b) => a.age - b.age,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
        
              filterSearch: false,
              // filterMode: "tree",
              // filters: tempPartnerFilter,
              // ...getColumnSearchProps("age", 0),
              // checkedOptions: [],
        
              width: 180,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "age",
              render: (age) => (
                <Tooltip placement="top" title={age}>
                  <Typography variant="tableRow">{age?Math.round(age/30):""}</Typography>
                </Tooltip>
              ),
            },
            {
              // title: <Typography variant="tableHeadBold">Eq. Cycle</Typography>,
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "eqCycleEst", "Eq. Cycle"),
              name: "eqCycleEst",
              dataIndex: "eqCycleEst",
              key: "eqCycleEst",
              
              sorter: (a, b) => a.eqCycleEst - b.eqCycleEst,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
        
              checkedOptions: [],
              align:"center",
              width: 170,
              uniqueKey: "eqCycleEst",
              render: (eqCycleEst) => (
                <Typography variant="tableRow">
                  {Math.round(eqCycleEst)}
                </Typography>
              ),
            },
        
            {
              name: "",
            },
          ],
        }
      ];

      dispatch(SetBatteryHealthColumns(columns));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <BatteryHealthMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default BatteryHealth;
