
export const SetAlertAnalyticsIOT = (iot) =>
{
    return {
        type: "AlertAnalyticsIOT",
        iot: iot
    }
}
export const SetAlertAnalyticsPack = (pack) =>
{
    return {
        type: "AlertAnalyticsPack",
        pack: pack
    }
}
export const SetAlertAnalyticsStartDate = (start) =>
{
    return {
        type: "AlertAnalyticsStartDate",
        start: start
    }
}
export const SetAlertAnalyticsEndDate = (end) =>
{
    return {
        type: "AlertAnalyticsEndDate",
        end: end
    }
}
export const SetAlertAnalyticsGranularity = (granular) =>
{
    return {
        type: "AlertAnalyticsGranularity",
        granular: granular
    }
}