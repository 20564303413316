export const SetStackTableOrder = (order) =>
{
    return{
        type: "StackTableOrder",
        order:order
    }
}

export const SetStackTableSort = (sort) =>
{
    return{
        type: "StackTableSort",
        sort:sort
    }
}

export const SetStackTableRowsPerPage = (rows) =>
{
    return {
        type: "StackTableRowsPerPage",
        rows: rows
    }
}