import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography, Box, IconButton } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import {
  getBatteryAlertsList,
  getBatteryMetrics,
} from "../../Api/SpecificBatteryApi";
import { DateFormatString } from "../../Helper/DatePicker/DateFormatters";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch } from "react-redux";
import { SetSessionExpired } from "../../Actions";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Close } from "@mui/icons-material";

import moment from "moment";
import Loader from "../../Helper/Loaders/Loader";
import "./ApexMultiYAxis.css";
import "./charts.css";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";
import SlickCarouselPackMetrics from "../../Features/Carousel/SlickCarouselPackMetrics";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";

function ApexMultiYAxis(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = decryptTheParams();

  const [batteryPackMetrics, setBatteryPackMetrics] = useState({
    batteryStatus: [],
    soc: [],
    temperature: [],
    timestamp: [],
    voltage: [],
    current: [],
    latitude: [],
    longitude: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryAlertsList, setBatteryAlertsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  const [statusClicked, setStatusClicked] = useState({
    Voltage: true,
    Current: true,
    SOC: true,
    Temperature: true,
  });

  const [annotation, showAnnotation] = useState(true);
  const handleToggleAnno = (event) => {
    showAnnotation(event.target.checked);
  };

  const [modalData, setModalData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  const [divStyle, setDivStyle] = useState({
    top: 0,
    left: 0,
    height: "200px",
    width: "320px",
    padding: "10px",
  });

  const [mounted, setMounted] = useState(false);

  function handleStatusClicked(status) {
    if (
      statusClicked.Voltage &&
      statusClicked.Current &&
      statusClicked.SOC &&
      statusClicked.Temperature
    ) {
      if (status === "Voltage (V)") {
        setStatusClicked({
          Voltage: statusClicked.Voltage,
          Current: !statusClicked.Current,
          SOC: !statusClicked.SOC,
          Temperature: !statusClicked.Temperature,
        });
      } else if (status === "Current (A)") {
        setStatusClicked({
          Voltage: !statusClicked.Voltage,
          Current: statusClicked.Current,
          SOC: !statusClicked.SOC,
          Temperature: !statusClicked.Temperature,
        });
      } else if (status === "SOC (%)") {
        setStatusClicked({
          Voltage: !statusClicked.Voltage,
          Current: !statusClicked.Current,
          SOC: statusClicked.SOC,
          Temperature: !statusClicked.Temperature,
        });
      } else if (status === "Temperature (°C)") {
        setStatusClicked({
          Voltage: !statusClicked.Voltage,
          Current: !statusClicked.Current,
          SOC: !statusClicked.SOC,
          Temperature: statusClicked.Temperature,
        });
      }
    } else {
      if (status === "Voltage (V)") {
        if (
          statusClicked.Current ||
          statusClicked.SOC ||
          statusClicked.Temperature
        )
          setStatusClicked({
            Voltage: !statusClicked.Voltage,
            Current: statusClicked.Current,
            SOC: statusClicked.SOC,
            Temperature: statusClicked.Temperature,
          });
      } else if (status === "Current (A)") {
        if (
          statusClicked.Voltage ||
          statusClicked.SOC ||
          statusClicked.Temperature
        )
          setStatusClicked({
            Voltage: statusClicked.Voltage,
            Current: !statusClicked.Current,
            SOC: statusClicked.SOC,
            Temperature: statusClicked.Temperature,
          });
      } else if (status === "SOC (%)") {
        if (
          statusClicked.Voltage ||
          statusClicked.Current ||
          statusClicked.Temperature
        )
          setStatusClicked({
            Voltage: statusClicked.Voltage,
            Current: statusClicked.Current,
            SOC: !statusClicked.SOC,
            Temperature: statusClicked.Temperature,
          });
      } else if (status === "Temperature (°C)") {
        if (statusClicked.Voltage || statusClicked.Current || statusClicked.SOC)
          setStatusClicked({
            Voltage: statusClicked.Voltage,
            Current: statusClicked.Current,
            SOC: statusClicked.SOC,
            Temperature: !statusClicked.Temperature,
          });
      }
    }
  }

  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        {[
          {
            status: "Current (A)",
            color: ThemeProperties.Current,
            target: statusClicked.Current,
          },
          {
            status: "Voltage (V)",
            color: ThemeProperties.Voltage,
            target: statusClicked.Voltage,
          },
          {
            status: "SOC (%)",
            color: ThemeProperties.SOC,
            target: statusClicked.SOC,
          },
          {
            status: "Temperature (°C)",
            color: ThemeProperties.Max_Temperature,
            target: statusClicked.Temperature,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClicked(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  function handleClosePopup() {
    setPopupVisible(false);
    let temp = {
      ...queryParams,
      alertFirstSeenOn: "",
    };
    encryptTheParams(temp, navigate, true);
  }

  function mountTheChart(
    current,
    voltage,
    soc,
    temperature,
    batteryStatus,
    timestamp,
    latitude,
    longitude,
    newAnnot,
    newData
  ) {
    const handleShowModal = (
      x,
      y,
      xData,
      yData,
      borderColor,
      textData,
      alertType
    ) => {
      const obj = newAnnot.find((item) => new Date(item.x).getTime() === xData);

      setDivStyle({
        ...divStyle,
        top: "230px",
        left: x - 250,
      });
      setModalData(obj.data);
    };

    var my_date = DateFormatString(props.startDate);

    const unique = [
      ...new Map(newAnnot.map((item) => [item.x, item])).values(),
    ];

    const new_series = [
      {
        name: "Current (A)",
        data: [],
      },
      {
        name: "Voltage (V)",
        data: [],
      },
      {
        name: "SOC (%)",
        data: [],
      },
      {
        name: "Temperature (°C)",
        data: [],
      },
    ];

    if (statusClicked.Current) {
      new_series[0]["data"] = current;
    }
    if (statusClicked.Voltage) {
      new_series[1]["data"] = voltage;
    }
    if (statusClicked.SOC) {
      new_series[2]["data"] = soc;
    }
    if (statusClicked.Temperature) {
      new_series[3]["data"] = temperature;
    }

    setChartState({
      text: "",
      series: new_series,
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          type: "line",
          zoom: {
            enabled: true,
            type: "x",
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
            // autoSelected: false,
          },
          events: {
            beforeZoom: function (chart, { xaxis }) {
              const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
              let min = xaxis.min;
              let max = xaxis.max;

              if (max - min >= 86400000) {
                return {
                  xaxis: {
                    min: new Date(props.startDate).getTime() - 19800000,
                    max:
                      new Date(props.startDate).getTime() + 86400000 - 19800000,
                    labels: {
                      format: "h TT",
                    },
                  },
                };
              }

              if (max - min < MIN_ZOOM_RANGE) {
                let newmax = min + MIN_ZOOM_RANGE;

                return {
                  xaxis: {
                    min: min,
                    max: newmax,
                    labels: {
                      format: "h:mm TT",
                    },
                  },
                };
              } else {
                return {
                  xaxis: {
                    min: min,
                    max: max,
                    labels: {
                      format: "h:mm TT",
                    },
                  },
                };
              }
            },

            beforeResetZoom: function (chartContext, opts) {
              return {
                xaxis: {
                  min: new Date(props.startDate).getTime() - 19800000,
                  max:
                    new Date(props.startDate).getTime() + 86400000 - 19800000,
                  labels: {
                    format: "h TT",
                  },
                },
              };
            },
            click: function (event, chartContext, config) {
              if (event?.target?.parentNode?.id) {
                config.config.annotations.points.forEach((annot) => {
                  // Comparing id of svg element
                  // eslint-disable-next-line eqeqeq
                  if (annot?.customSVG?.id == event?.target?.parentNode?.id) {
                    handleShowModal(
                      event.x,
                      event.y,
                      annot.x,
                      annot.y,
                      annot.label.borderColor,
                      annot.customSVG.text
                    );
                    setPopupVisible(true);
                  }
                });
              } else {
                setPopupVisible(false);
              }
            },
          },
        },
        annotations: {
          xaxis: [
            ...unique.map((data) => {
              return {
                x: new Date(data.x).getTime(),
                borderColor: "#ececec",
                borderWidth: 2,
                width: "100px",

                label: {
                  borderColor: "#ececec",
                  borderWidth: 2,
                  offsetY: data.y,
                  orientation: "horizontal",
                  style: {
                    color: "#000",
                    fill: "#fff",
                    cssClass: "yAxisAnnot",
                    padding: {
                      left: 7,
                      right: 7,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  borderRadius: "50%",
                },
              };
            }),
          ],
          points: [
            ...unique.map((item, key) => {
              return {
                x: new Date(item.x).getTime(),
                y: item.y,

                marker: {
                  size: 8,
                  fillColor: "#fff",
                  strokeColor: item.borderColor,
                  // radius: 1,
                  cssClass: "apexcharts-custom-class",
                  cursor: "pointer",
                },

                label: {
                  borderColor: item.color,
                  offsetY: 20,
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                    cursor: "pointer",
                  },
                  size: 8,

                  borderRadius: "100%",

                  cssClass: "annotClass",
                  // text:"0",
                },

                customSVG: {
                  SVG: `<svg id=${key} width='18' height='18' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
                  
                  <circle cx="64" cy="64" r="50" fill="#fff" stroke="#000" stroke-width="2"/>
                  <text text-anchor="middle" x="50%" y="50%" dy=".35em" font-family="sans-serif" font-size="80px" fill="#000" >${item.text}</text>
                  
                  </svg>`,
                  cssClass: "svgClass",
                  offsetX: -9,
                  offsetY: -9,
                  pointValue: item.x,
                  id: key,
                  text: item.text,
                },
              };
            }),
          ],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1.7,
          curve: "smooth",
          // dashArray: [0, 2, 5, 8],
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        title: {
          text: undefined,
        },
        colors: [
          ThemeProperties.Current,
          ThemeProperties.Voltage,
          ThemeProperties.SOC,
          ThemeProperties.Max_Temperature,
        ],
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 4,
            sizeOffset: 1,
          },
        },
        yaxis: [
          {
            seriesName: "Current (A)",
            axisTicks: {
              show: true,
              offsetX: 3,
            },
            min: -60,
            max: 40,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: ThemeProperties.c_axis_labels_color,
              offsetX: -3,
            },
            labels: {
              style: {
                colors: [ThemeProperties.c_axis_labels_color],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -20,
            },
            title: {
              text: "Current (A)",
              offsetX: 3,
              style: {
                color: ThemeProperties.Current,
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Voltage (V)",
            axisTicks: {
              show: true,
            },
            min: 20000,
            max: 70000,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: ThemeProperties.c_axis_labels_color,
              // offsetX:-10
            },
            labels: {
              style: {
                colors: [ThemeProperties.c_axis_labels_color],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value / 1000);
              },
              offsetX: -20,
            },
            title: {
              text: "Voltage (V)",
              style: {
                color: ThemeProperties.Voltage,
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "SOC (%)",
            max: 100,
            min: 0,
            tickAmount: 5,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: ThemeProperties.c_axis_labels_color,
            },
            labels: {
              style: {
                colors: [ThemeProperties.c_axis_labels_color],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -30,
            },
            title: {
              text: "SOC (%)",
              offsetX: -10,
              style: {
                color: ThemeProperties.SOC,
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
          },
          {
            seriesName: "Temperature (°C)",
            max: 100,
            min: 0,
            tickAmount: 5,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: ThemeProperties.c_axis_labels_color,
            },
            labels: {
              style: {
                colors: [ThemeProperties.c_axis_labels_color],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -20,
            },
            title: {
              text: "Temperature (°C)",
              style: {
                color: ThemeProperties.Max_Temperature,
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
          },
        ],
        xaxis: {
          type: "datetime",
          tickAmount: 12,
          categories: timestamp,
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            rotateAlways: false,
            align: "center",
            style: {
              colors: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetX: 0,
            offsetY: 0,
            format: "h TT",
          },

          min: new Date(my_date).getTime(),
          max: new Date(my_date).getTime() + 86400000,
        },
        tooltip: {
          followCursor: true,

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = moment(
              w.globals.lastXAxis.categories[dataPointIndex]
            ).format("DD MMM'YY, hh:mm:ss A");

            let current, volt, soc, temp;
            let currentClass, voltClass, socClass, tempClass;
            let currentFlexClass, voltFlexClass, socFlexClass, tempFlexClass;
            let currentDotClass, voltDotClass, socDotClass, tempDotClass;
            let currentBrClass, voltBrClass, socBrClass, tempBrClass;

            console.log(series);

            if (series[0][dataPointIndex] === undefined) {
              current = 0;
              currentClass = "displaying_None";
              currentDotClass = "displaying_None";
              currentFlexClass = "displaying_None";
              currentBrClass = "displaying_Br_None";
            } else {
              current = series[0][dataPointIndex];
              currentClass = "text_container";
              currentDotClass = "dot_purple";
              currentFlexClass = "flex_center";
              currentBrClass = "displaying_Br";
            }
            if (series[1][dataPointIndex] === undefined) {
              volt = 0;
              voltClass = "displaying_None";
              voltDotClass = "displaying_None";
              voltFlexClass = "displaying_None";
              voltBrClass = "displaying_Br_None";
            } else {
              volt =
                series[1][dataPointIndex] === 0
                  ? 0
                  : (series[1][dataPointIndex] / 1000)?.toFixed(2);
              voltClass = "text_container";
              voltDotClass = "dot_red";
              voltFlexClass = "flex_center";
              voltBrClass = "displaying_Br";
            }
            if (series[2][dataPointIndex] === undefined) {
              soc = 0;
              socClass = "displaying_None";
              socDotClass = "displaying_None";
              socFlexClass = "displaying_None";
              socBrClass = "displaying_Br_None";
            } else {
              soc = series[2][dataPointIndex];
              socClass = "text_container";
              socDotClass = "dot_yellow";
              socFlexClass = "flex_center";
              socBrClass = "displaying_Br";
            }
            if (series[3][dataPointIndex] === undefined) {
              temp = 0;
              tempClass = "displaying_None";
              tempDotClass = "displaying_None";
              tempFlexClass = "displaying_None";
              tempBrClass = "displaying_Br_None";
            } else {
              temp = series[3][dataPointIndex];
              tempClass = "text_container";
              tempDotClass = "dot_green";
              tempFlexClass = "flex_center";
              tempBrClass = "displaying_Br";
            }

            return (
              '<div class="tooltip_container">' +
              '<span class="time_container">' +
              data +
              "</span>" +
              "</br>" +
              `<span class=${currentBrClass}></span>` +
              `<span class=${currentFlexClass}>` +
              `<span class=${currentDotClass}></span>` +
              `<span class=${currentClass}>&nbsp; Current <b>: ` +
              current?.toFixed(2) +
              "A</b></span>" +
              "</span>" +
              `<span class=${voltBrClass}></span>` +
              `<span class=${voltFlexClass}>` +
              `<span class=${voltDotClass}></span>` +
              `<span class=${voltClass}>&nbsp; Voltage <b>: ` +
              volt +
              "V</b></span>" +
              "</span>" +
              `<span class=${socBrClass}></span>` +
              `<span class=${socFlexClass}>` +
              `<span class=${socDotClass}></span>` +
              `<span class=${socClass}>&nbsp; SOC <b>: ` +
              soc +
              "%</b></span>" +
              "</span>" +
              `<span class=${tempBrClass}></span>` +
              `<span class=${tempFlexClass}>` +
              `<span class=${tempDotClass}></span>` +
              `<span class=${tempClass}>&nbsp; Temperature <b>: ` +
              temp +
              "°C</b></span>" +
              "</span>" +
              "<hr></hr>" +
              `<span class=${tempClass}>&nbsp; Lat/Long <b>: ` +
              latitude[dataPointIndex]?.toFixed(6) +
              " / " +
              latitude[dataPointIndex]?.toFixed(6) +
              "</b></span>" +
              "</div>"
            );
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        legend: {
          show: false,
        },
      },
    });

    setMounted(true);
  }

  useEffect(() => {
    let queryParams = decryptTheParams();

    let finalType, finalValue;
    if (queryParams["batteryID"] && queryParams["batteryID"] !== "undefined") {
      finalType = "Device";
      finalValue = queryParams["batteryID"];
    } else if (
      queryParams["batteryIDGlobal"] &&
      queryParams["batteryIDGlobal"] !== "undefined"
    ) {
      finalType = "Battery";
      finalValue = queryParams["batteryIDGlobal"];
    }

    setBatteryAlertsList({
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    setBatteryPackMetrics({
      batteryStatus: [],
      soc: [],
      temperature: [],
      timestamp: [],
      voltage: [],
      current: [],
      latitude: [],
      longitude: [],
      responseStatus: { code: -1, msg: "" },
    });
    props.SetCSVData([]);
    // console.log("ApexMultiYAxis", props.startDate);
    getBatteryMetrics([props.startDate, props.startDate], finalType, finalValue)
      .then((res) => {
        let csvData = [];
        if (res.responseStatus.code === 200) {
          let tempStatus = [],
            tempSoc = [],
            tempTemperature = [],
            tempTimestamp = [],
            tempVoltage = [],
            tempLat = [],
            tempLong = [],
            tempCurrent = [];

          res.response.metricsData.forEach((item) => {
            tempStatus.push(item.batteryStatus);
            tempSoc.push(item.soc);
            tempTemperature.push(item.temperature);
            tempTimestamp.push(convertUTCtoZone(item.batteryDateTime, true));
            tempVoltage.push(item.voltagePack);
            tempCurrent.push(item.currentPack);
            tempLat.push(item.latitude);
            tempLong.push(item.longitude);

            csvData.push({
              "Current (A)": item.currentPack,
              "Voltage (V)": (item.voltagePack / 1000)?.toFixed(2),
              "SOC (%)": item.soc,
              "Temperature (°C)": item.temperature,
              Latitude: item.latitude?.toFixed(6),
              Longitude: item.longitude?.toFixed(6),
              Status: item.batteryStatus,
              Timestamp: item.batteryDateTime
                ? convertUTCtoZone(item.batteryDateTime, true).replace(/,/g, "")
                : "",
            });
          });

          props.SetCSVData(csvData);

          setBatteryPackMetrics({
            batteryStatus: tempStatus,
            soc: tempSoc,
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            latitude: tempLat,
            longitude: tempLong,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          csvData.push({
            "Current (A)": "",
            "Voltage (V)": "",
            "SOC (%)": "",
            "Temperature (°C)": "",
            Latitude: "",
            Longitude: "",
            Status: "",
            Timestamp: "",
          });
          props.SetCSVData(csvData);
          setBatteryPackMetrics({
            batteryStatus: [],
            soc: [],
            temperature: [],
            timestamp: [],
            voltage: [],
            current: [],
            latitude: [],
            longitude: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      })
      .then(() => {
        getBatteryAlertsList(
          [props.startDate, props.startDate],
          finalType,
          finalValue
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            // console.log(res);
            setBatteryAlertsList({
              data: res.response.alertData,
              responseStatus: {
                code: res.responseStatus.code,
                msg: res.responseStatus.msg,
              },
            });
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryAlertsList({
              data: [],
              responseStatus: {
                code: res.responseStatus.code,
                msg: res.responseStatus.msg,
              },
            });
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDate]);

  useEffect(() => {
    setMounted(false);
    setPopupVisible(false);
    if (batteryPackMetrics.responseStatus.code === 200) {
      // console.log(props.startDate)
      var start = new Date(`${props.startDate} 00:00:00`);
      var end = new Date(`${props.startDate} 23:59:59`);

      var slices = {},
        buckets = [],
        finalBuckets = [];
      var count = 0;
      // var moment;

      const minutesBucket = 15 * 60 * 1000;

      while (end >= start) {
        start = new Date(start.getTime() + minutesBucket); // in minutes
        slices[count] = start;
        count++;
      }
      buckets = Object.values(slices);
      finalBuckets.push({
        start: new Date(start.getTime() - 86400000),
        end: new Date(start.getTime() + minutesBucket - 86400000),
      });
      for (var i = 0; i < buckets.length - 1; i = i + 1) {
        finalBuckets.push({
          start: buckets[i],
          end: buckets[i + 1],
          data: [],
        });
      }

      let data = batteryAlertsList.data;

      let newData = [];
      data.forEach((item) => {
        newData.push({
          ...item,
          insertionTime: moment(
            convertUTCtoZone(new Date(item.insertionTime))
          ).format("YYYY-MM-DD HH:mm"),
        });
      });

      finalBuckets.forEach((item) => {
        let finalBucketData = item.data ? item.data : [];
        for (var i = 0; i < newData.length; i++) {
          if (
            new Date(newData[i]["insertionTime"]).getTime() <=
              item.end.getTime() &&
            new Date(newData[i]["insertionTime"]).getTime() >
              item.start.getTime()
          ) {
            finalBucketData.push({
              alertDescription: newData[i]["alertDescription"],
              alertStatus: newData[i]["alertStatus"],
              statusChangedTime: newData[i]["statusChangedTime"],
              itemID: newData[i]["itemID"],
              severity: FindTheSeverity(newData[i]["severity"]),
              insertionTime: newData[i]["insertionTime"],
            });
          }
        }
        let filtered = finalBucketData?.sort(function (a, b) {
          return new Date(a.insertionTime) - new Date(b.insertionTime);
        });
        item.data = filtered;
      });

      const actualAnnotations = finalBuckets.filter(
        (item) => item.data?.length > 0
      );

      let nreAnnot = [];
      actualAnnotations.forEach((item) => {
        const isPresent = item.data.findIndex(
          (i) =>
            i.insertionTime ===
            moment(new Date(props.alertFirstSeenOn)).format("YYYY-MM-DD HH:mm")
        );

        nreAnnot.push({
          x: item.start,
          y: 44.5,
          color: ThemeProperties.black,
          text: item.data?.length,
          data: item.data,
          borderColor: isPresent === -1 ? "black" : "red",
        });
      });
      if (annotation)
        mountTheChart(
          batteryPackMetrics.current,
          batteryPackMetrics.voltage,
          batteryPackMetrics.soc,
          batteryPackMetrics.temperature,
          batteryPackMetrics.batteryStatus,
          batteryPackMetrics.timestamp,
          batteryPackMetrics.latitude,
          batteryAlertsList.longitude,
          nreAnnot,
          newData
        );
      else
        mountTheChart(
          batteryPackMetrics.current,
          batteryPackMetrics.voltage,
          batteryPackMetrics.soc,
          batteryPackMetrics.temperature,
          batteryPackMetrics.batteryStatus,
          batteryPackMetrics.timestamp,
          batteryPackMetrics.latitude,
          batteryAlertsList.longitude,
          [],
          []
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batteryPackMetrics.responseStatus.code,
    batteryAlertsList.data,
    statusClicked,
    annotation,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queryParams["alertFirstSeenOn"],
  ]);

  useEffect(
    () => () => {
      setMounted(false);
      setChartState({
        text: "",
        series: [],
        options: {},
      });
      setBatteryPackMetrics({
        batteryStatus: [],
        soc: [],
        temperature: [],
        timestamp: [],
        voltage: [],
        current: [],
        latitude: [],
        longitude: [],
        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {batteryPackMetrics.responseStatus.code === 200 && mounted ? (
        <div>
          <FormGroup>
            <FormControlLabel
              sx={{
                ml: -1,
                mb: -2,
                zIndex: 1,
                width: "min-content",
                bgcolor: "#fff",
              }}
              control={
                <Switch
                  checked={annotation}
                  onChange={handleToggleAnno}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              }
              label={
                <Typography variant="textSubtitle">Annotations</Typography>
              }
            />
          </FormGroup>
          <div style={{ height: "460px" }}>
            <Chart
              options={chartState.options}
              series={chartState.series}
              type="line"
              height="100%"
              width="100%"
              id="hideAlternateLabel"
            />
            <div className="topBox_packMetrics"></div>
            {popupVisible && (
              <div className="popUpContainer" style={divStyle}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div></div>
                  <IconButton onClick={() => handleClosePopup()}>
                    <Close className="closeIcon" />
                  </IconButton>
                </div>
                <div className="popupContent">
                  <Box>
                    <SlickCarouselPackMetrics modalData={modalData} />
                  </Box>
                </div>
              </div>
            )}
          </div>
          <Box sx={{ height: "60px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
                color: ThemeProperties.c_axis_title_color,
              }}
            >
              Time
            </Typography>
            {Legends()}
          </Box>
        </div>
      ) : (
        <div style={{ height: "520px" }}>
          {batteryPackMetrics.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <>
              {batteryPackMetrics.responseStatus.code === 1999 ||
                (batteryPackMetrics.responseStatus.code === 404 && (
                  <>
                    <div style={{ position: "relative", height: "460px" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "41.5%",
                          top: "42%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#ececec",
                            padding: "10px",
                            borderRadius: "20px",
                          }}
                        >
                          <Typography variant="textSubtitle">
                            No data found for selected date
                          </Typography>
                        </div>
                      </div>
                      <Chart
                        options={{
                          chart: {
                            type: "line",
                            zoom: {
                              enabled: false,
                            },
                            toolbar: {
                              show: false,
                              offsetY: -20,
                              tools: {
                                download: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: [],
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 0,
                            curve: "smooth",
                            // dashArray: [0, 2, 5, 8],
                          },
                          grid: {
                            padding: {
                              left: 0,
                              right: 0,
                            },
                          },
                          title: {
                            text: undefined,
                          },
                          colors: [
                            ThemeProperties.Current,
                            ThemeProperties.Voltage,
                            ThemeProperties.SOC,
                            ThemeProperties.Max_Temperature,
                          ],
                          markers: {
                            size: 0,
                          },
                          yaxis: [
                            {
                              seriesName: "Current (A)",
                              axisTicks: {
                                show: true,
                                offsetX: 3,
                              },
                              min: -60,
                              max: 40,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                offsetX: -3,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -20,
                              },
                              title: {
                                text: "Current (A)",
                                offsetX: 3,
                                style: {
                                  color: ThemeProperties.Current,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "Voltage (V)",
                              axisTicks: {
                                show: true,
                              },
                              min: 20000,
                              max: 70000,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                // offsetX:-10
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value / 1000);
                                },
                                offsetX: -20,
                              },
                              title: {
                                text: "Voltage (V)",
                                style: {
                                  color: ThemeProperties.Voltage,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "SOC (%)",
                              max: 100,
                              min: 0,
                              tickAmount: 5,
                              opposite: true,
                              axisTicks: {
                                show: true,
                              },
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -20,
                              },
                              title: {
                                text: "SOC (%)",
                                offsetX: -10,
                                style: {
                                  color: ThemeProperties.SOC,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                            },
                            {
                              seriesName: "Temperature (°C)",
                              max: 60,
                              min: 10,
                              tickAmount: 5,
                              opposite: true,
                              axisTicks: {
                                show: true,
                              },
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -20,
                              },
                              title: {
                                text: "Temperature (°C)",
                                style: {
                                  color: ThemeProperties.Max_Temperature,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                            },
                          ],
                          xaxis: {
                            categories: [
                              "12 AM",
                              "1 AM",
                              "2 AM",
                              "3 AM",
                              "4 AM",
                              "5 AM",
                              "6 AM",
                              "7 AM",
                              "8 AM",
                              "9 AM",
                              "10 AM",
                              "11 AM",
                              "12 PM",
                              "1 PM",
                              "2 PM",
                              "3 PM",
                              "4 PM",
                              "5 PM",
                              "6 PM",
                              "7 PM",
                              "8 PM",
                              "9 PM",
                              "10 PM",
                              "11 PM",
                              "12 AM",
                            ],

                            // tickAmount:10,

                            tooltip: {
                              enabled: false,
                            },
                            title: {
                              text: undefined,
                              // offsetX: 0,
                              // offsetY: 0,
                              // style: {
                              //   colors: [ThemeProperties.c_axis_title_color],
                              //   fontSize: ThemeProperties.c_axis_title,
                              //   fontFamily: "Roboto",
                              //   fontWeight: ThemeProperties.c_axis_title_weight,
                              // },
                            },
                            labels: {
                              show: true,
                              datetimeUTC: false,
                              rotateAlways: false,
                              align: "center",
                              style: {
                                colors: ThemeProperties.c_axis_labels_color,
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                              offsetX: 0,
                              offsetY: 0,
                              format: "h TT",
                            },

                            // min: new Date(my_date).getTime(),
                            // max: new Date(my_date).getTime()+86400000
                          },
                          tooltip: {
                            enabled: false,
                            enabledOnSeries: undefined,
                            shared: true,
                            followCursor: false,
                            intersect: false,
                            inverseOrder: false,
                            custom: undefined,
                            fillSeriesColor: false,
                            theme: false,
                            style: {
                              style: {
                                colors: [ThemeProperties.c_axis_labels_color],
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                            },
                            onDatasetHover: {
                              highlightDataSeries: false,
                            },
                            x: {
                              show: true,
                              format: "dd MMM yyyy hh:mm:ss TT",
                              formatter: undefined,
                              title: {
                                formatter: (seriesName) => seriesName,
                              },
                            },
                            y: [
                              {
                                seriesName: "Current (A)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Voltage (V)",
                                formatter: function (value) {
                                  return ": " + Math.round(value / 1000);
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "SOC (%)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Temperature (°C)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                            ],
                            z: {
                              formatter: undefined,
                              title: "Size: ",
                            },
                            marker: {
                              show: true,
                            },

                            fixed: {
                              enabled: false,
                              position: "topRight",
                              offsetX: 0,
                              offsetY: 0,
                            },
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                          legend: {
                            show: false,
                            // colors: [ThemeProperties.c_legend_labels_color],
                            // fontSize: ThemeProperties.c_legend_labels,
                            // fontFamily: "Roboto",
                            // fontWeight: ThemeProperties.c_legend_labels_weight,

                            // offsetY: 7,
                          },
                        }}
                        series={[
                          {
                            name: "Current (A)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Voltage (V)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "SOC (%)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Temperature (°C)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                        ]}
                        type="line"
                        height="100%"
                        width="100%"
                        id="hideAlternateLabel"
                      />
                    </div>
                    <Box sx={{ height: "60px" }}>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: ThemeProperties.c_axis_title,
                          fontFamily: "Roboto",
                          fontWeight: ThemeProperties.c_axis_title_weight,
                          color: ThemeProperties.c_axis_title_color,
                        }}
                      >
                        Time
                      </Typography>
                      {Legends()}
                    </Box>
                  </>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ApexMultiYAxis;
