import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  IconButton,
  Box,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedButton } from "../../Components/CustomButton";
import ToField from "../../Helper/MailToField/ToField";
import {
  getCartBatteries,
  sendPostTakeAction,
  postPopFromCart,
  getSelectAllBatteries,
  getCSVOfBatteries,
} from "../../Api/CartBatteriesApi";
import CartTable from "../Table/AntDesign/CartTable";

import { newColumns as columns } from "../../Static/Data/Appbar/BatteriesInStackAntd";
import Confirm from "../../Helper/Loaders/Confirm";
import SendingMail from "../../Helper/Loaders/SendingMail";
import Error from "../../Helper/Loaders/Error";
import { useDispatch, useSelector } from "react-redux";
import { SetIsDeleted, SetSessionExpired } from "../../Actions";
import { DashboardExistingBatteries } from "../../Actions/Dashboard";
import Events from "../../Analytics/Events";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";

function BatteriesInCart(props) {
  const dispatch = useDispatch();

  const [to, setTo] = useState([]);
  const [actual_to, setActualTo] = useState([]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const [helperText, setHelperText] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const rowsPerPage = useSelector((state) => state.StackTableRowsPerPage.value);
  const orderChangedTo = useSelector((state) => state.StackTableOrder.value);
  const sortChangedTo = useSelector((state) => state.StackTableSort.value);

  const [batteries, setBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [selectedBatteries, setSelectedBatteries] = useState([]);

  const [loader, setLoader] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(false);

  const SetBatteriesInCart = () => {
    setBatteries({
      batteries: [],
      responseStatus: { code: -1, msg: "" },
    });
    getCartBatteries(
      pageNumber,
      sortChangedTo,
      orderChangedTo,
      rowsPerPage
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        setMounted(true);
        setBatteries({
          batteries: res.response.alertList,
          total: res.response.total.total,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        setActualTo(res.response.mail);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setMounted(false);
        setBatteries({
          batteries: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
  };

  const handleClickDownLoadCSV = () => {
    let newLastPingedTime,
      newAlertFirst,
      newBatteries = [];

    getCSVOfBatteries(selectedBatteries).then((res) => {
      if (res.responseStatus.code === 200) {
        Events("Download CSV from Selected Batteries For Action");
        res.response.alertList.forEach((row, index) => {
          if ("lastPingedTime" in row)
            newLastPingedTime = row.lastPingedTime
              ? convertUTCtoZone(row.lastPingedTime).replace(/,/g, "")
              : "";
          if ("alertTime" in row)
            newAlertFirst = row.alertTime
              ? convertUTCtoZone(row.alertTime).replace(/,/g, "")
              : "";


          newBatteries.push({
            "Device ID": row.batteryID,
            "Battery ID": row.assetID,
            "Operating State":row.batteryFlag === 0 ?"Operational" : row.batteryFlag === 1 ?  "Out of Service" : row.batteryFlag === 2 ? "Under Maintenance": "Isolated",
            "SOC (%)": row.socBMS,
            Severity: FindTheSeverity(row.severity),
            "Alert Type": row.alertType,
            "Alert Status": row.alertStatus,
            "Alert Description": row.alertDescription,
            "Alert First Seen": newAlertFirst,
            "Last Pinged": newLastPingedTime,
            // "Battery Status": row.batteryStatus,
            "Occupant": row.partner,
            "Lat/Long": row.latitude?.toFixed(6) +"/"+ row.longitude?.toFixed(6),
            // "Action":row.action
          });
        });
        DownloadCSV(
          NameTheCSV("Selected_Batteries_For_Action", "Download CSV"),
          newBatteries
        );
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
  };
  const handleClickSendEmail = () => {
    if (to.length !== 0) {
      setLoader(true);
      sendPostTakeAction(to, cc, bcc, selectedBatteries).then((res) => {
        if (res.responseStatus.code === 200) {
          setConfirmation(true);
          setLoader(false);
          postPopFromCart(selectedBatteries).then((res) => {
            if (res.responseStatus.code === 200) {
              props.SetCountForCart();
              SetBatteriesInCart();
            } else {
              if (res.responseStatus.code === -2)
                dispatch(SetSessionExpired(true));
            }
          });
          // props.getListOfBatteries();
          // props.getALertType();
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setLoader(false);
          setError(true);
        }
      });
      Events("Mail sent from Selected Batteries For Action");
    } else {
      setHelperText(true);
    }
  };

  const handleClickRemove = () => {
    postPopFromCart(selectedBatteries).then((res) => {
      if (res.responseStatus.code === 200) {
        props.SetCountForCart();
        SetBatteriesInCart();
        setPageNumber(1);
        Events("Removed from Selected Batteries For Action");
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
    setSelectedBatteries([]);
    dispatch(SetIsDeleted("yes"));
  };

  const handleClickEmpty = () => {

    let itemIds = [];
    getSelectAllBatteries()
      .then((res) => {
        if (res.responseStatus.code === 200) {
          itemIds = res.response.alertList;
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          // setCartBatteries(0);
          itemIds = [];
        }
        Events("Emptied Selected Batteries For Action");
        dispatch(DashboardExistingBatteries([]));
        props.setCartBatteriesCount(0);
        return itemIds;
        // setMountedCartBatteries(true)
      })
      .then((tempIDs) =>
        postPopFromCart(tempIDs).then((res) => {
          if (res.responseStatus.code === 200) {
            // props.SetCountForCart("empty");
            SetBatteriesInCart();
            Events("Emptied the cart");
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));
          }
        })
      );

    setSelectedBatteries([]);
    dispatch(SetIsDeleted("yes"));
  };

  useEffect(() => {
    setBatteries({
      batteries: [],
      responseStatus: { code: -1, msg: "" },
    });
    // console.log(props.batteries);
    if (props.batteries.batteries.length > 0) {
      setBatteries(props.batteries);
    } else {
      setBatteries({
        batteries: [],
        responseStatus: props.batteries.responseStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteries.batteries]);

  useEffect(() => {
    SetBatteriesInCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, orderChangedTo, sortChangedTo, rowsPerPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      confirmation && props.handleCloseBatteryStackDialog();
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  function handleSelectedBatteries(batteries) {
    setSelectedBatteries(batteries);
  }

  return (
    <div>
      {!confirmation && !error ? (
        <Dialog
          onClose={props.handleCloseBatteryStackDialog}
          aria-labelledby="customized-dialog-title"
          open={props.openBatteryStackDialog}
          // anchorEl={props.anchorElBatteryStackDialog}
          // container={props.anchorElBatteryStackDialog.parentNode}
          fullWidth
          maxWidth="none"
          PaperProps={{
            sx: {
              width: "100%",
              height: "calc(100%-50px)",
              maxHeight: "none",
            },
          }}
        >
          <DialogContent>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="paperHeader">
                  Selected Batteries For Action
                </Typography>
                <IconButton
                  onClick={props.handleCloseBatteryStackDialog}
                  fontSize="small"
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </div>

              {!loader && !confirmation && !error ? (
                <>
                  <ToField
                    actual_to={actual_to}
                    to={to}
                    cc={cc}
                    bcc={[]}
                    helperText={helperText}
                    setTo={setTo}
                    setCC={setCC}
                    setBCC={setBCC}
                    setHelperText={setHelperText}
                  />
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <CartTable
                      mounted={mounted}
                      data={batteries}
                      columns={columns}
                      selection={true}
                      setPageNumber={setPageNumber}
                      selectedBatteries={selectedBatteries}
                      handleSelectedBatteries={handleSelectedBatteries}
                      SetCountForCart={props.SetCountForCart}
                      existingBatteries={[]}
                    />
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          onClick={() => handleClickRemove()}
                          disabled={selectedBatteries.length === 0}
                        >
                          <Typography
                            variant={
                              selectedBatteries.length === 0
                                ? "textSubtitleGrey"
                                : "textSubtitlePurple"
                            }
                          >
                            Remove
                          </Typography>
                        </OutlinedButton>
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          sx={{ ml: 2 }}
                          onClick={() => handleClickEmpty()}
                          disabled={
                            selectedBatteries.length === 0 &&
                            batteries.batteries.length === 0
                          }
                        >
                          <Typography
                            variant={
                              selectedBatteries.length === 0 &&
                              batteries.batteries.length === 0
                                ? "textSubtitleGrey"
                                : "textSubtitlePurple"
                            }
                          >
                            Clear
                          </Typography>
                        </OutlinedButton>
                      </Box>

                      <Box>
                        <OutlinedButton
                          variant="contained"
                          disabled={selectedBatteries.length === 0}
                          onClick={() => handleClickDownLoadCSV()}
                        >
                          <Typography variant="textSubtitleWhite">
                            Download CSV
                          </Typography>
                        </OutlinedButton>
                        <OutlinedButton
                          variant="contained"
                          sx={{ ml: 2 }}
                          onClick={() => handleClickSendEmail()}
                          disabled={selectedBatteries.length === 0}
                        >
                          <Typography variant="textSubtitleWhite">
                            Send Email
                          </Typography>
                        </OutlinedButton>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {!confirmation && !error && (
                    <div style={{ height: "636.75px" }}>
                      <SendingMail />
                    </div>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          onClose={props.handleCloseBatteryStackDialog}
          aria-labelledby="customized-dialog-title"
          open={props.openBatteryStackDialog}
        >
          <DialogContent>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="paperHeader">
                  {/* Selected Batteries For Action */}
                </Typography>
                <IconButton
                  onClick={props.handleCloseBatteryStackDialog}
                  fontSize="small"
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </div>
            </Box>
            {confirmation && (
              <div style={{ height: "227px",width: "500px" }}>
                <Confirm text={"Alerts are moved to Open Alerts"} />
              </div>
            )}
            {error && (
              <div style={{ height: "227px",width: "500px" }}>
                <Error />
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
export default BatteriesInCart;
