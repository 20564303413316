import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ThemeProperties } from "../../Theme/ThemeProperties";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import { Tooltip } from "antd";

function RealtimeKPI(props) {

  const findColor = (key, value) => {
    if ((key === "alertCount" || key === "errors") && value > 0)
      return ThemeProperties.red_400;
    else return ThemeProperties.black;
  };


  function KPIBoxSpecific(kpi, setDate, ChangeTheTab) {
    const [tooltipText, setTooltipText] = React.useState("");
    var minDate = new Date();
    minDate.setDate(minDate.getDate() - 30);

    return (
      <>
      <Tooltip placement={"left"} title={tooltipText} open={tooltipText && tooltipText.length > 11 ? true : false} >
          {/* {kpi.isActive ? ( */}
            <Box
              // onClick={() => {
              //   let temp;
              //   // props.setTabIndex(kpi.index);
              //   // props.changeTheDate(formatDateNumeric(new Date()), formatDateNumeric(new Date()));
              //   // ChangeTheTab(kpi.tabName);
              //   temp={
              //     ...queryParams,
              //     tabName: kpi.tabName,
              //     startDate: formatDateNumeric(new Date()),
              //     endDate: formatDateNumeric(new Date())
              //   }
              //   encryptTheParams(temp,props.navigate,true)
              //   queryParams && queryParams["startDate"] && formatDateNumeric(new Date(queryParams["startDate"]))!==formatDateNumeric(new Date()) && props.handleOpenToast("Date filter changed to today's date");
              //   Events("clicked Specific battery Realtime status");
              // }}
              onMouseEnter={() =>
                setTooltipText(
                  kpi.formatter === "date"
                    ? convertUTCtoZone(props.realtimeKPIValues[kpi.key], false)
                    : props.realtimeKPIValues[kpi.key]
                )
              }
              onMouseLeave={() => setTooltipText("")}
            >
              <Box sx={{ display: "inline" }}>
                <Box>
                  <Typography sx={{
                      fontFamily: ThemeProperties.primaryFont,
                      fontSize: "1.4rem",
                      fontWeight: 400,
                      color: ThemeProperties.mainTextColor,
                    }}
                    id="overflow-text-2"><b>{kpi.name}</b></Typography>
                </Box>
                <Box sx={{ mt: 0 }}>
                  <Typography
                    sx={{
                      fontFamily: ThemeProperties.primaryFont,
                      fontSize: "1.6rem",
                      fontWeight: 500,
                      color: findColor(
                        kpi.key,
                        props.realtimeKPIValues[kpi.key]
                      ),
                    }}
                    id="overflow-text-2"
                  >
                    {props.realtimeKPIValues[kpi.key] === undefined
                      ? "..." 
                      :props.realtimeKPIValues[kpi.key] === null || props.realtimeKPIValues[kpi.key] === ""
                      ? "--"
                      : kpi.formatter === "date"
                      ? convertUTCtoZone(
                          props.realtimeKPIValues[kpi.key],
                          false
                        )
                      : typeof(props.realtimeKPIValues[kpi.key]) === "number" ? 
                      Math.round(props.realtimeKPIValues[kpi.key]) : props.realtimeKPIValues[kpi.key]}
                    {kpi.formatter !== "date" && props.realtimeKPIValues[kpi.key] === undefined
                      ? "..." 
                      :props.realtimeKPIValues[kpi.key] === null || props.realtimeKPIValues[kpi.key] === ""
                      ? "--"
                      :kpi.formatter}
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Tooltip>
      </>
    );
  }

  return (
    <div className="specificBattery-kpiContainer">
      {props.newRealTime.map((item, index) => (
        <div key={index} className={"specificBattery-kpi"}>
          {KPIBoxSpecific(item, props.setDate, props.ChangeTheTab)}
        </div>
      ))}
    </div>
  );
}

export default RealtimeKPI;
