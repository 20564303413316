import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";
const BasicSelect = styled(Select)
(
  ({ theme,myColor }) => ({
  backgroundColor: myColor,
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${myColor}`,
},
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-focused": {
      borderColor:"white"
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: myColor,
  },
})
)
export { BasicSelect };