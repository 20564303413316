const initialFullScreen = {
  value: false,
};
const initialSearch = {
  value: false,
};
const initialSearchedBattery = {
  value: "",
};
const initialBatteryPartnerSelect = {
  value: "Battery ID",
};
const initialClusterClick = {
  value: false,
};
const initialVolt = {
  value: "",
};
const initialCity = {
  value: "delhi",
};
const initialStatus = {
  value: "",
};
const initialSeverity = {
  value: "",
};
const initialZone = {
  value: "",
};
const initialALertType = {
  value: "",
};
const initialIotVendor = {
  value: "",
};
const initialBatteryVendor = {
  value: "",
};
const initialMultiplePartners = {
  value: [],
};
const initialUserInputBatteries = {
  value: [],
};
const initialFoundBatteries = {
  value: [],
};
const initialNotFoundBatteries = {
  value: [],
};
const MapFullScreen = (
  state = initialFullScreen,
  action
) => {
  switch (action.type) {
    case "MapFullScreen":
      return {
        value: action.fullScreen,
      };
    default:
      return state;
  }
};
const MapSearch = (
  state = initialSearch,
  action
) => {
  switch (action.type) {
    case "MapSearch":
      return {
        value: action.search,
      };
    default:
      return state;
  }
};
const MapSearchedBattery = (
  state = initialSearchedBattery,
  action
) => {
  switch (action.type) {
    case "MapSearchedBattery":
      return {
        value: action.searchBattery,
      };
    default:
      return state;
  }
};

const MapBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "MapBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};
const MapClusterClick = (
  state = initialClusterClick,
  action
) => {
  switch (action.type) {
    case "MapClusterClick":
      return {
        value: action.clusterClick,
      };
    default:
      return state;
  }
};
const MapVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "MapVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const MapCity = (state = initialCity, action) => {
  switch (action.type) {
    case "MapCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const MapStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "MapStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};
const MapSeverity = (state = initialSeverity, action) => {
  switch (action.type) {
    case "MapSeverity":
      return {
        value: action.severity,
      };
    default:
      return state;
  }
};
const MapZone = (state = initialZone, action) => {
  switch (action.type) {
    case "MapZone":
      return {
        value: action.zone,
      };
    default:
      return state;
  }
};
const MapAlertType = (state = initialALertType, action) => {
  switch (action.type) {
    case "MapAlertType":
      return {
        value: action.alertType,
      };
    default:
      return state;
  }
};
const MapIotVendor = (state = initialIotVendor, action) => {
  switch (action.type) {
    case "MapIotVendor":
      return {
        value: action.IotVendor,
      };
    default:
      return state;
  }
};
const MapBatteryVendor = (state = initialBatteryVendor, action) => {
  switch (action.type) {
    case "MapBatteryVendor":
      return {
        value: action.BatteryVendor,
      };
    default:
      return state;
  }
};
const MapUserInputBatteries = (state = initialUserInputBatteries, action) => {
  switch (action.type) {
    case "MapUserInputBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};

const MapFoundBatteries = (state = initialFoundBatteries, action) => {
  switch (action.type) {
    case "MapFoundBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};

const MapNotFoundBatteries = (state = initialNotFoundBatteries, action) => {
  switch (action.type) {
    case "MapNotFoundBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};
const MapMultiplePartners = (state = initialMultiplePartners, action) => {
  switch (action.type) {
    case "MapMultiplePartners":
      return {
        value: action.partners,
      };
    default:
      return state;
  }
};
export {
  MapVolt,
  MapCity,
  MapStatus,
  MapSeverity,
  MapZone,
  MapAlertType,
  MapIotVendor,
  MapBatteryVendor,
  MapClusterClick,
  MapBatteryPartnerSelect,
  MapUserInputBatteries,
  MapFoundBatteries,
  MapNotFoundBatteries,
  MapMultiplePartners,
  MapFullScreen,
  MapSearch,
  MapSearchedBattery
};
