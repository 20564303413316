import { PTypography } from "../../../Components/CustomP";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import { Tooltip } from "antd";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";
import FindTheSeverity from "../../../Helper/Operations/FindTheSeverity";

const columnsNew = [
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "insertionTime", "Alert First Seen"),
    dataIndex: "insertionTime",
    key: "insertionTime",
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    uniqueKey: "insertionTime",
    filterSearch: false,
    width: 140,
    sorter: (a, b) => new Date(a.insertionTime) - new Date(b.insertionTime),
    // maxWidth:"18px",
    render: (insertionTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(insertionTime)}
      </Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Alert Type</Typography>,
    dataIndex: "alertDescription",
    key: "alertDescription",
    sorter: false,
    uniqueKey: "alertDescription",
    ellipsis: {
      showTitle: false,
    },
    filterSearch: false,
    width: 150,
    render: (alertDescription) => (
      <Tooltip placement="top" title={alertDescription}>
        <Typography variant="tableRow">{alertDescription}</Typography>
      </Tooltip>
    ),
  },
  // {
  //   title: <Typography variant="tableHeadBold">Severity</Typography>,
  //   dataIndex: "severity",
  //   key: "severity",
  //   sorter: false,
  //   uniqueKey: "severity",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 80,
  //   // maxWidth:"18px",
  //   render: (severity) => (
  //     <Typography variant="tableRow">{FindTheSeverity(severity)}</Typography>
  //   ),
  // },
  {
    title: <Typography variant="tableHeadBold">Status</Typography>,
    dataIndex: "alertStatus",
    key: "alertStatus",
    sorter: false,
    align: "left",
    uniqueKey: "alertStatus",
    filterSearch: false,
    width: 80,
    // maxWidth:"18px",
    render: (alertStatus) => (
      <PTypography
        mycolor={
          alertStatus === "Active"
            ? ThemeProperties.red_100
            : alertStatus === "Open"
            ? ThemeProperties.blue_100
            : alertStatus === "Resolved"
            ? ThemeProperties.green_100
            : alertStatus === "Ignored"
            ? ThemeProperties.pink_100
            : ThemeProperties.blueGrey_100
        }
      >
        <Typography variant="tableRow">{alertStatus}</Typography>
      </PTypography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "statusChangedTime", "Update Time"),
    dataIndex: "statusChangedTime",
    key: "statusChangedTime",
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    uniqueKey: "insertionTime",
    filterSearch: false,
    width: 140,
    sorter: (a, b) => new Date(a.insertionTime) - new Date(b.insertionTime),
    // maxWidth:"18px",
    render: (statusChangedTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(statusChangedTime)}
      </Typography>
    ),
  },
  // {
  //   title: (
  //     <Typography variant="tableHeadBold">Occupant During Alert</Typography>
  //   ),
  //   dataIndex: "partnerAlertTime",
  //   key: "partnerAlertTime",
  //   sorter: false,
  //   uniqueKey: "partnerAlertTime",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 130,
  //   // maxWidth:"18px",
  //   render: (partnerAlertTime) => (
  //     <Typography variant="tableRow">{partnerAlertTime}</Typography>
  //   ),
  // },
  // {
  //   title: <Typography variant="tableHeadBold">Current (A)</Typography>,
  //   dataIndex: "currentAlertTime",
  //   key: "currentAlertTime",
  //   sorter: false,
  //   uniqueKey: "currentAlertTime",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 80,
  //   // maxWidth:"18px",
  //   render: (currentAlertTime) => (
  //     <Typography variant="tableRow">{currentAlertTime?.toFixed(2)}</Typography>
  //   ),
  // },
  // {
  //   title: <Typography variant="tableHeadBold">Voltage (V)</Typography>,
  //   dataIndex: "voltageAlertTime",
  //   key: "voltageAlertTime",
  //   sorter: false,
  //   uniqueKey: "voltageAlertTime",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 80,
  //   // maxWidth:"18px",
  //   render: (voltageAlertTime) => (
  //     <Typography variant="tableRow">{voltageAlertTime?.toFixed(2)}</Typography>
  //   ),
  // },
  // {
  //   title: (
  //     <Tooltip title="Temperature (°C)">
  //       <Typography variant="tableHeadBold">Temperature (°C)</Typography>
  //     </Tooltip>
  //   ),
  //   dataIndex: "temperatureAlertTime",
  //   key: "temperatureAlertTime",
  //   sorter: false,
  //   uniqueKey: "temperatureAlertTime",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 100,
  //   // maxWidth:"18px",
  //   render: (temperatureAlertTime) => (
  //     <Typography variant="tableRow">
  //       {temperatureAlertTime?.toFixed(2)}
  //     </Typography>
  //   ),
  // },

  // {
  //   title: (
  //     <Tooltip title="Temperature (°C)">
  //       <Typography variant="tableHeadBold">Lat/Long</Typography>
  //     </Tooltip>
  //   ),
  //   dataIndex: "severity",
  //   key: "severity",
  //   sorter: false,
  //   uniqueKey: "severity",
  //   ellipsis: {
  //     showTitle: false,
  //   },
  //   filterSearch: false,
  //   width: 100,
  //   // maxWidth:"18px",
  //   render: (severity, record) => (
  //     <Typography variant="tableRow">
  //       {record.latitudeAlertTime?.toFixed(4) 
  //         }{"/"} {record.longitudeAlertTime?.toFixed(4) }
  //     </Typography>
  //   ),
  // },
];

export { columnsNew };
