import React from 'react';
import PulseLoader from "react-spinners/HashLoader";
import { Box } from "@mui/material";
import { ThemeProperties } from '../../Theme/ThemeProperties';

function MapLoader(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <Box sx={{ width: "100%", display:"flex",justifyContent:"center" }}>
          <PulseLoader color={ThemeProperties.purple} size={30} speedMultiplier={1} />
        </Box>
      </div>
    </div>
  );
}

export default MapLoader;