import React, { useState, useEffect } from "react";
import { CustomAutocomplete } from "../../Components/CustomAutocomplete";
import { CustomTextFieldToolbar } from "../../Components/CustomTextField";
import Events from "../../Analytics/Events";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate, useLocation } from "react-router-dom";

import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useSelector, useDispatch } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Typography } from "@mui/material";

import { SetSessionExpired } from "../../Actions";

import { getAllToolbarBatteries } from "../../Api/Api";
import {
  encryptTheParams,
  decryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { SetDashboardOpenAddSelectedBatteries } from "../../Actions/Dashboard";
import { SetWarrantyOpenAddSelectedBatteries } from "../../Actions/Warranty";
import { SetDiagnosticsOpenAddSelectedBatteries } from "../../Actions/Diagnostics";

export default function SearchOnTypeAutocomplete(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = decryptTheParams();
  const [batteryList, setBatteryList] = useState([]);

  const [batteryID, setBatteryId] = React.useState("");
  const [typedValue, setTypedValue] = useState("");

  const [errorTextfield, setErrorTextField] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [value,setValue] = useState("");

  const universal = useSelector((state) => state.DashboardStack.arr);
  const universalWarranty = useSelector((state) => state.WarrantyStack.arr);
  const universalDiag = useSelector((state)=>state.DiagnosticsStack.arr);

  const [select, setSelected] = React.useState("Battery ID");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  useEffect(() => {
    if (typedValue.length > 2) {
      // location.pathname!=="/specificBattery" && setOpen(true);
      // handleOpen();
      getAllToolbarBatteries(select, typedValue).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryList(res.response.data);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteryList([]);
        }
      });
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedValue, queryParams?.["batteryIDGlobal"]]);

  useEffect(()=>{
    setBatteryList([])
  },[select])

  useEffect(
    () => () => {
      setBatteryList([]);
      setBatteryId("");
      setTypedValue("");
      setErrorTextField("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpen = (value) => {
    if (value.length > 2) {
      setOpen(true);
    }
  };

  const onTagsChange = (event, value) => {
    setValue("");
    setOpen(false);
    let itemIDS = [];
    universal.map((item) => itemIDS.push(...item.itemIDs));

    if (itemIDS.length > 0) {
      event.preventDefault();
      value && dispatch(SetDashboardOpenAddSelectedBatteries(true));
    } 
    else if(universalWarranty.length>0)
    {
      event.preventDefault();
      value && dispatch(SetWarrantyOpenAddSelectedBatteries(true));
    }
    else if(universalDiag.length>0)
    {
      event.preventDefault();
      value && dispatch(SetDiagnosticsOpenAddSelectedBatteries(true));
    }
    else {
      if (value !== null && batteryList.includes(value)) {
        let temp;
        if (location.pathname !== "/specificBattery")
          temp = {
            ...queryParams,
            batteryIDGlobal: value,
            batterySearchedGlobal:
              select === "Device ID" ? "false" : "true",
            batteryID: select === "Device ID" ? value : undefined,

            tabValue: 0,
            tabName: "Battery Vitals",
            prevLink: location.pathname + location.search,
          };
        else
          temp = {
            ...queryParams,
            batteryIDGlobal: value,
            batterySearchedGlobal:
              select === "Device ID" ? "false" : "true",
            batteryID: select === "Device ID" ? value : undefined,

            tabValue: 0,
            tabName: "Battery Vitals",
          };
        Events("Searched battery globally");
        setErrorTextField(false);
        encryptTheParams(temp, navigate, true);
      }
    }
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (batteryList.includes(e.target.value)) {
        setBatteryId(e.target.value);
        onTagsChange(e, e.target.value);
        setErrorTextField(false);
      } else {
        setBatteryId(e.target.value);
        setErrorTextField(true);
        select === "Battery ID"
          ? props.openNotification(
              "bottomLeft",
              "This Battery ID does not exist in operation. Please confirm the latest Battery ID for this battery and search again."
            )
          : props.openNotification(
              "bottomLeft",
              "This Device ID does not exist in operation. Please confirm the latest Device ID for this battery and search again."
            );
      }
    }
  }

  return (
    <div id="vaidehi">
      <CustomAutocomplete
        // multiple
        filterSelectedOptions
        freeSolo
        ListboxProps={{
          sx: {
            fontSize: 13,
            fontWeight: 500,
            textAlign: "center",
            pl: 1,
            pr: 1
          },
        }}
        disablePortal
        id="vaidehi"
        options={batteryList.filter((item, i, ar) => ar.indexOf(item) === i)}
        sx={{ width: props.width }}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        clearOnBlur={true}
        value={""}
        onChange={onTagsChange}
        inputValue={value}
        onKeyDown={keyPress}
        onInputChange={(event, value) => {
          
          setTypedValue(value);
          setErrorTextField(false);
          handleOpen(value);
          if (batteryList.includes(value)) {
            setBatteryId(value);
          } else {
            setBatteryId(value);
          }
          setValue(value);
        }}
        getOptionLabel={(option) => option}
        forcePopupIcon={false}
        clearIcon={
          <HighlightOffIcon
            sx={{
              fontSize: "18px",
              color: ThemeProperties.white,
              mt: "-4px",
            }}
          />
        }
        size="small"
        renderInput={(params) => (
          <CustomTextFieldToolbar
            {...params}
            error={errorTextfield}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& input": {
                  textAlign: "center",
                  ml: 4,
                  color: "white",
                },
              },
              startAdornment: (
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={select}
                  onChange={handleChange}
                  label="Age"
                  autoWidth
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{ color: "#fff",fontWeight:400,'.MuiSvgIcon-root ': {
                    fill: "white !important",
                  } }}
                >
                  <MenuItem value={"Battery ID"}>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        fontFamily: "Roboto",
                      }}
                    >
                      Battery ID
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Device ID"}>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        fontFamily: "Roboto",
                      }}
                    >
                      Device ID
                    </Typography>
                  </MenuItem>
                </Select>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <Box>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setOpen(false);
                        if (
                          batteryID !== "" &&
                          batteryList.includes(batteryID)
                        ) {
                          setErrorTextField(false);
                          onTagsChange(undefined, batteryID);
                        } else {
                          setErrorTextField(true);
                          select === "Battery ID"
                            ? props.openNotification(
                                "bottomLeft",
                                "This Battery ID does not exist in operation. Please confirm the latest Battery ID for this battery and search again."
                              )
                            : props.openNotification(
                                "bottomLeft",
                                "This Device ID does not exist in operation. Please confirm the latest Device ID for this battery and search again."
                              );
                        }
                      }}
                    >
                      <SearchIcon
                        sx={{
                          fontSize: "18px",
                          color: errorTextfield ? "#c62828" : "white",
                        }}
                      />
                    </IconButton>
                  </Box>
                </InputAdornment>
              ),
            }}
            placeholder={select === "Battery ID" ? "Search single battery" : "Search single device"}
            sx={{
              fontSize: 17,
              fontWeight: 500,
              fontFamily: "Roboto",
              bgcolor: "#463dbb",
              color: ThemeProperties.red_100,
              borderRadius: "4px",
              "& label": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                "&.Mui-focused": {
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                },
              },
            }}
          />
        )}
      />
    </div>
  );
}
