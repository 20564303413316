import React from 'react';
import { Button } from "antd";
import { Dialog, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeProperties } from '../../Theme/ThemeProperties';



function PageNotFound(props) {
    return (
        <Dialog
        aria-labelledby="customized-dialog-title"
        open={props.notFound}
        maxWidth="sm"
        PaperProps={{
          sx: {
            width: "50%",
            height: 180,
          },
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "inline",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <InfoIcon sx={{fontSize:"20px",color:ThemeProperties.purple}}/>
          <br/>
          <br/>
          <Typography variant="paperHeader">
            Page Not Found
          </Typography>
          <br/>
          <Typography variant="textSubtitle">
          You are not having access to the requested page. Please contact admin.
          </Typography>
          <div
            className="dropDownBottom"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              onClick={() => props.setNotFound(false)}
              style={{ backgroundColor: ThemeProperties.purple }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Dialog>
    );
}

export default PageNotFound;