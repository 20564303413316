import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Typography, Box, Popover } from "@mui/material";
import copy from "copy-to-clipboard";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import { SecondaryButton } from "../../Components/CustomButton";
import { decryptQueryParams, encryptQueryParams } from "query-string-hash";
import Events from "../../Analytics/Events";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyID = (id) => {
    const link = window.location.origin;

    let query = window.location.search.slice(1);
    const queryParams = decryptQueryParams(query);

    let temp;
    temp = {
      ...queryParams,
      id: id,
    };

    const que = new URLSearchParams(temp).toString();
    const hash = encryptQueryParams(que);

    const copiedLink = `${link}/${props.page}?${hash}`;
    copy(copiedLink)
  };

  function CreateCSV() {
    const newBatteries = [...props.csvData];
    if (newBatteries !==null && newBatteries !== undefined && newBatteries.length>0) {
      DownloadCSV(NameTheCSV("CSV", ""), newBatteries);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E7E5E5",
        p: "0px 12px",
        height: "50px",
      }}
    >
      <Typography variant="paperHeader">{props.heading}</Typography>

      {props.showIButton && <IconButton
        fontSize="small"
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          backgroundColor: open ? ThemeProperties.purple : "white",
          color: open ? ThemeProperties.white : ThemeProperties.purple,
        }}
      >
        <MoreVertIcon
          fontSize="small"
          style={{
            fontSize: "18px",
          }}
        />
      </IconButton>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            borderRadius: "12px",
            padding: "12px",
            maxWidth: "300px",
          },
        }}
      >
        {props.showIButton ? (
          <Box sx={{ display: "inline" }}>
            <Box>
              <SecondaryButton
                onClick={() => {
                  Events("Copied Link");
                  handleCopyID(props.id);
                  handleClose();
                }}
                sx={{ pr: 1 }}
              >
                <LinkIcon
                  color="primary"
                  fontSize="small"
                  sx={{
                    fontSize: "18px",
                    mr: 1,
                  }}
                />
                <Typography variant="textSubtitlePurple">Copy Link</Typography>
              </SecondaryButton>
            </Box>
            <Box>
              {props.showCSVDownload && (
                <SecondaryButton
                  onClick={() => {
                    Events("Landing clicked Csv Download");
                    CreateCSV();
                    handleClose();
                  }}
                  sx={{ pr: 1 }}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                    sx={{
                      fontSize: "18px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitlePurple">
                    Download CSV
                  </Typography>
                </SecondaryButton>
              )}
            </Box>
            <Box>
              {props.showCSVDevicePingLanding && 
              <>
                <SecondaryButton
                  onClick={() => {
                    Events("Landing clicked Device Ping Csv Download (All)");
                    props.getCSVData("All")
                    handleClose();
                  }}
                  sx={{ pr: 1 }}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                    sx={{
                      fontSize: "18px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitlePurple">
                    Download CSV (All)
                  </Typography>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    Events("Landing clicked Device Ping Csv Download (Live)");
                    props.getCSVData("Live")
                    handleClose();
                  }}
                  sx={{ pr: 1 }}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                    sx={{
                      fontSize: "18px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitlePurple">
                    Download CSV (Live)
                  </Typography>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    Events("Landing clicked Device Ping Csv Download (Not pinged since 1 day)");
                    props.getCSVData("Offline")
                    handleClose();
                  }}
                  sx={{ pr: 1 }}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                    sx={{
                      fontSize: "18px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitlePurple">
                    Download CSV (Not pinged since 1 day)
                  </Typography>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    Events("Landing clicked Device Ping Csv Download (Not pinged since 7 days)");
                    props.getCSVData("Non-Operational")
                    handleClose();
                  }}
                  sx={{ pr: 1 }}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                    sx={{
                      fontSize: "18px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitlePurple">
                    Download CSV (Not pinged since 7 days)
                  </Typography>
                </SecondaryButton>
                </>
              }
            </Box>

          </Box>
        ) : (
          <>
            <SecondaryButton
              onClick={() => {
                Events("SB Clicked Copy Link");
                handleCopyID(props.id);
                handleClose();
              }}
            >
              <LinkIcon
                color="primary"
                fontSize="small"
                sx={{
                  fontSize: "18px",
                  mr: 1,
                }}
              />
              <Typography variant="textSubtitlePurple">Copy Link</Typography>
            </SecondaryButton>
          </>
        )}
      </Popover>
    </Box>
  );
}

export default Header;
