const VoltFilter = {
  itemName: "Volt",
  items: ["All","48V", "60V"],
};

const ZoneFilter = {
  itemName: "Zone",
  items: ["All","North", "South", "East", "West", "Central", "No Matching Zone"],
};

const BMSFilter = {
  itemName: "BMS",
  items: ["All","ION", "LITE"],
};

const BatteryStatusFilter = {
  itemName: "Status",
  items: [
    "All",
    "Idle(0)",
    "Error(1)",
    "Discharging(2)",
    "Connected to Charger(3)",
    "Charging(4)",
  ],
};

const CityFilter = {
  itemName: "City",
  items: ["All","Bengaluru", "Vijayawada", "Gurugram"],
};

const CityFilterWithOther = {
  itemName: "City",
  items: ["All","Bengaluru", "Vijayawada", "Gurugram", "Others"],
};

const PriorityFilter = {
  itemName: "Priority",
  items: ["All",1,2,3],
};

const DateFilter = {
  itemName:"Date",
  items: ["08-09-2022","09-09-2022","10-09-2022"]
}

const SearchTypeFilter = {
  itemName:"Select",
  items: ["Battery","Partner"]
}

const BatteryState = {
  itemName:"State",
  items: ["All","Operational","Non Operational", "Total Loss"]
}

// const BatteriesType = {
//   itemName:"Type",
//   items: ["All","Critical Batteries","Healthy Batteries"]
// }

const ReportsFilter = {
  itemName: "Time",
  items:["Last 7 Days","Last 30 Days","Last 3 Months","Last 6 Months"]
}

const BusinessType = {
  itemName: "Business",
  items:["All","LTR", "Infinity"]
}

const CellType = {
  itemName:"Cell",
  items: ["All","Panasonic","LG","Not Available"]
}

const SOHLevel = {
  itemName:"SOH Level",
  items: ["All","100% to 90%","90% to 80%","80% and below"]
}

const EqCycle = {
  itemName:"Eq. Cycle",
  items: ["All","0 to 100","100 to 500","500 to 1200","1200 and above"]
}

const newSeverity = ["1","2","3","4"];
const newCity = ["delhi","faridabad","ghaziabad","gurgaon","jaipur","Kanpur","Karnal","Lucknow","Meerut","Noida","Panipat","Sonipat"];
const newPack = ["livguard"];
const newIOT = ["Wynncom","Intellicar"];
const newZone = ["Mayur Vihar","Noida","South Delhi","Jankapuri Kirtinagar","Rohini","Jahagirpuri","Bawana","Central Delhi","Faridabad","Shahadra","Anand Vihar","Gurgaon"];
const newStatus = ["0","4","2"]

export {
  VoltFilter,
  ZoneFilter,
  BatteryStatusFilter,
  CityFilter,
  CityFilterWithOther,
  PriorityFilter,
  BMSFilter,
  DateFilter,
  SearchTypeFilter,
  BatteryState,
  ReportsFilter,
  BusinessType,
  CellType,
  SOHLevel,
  EqCycle,
  
  newSeverity,
  newCity,
  newPack,
  newIOT,
  newZone,
  newStatus
};
