import React from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { PaperHighlight } from "../../Components/CustomPaper";
import Header from "../Header/Header";
import NumberFormatter from "../../Helper/Formatters/NumberFormatter";
import getlength from "../../Helper/Formatters/GetLengthNumber";
import { encryptQueryParams, decryptQueryParams } from "query-string-hash";

import issuesIcon from "../../Assets/Icons/Landing/red.svg";
import batteryAssessmentIcon from "../../Assets/Icons/Landing/blue.svg";
import recycleIcon from "../../Assets/Icons/Landing/Battery.svg";
import batteryWarrantyBWIcon from "../../Assets/Icons/Landing/green.svg";

import { useNavigate } from "react-router-dom";
import { formatDateNumeric } from "../../Helper/DatePicker/DateFormatters";
import { Tooltip } from "antd";
import Events from "../../Analytics/Events";

const EventCard = (props) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "40%", margin: "5%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={props.icon}
          alt={props.heading}
          width={props.heading === "Batteries With Alerts" ? 10 : 14}
          height={props.heading === "Batteries With Alerts" ? 15 : 14}
        />

        {props.heading !== "Batteries With Alerts" ? (
          <Box sx={{ margin: "0.75rem" }}>
            <Tooltip placement={props.placement} title={props.description}>
              <Typography variant="textSubtitle">{props.heading}</Typography>
            </Tooltip>
          </Box>
        ) : (
          <Box sx={{ margin: "0.75rem" }}>
            <Typography variant="textSubtitle">{props.heading}</Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
          borderRadius: "1rem",
          backgroundColor: ThemeProperties.white,
          border: `1px solid #ececec`,
          "&:hover": {
            border:
              props.heading !== "Batteries With Alerts"
                ? `1px solid ${ThemeProperties.greyBackground}`
                : `1px solid #ececec`,
            backgroundColor:
              props.heading !== "Batteries With Alerts"
                ? ThemeProperties.greyBackground
                : ThemeProperties.white,
            cursor:
              props.heading !== "Batteries With Alerts" ? "pointer" : "default",
          },
        }}
        onClick={() => {
          let temp;
          let query = window.location.search.slice(1);
          const queryParams = decryptQueryParams(query);

          if (props.heading === "Super Critical Alerts") {
            Events("clicked active alerts from What's happening in your fleet")
            temp = {
              ...queryParams,
              tabValue: 0,
              Severity: 4
            };
          } else if (props.heading === "Open Alerts") {
            Events("clicked open alerts from What's happening in your fleet")
            temp = {
              ...queryParams,
              tabValue: 1,
            };

          } else if (props.heading === "Resolved Alerts") {
            Events("clicked resolved alerts from What's happening in your fleet")
            temp = {
              ...queryParams,
              tabValue: 2,
              startDate: formatDateNumeric(new Date()),
              endDate: formatDateNumeric(new Date()),
            };
          }

          const que = new URLSearchParams(temp).toString();
          const hash = encryptQueryParams(que);
          navigate(`/Alerts?${hash}`);

          // props.heading === "Active Alerts"
          //   ? dispatch(SetDashboardTabValue(0))
          //   : props.heading === "Open Alerts"
          //   ? dispatch(SetDashboardTabValue(1))
          //   : props.heading === "Resolved Alerts"
          //   ? callIt()
          //   : console.log("");

          // props.heading !== "Batteries With Alerts" && navigate("/Alerts");
        }}
      >
        <Box sx={{ width: "65%" }}>
          <Typography variant="bigNumberPurple">
            {getlength(props.count) < 6
              ? props.count
              : NumberFormatter(props.count, 1)}
          </Typography>
        </Box>
        <Box sx={{ width: "35%" }}>
          <Typography variant="textSubtitle">{props.text}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

function CardWithNumbers(props) {
  return (
    <PaperHighlight elevation={0} sx={{ height: "100%" }} onMouseEnter={()=>Events("hovered What's happening in fleet")}>
      <Header
        heading="What's happening in your fleet"
        description="What's Happening in Fleet"
        showIButton={false}
        showThreeDots={false}
      />
      <div className="section">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          {[
            {
              id: "1",
              heading: "Super Critical Alerts",
              text: "Actionable Alerts",
              icon: issuesIcon,
              count: props.active,
              description: "Needs attention",
              placement: "topLeft",
            },
            {
              id: "2",
              heading: "Open Alerts",
              text: "Alerts yet to close",
              icon: batteryAssessmentIcon,
              count: props.open,
              description: "Action taken",
              placement: "topLeft",
            },
            {
              id: "3",
              heading: "Resolved Alerts",
              text: " Resolved Today ",
              icon: batteryWarrantyBWIcon,
              count: props.resolved,
              description: "Alert recovered",
              placement: "topLeft",
            },
            {
              id: "4",
              heading: "Batteries With Alerts",
              text: "Batteries having issues",
              icon: recycleIcon,
              count: props.total,
              description: "Count of individual batteries facing issues",
              placement: "topLeft",
            },
          ].map((value, index) => (
            <EventCard
              key={index}
              heading={value.heading}
              text={value.text}
              icon={value.icon}
              count={value.count}
              description={value.description}
              placement={value.placement}
              unique_id={index}
              // total={props.total}
            />
          ))}
        </Box>
      </div>
    </PaperHighlight>
  );
}
export default CardWithNumbers;
