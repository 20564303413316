import React from "react";
import { NavLink as NavLinkBase } from "react-router-dom";
import { ListItem, ListItemIcon, Typography } from "@mui/material";
import Events from "../../../Analytics/Events";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { SetDashboardOpenAddSelectedBatteries } from "../../../Actions/Dashboard";

const NavLink = React.forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={props.activeClassName}
    exact="true"
  />
));

const MyNavLink = (props) => {
  const dispatch = useDispatch();
  const universal = useSelector((state) => state.DashboardStack.arr);

  return (
    <ListItem
      component={NavLink}
      activeClassName={({ isActive }) =>
        isActive ? "activeClass" : "inactiveClass"
      }
      to={props.to}
      onClick={(event) => {
        let itemIDS = [];
        universal.map((item) => itemIDS.push(...item.itemIDs));

        if (itemIDS.length > 0) {
          event.preventDefault();
          dispatch(SetDashboardOpenAddSelectedBatteries(true));
        }
        Events("clicked " + props.textPrimary);
      }}
    >
      <Tooltip placement="right" title={props.textPrimary} zIndex={2000}>
        <ListItem button key={props.mykey}>
          <ListItemIcon sx={{ m: 0 }}>
            {window.location.pathname === props.to
              ? props.icon_white
              : props.icon}
          </ListItemIcon>
          <Typography
            sx={{
              fontSize: "14px",
              ml: 0,
              color: window.location.pathname === props.to ? "white" : "black",
            }}
          >
            {props.textPrimary}
          </Typography>
        </ListItem>
      </Tooltip>
    </ListItem>
  );
};

export default MyNavLink;
