import React, { useState, useEffect } from "react";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";
import { useSelector } from "react-redux";

// import BasicTableWOPagination from "../../Features/Table/BasicTableWOPagination";
import BasicTableFleetBatteryHealth from "../../Features/Table/AntDesign/BasicTableFleetBatteryHealth";

function FleetBatteryHealthTabular(props) {
  const [batteryConfigList, setBatteryConfigList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });
  const newColumns = useSelector((state) => state.BatteryHealthColumns.value);

  useEffect(() => {
    setBatteryConfigList({
      data: [],
      responseStatus: { code: -1, msg: "" },
    });
    console.log(props.tableData)
    if (props.tableData && props.tableData.data.length > 0) {
      setBatteryConfigList({
        data: props.tableData.data,
        responseStatus: { code: 200, msg: "" },
      });
    } else {
      setBatteryConfigList({
        data: [],
        responseStatus: { code: 404, msg: "No data found" },
      });
    }
  }, [props.tableData]);
  return (
    <div style={{ height: "800px" }}>
        <BasicTableFleetBatteryHealth
          data={batteryConfigList}
          total={batteryConfigList.data.length}
          columns={newColumns[0]["columns"]}
          dateString={["", ""]}
          setBatteryConfigList={setBatteryConfigList}
          changeTheDate={() => {}}
          ChangeTheTab={() => {}}
          handleOpenToast={() => {}}
          onClickColumn={"insertionTime"}
          navigateTo={"batteryId"}
          height={740}
          pagePerSize={20}
          id="CanBeClicked"
        />
    </div>
  );
}

export default FleetBatteryHealthTabular;
