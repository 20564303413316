import React, { useEffect, useState } from "react";
import MapMain from "./MapMain";
import { getPagesComponents } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import {
  SetMapAlertType,
  SetMapBatteryPartnerSelect,
  SetMapBatteryVendor,
  SetMapCity,
  SetMapIotVendor,
  SetMapMultiplePartners,
  SetMapSeverity,
  SetMapStatus,
  SetMapUserInputBatteries,
  SetMapVolt,
  SetMapZone,
} from "../../Actions/Map";

function Map(props) {
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });

  useEffect(() => {
    setTimeout(() => {
      getPagesComponents("Map").then((res) => {
        if (res.responseStatus.code === 200) {
          let newFilterWithData = [];
          let filters = [
            {
              itemName: "Alert Type",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Safety Risk",
                  value: "Safety Risk",
                },
                {
                  key: "Operational Issue",
                  value: "Operational Issue",
                },

                {
                  key: "Violations",
                  value: "Violations",
                }
              ],
            },
            {
              itemName: "Severity",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Super Critical",
                  value: "4",
                },
                {
                  key: "Critical",
                  value: "3",
                },
                {
                  key: "High",
                  value: "2",
                },
                {
                  key: "Low",
                  value: "1",
                },
              ],
            },
            {
              itemName: "Status",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Idle",
                  value: "0",
                },
                {
                  key: "Charging",
                  value: "4",
                },
                {
                  key: "Discharging",
                  value: "2",
                },
              ],
            },
            {
              itemName: "City",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Delhi",
                  value: "delhi",
                },
                {
                  key: "Faridabad",
                  value: "faridabad",
                },
                {
                  key: "Ghaziabad",
                  value: "ghaziabad",
                },
                {
                  key: "Gurgaon",
                  value: "gurgaon",
                },
                {
                  key: "Jaipur",
                  value: "jaipur",
                },
                {
                  key: "Kanpur",
                  value: "Kanpur",
                },
                {
                  key: "Karnal",
                  value: "Karnal",
                },
                {
                  key: "Lucknow",
                  value: "Lucknow",
                },
                {
                  key: "Meerut",
                  value: "Meerut",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "Panipat",
                  value: "Panipat",
                },
                {
                  key: "Sonipat",
                  value: "Sonipat",
                },
              ],
            },
            {
              itemName: "Zone",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Mayur Vihar",
                  value: "Mayur Vihar",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "South Delhi",
                  value: "South Delhi",
                },
                {
                  key: "Jankapuri Kirtinagar",
                  value: "Jankapuri Kirtinagar",
                },
                {
                  key: "Rohini",
                  value: "Rohini",
                },
                {
                  key: "Jahagirpuri",
                  value: "Jahagirpuri",
                },
                {
                  key: "Bawana",
                  value: "Bawana",
                },
                {
                  key: "Central Delhi",
                  value: "Central Delhi",
                },
                {
                  key: "Faridabad",
                  value: "Faridabad",
                },
                {
                  key: "Shahadra",
                  value: "Shahadra",
                },
                {
                  key: "Anand Vihar",
                  value: "Anand Vihar",
                },
                {
                  key: "Gurgaon",
                  value: "Gurgaon",
                },
              ],
            },

            {
              itemName: "IOT",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Wynncom",
                  value: "Wynncom",
                },
                {
                  key: "Intellicar",
                  value: "INTELLICAR",
                },
              ],
            },
            {
              itemName: "Pack",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Livguard",
                  value: "Livguard",
                },
              ],
            },
          ];
          filters.map(
            (
              item //res.response.response.filter.filtersWithData
            ) => newFilterWithData.push(item["itemName"])
          );
          const filteredArray = newFilterWithData;

          const queryParams = decryptTheParams();


          for (var value of Object.keys(queryParams)) {
            if (filteredArray.includes(value)) {
              if (value === "Volt") {
                dispatch(SetMapVolt(+queryParams[value]));
              } else if (value === "Alert Type") {
                dispatch(SetMapAlertType(queryParams[value]));
              } else if (value === "City") {
                dispatch(SetMapCity(queryParams[value]));
              } else if (value === "Zone") {
                dispatch(SetMapZone(queryParams[value]));
              } else if (value === "Status") {
                dispatch(SetMapStatus(queryParams[value]));
              } else if (value === "Severity") {
                dispatch(SetMapSeverity(queryParams[value]));
              } else if (value === "IOT") {
                dispatch(SetMapIotVendor(queryParams[value]));
              } else if (value === "Pack") {
                dispatch(SetMapBatteryVendor(queryParams[value]));
              }
            } else {
              if (value === "batteryPartnerSelect") {
                dispatch(SetMapBatteryPartnerSelect(queryParams[value]));
              } else if (value === "userInputMapBatteries") {
                let arr =
                  queryParams[value].length === 0
                    ? []
                    : queryParams[value].split(",");
                dispatch(SetMapUserInputBatteries(arr));
              } else if (value === "multipleMapPartners") {
                let arr =
                  queryParams[value].length === 0
                    ? []
                    : queryParams[value].split(",");
                dispatch(SetMapMultiplePartners(arr));
              }
            }
          }
          setPagesContent({
            ...pagesContent,
            pageHeader: res.response.response.textInfo["pageHeader"],
            filtersWithData: filters, //res.response.response.filter.filtersWithData
            filteredArray: filteredArray,
            searchTypeFilter: { //res.response.response.filter.searchTypeFilter
              "items": [
                  "Battery ID",
                  // "Partner"
              ],
              "itemName": "Select"
          },
            batteryType: "",
            mounted: true,
          });
        } else if (res.responseStatus.code === -2) {
          dispatch(SetSessionExpired(true));
        }
      });
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <MapMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default Map;
