import {
  formatDateNumeric,
  returnLastSunday,
} from "../../Helper/DatePicker/DateFormatters";

const initialIOT = {
  value: "",
};
const initialPack = {
  value: "",
};

const initialGran = {
  value: "Weekly",
};

const initialStartDate = {
  value: formatDateNumeric(new Date(new Date().setDate(new Date().getDate() - 34))),
};
const initialEndDate = {
  value: formatDateNumeric(new Date(returnLastSunday())),
};

const AlertAnalyticsIOT = (state = initialIOT, action) => {
  switch (action.type) {
    case "AlertAnalyticsIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};

const AlertAnalyticsPack = (state = initialPack, action) => {
  switch (action.type) {
    case "AlertAnalyticsPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};
const AlertAnalyticsStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "AlertAnalyticsStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const AlertAnalyticsEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "AlertAnalyticsEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};
const AlertAnalyticsGranularity = (state = initialGran, action) => {
  switch (action.type) {
    case "AlertAnalyticsGranularity":
      return {
        value: action.granular,
      };
    default:
      return state;
  }
};

export {
  AlertAnalyticsIOT,
  AlertAnalyticsPack,
  AlertAnalyticsStartDate,
  AlertAnalyticsEndDate,
  AlertAnalyticsGranularity,
};
