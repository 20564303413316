import React, { useEffect, useState } from "react";
import { getMapInfoWindow } from "../../Api/MapApi";
import { SetSessionExpired } from "../../Actions";
import CODE from "../../Static/Constants/StatusCodes";
import { useDispatch } from "react-redux";
import Loader from "../../Helper/Loaders/Loader";
import { Box, Grid, Typography, IconButton, Avatar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import LaunchIcon from "@mui/icons-material/Launch";
import Events from "../../Analytics/Events";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { Brightness1 } from "@mui/icons-material";

function MapInfoWindow(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();

  const [windoInfo, setWindowInfo] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const [severity, setSeverity] = useState([
    {
      id: 4,
      name: "Super Critical",
      color: ThemeProperties.map_critical,
      textSelected: "#fff",
      value: 0,
      selected: false,
      alerts: [],
    },
    {
      id: 3,
      name: "Critical",
      color: ThemeProperties.map_high,
      textSelected: "#fff",
      value: 0,
      selected: false,
      alerts: [],
    },
    {
      id: 2,
      name: "High",
      color: ThemeProperties.map_medium,
      textSelected: "#000",
      value: 0,
      selected: false,
      alerts: [],
    },
    {
      id: 1,
      name: "Low",
      color: ThemeProperties.map_low,
      textSelected: "#000",
      value: 0,
      selected: false,
      alerts: [],
    },
  ]);
  const [zeroAlerts, setZeroAlerts] = useState(true);

  const handleClickSeverity = (value) => {
    const temp = [...severity];
    temp.forEach((item, index) => {
      if (index + 1 === value) {
        item.selected = !severity[value - 1].selected;
      } else {
        item.selected = false;
      }
    });
    setSeverity(temp); //item-1

    Events("Map clicked severity icon InfoWindow");
  };

  useEffect(() => {
    setWindowInfo({
      data: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    });
    props.batteryID && getMapInfoWindow(props.searchType,props.batteryID).then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        let temp = [
          {
            id: 4,
            name: "Super Critical",
            color: ThemeProperties.map_critical,
            value: 0,
            selected: false,
            alerts: [],
          },
          {
            id: 3,
            name: "Critical",
            color: ThemeProperties.map_high,
            value: 0,
            selected: false,
            alerts: [],
          },
          {
            id: 2,
            name: "High",
            color: ThemeProperties.map_medium,
            value: 0,
            selected: false,
            alerts: [],
          },
          {
            id: 1,
            name: "Low",
            color: ThemeProperties.map_low,
            value: 0,
            selected: false,
            alerts: [],
          },
        ];

        if (res.response.alerts.length > 0) {
          res.response.alerts.forEach((item) => {
            temp[temp.findIndex((o) => o.id === item.severity)].alerts.push(
              item.alertDescription
            );
          });
          setZeroAlerts(false);
        }
        setSeverity(temp);

        setWindowInfo({
          data: res.response.infoWindow[0],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setWindowInfo({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID]);
  return (
    <div style={{ height: "100%", width: "350px", padding: "15px" }}>
      {windoInfo.responseStatus.code === CODE.SUCCESS ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            
              <Grid container spacing={1}>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="tableHead">Device ID</Typography>&nbsp;
              <br></br>
              <Typography variant="textSubtitleSmall">
              {props.batteryID}
              </Typography>

              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>

              </Grid>

              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <Typography variant="tableHead">Battery ID</Typography>&nbsp;
              <br></br>
              <Typography variant="textSubtitleSmall">
              {props.textToShow? props.textToShow : "Not found"}
              </Typography>

              </Grid>

          
              </Grid>
              {/* <Typography variant="tableHead">Device ID : {props.batteryID}</Typography> */}
              
              <LaunchIcon
                sx={{
                  color: ThemeProperties.purple,
                  ml: 5,
                  cursor: "pointer",
                }}
                onClick={() => {
                  Events("Map clicked go to specific battery page InfoWindow");
                  let temp;
                  temp = {
                    ...queryParams,
                    batteryID: props.batteryID,
                    batterySearchedGlobal:"false",
                    tabValue: 0,
                    tabName: "Alerts",
                    prevLink: location.pathname + location.search,
                  };
                  encryptTheParams(temp, navigate, true);
                }}
              />
            <IconButton
              fontSize="small"
              onClick={() => props.handleToggleClose()}
            >
              <Close style={{ fontSize: "15px" }} />
            </IconButton>
          </div>

          <Box sx={{ mt: 1 }}></Box>

          <hr style={{ borderTop: "1px solid #ececec" }}></hr>
          <Box sx={{ mt: 2 }}></Box>
          {[
            {
              key: "SOC (%)",
              value: windoInfo.data["socBMS"],
            },
            {
              key: "Battery Status",
              value: windoInfo.data["batteryStatus"],
            },
            {
              key: "Occupant",
              value: windoInfo.data["partner"],
            },
            {
              key: "Lat/Long",
              value: props.lat?.toFixed(6)+"/"+props.lng?.toFixed(6)
            }
          ].map((item, index) => (
            <Grid container spacing={0} sx={{ mb: 1 }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="tableHead"> {item.key} </Typography>
              </Grid>
              {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid> */}
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography variant="textSubtitleSmall">
                  {" "}
                  {item.value === "" ||
                  item.value === null ||
                  item.value === undefined
                    ? "Not Found"
                    : item.value}{" "}
                </Typography>
              </Grid>
            </Grid>
          ))}

          {!zeroAlerts && (
            <>
              <Box sx={{ mt: 2 }}></Box>
              <Grid container spacing={0} sx={{ mb: 1 }}>
            {severity.map((item, index) => (
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box
                  sx={{
                    display: "block",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Avatar
                      sx={{
                        bgcolor: item.selected ? item.color : "#fff",
                        width: 30,
                        height: 30,
                        // opacity: item.selected ? 1 : 0.8,
                        // borderRadius: "50%",
                        border: `2px solid ${item.color}`,
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        cursor: "pointer",
                        color: item.selected ? item.textSelected : "#000",
                        fontWeight: 500,
                      }}
                      onClick={() => handleClickSeverity(index+1)}
                    >
                      {item.alerts.length > 0 && item.alerts.length}
                    </Avatar>
                  </Box>

                  <Typography variant="textSubtitleSmall">
                    {item.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
              <Box sx={{ mt: 2 }}></Box>

              <Box
                sx={{
                  height:
                    severity.filter((item) => item.selected === true).length > 0
                      ? "50px"
                      : "0px",
                  maxHeight: "50px",
                  overflowY: "scroll",
                }}
              >
                {severity.filter((item) => item.selected === true).length >
                  0 && (
                  <>
                    {severity.filter((item) => item.selected === true)[0][
                      "alerts"
                    ].length > 0 ? (
                      <>
                        {/* <Typography variant="textSubtitleSmall">
                      <b>
                        {
                          severity.filter((item) => item.selected === true)[0][
                            "name"
                          ]
                        }
                      </b>
                    </Typography> */}
                        {/* <Box sx={{ mt: 1 }}></Box> */}
                        {severity
                          .filter((item) => item.selected === true)[0]
                          ["alerts"].map((item) => (
                            <Box
                              sx={{
                                // mb: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Brightness1 sx={{ fontSize: "10px",mr:1 }} />
                              <Typography variant="textSubtitleSmall">
                                {" "}
                                {item}{" "}
                              </Typography>
                            </Box>
                          ))}
                      </>
                    ) : (
                      <Box>
                            <Box
                              sx={{
                                // mb: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Brightness1 sx={{ fontSize: "10px",mr:1,color:ThemeProperties.greyTextColor }} />
                              <Typography variant="textDescription">
No Alerts Found
                              </Typography>
                            </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
        </div>
      ) : (
        <div style={{ height: "200px", width: "100%" }}>
          {windoInfo.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default MapInfoWindow;
