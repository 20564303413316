import qs from "qs";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import { GetData } from "./Functions/SettingData";

async function getRealTimeStatus(type, value) {
  var rawJson = {
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
    batteryFlag: [0],
  };
  var config = {
    method: "get",
    url: `/api/current_status/real_time`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function postTagTheBattery(tag, type, value) {
  // let data = {
  //   response: {
  //     msg: "not found",
  //   },
  //   responseStatus: {
  //     code: 0,
  //     msg: null,
  //   },
  // };

  var rawJson = {
    tag: tag,
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "post",
    url: `/api/tag_battery`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
  // let res = await axios(config);

  // if (res.data.responseStatus.code === 201) {
  //   data.response.msg = "found";
  // } else {
  //   data.response.msg = "not found";
  // }
  // data.responseStatus.code = res.data.responseStatus.code;
  // data.responseStatus.msg = res.data.responseStatus.msg;

  // return data;
}
//Battery Vitals
async function getBatteryMetrics(date, type, value) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/battery_metrics`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getBatteryStatus(date, batteryID) {
  //date format not changed

  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/battery_status/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getVoltageMetrics(date, batteryID) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/voltage_capacity/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

//Alerts
async function getBatteryAlerts(date, type, value) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryAlertsList(date, type, value) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/alert_list`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

//Errors
async function getBatteryErrors(date, batteryID) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/errors/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryErrorsList(date, batteryID) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/error_list/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryErrorsHistoric(batteryID) {
  var config = {
    method: "get",
    url: `/api/specificAnalytics/historic_errors/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = GetData(config);
  return data;
}

//Anomaly
async function getBatteryAnomaly(date, batteryID) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/anomaly/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryAnomalyList(date, batteryID) {
  var rawJson = {
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/anomaly_list/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

//Health
async function getBatteryHealth(date, batteryID) {
  //date format not changed

  var rawJson = {
    startTime: date[0],
    endTime: date[1],
    assetID: batteryID,
    batteryID: "",
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/soh_cycles_plot`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

//Configuration
async function getConfigurationList(date, batteryID) {
  //date format not changed
  var rawJson = {
    startTime: date[0],
    endTime: date[1],
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/build_config/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

//SOH Degradation
async function getSOHDegradation(type, value) {
  //date format not changed
  var rawJson = {
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "get",
    url: `/api/specific_battery/health_degradation`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getSohRUL(type, value) {
  //date format not changed
  var rawJson = {
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
  };
  var config = {
    method: "get",
    url: `/api/specific_battery/rul`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getCSVList(date, batteryID) {
  //date format not changed

  var rawJson = {
    startTime: date[0],
    endTime: date[1],
  };
  var config = {
    method: "get",
    url: `/api/specificAnalytics/data_dump/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

export {
  getRealTimeStatus,
  getBatteryStatus,
  getBatteryMetrics,
  getVoltageMetrics,
  getBatteryAlerts,
  getBatteryAlertsList,
  getBatteryErrors,
  getBatteryErrorsList,
  getBatteryErrorsHistoric,
  getBatteryHealth,
  getConfigurationList,
  getSOHDegradation,
  getSohRUL,
  getBatteryAnomaly,
  getBatteryAnomalyList,
  getCSVList,
  postTagTheBattery,
};
