import SuperCritical from "../../Assets/Icons/Map/Critical.png";
import Critical from "../../Assets/Icons/Map/High.png";
import High from "../../Assets/Icons/Map/Medium.png";
import Low from "../../Assets/Icons/Map/Low.png";
import NoSeverity from "../../Assets/Icons/Map/NoAlertsBlue.png";

function FindMapIcon(severity) {
  if(severity === 4)
  return SuperCritical
  else if(severity === 3)
  return Critical
  else if(severity === 2)
  return High
  else if(severity === 1)
  return Low
  else
  return NoSeverity
  // return status === 0
  //   ? OperationalIssue
  //   : status === "Safety Risk"
  //   ? SafetyRisk
  //   : NoAlert;
}

export default FindMapIcon;