import React, { useEffect, useState } from "react";
import AlertAnalyticsMain from "./AlertAnalyticsMain";
import { getPagesComponents } from "../../../../Api/Api";
import { SetSessionExpired } from "../../../../Actions";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../../../Theme/ThemeProperties";
import Loader from "../../../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import { decryptTheParams } from "../../../../Helper/QueryParams/EncryptDecrypt";
import {
  SetAlertAnalyticsEndDate,
  SetAlertAnalyticsGranularity,
  SetAlertAnalyticsIOT,
  SetAlertAnalyticsPack,
  SetAlertAnalyticsStartDate,
} from "../../../../Actions/Reports/AlertAnalytics";

function AlertAnalytics(props) {
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    granularityFilter:{},
    batteryType: "",
    mounted: false,
  });

  useEffect(() => {
    setTimeout(() => {
      //   getPagesComponents("Map").then((res) => {
      // if (res.responseStatus.code === 200) {
      let newFilterWithData = [];
      let filters = [
        {
          itemName: "IOT",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Wynncom",
              value: "Wynncom",
            },
            {
              key: "Intellicar",
              value: "INTELLICAR",
            },
          ],
        },
        {
          itemName: "Pack",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Livguard",
              value: "Livguard",
            },
          ],
        },
      ];
      filters.map(
        (
          item //res.response.response.filter.filtersWithData
        ) => newFilterWithData.push(item["itemName"])
      );
      const filteredArray = newFilterWithData;
      const queryParams = decryptTheParams();

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "IOT") {
            dispatch(SetAlertAnalyticsIOT(queryParams[value]));
          } else if (value === "Pack") {
            dispatch(SetAlertAnalyticsPack(queryParams[value]));
          }
        } else {
          if (value === "startDate") {
            dispatch(SetAlertAnalyticsStartDate(queryParams[value]));
          } else if (value === "endDate") {
            dispatch(SetAlertAnalyticsEndDate(queryParams[value]));
          } else if (value === "Granularity") {
            dispatch(SetAlertAnalyticsGranularity(queryParams[value]));
          }
        }
      }
      setPagesContent({
        ...pagesContent,
        pageHeader: "Alert Analytics",
        filtersWithData: filters, //res.response.response.filter.filtersWithData
        filteredArray: filteredArray,
        searchTypeFilter: {
          //res.response.response.filter.searchTypeFilter
        },
        granularityFilter: {
          itemName: "Granularity",
          items: [
            {
              key: "Daily",
              value: "Daily",
            },
            {
              key: "Weekly",
              value: "Weekly",
            },
            {
              key: "Monthly",
              value: "Monthly",
            },
          ],
        },
        batteryType: "",
        mounted: true,
      });
      // } else if (res.responseStatus.code === -2) {
      //   dispatch(SetSessionExpired(true));
      // }
      //   });
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        granularityFilter:{},
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <AlertAnalyticsMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default AlertAnalytics;