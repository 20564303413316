import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Events from "../../Analytics/Events";

function IconContainer(props) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
         padding: "1rem",
      }}
    >
      <Box
        sx={{ textAlign: "center", cursor: "pointer" }}
        className="icons_enlarge"
        onClick={() => {
          navigate(props.to);
          Events("clicked " + props.text);
        }}
      >
        <Box>
            <Box sx={{display:"flex",justifyContent:"center"}}>
          <img src={props.src} alt={"not found"} height={"25px"} />
          </Box>
          <Typography
            component="p"
            sx={{ mt: 0.5, fontSize: 12, fontWeight: 500,textAlign:"center" }}
          >
            {props.text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default IconContainer;
