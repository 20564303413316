import { Typography } from "@mui/material";
import { Tooltip } from "antd";

const columnsNewLanding = [
  {
    title: (
      <Typography variant="tableHeadBold">Battery Manufacturer</Typography>
    ),
    dataIndex: "IOT Vendor",
    key: "IOT Vendor",
    sorter: false,
    uniqueKey: "IOT Vendor",
    filterSearch: false,
    width: 130,
    // maxWidth:"18px",
    // render: (text,record) => (
    //   <Typography variant="tableRow">{record.batteryVendor}&nbsp;({record.iotVendor})</Typography>
    // ),
    render(text, record) {
      return {
        props: {
          style: { cursor:"default" },
        },
        children: <Typography variant="tableRow">{record["Battery Vendor"]}&nbsp;({record["IOT Vendor"]})</Typography>,
      };
    },
  },
  {
    title: (
      <Tooltip title="Pinged once in last 24 hrs">
      <Typography variant="tableHeadBold">Live</Typography>
      </Tooltip>
    ),
    dataIndex: "Live",
    key: "Live",
    sorter: false,
    uniqueKey: "Live",
    ellipsis: {
      showTitle: false,
    },
    filterSearch: false,
    width: 50,
    align: "center",
    // maxWidth:"18px",
    render(Live) {
      return {
        props: {
          style: { cursor:"default" },
        },
        children: <Typography variant="tableRow">{Live}</Typography>
      };
    }
  },
  {
    title: (
      <Tooltip title="Not pinged in last 24 hrs but pinged in last 7 days">
      <Typography variant="tableHeadBold">Not pinged since 1 day</Typography>
      </Tooltip>
    ),
    dataIndex: "Offline",
    key: "Offline",
    sorter: false,
    align: "left",
    uniqueKey: "Offline",
    filterSearch: false,
    width: 160,
    align: "center",
    // maxWidth:"18px",
    render(Offline) {
      return {
        props: {
          style: { cursor:"default" },
        },
        children: <Typography variant="tableRow">{Offline}</Typography>
      };
    },
  },
  {
    title: (
      <Tooltip title="Not  pinged in last 7 days">
      <Typography variant="tableHeadBold">Not pinged since 7 days</Typography>
      </Tooltip>
    ),
    // title: (titleProps) => getColumnTitleSort(titleProps,"statusChangedTime","Non Operational"),
    dataIndex: "NonOperational",
    key: "NonOperational",
    sorter: false,
    uniqueKey: "NonOperational",
    filterSearch: false,
    align: "center",
    width: 150,
    // maxWidth:"18px",
    render(NonOperational) {
      return {
        props: {
          style: { cursor:"default" },
        },
        children: <Typography variant="tableRow">{NonOperational}</Typography>
      };
    },
    
  },
];

export {  columnsNewLanding };
