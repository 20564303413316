import React, { useEffect, useState } from "react";
// import useState from "react-usestateref";
// import "./table.css";
import { Table, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { SetSessionExpired } from "../../../Actions";
import { getSelectAllBatteries } from "../../../Api/CartBatteriesApi";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import {
  SetStackTableOrder,
  SetStackTableSort,
  SetStackTableRowsPerPage,
} from "../../../Actions/BatteriesInStack";
import Events from "../../../Analytics/Events";

export default function CartTable(props) {
  const dispatch = useDispatch();

  const [columns, setColumns] = useState(props.columns);
  const [list, setList] = useState({
    batteries: [],
    total: 0,
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const [pageNumber, setPageNumber] = useState(1);
  const rowsPerPage = useSelector((state) => state.StackTableRowsPerPage.value);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newColumns = [...columns];
      const item = props.rowSelection
        ? newColumns.splice(fromIndex - 1, 1)[0]
        : newColumns.splice(fromIndex - 1, 1)[0];
      props.rowSelection
        ? newColumns.splice(toIndex - 1, 0, item)
        : newColumns.splice(toIndex - 1, 0, item);

      setColumns(newColumns);
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const rowSelection = {
    selectedRowKeys,
    onSelectAll: function (selected, selectedRows, changeRows) {
      clickedSelectAllCheckbox(selected);
      Events("clicked global select Selected Batteries For Action");
    },
    onSelect: function (selected, selectedRows, changeRows) {
      if (selectedRows) {
        props.handleSelectedBatteries([...selectedRowKeys, selected.itemID]);
      } else {

        props.handleSelectedBatteries(
          selectedRowKeys.filter((key) => key !== selected.itemID)
        );
      }
    },
    getCheckboxProps: (record) => ({
      name: record.itemID,
    }),
  };

  const handleTableChange = (pagination, filters, sorter, extras) => {

    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";

      dispatch(SetStackTableSort(sorter.columnKey));
      dispatch(SetStackTableOrder(order));
    }
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
    props.setPageNumber(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageNumber(1);

    dispatch(SetStackTableRowsPerPage(pageSize));
  };
  function clickedSelectAllCheckbox(selectAll) {
    if (selectAll) {
      let itemIds = [];

      getSelectAllBatteries().then((res) => {
        if (res.responseStatus.code === 200) {
          itemIds = res.response.alertList;
          // setSelectedRowKeys(itemIds);
          props.handleSelectedBatteries(itemIds);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          // setSelectedRowKeys([]);
          props.handleSelectedBatteries([]);
        }
      });
    } else {

      props.handleSelectedBatteries([]);
    }
  }

  useEffect(() => {

    if (props.data && props.data.batteries.length > 0) {
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        batteries: newData,
        total: props.data.total,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        batteries: [],
        total: 0,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
 // eslint-disable-line react-hooks/exhaustive-deps
  }, [props.data]); 


  useEffect(() => {

    setSelectedRowKeys(props.selectedBatteries);
     // eslint-disable-line react-hooks/exhaustive-deps

  }, [props.selectedBatteries]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          {list.responseStatus.code === CODE.SUCCESS ? (
            <>
              <Table
                locale={{
                  emptyText: (
                    <span>
                      <p style={{ minHeight: "300px", color: "white" }}></p>
                    </span>
                  ),
                }}
                columns={columns}
                rowSelection={rowSelection}
                rowKey={(record) => record.itemID}
                rowClassName="rowClass"
                dataSource={
                  list.responseStatus.code === CODE.LOADING
                    ? []
                    : list.batteries
                }
                pagination={false}
                loading={
                  list.responseStatus.code === CODE.LOADING ? true : false
                }
                onChange={handleTableChange}
                className="CartTable"
                style={{ height: "500px" }}
                scroll={{ y: 440 }}
                bordered
                size="middle"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  pageSize={rowsPerPage}
                  pageSizeOptions={[10, 20, 30, 100]}
                  current={pageNumber}
                  onChange={handlePageChange}
                  total={list.total}
                  showTotal={(total) => `Total ${total} items`}
                />
              </div>
            </>
          ) : (
            <Table
              locale={{
                emptyText: (
                  <div style={{ minHeight: "400px", height: "400px" }}>
                    {props.data.responseStatus.code === 404 ||
                    props.data.responseStatus.code === 1999 ? (
                      <div style={{ height: "100%" }}>
                        <NoData text={"No batteries found"} />
                      </div>
                    ) : (
                      <p style={{ height: "100%", color: "white" }}></p>
                    )}
                  </div>
                ),
              }}
              columns={columns}
              rowSelection={false}
              rowKey={(record) => record.itemID}
              rowClassName="rowClass"
              dataSource={[]}
              pagination={false}
              loading={
                props.data.responseStatus.code === 404 ||
                props.data.responseStatus.code === 1999
                  ? false
                  : true
              }
              className="CartTable"
              style={{ height: "500px" }}
              scroll={{ y: 440 }}
              bordered
              size="middle"
            />
          )}
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
