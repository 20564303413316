import React, { useState, useEffect } from "react";
// import useState from "react-usestateref";
// import "./table.css";
import { Table, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDashboardStack,
  SetDashboardColumnSort,
  SetDashboardPageNumber,
  SetDashboardRowsPerPage,
  SetDashboardColumns,
  SetDashboardOpenAddSelectedBatteries,
} from "../../../Actions/Dashboard";
import { SetSessionExpired } from "../../../Actions";
import { SetTableOrder } from "../../../Actions";
import { Tooltip } from "antd";
import { getSelectAllBatteries } from "../../../Api/DashboardApi";
import { useNavigate, useLocation } from "react-router-dom";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import { handleScroll } from "./TableFunctions";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import Events from "../../../Analytics/Events";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";
import { formatDateNumeric } from "../../../Helper/DatePicker/DateFormatters";

export default function TableComponent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // ############  Search  ###########
  //   const preColumns = props.columns.map((column) => {
  //     return { ...column };
  //   });
  const [columns, setColumns] = useState(
    props.columns[props.tabvalue]["columns"]
  );

  const queryParams = decryptTheParams();

  const [list, setList] = useState({
    batteries: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  ////vvvvvvImp
  const universal = useSelector((state) => state.DashboardStack.arr);
  const existingBatteries = useSelector(
    (state) => state.DashboardExistingBatteries.arr
  );
  const pageNumber = useSelector((state) => state.DashboardPageNumber.value);
  const rowsPerPage = useSelector((state) => state.DashboardRowsPerPage.value);
  const sortChangedTo = useSelector((state) => state.DashboardColumnSort.arr);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = props.rowSelection
        ? fromIndex > 1 && toIndex > 1
        : fromIndex > 0 && toIndex > 0;

      if (dragIndex) {
        const newColumns = [...columns];
        const item = props.rowSelection
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        props.rowSelection
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);

        const finalColumns = [...props.columns];
        finalColumns[props.tabvalue]["columns"] = newColumns;

        dispatch(SetDashboardColumns(finalColumns));
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  let itemIDS = [];
  universal.map((item) => itemIDS.push(...item.itemIDs));

  const rowSelection = {
    selectedRowKeys: itemIDS,

    onSelectAll: function (selected, selectedRows, changeRows) {
      // props.getALertType();
      // props.getListOfBatteries();
      clickedSelectAllCheckbox(selected);
    },

    onSelect: function (selected, selectedRows, changeRows) {
      let itemIds = universal.find(
        (item) => item.alertCode === props.alertCode
      )["itemIDs"];

      let index = universal
        .map((item) => item.alertCode)
        .indexOf(props.alertCode);
      let newState = [...universal];

      if (selectedRows) {
        let uniqueChars = [...itemIds, selected.itemID];
        newState[index]["itemIDs"] = [...uniqueChars];
        dispatch(SetDashboardStack(newState));
      } else {
        let uniqueChars = itemIds.filter((key) => key !== selected.itemID);
        newState[index]["itemIDs"] = [...uniqueChars];
        dispatch(SetDashboardStack(newState));
      }
    },
    renderCell: (a, b, c, d) =>
      d.props.disabled ? (
        <Tooltip
          placement="top"
          title={
            "Battery added to take action. If needed to be removed, please remove from cart"
          }
        >
          <IndeterminateCheckBoxIcon
            sx={{ fontSize: "20px", color: "#d8d8d8", cursor: "no-drop" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Tooltip>
      ) : (
        d
      ),
    getCheckboxProps: (record) => ({
      disabled: existingBatteries.includes(record.itemID),
      // Column configuration not to be checked
    }),
  };

  function hideColumns(columns) {
    return columns.filter((item) => !item.hidden);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      const tempSort = [...sortChangedTo];
      tempSort[props.tabvalue]["sortBy"] = sorter.columnKey;
      dispatch(SetDashboardColumnSort(tempSort));
      dispatch(SetTableOrder(order));
    }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(SetDashboardPageNumber(page));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(SetDashboardPageNumber(1));

    dispatch(SetDashboardRowsPerPage(pageSize));
  };

  function clickedSelectAllCheckbox(selectAll) {
    let newtempIds = [];
    if (selectAll) {
      getSelectAllBatteries(
        props.city,
        props.zone,
        props.volt,
        props.category,

        props.alertCode,
        props.batterystatus,
        props.severity,
        props.iotVendor,
        props.packVendor,
        props.tabvalue,

        props.priority,
        props.batteryState,
        props.date,

        props.batteryID,
        props.partner,
        props.batteryType
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          newtempIds = res.response.alertList;

          let tempSelected = res.response.alertList;

          let finalArr = tempSelected.filter((n) => !newtempIds.includes(n));

          let final2 = universal
            .find((item) => item.alertCode === props.alertCode)
            ["itemIDs"].filter((n) => !finalArr.includes(n));

          let final3 = final2.concat(newtempIds);
          let final4 = final3.filter((n) => !existingBatteries.includes(n));

          let uniqueChars = [...new Set(final4)];

          let index = universal
            .map((item) => item.alertCode)
            .indexOf(props.alertCode);
          let newState = [...universal];
          newState[index]["itemIDs"] = [...uniqueChars];

          props.openNotification(
            "bottomLeft",
            `${uniqueChars.length} alerts selected`
          );
          dispatch(SetDashboardStack(newState));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          dispatch(
            SetDashboardStack([
              {
                alertCode: "sft_temp_chrg",
                alertDescription: "High temperature during charging",
                itemIDs: [],
              },
              {
                alertCode: "sft_temp_drv",
                alertDescription: "High temperature inside vehicle",
                itemIDs: [],
              },
              {
                alertCode: "sft_temp_idl",
                alertDescription: "High temperature in warehouse",
                itemIDs: [],
              },
              {
                alertCode: "sft_volt",
                alertDescription: "Over voltage protection breach",
                itemIDs: [],
              },
              {
                alertCode: "sft_cc",
                alertDescription: "Cold charging",
                itemIDs: [],
              },
              {
                alertCode: "sft_cur_chrg",
                alertDescription: "Abnormally high current during charging",
                itemIDs: [],
              },
              {
                alertCode: "sft_cur_drv",
                alertDescription: "Abnormally high current during driving",
                itemIDs: [],
              },
              {
                alertCode: "sft_cur_fluc_chrg",
                alertDescription: "High current fluctuation during charging",
                itemIDs: [],
              },
              {
                alertCode: "sft_cur_high_soc",
                alertDescription:
                  "High current at full voltage during charging",
                itemIDs: [],
              },

              {
                alertCode: "ops_low_volt_vehicle",
                alertDescription: "Low voltage in vehicle",
                itemIDs: [],
              },
              {
                alertCode: "ops_low_volt_warehouse",
                alertDescription: "Low voltage in warehouse",
                itemIDs: [],
              },
              {
                alertCode: "ops_ml_dd",
                alertDescription: "Potential deep discharge",
                itemIDs: [],
              },
              {
                alertCode: "ops_not_ping",
                alertDescription: "Battery not pinging",
                itemIDs: [],
              },
              {
                alertCode: "vln_fc",
                alertDescription: "Field charging",
                itemIDs: [],
              },
              {
                alertCode: "vln_nc",
                alertDescription: "Night charging",
                itemIDs: [],
              },
              {
                alertCode: "vln_uc",
                alertDescription: "Unauthorised charging",
                itemIDs: [],
              },
              {
                alertCode: "vln_ufc",
                alertDescription: "Unauthorised field charging",
                itemIDs: [],
              },
            ])
          );
        }
      });
    } else {
      let index = universal
        .map((item) => item.alertCode)
        .indexOf(props.alertCode);
      let newState = [...universal];
      newState[index]["itemIDs"] = [];
      dispatch(SetDashboardStack(newState));
    }
    Events("clicked global select Alerts page table");
  }

  useEffect(() => {
    if (props.data && props.data.batteries.length > 0) {
      // props.data.responseStatus.code !== 1999/404
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        batteries: newData,
        responseStatus: {
          code: props.extraStatus === -1 ? -1 : props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        batteries: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  let itemIDS = [];
                  universal.map((item) => itemIDS.push(...item.itemIDs));

                  if (
                    event.target.classList[1] ===
                      "ant-table-selection-column" ||
                    event.target.classList[0] === "ant-checkbox-inner" ||
                    event.target.classList[2] === "ant-checkbox-checked" ||
                    event.target.classList[0] === "ant-checkbox-wrapper" ||
                    event.target.classList[0] === "ant-checkbox"
                  ) {
                  } else {
                    if (itemIDS.length > 0) {
                      event.preventDefault();
                      dispatch(SetDashboardOpenAddSelectedBatteries(true));
                    } else {
                      let temp;
                      temp = {
                        ...queryParams,
                        batteryID: record.batteryID,
                        startDateSB: formatDateNumeric(
                          new Date(convertUTCtoZone(record.alertTime))
                        ),
                        endDateSB: formatDateNumeric(new Date()),
                        alertFirstSeenOn: convertUTCtoZone(record.alertTime),
                        batterySearchedGlobal: "false",
                        tabValue: 0,
                        tabName: "Battery Vitals",
                        prevLink: location.pathname + location.search,
                      };
                      encryptTheParams(temp, navigate, true);
                    }
                  }
                }, // click row
              };
            }}
            className={
              props.rowSelection
                ? "LandingPageTable Selection"
                : "LandingPageTable NoSelection"
            }
            locale={{
              emptyText: (
                <div style={{ minHeight: "500px", height: "500px" }}>
                  {list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2 ? (
                    <div style={{ height: "100%" }}>
                      <NoData text={"No alerts found"} />
                    </div>
                  ) : (
                    <p style={{ height: "100%", color: "white" }}></p>
                  )}
                </div>
              ),
            }}
            columns={hideColumns(columns)}
            rowSelection={props.rowSelection && rowSelection}
            rowKey={(record) => record.itemID}
            rowClassName="rowClass"
            dataSource={
              list.responseStatus.code === CODE.LOADING
                ? []
                : list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2
                ? []
                : list.batteries
            }
            pagination={false}
            loading={
              list.responseStatus.code === CODE.LOADING
                ? true
                : list.responseStatus.code === CODE.NODATA ||
                  list.responseStatus.code === -2
                ? false
                : false
            }
            onChange={handleTableChange}
            style={{ height: "575px" }}
            scroll={handleScroll([...columns])}
            bordered
            size="middle"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={rowsPerPage}
              pageSizeOptions={[10, 20, 30, 100]}
              current={pageNumber}
              onChange={handlePageChange}
              total={props.total}
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
