import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid, Box, Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";

import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import ApexDonutAnalytics from "../../../../../Charts/Donut/ApexDonutAnalytics";
import ApexHorizontalBar from "../../../../../Charts/Bar/ApexHorizontalBar";
import ApexMultiSeries from "../../../../../Charts/Line/ApexMultiSeries";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import moment from "moment";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";
import { FindNearestMaxAnalytics, FindNextHighestMultipleOfNum } from "../../../../../Helper/Operations/FindNearestMaxAnalytics";

function Operational(props) {
  const dispatch = useDispatch();

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const [operationalAlertsCount, setOperationalAlertsCount] = useState({
    code: [],
    count: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [operationalAlertsDevices, setOperationalAlertsDevices] = useState({
    code: [],
    count: [],
    max: 0,
    responseStatus: { code: -1, msg: "" },
  });

  const [statusClicked, setStatusClicked] = useState({
    first: true,
    second: true,
    third: true,
    fourth: true,
  });
  function handleStatusClicked(status) {
    if (
      statusClicked.first &&
      statusClicked.second &&
      statusClicked.third &&
      statusClicked.fourth
    ) {
      if (status === "Low voltage in vehicle") {
        setStatusClicked({
          first: statusClicked.first,
          second: !statusClicked.second,
          third: !statusClicked.third,
          fourth: !statusClicked.fourth,
        });
      } else if (status === "Low voltage in warehouse") {
        setStatusClicked({
          first: !statusClicked.first,
          second: statusClicked.second,
          third: !statusClicked.third,
          fourth: !statusClicked.fourth,
        });
      } else if (status === "Potential deep discharge") {
        setStatusClicked({
          first: !statusClicked.first,
          second: !statusClicked.second,
          third: statusClicked.third,
          fourth: !statusClicked.fourth,
        });
      } else if (status === "Battery not pinging") {
        setStatusClicked({
          first: !statusClicked.first,
          second: !statusClicked.second,
          third: !statusClicked.third,
          fourth: statusClicked.fourth,
        });
      }
    } else {
      if (status === "Low voltage in vehicle") {
        if (statusClicked.second || statusClicked.third || statusClicked.fourth)
          setStatusClicked({
            first: !statusClicked.first,
            second: statusClicked.second,
            third: statusClicked.third,
            fourth: statusClicked.fourth,
          });
      } else if (status === "Low voltage in warehouse") {
        if (statusClicked.first || statusClicked.third || statusClicked.fourth)
          setStatusClicked({
            first: statusClicked.first,
            second: !statusClicked.second,
            third: statusClicked.third,
            fourth: statusClicked.fourth,
          });
      } else if (status === "Potential deep discharge") {
        if (statusClicked.first || statusClicked.second || statusClicked.fourth)
          setStatusClicked({
            first: statusClicked.first,
            second: statusClicked.second,
            third: !statusClicked.third,
            fourth: statusClicked.fourth,
          });
      } else if (status === "Battery not pinging") {
        if (statusClicked.first || statusClicked.second || statusClicked.third)
          setStatusClicked({
            first: statusClicked.first,
            second: statusClicked.second,
            third: statusClicked.third,
            fourth: !statusClicked.fourth,
          });
      }
    }
  }
  function handleAlertsStatusClicked() {
    let tempSeries = [],
      tempColors = [];
    if (statusClicked.first) {
      tempSeries.push({
        name: "Low voltage in vehicle",
        data: operationalAlertsDistribution.ops_low_volt_vehicle,
      });
      tempColors.push(ThemeProperties.operational_1);
    }
    if (statusClicked.second) {
      tempSeries.push({
        name: "Low voltage in warehouse",
        data: operationalAlertsDistribution.ops_low_volt_warehouse,
      });
      tempColors.push(ThemeProperties.operational_2);
    }
    if (statusClicked.third) {
      tempSeries.push({
        name: "Potential deep discharge",
        data: operationalAlertsDistribution.ops_ml_dd,
      });
      tempColors.push(ThemeProperties.operational_3);
    }
    if (statusClicked.fourth) {
      tempSeries.push({
        name: "Battery not pinging",
        data: operationalAlertsDistribution.ops_not_ping,
      });
      tempColors.push(ThemeProperties.operational_4);
    }
    return [tempSeries, tempColors];
  }
  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Low voltage in vehicle",
            color: ThemeProperties.operational_1,
            target: statusClicked.first,
          },
          {
            status: "Low voltage in warehouse",
            color: ThemeProperties.operational_2,
            target: statusClicked.second,
          },
          {
            status: "Potential deep discharge",
            color: ThemeProperties.operational_3,
            target: statusClicked.third,
          },
          {
            status: "Battery not pinging",
            color: ThemeProperties.operational_4,
            target: statusClicked.fourth,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClicked(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
  const [operationalAlertsDistribution, setOperationalAlertsDistribution] =
    useState({
      ops_low_volt_vehicle: [],
      ops_low_volt_warehouse: [],
      ops_ml_dd: [],
      ops_not_ping: [],
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

  const [statusClickedDevices, setStatusClickedDevices] = useState({
    first: true,
    second: true,
    third: true,
    fourth: true,
  });
  function handleStatusClickedDevices(status) {
    if (
      statusClickedDevices.first &&
      statusClickedDevices.second &&
      statusClickedDevices.third &&
      statusClickedDevices.fourth
    ) {
      if (status === "Low voltage in vehicle") {
        setStatusClickedDevices({
          first: statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: !statusClickedDevices.third,
          fourth: !statusClickedDevices.fourth,
        });
      } else if (status === "Low voltage in warehouse") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: statusClickedDevices.second,
          third: !statusClickedDevices.third,
          fourth: !statusClickedDevices.fourth,
        });
      } else if (status === "Potential deep discharge") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: statusClickedDevices.third,
          fourth: !statusClickedDevices.fourth,
        });
      } else if (status === "Battery not pinging") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: !statusClickedDevices.third,
          fourth: statusClickedDevices.fourth,
        });
      }
    } else {
      if (status === "Low voltage in vehicle") {
        if (
          statusClickedDevices.second ||
          statusClickedDevices.third ||
          statusClickedDevices.fourth
        )
          setStatusClickedDevices({
            first: !statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: statusClickedDevices.third,
            fourth: statusClickedDevices.fourth,
          });
      } else if (status === "Low voltage in warehouse") {
        if (
          statusClickedDevices.first ||
          statusClickedDevices.third ||
          statusClickedDevices.fourth
        )
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: !statusClickedDevices.second,
            third: statusClickedDevices.third,
            fourth: statusClickedDevices.fourth,
          });
      } else if (status === "Potential deep discharge") {
        if (
          statusClickedDevices.first ||
          statusClickedDevices.second ||
          statusClickedDevices.fourth
        )
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: !statusClickedDevices.third,
            fourth: statusClickedDevices.fourth,
          });
      } else if (status === "Battery not pinging") {
        if (
          statusClickedDevices.first ||
          statusClickedDevices.second ||
          statusClickedDevices.third
        )
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: statusClickedDevices.third,
            fourth: !statusClickedDevices.fourth,
          });
      }
    }
  }
  function handleAlertsStatusClickedDevices() {
    let tempSeries = [],
      tempColors = [];
    if (statusClickedDevices.first) {
      tempSeries.push({
        name: "Low voltage in vehicle",
        data: operationalDevicesDistribution.ops_low_volt_vehicle_devices,
      });
      tempColors.push("#dd2c00");
    }
    if (statusClickedDevices.second) {
      tempSeries.push({
        name: "Low voltage in warehouse",
        data: operationalDevicesDistribution.ops_low_volt_warehouse_devices,
      });
      tempColors.push("#ef6c00");
    }
    if (statusClickedDevices.third) {
      tempSeries.push({
        name: "Potential deep discharge",
        data: operationalDevicesDistribution.ops_ml_dd_devices,
      });
      tempColors.push("#fb8c00");
    }
    if (statusClickedDevices.fourth) {
      tempSeries.push({
        name: "Battery not pinging",
        data: operationalDevicesDistribution.ops_not_ping_devices,
      });
      tempColors.push("#ffd600");
    }
    return [tempSeries, tempColors];
  }
  function LegendsDevices() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Low voltage in vehicle",
            color: "#dd2c00",
            target: statusClickedDevices.first,
          },
          {
            status: "Low voltage in warehouse",
            color: "#ef6c00",
            target: statusClickedDevices.second,
          },
          {
            status: "Potential deep discharge",
            color: "#fb8c00",
            target: statusClickedDevices.third,
          },
          {
            status: "Battery not pinging",
            color: "#ffd600",
            target: statusClickedDevices.fourth,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedDevices(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
  const [operationalDevicesDistribution, setOperationalDevicesDistribution] =
    useState({
      ops_low_volt_vehicle_devices: [],
      ops_low_volt_warehouse_devices: [],
      ops_ml_dd_devices: [],
      ops_not_ping_devices: [],
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

  const SetTheAlertsCountAndDevices = (data) => {
    if (data.responseStatus.code === 200) {
      let tempCode = [],
        tempCount = [],
        tempData = [];

      let tempCode1 = [],
        tempCount1 = [],
        tempData1 = [];

      // data.response.alertList.map((item) => {
      //   if (item["alertCount"] > max) max = item["alertCount"];
      // });

      const gy = groupByKey(data.response.alertList, "alertDescription");

      gy.map((item) => {
        let count = 0;
        item["values"].map((i) => (count = count + i["alertCount"]));
        tempData.push({
          code: item["key"],
          count: count,
        });

        let count1 = 0;
        item["values"].map((i) => (count1 = count1 + i["deviceCount"]));
        tempData1.push({
          code: item["key"],
          count: count1,
        });
      });

      let dataV = tempData.sort((a, b) => b.count - a.count);
      dataV.map((item) => {
        tempCode.push(item["code"]);
        tempCount.push(item["count"]);
      });

      let dataV1 = tempData1.sort((a, b) => b.count - a.count);
      dataV1.map((item) => {
        tempCode1.push(item["code"]);
        tempCount1.push(item["count"]);
      });

      setOperationalAlertsCount({
        code: tempCode,
        count: tempCount,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setOperationalAlertsDevices({
        code: tempCode1,
        count: tempCount1,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setOperationalAlertsCount({
        code: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setOperationalAlertsDevices({
        code: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheOperationalAlertsDistribution = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempops_low_volt_vehicle = [],
        tempops_low_volt_warehouse = [],
        tempops_ml_dd = [],
        tempops_not_ping = [],
        tempops_low_volt_vehicle_devices = [],
        tempops_low_volt_warehouse_devices = [],
        tempops_ml_dd_devices = [],
        tempops_not_ping_devices = [],
        tempCategories = [];

      let tempAlertList = [];
      data.response.alertList.map((item) => {
        tempAlertList.push({
          deviceCount: item.deviceCount,
          alertCount: item.alertCount,
          alertDescription: item.alertDescription,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempAlertList, "startDate");
      gy.map((item) => {
        if (props.granular === "Weekly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
        } else if (props.granular === "Monthly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("MMM")
          );
        } else tempCategories.push(item["key"] + " GMT");

        tempops_low_volt_vehicle.push(
          item["values"].find(
            (i) => i.alertDescription === "Low voltage in vehicle"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Low voltage in vehicle"
              )["alertCount"]
            : 0
        );
        tempops_low_volt_warehouse.push(
          item["values"].find(
            (i) => i.alertDescription === "Low voltage in warehouse"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Low voltage in warehouse"
              )["alertCount"]
            : 0
        );
        tempops_ml_dd.push(
          item["values"].find(
            (i) => i.alertDescription === "Potential deep discharge"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Potential deep discharge"
              )["alertCount"]
            : 0
        );
        tempops_not_ping.push(
          item["values"].find(
            (i) => i.alertDescription === "Battery not pinging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Battery not pinging"
              )["alertCount"]
            : 0
        );

        tempops_low_volt_vehicle_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Low voltage in vehicle"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Low voltage in vehicle"
              )["deviceCount"]
            : 0
        );
        tempops_low_volt_warehouse_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Low voltage in warehouse"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Low voltage in warehouse"
              )["deviceCount"]
            : 0
        );
        tempops_ml_dd_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Potential deep discharge"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Potential deep discharge"
              )["deviceCount"]
            : 0
        );
        tempops_not_ping_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Battery not pinging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Battery not pinging"
              )["deviceCount"]
            : 0
        );
      });

      setOperationalAlertsDistribution({
        ops_low_volt_vehicle: tempops_low_volt_vehicle,
        ops_low_volt_warehouse: tempops_low_volt_warehouse,
        ops_ml_dd: tempops_ml_dd,
        ops_not_ping: tempops_not_ping,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });

      setOperationalDevicesDistribution({
        ops_low_volt_vehicle_devices: tempops_low_volt_vehicle_devices,
        ops_low_volt_warehouse_devices: tempops_low_volt_warehouse_devices,
        ops_ml_dd_devices: tempops_ml_dd_devices,
        ops_not_ping_devices: tempops_not_ping_devices,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      setOperationalAlertsDistribution({
        ops_low_volt_vehicle: [],
        ops_low_volt_warehouse: [],
        ops_ml_dd: [],
        ops_not_ping: [],
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });

      setOperationalDevicesDistribution({
        ops_low_volt_vehicle_devices: [],
        ops_low_volt_warehouse_devices: [],
        ops_ml_dd_devices: [],
        ops_not_ping_devices: [],
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setOperationalAlertsCount({
      code: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    setOperationalAlertsDevices({
      code: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetTheAlertsCountAndDevices = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Operational Issue",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/alerts_type_severity_distribution`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheAlertsCountAndDevices(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setOperationalAlertsCount({
            ...operationalAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setOperationalAlertsDevices({
            ...operationalAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setOperationalAlertsCount({
            ...operationalAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setOperationalAlertsDevices({
            ...operationalAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            console.log("yessssss....refreshed called");
            try {
              let res = await axios.get(
                `/api/alerts_type_severity_distribution`,
                {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                }
              );
              const data = callTheAPI(res);
              SetTheAlertsCountAndDevices(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setOperationalAlertsCount({
                  ...operationalAlertsCount,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
                setOperationalAlertsDevices({
                  ...operationalAlertsDevices,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };
    GetTheAlertsCountAndDevices();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
  ]);

  useEffect(() => {
    setOperationalAlertsDistribution({
      ops_low_volt_vehicle: [],
      ops_low_volt_warehouse: [],
      ops_ml_dd: [],
      ops_not_ping: [],
      categories: [],
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });

    setOperationalDevicesDistribution({
      ops_low_volt_vehicle_devices: [],
      ops_low_volt_warehouse_devices: [],
      ops_ml_dd_devices: [],
      ops_not_ping_devices: [],
      categories: [],
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });
    const controller = new AbortController();
    const GetOperationalAlertsDistribution = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Operational Issue",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/alert_type_distribution`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheOperationalAlertsDistribution(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setOperationalAlertsDistribution({
            ...operationalAlertsDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setOperationalAlertsDistribution({
            ...operationalAlertsDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(
                `/api/alerts_type_severity_distribution`,
                {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                }
              );
              const data = callTheAPI(res, true);
              SetTheOperationalAlertsDistribution(data);
            } catch (error) {
              console.log(error);
              if (error.response?.status === CODE.URL_NODATA) {
                setOperationalAlertsDistribution({
                  ...operationalAlertsDistribution,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };
    GetOperationalAlertsDistribution();
    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
    props.granular,
  ]);

  ScrollToTheElement();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("AA Hovered Operational Alerts")}
        >
          <Header
            heading="Total batteries with various operational alerts"
            description="Count of Devices with Operational Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Count_of_Batteries_with_Operational_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Count_of_Batteries_with_Operational_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              data={operationalAlertsDevices}
              horizontal={true}
              barHeight={"50%"}
              toggleDataPointSelection={false}
              xaxisTitle={"Number of batteries"}
              xTickAmount={5}
              distributed={false}
              yaxisTitle={undefined}
              series={[
                {
                  name: "Number of batteries",
                  data: operationalAlertsDevices.count,
                },
              ]}
              tickAmount={4}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                Math.max(...operationalAlertsDevices.count)
              ),500)}
              formatter={true}
              categories={operationalAlertsDevices.code}
              offsetX={20}
              offsetY={0}
              colors={["#EF6C00", "#FB8C00", "#FFA726", "#FFB74D"]}
              hoverFilter={"none"}
              activeFilter={"none"}
            />
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() =>
            Events("AA Hovered Distribution Of Devices Based on Severity")
          }
        >
          <Header
            heading="Batteries with various operational alerts spread across dates"
            description="Number of Alerts generated"
            showIButton={true}
            showThreeDots={false}
            id="Number_of_batteries_which_generated_alerts_operational"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Number_of_batteries_which_generated_alerts_operational"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
              {/* <ApexMultiSeries
                xaxisType={props.granular === "Daily" ? "datetime" : "category"}
                granularity={props.granular}
                data={operationalDevicesDistribution}
                xaxisTitle={undefined}
                yaxisTitle={"Number of batteries"}
                tickAmount={8}
                series={[
                  {
                    name: "Low voltage in vehicle",
                    data: operationalDevicesDistribution.ops_low_volt_vehicle_devices,
                  },
                  {
                    name: "Low voltage in warehouse",
                    data: operationalDevicesDistribution.ops_low_volt_warehouse_devices,
                  },
                  {
                    name: "Potential deep discharge",
                    data: operationalDevicesDistribution.ops_ml_dd_devices,
                  },
                  {
                    name: "Battery not pinging",
                    data: operationalDevicesDistribution.ops_not_ping_devices,
                  },
                ]}
                statusClicked={statusClickedDevices}
                handleLegendClick={handleAlertsStatusClickedDevices}
                categories={operationalDevicesDistribution.categories}
                // colors={["#EF6C00", "#FB8C00", "#FFA726", "#FFB74D"]}
              /> */}
              <TestApexBar
                data={operationalDevicesDistribution}
                horizontal={false}
                barHeight={"90%"}
                yaxisTitle={"Number of batteries"}
                xaxisTitle={undefined}
                tickAmount={8}
                granularity={props.granular}
                ymax={FindNextHighestMultipleOfNum(
                  FindNearestMaxAnalytics(operationalDevicesDistribution.max),
                  500
                )}
                columnWidth={
                  operationalDevicesDistribution.categories.length >= 1 &&
                  operationalDevicesDistribution.categories.length < 9
                    ? 10 * operationalDevicesDistribution.categories.length +
                      "%"
                    : "80%"
                }
                stacked={false}
                showDataLabels={false}
                categories={operationalDevicesDistribution.categories}
                series={[
                  {
                    name: "Low voltage in vehicle",
                    data: operationalDevicesDistribution.ops_low_volt_vehicle_devices,
                  },
                  {
                    name: "Low voltage in warehouse",
                    data: operationalDevicesDistribution.ops_low_volt_warehouse_devices,
                  },
                  {
                    name: "Potential deep discharge",
                    data: operationalDevicesDistribution.ops_ml_dd_devices,
                  },
                  {
                    name: "Battery not pinging",
                    data: operationalDevicesDistribution.ops_not_ping_devices,
                  },
                ]}
                offsetX={20}
                offsetY={0}
                customLegend={true}
                statusClicked={statusClickedDevices}
                handleLegendClick={handleAlertsStatusClickedDevices}
                colors={[
                  ThemeProperties.map_critical,
                  ThemeProperties.map_high,
                  ThemeProperties.map_medium,
                  ThemeProperties.map_low,
                ]}
                width={"100%"}
              />
            </div>
            {operationalDevicesDistribution.responseStatus.code ===
              CODE.SUCCESS && <LegendsDevices />}
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default Operational;
