import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Backdrop, Box, IconButton, List, Typography } from "@mui/material";
import { notification, Popover, Tooltip } from "antd";

import { NavLink } from "react-router-dom";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";

import Coulomb_AI from "../../Assets/Images/Sidebar/Coulomb_AI.svg";
// import { AppbarLinks } from "../../Static/Data/_Common/AppbarLinks";

import MyNavLink from "./Links/MyNavLink";
import ProfileAvatar from "../Avatar/ProfileAvatar";
import ProfilePopover from "../Popover/ProfilePopover";
import { CustomToolbar } from "../../Components/CustomToolbar";
import SearchOnTypeAutocomplete from "../Autocomplete/SearchOnTypeAutocomplete";
import NotificationData from "../Notifications.js/NotificationData";

import { DrawerHeader, AppBar, Drawer } from "./AppbarStyles";
import {
  SetGlobalBatterySearched,
  SetGlobalBatteryTypedValue,
  SetSessionExpired,
} from "../../Actions";
import {
  SetAllBatteriesState,
  SetAllBatteriesVolt,
  SetAllBatteriesCity,
  SetAllBatteriesStatus,
  SetAllBatteriesMultiplePartners,
  SetAllBatteriesMultiplePartnersDropdown,
  SetAllBatteriesMultiplePartnersSelectAll,
  SetAllBatteriesUserInputBatteries,
  SetAllBatteriesFoundBatteries,
  SetAllBatteriesNotFoundBatteries,
  SetAllBatteriesPageNumber,
  SetAllBatteriesBatteryPartnerSelect,
  SetAllBatteriesStack,
  SetAllBatteriesBusiness,
} from "../../Actions/AllBatteries";

import {
  SetIsBatteryIsPartnerIsNull,
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetDashboardVolt,
  SetDashboardCategory,
  SetDashboardPageNumber,
  SetDashboardCity,
  SetDashboardZone,
  SetDashboardStatus,
  SetDashboardTabValue,
  SetDashboardAlertTypeIndex,
  SetDashboardAlertTypeCode,
  SetDashboardTotal,
  SetDashboardIOT,
  SetDashboardPack,
  SetDashboardSeverity,
  SetDashboardStartDate,
  SetDashboardEndDate,
  SetDashboardOpenAddSelectedBatteries,
  SetDashboardDeviceID,
  SetDashboardBatteryPartnerSelect
} from "../../Actions/Dashboard";

import {
  SetIsBatteryIsPartnerIsNullDiagnostics,
  SetDiagnosticsBatteryID,
  SetDiagnosticsPartner,
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsTabValue,
  SetDiagnosticsPageNumber,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsErrorTypeCode,
  SetDiagnosticsTotal,
  SetDiagnosticsStartDate,
  SetDiagnosticsEndDate,
  SetDiagnosticsErrorTypeList,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsOpenAddSelectedBatteries,
  SetDiagnosticsStack,
} from "../../Actions/Diagnostics";

import {
  SetBatteryHealthCycleAge,
  SetBatteryHealthToggleTable,
  SetBatteryHealthEqCycle,
  SetBatteryHealthSOH,
  SetBatteryHealthCell,
  SetBatteryHealthVolt,
  SetBatteryHealthBusiness,
  SetBatteryHealthIOT,
  SetBatteryHealthPack,
  SetBatteryHealthBatteryID,
  SetBatteryHealthDeviceID,
  SetBatteryHealthPartner,
  SetBatteryHealthBatteryPartnerSelect,
  SetBatteryHealthSort,
  SetBatteryHealthSortOrder,
  SetBatteryHealthPageNumber,
  SetBatteryHealthRowsPerPage,
} from "../../Actions/BatteryHealth";

import {
  SetIsBatteryIsPartnerIsNullAnomaly,
  SetAnomalyBatteryID,
  SetAnomalyPartner,
  SetAnomalyVolt,
  SetAnomalyCell,
  SetAnomalyCity,
  SetAnomalyZone,
  SetAnomalyVersion,
  SetAnomalyBuildNo,
  SetAnomalyStatus,
  SetAnomalyTabValue,
  SetAnomalyIndex,
  SetAnomalyCode,
  SetAnomalyTotal,
  SetAnomalyStartDate,
  SetAnomalyEndDate,
  SetAnomalyList,
  SetAnomalyListOfBatteries,
} from "../../Actions/Anomaly";

import {
  
  SetWarrantyTabValue,
  SetWarrantyIndex,
  SetWarrantyCode,
  SetWarrantyTotal,

  SetWarrantyList,
  SetWarrantyListOfBatteries,
  SetWarrantyIOT,
  SetWarrantyStack,
  SetWarrantyPack,
  SetWarrantyCycle,
  SetWarrantyAge,
  SetWarrantySOH,
  SetWarrantyOpenAddSelectedBatteries
} from "../../Actions/Warranty";

import {
  SetMapFullScreen,
  SetMapSearch,
  SetMapSearchedBattery,
  SetMapBatteryPartnerSelect,
  SetMapUserInputBatteries,
  SetMapFoundBatteries,
  SetMapNotFoundBatteries,
  SetMapMultiplePartners,
  SetMapVolt,
  SetMapCity,
  SetMapStatus,
  SetMapSeverity,
  SetMapZone,
  SetMapAlertType,
  SetMapIotVendor,
  SetMapBatteryVendor
} from "../../Actions/Map";

import {
  SetAlertAnalyticsEndDate,
  SetAlertAnalyticsGranularity,
  SetAlertAnalyticsIOT,
  SetAlertAnalyticsPack,
  SetAlertAnalyticsStartDate,
} from "../../Actions/Reports/AlertAnalytics";

import {
  SetReportsDQIOT,
  SetReportsDQPack,
  SetReportsDQStartDate,
  SetReportsDQEndDate,
  SetReportsDQGranularity,
} from "../../Actions/Reports/DataQuality";

import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import Events from "../../Analytics/Events";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getLastUpdated } from "../../Api/Api";
import AdvanceSearchBatteries from "../Dialog/AdvanceSearchBatteries";
import { formatDateNumeric, returnLastSunday } from "../../Helper/DatePicker/DateFormatters";
import { newCity, newIOT, newPack, newSeverity, newStatus, newZone } from "../../Static/Data/Dashboard/DashboardFilters";
const Context = React.createContext({
  name: "Default",
});

export default function Appbar(props) {
  const cookies = new Cookies();
  const location = useLocation();
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [myData, setMyData] = useState([]);

  const universal = useSelector((state) => state.DashboardStack.arr);

  const theme = useTheme();
  const [currentLocation, setCurrentLocation] = useState("");
  const previousLocation = useRef(null);
  const [lastUpdated, setLastUpdated] = useState("DD MMM, 00:00 PM");

  const [isRtl, setIsRtl] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
    setIsRtl(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setIsRtl(false);
  };

  const [anchorElProfilePopup, setAnchorElProfilePopup] = React.useState(null);
  const openProfilePopup = Boolean(anchorElProfilePopup);
  const idProfilePopup = openProfilePopup
    ? "simple-popover-profile"
    : undefined;
  const handleOpenProfilePopup = (event) => {
    setAnchorElProfilePopup(event.currentTarget);
  };
  const handleCloseProfilePopup = () => {
    setAnchorElProfilePopup(null);
  };

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const handleCloseAdvancedSearch = () => {
    setAdvancedSearch(false);
  };

  useEffect(() => {
    let itemIDS = [];
    universal.map((item) => itemIDS.push(...item.itemIDs));

    if (itemIDS.length > 0) {
      window.onbeforeunload = function () {
        return "";
      };
      dispatch(SetDashboardOpenAddSelectedBatteries(true));
    }

    getLastUpdated().then((res) => {
      if (res.responseStatus.code === 200) {
        setLastUpdated(convertUTCtoZone(res.response.data.lastPingedTime));
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // setLastUpdated(moment().format("DD MMM'YY, hh:mm A"));

    previousLocation.current = currentLocation;
    setCurrentLocation(location.pathname);

    if (location.pathname !== "/specificBattery") {
      dispatch(SetGlobalBatterySearched(""));
      dispatch(SetGlobalBatteryTypedValue(""));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/AllBatteries"
    ) {
      dispatch(SetAllBatteriesStack([]));
      dispatch(SetAllBatteriesState(""));
      dispatch(SetAllBatteriesVolt(""));
      dispatch(SetAllBatteriesCity(""));
      dispatch(SetAllBatteriesStatus(""));
      dispatch(SetAllBatteriesMultiplePartners([]));
      dispatch(SetAllBatteriesMultiplePartnersDropdown([]));
      dispatch(SetAllBatteriesMultiplePartnersSelectAll(false));
      dispatch(SetAllBatteriesUserInputBatteries([]));
      dispatch(SetAllBatteriesFoundBatteries([]));
      dispatch(SetAllBatteriesNotFoundBatteries([]));
      dispatch(SetAllBatteriesPageNumber(1));
      dispatch(SetAllBatteriesBatteryPartnerSelect("Battery ID"));
    }
    // if (checkType("Dashboard", DashboardType))
    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Alerts"
    ) {
      dispatch(SetDashboardBatteryPartnerSelect("Battery ID"));
      dispatch(SetDashboardAlertTypeIndex(0));
      dispatch(SetDashboardAlertTypeCode(""));
      dispatch(SetDashboardTabValue(0));
      dispatch(SetDashboardTotal(0));
      dispatch(SetIsBatteryIsPartnerIsNull(null));
      dispatch(SetDashboardBatteryID(""));
      dispatch(SetDashboardDeviceID(""));
      dispatch(SetDashboardPartner(""));
      dispatch(SetDashboardPageNumber(1));
      // dispatch(SetDashboardRowsPerPage(20));
      dispatch(SetDashboardVolt(""));
      dispatch(SetDashboardCategory(""));
      dispatch(SetDashboardCity(newCity));
      dispatch(SetDashboardZone(newZone));
      dispatch(SetDashboardStatus(newStatus));
      dispatch(SetDashboardSeverity(newSeverity));
      dispatch(SetDashboardIOT(newIOT));
      dispatch(SetDashboardPack(newPack));

      dispatch(
        SetDashboardStartDate(
          formatDateNumeric(
            new Date(new Date().setDate(new Date().getDate() - 45))
          )
        )
      );
      dispatch(SetDashboardEndDate(formatDateNumeric(new Date())));
    }
    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Diagnostics"
    ) {
      dispatch(SetDiagnosticsStack([]));
      dispatch(SetIsBatteryIsPartnerIsNullDiagnostics(null));
      dispatch(SetDiagnosticsBatteryID(null));
      dispatch(SetDiagnosticsPartner(""));

      dispatch(SetDiagnosticsCity(newCity));
      dispatch(SetDiagnosticsZone(newZone));
      dispatch(SetDiagnosticsStatus(newStatus));
      dispatch(SetDiagnosticsIOT(newIOT));
      dispatch(SetDiagnosticsPack(newPack))

      dispatch(SetDiagnosticsPageNumber(1));
      dispatch(SetDiagnosticsTabValue(0));
      dispatch(SetDiagnosticsErrorTypeList([]));
      dispatch(SetDiagnosticsErrorTypeCode(""));
      dispatch(SetDiagnosticsErrorTypeIndex(0));

      dispatch(SetDiagnosticsTotal(0));

      dispatch(SetDiagnosticsStartDate(""));
      dispatch(SetDiagnosticsEndDate(""));
      dispatch(SetDiagnosticsOpenAddSelectedBatteries(false));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Anomaly"
    ) {
      dispatch(SetIsBatteryIsPartnerIsNullAnomaly(null));
      dispatch(SetAnomalyBatteryID(null));
      dispatch(SetAnomalyPartner(null));

      dispatch(SetAnomalyVolt(""));
      dispatch(SetAnomalyCell(""));
      dispatch(SetAnomalyCity(""));
      dispatch(SetAnomalyZone(""));
      dispatch(SetAnomalyVersion(""));
      dispatch(SetAnomalyBuildNo([]));
      dispatch(SetAnomalyStatus(""));

      dispatch(SetAnomalyTabValue(0));
      dispatch(SetAnomalyIndex(0));
      dispatch(SetAnomalyCode(""));
      dispatch(SetAnomalyTotal(0));

      dispatch(SetAnomalyStartDate(""));
      dispatch(SetAnomalyEndDate(""));
      dispatch(SetAnomalyList([]));
      dispatch(SetAnomalyListOfBatteries([]));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Warranty"
    ) {
      dispatch(SetWarrantyStack([]));
      dispatch(SetWarrantyIOT(""));
      dispatch(SetWarrantyPack(""));
      dispatch(SetWarrantyCycle(""));
      dispatch(SetWarrantyAge(""));
      dispatch(SetWarrantySOH(""));
      dispatch(SetWarrantyOpenAddSelectedBatteries(false));
  

      dispatch(SetWarrantyTabValue(0));
      dispatch(SetWarrantyIndex(0));
      dispatch(SetWarrantyCode(""));
      dispatch(SetWarrantyTotal(0));

      dispatch(SetWarrantyList([]));
      dispatch(SetWarrantyListOfBatteries([]));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/BatteryHealth"
    ) {
      dispatch(SetBatteryHealthBatteryPartnerSelect("Battery ID"));
      dispatch(SetBatteryHealthBatteryID(""));
      dispatch(SetBatteryHealthDeviceID(""));
      dispatch(SetBatteryHealthPartner(""));
      dispatch(SetBatteryHealthCycleAge(false));
      dispatch(SetBatteryHealthToggleTable(false));
      dispatch(SetBatteryHealthEqCycle(""));
      dispatch(SetBatteryHealthSOH(""));
      dispatch(SetBatteryHealthCell(""));
      dispatch(SetBatteryHealthVolt(""));
      dispatch(SetBatteryHealthBusiness(""));
      dispatch(SetBatteryHealthIOT("Wynncom"));
      dispatch(SetBatteryHealthPack(""));
      dispatch(SetBatteryHealthSort("sohEst"));
      dispatch(SetBatteryHealthSortOrder("descend"));
      dispatch(SetBatteryHealthPageNumber(1));
      dispatch(SetBatteryHealthRowsPerPage(20));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Map"
    ) {
      // console.log("this is not called");
      dispatch(SetMapFullScreen(false));
      dispatch(SetMapSearch(false));
      dispatch(SetMapSearchedBattery(""));
      dispatch(SetMapBatteryPartnerSelect("Battery ID"));
      dispatch(SetMapUserInputBatteries([]));
      dispatch(SetMapFoundBatteries([]));
      dispatch(SetMapNotFoundBatteries([]));
      dispatch(SetMapMultiplePartners([]));
      dispatch(SetMapVolt(""));
      dispatch(SetMapCity("delhi"));
      dispatch(SetMapStatus(""));
      dispatch(SetMapSeverity(""));
      dispatch(SetMapZone(""));
      dispatch(SetMapAlertType(""));
      dispatch(SetMapIotVendor(""));
      dispatch(SetMapBatteryVendor(""));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/_Reports/AlertAnalytics"
    ) {
      // console.log("this is not called");
      dispatch(
        SetAlertAnalyticsStartDate(
          formatDateNumeric(
            new Date(new Date().setDate(new Date().getDate() - 34))
          )
        )
      );
      dispatch(SetAlertAnalyticsEndDate(formatDateNumeric(new Date(returnLastSunday()))));
      dispatch(SetAlertAnalyticsGranularity("Weekly"));
      dispatch(SetAlertAnalyticsIOT(""))
      dispatch(SetAlertAnalyticsPack(""))
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/_Reports/DataQuality"
    ) {
      // console.log("this is not called");
      dispatch(
        SetReportsDQStartDate(
          formatDateNumeric(
            new Date(new Date().setDate(new Date().getDate() - 34))
          )
        )
      );
      dispatch(SetReportsDQEndDate(formatDateNumeric(new Date(returnLastSunday()))));
      dispatch(SetReportsDQGranularity("Weekly"));
      dispatch(SetReportsDQIOT(""))
      dispatch(SetReportsDQPack(""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const pagesLinks = props.appbarLinks;
  const AppbarLinks = [];
  pagesLinks.forEach((item) => {
    if (cookies.get("pages") && cookies.get("pages").includes(item["mykey"])) {
      if (item["queryParam"] && Object.keys(item["queryParam"]).length !== 0) {
        const queryParam = Object.entries(item["queryParam"]);
        const path =
          item["to"] + "/" + queryParam[0][0] + "=" + queryParam[0][1];
        AppbarLinks.push({
          to: path,
          mykey: item["mykey"],
          textPrimary: item["text"],
          icon: item["src"],
          icon_white: item["src_white"],
        });
      } else {
        const path = item["to"];

        AppbarLinks.push({
          to: path,
          mykey: item["mykey"],
          textPrimary: item["text"],
          icon: item["src"],
          icon_white: item["src_white"],
        });
      }
    }
  });

  // const AppbarLinks = [
  //   {
  //     to: "/Dashboard/batteryType=Share",
  //     mykey: "Dashboard",
  //     textPrimary: "Bounce LTR",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/BounceLTR.png",
  //   },
  //   {
  //     to: "/Dashboard/batteryType=Infinity",
  //     mykey: "Dashboard",
  //     textPrimary: "Bounce Infinity",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Infinity.png",
  //   },
  //   {
  //     to: "/BatteryHealth",
  //     mykey: "BatteryHealth",
  //     textPrimary: "Battery Health",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/batteryhealth.svg",
  //   },
  //   {
  //     to: "/Diagnostics",
  //     mykey: "Diagnostics",
  //     textPrimary: "Diagnostics",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/diagnostics.svg",
  //   },
  //   {
  //     to: "/Anomaly",
  //     mykey: "Anomaly",
  //     textPrimary: "Anomaly",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Anomaly.svg",
  //   },
  //   {
  //     to: "/Warranty",
  //     mykey: "Warranty",
  //     textPrimary: "Warranty",
  //     icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Warranty.svg",
  //   },
  //   // {
  //   //   to: "/Reports",
  //   //   mykey: "Reports",
  //   //   textPrimary: "Reports",
  //   //   icon: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/reports.svg",
  //   // },
  // ];

  // useEffect(() => {
  //   const gameStartInternal = setInterval(() => {
  //     setGamePlayTime((t) => t + 1);
  //   }, 5000);
  //   return () => {
  //     clearInterval(gameStartInternal);
  //   };
  // }, []);

  return (
    <>
    <Context.Provider value={contextValue}>
            {contextHolder}
    <div>
      <AppBar>
        <CustomToolbar>
          <NavLink exact to="/">
            <img
              src={Coulomb_AI}
              alt="not found"
              onClick={(event) => {
                let itemIDS = [];
                universal.map((item) => itemIDS.push(...item.itemIDs));

                if (itemIDS.length > 0) {
                  event.preventDefault();
                  dispatch(SetDashboardOpenAddSelectedBatteries(true));
                }
                else
                {
                Events("clicked Landing page");
                handleDrawerClose();
                }
              }}
              style={{ margin: "0px -10px 0px 10px" }}
            />
          </NavLink>

          <Box sx={{ display: "flex", ml: 20 }}>
            <SearchOnTypeAutocomplete
              // batteryPartnerList={allBatteries}
              text="Search single battery"
              width={350}
              openNotification={openNotification}
            />
            <div
              className="button_hover"
              onClick={(event) => {
                // navigate("/AllBatteries");
                let itemIDS = [];
                universal.map((item) => itemIDS.push(...item.itemIDs));

                if (itemIDS.length > 0) {
                  event.preventDefault();
                  dispatch(SetDashboardOpenAddSelectedBatteries(true));
                }
                else
                {
                setAdvancedSearch(true);
                dispatch(SetAllBatteriesBusiness(""));
                }
                Events("Clicked Advance Search")
              }}
              
            >
              Advance Search
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              //width: 80,
              mr: 2,
            }}
          >
            <Tooltip
              placement="bottom"
              title={
                "IOT data is received till this time"
              }
              zIndex={2000}
            >
              <Typography sx={{ color: "white", fontSize: "12px", mr: 1 }}>
                Last Updated - &nbsp;{lastUpdated}
              </Typography>
            </Tooltip>
            <Popover
              placement="bottomRight"
              content={<NotificationData setData={setMyData} data={myData} />}
              trigger="click"
            >
              {/* <Badge
                  badgeContent={90}
                  max={20000}>
                  <NotificationsNoneIcon />
                </Badge> */}

              {/* <Badge
                badgeContent={myData.length}
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    // fontSize: "13px",
                    // minHeight: 16,
                    // // minWidth: 15,
                    // p: 1,
                    // border: "1px solid grey",
                    bgcolor: "white",
                    mt: 1,
                    mr:1.3,
                    // ml:"10px",
                    color: ThemeProperties.purple,
                  },
                }}
              >
                <IconButton
                >
                <NotificationsNoneIcon
                  sx={{ color: "white", fontSize: "30px" }}
                />
                </IconButton>
              </Badge> */}
            </Popover>

            <div onClick={handleOpenProfilePopup}>
              <ProfileAvatar
                showIcon={true}
                openProfilePopup={openProfilePopup}
              />
            </div>
          </Box>
        </CustomToolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        // PaperProps={{
        //   sx: {
        //     backgroundColor: "#F7F4FF",
        //   },
        // }}
      >
        <List style={{ paddingTop: "50px" }}>
          {AppbarLinks.map((item, index) => (
            <>
              {cookies.get("pages") !== undefined ? (
                <>
                  <MyNavLink
                    to={item.to}
                    mykey={item.mykey}
                    textPrimary={item.textPrimary}
                    icon={
                      <img
                        src={item.icon}
                        alt="not found"
                        height={"20px"}
                        style={{ marginLeft: "-3px" }}
                      />
                    }
                    icon_white={
                      <img
                        src={item.icon_white}
                        alt="not found"
                        height={"20px"}
                        style={{ marginLeft: "-3px" }}
                      />
                    }
                    key={index}
                  />
                </>
              ) : (
                removeAccessToken()
              )}
            </>
          ))}
        </List>

        <DrawerHeader sx={{ mt: -3 }}>
          {isRtl ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <ChevronRightIcon fontSize="large" />
            </IconButton>
          )}
        </DrawerHeader>
      </Drawer>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer - 1,
          color: "#fff",
        }}
        open={open}
        onClick={handleDrawerClose}
      ></Backdrop>

      {openProfilePopup && (
        <ProfilePopover
          idProfilePopup={idProfilePopup}
          openProfilePopup={openProfilePopup}
          anchorElProfilePopup={anchorElProfilePopup}
          handleCloseProfilePopup={handleCloseProfilePopup}
        />
      )}
      <AdvanceSearchBatteries
        advancedSearch={advancedSearch}
        handleCloseAdvancedSearch={handleCloseAdvancedSearch}
      />
    </div>
    </Context.Provider>
    </>
  );
}
