import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Typography } from "@mui/material";

const getColumnTitleSort = (titleProps, key, title) => {
  const sortedColumn = titleProps.sortColumns?.find(
    ({ column }) => column.key === key
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
      // onClick={(e)=>e.stopPropagation()}
    >
      <Typography
        variant="tableHeadBold"
        onClick={(e) => e.stopPropagation()}
      >
        {title}
      </Typography>

      <div className="sortIconDiv">
        <div style={{ margin: "0px 3px" }}>
          <CaretUpOutlined
            className={
              sortedColumn?.order === "ascend" &&
              sortedColumn?.column.key === key
                ? "sortIcon up active"
                : "sortIcon up"
            }
          />
        </div>
        <div>
          <CaretDownOutlined
            className={
              sortedColumn?.order === "descend" &&
              sortedColumn?.column.key === key
                ? "sortIcon down active"
                : "sortIcon down"
            }
          />
        </div>
      </div>

      {/* <div style={{ textAlign: "center" }} className="antTableSort">
        <ArrowDropUpIcon
          sx={{
            fontSize: "20px",
            color:
              sortedColumn?.order === "ascend" &&
              sortedColumn?.column.key === key
                ? ThemeProperties.purple
                : ThemeProperties.greyTextColor,
            // marginBottom:"-15px",
            padding: "0px",
            marginBottom: "-10px",
            // margin:"0px"
          }}
        />
        <ArrowDropDownIcon
          sx={{
            fontSize: "20px",
            marginTop: "-40px",
            padding: "0px",
            // margin:"0px",
            color:
              sortedColumn?.order === "descend" &&
              sortedColumn?.column.key === key
                ? ThemeProperties.purple
                : ThemeProperties.greyTextColor,
          }}
        />
      </div> */}
    </div>
  );
};

const handleScroll = (arr) => {
  let x = 0;
  let elewidth = 0;
  let widthCount = 0;
  arr.forEach((ele) => {
    if (ele.hidden === false) {
      widthCount++;
      if (ele.width) {
        elewidth += ele.width;
        widthCount--;
      }
    }
  });
  let width = widthCount * 240 + elewidth;

  width > 600 ? (x = width) : (x = 0);

  return {
    x: x,
    y: 500,
  };
};

export { getColumnTitleSort, handleScroll };
