import { GetData } from "./Functions/SettingData";
async function postAddToCart(itemID) {
  var config = {
    method: "post",
    url: "/api/battery_stack/1",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      itemList: itemID,
      actionType: "Push",
    },
  };
  let data = GetData(config);
  return data;
}
async function postPopFromCart(itemID) {
  var config = {
    method: "post",
    url: "/api/battery_stack/1",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      itemList: itemID,
      actionType: "Pop",
    },
  };
  let data = GetData(config);
  return data;
}

//get number of Batteries present in cart
async function getCartBatteries(pageNumber,sort,order,rowsPerPage) {
  var config = {
    method: "get",
    url: `/api/battery_stack/${pageNumber}`,
    headers: {
      "Content-Type": "application/json",
    },

    params: {
      pageCount: rowsPerPage,
      sortBy: sort,
      order: order,
    },
  };
  let data = GetData(config);
  return data;
}

async function sendPostTakeAction(to, cc, bcc, selectedBatteries) {
  var config = {
    method: "post",
    url: `/api/mail_action`,
    data: {
      to: to,
      cc: cc,
      bcc: bcc,
      itemID: selectedBatteries,
    },
  };
  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries() {
  var config = {
    method: "get",
    url: `/api/global_selection_battery_stack`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = GetData(config);
  return data;
}

async function getCSVOfBatteries(tempIDs) {
  
    var rawJson = {
      itemList: tempIDs,
    };
  
    var config = {
      method: "post",
      url: `/api/battery_stack_csv`,
      headers: {
        "Content-Type": "application/json",
      },
      data: rawJson,
    };
  
    let data = GetData(config);
    return data;
  }

export {
  postAddToCart,
  postPopFromCart,
  getCartBatteries,
  sendPostTakeAction,
  getSelectAllBatteries,
  getCSVOfBatteries
};
