import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, DatePicker } from "antd";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import { formatDateNumeric } from "../DatePicker/DateFormatters";
import { useFetcher } from "react-router-dom";
import RemoveIcon from '@mui/icons-material/Remove';
// import "./datePicker.css";

const rangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
  },
  {
    label: "7D",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "14D",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "30D",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "45D",
    value: [dayjs().add(-45, "d"), dayjs()],
  },
];

const AntDatePickerReports = (props) => {
  const { RangePicker } = DatePicker;
  const [selectedDates, setSelectedDates] = useState([]);
  const [appliedDates, setAppliedDates] = useState(null);
  const [isDateRangePanelActive, setIsDateRangePanelActive] = useState(false);

  useEffect(() => {
    const handleRangePickerBlur = (event) => {
      const rangePickerElement = document.querySelector(".ant-picker-dropdown");
      const rangeInput = document.querySelector(".ant-picker");
      //if something outside rangeInput or range picker is clicked then close the range picker
      if (
        rangePickerElement &&
        rangeInput &&
        !rangePickerElement?.contains(event.target) &&
        !rangeInput?.contains(event.target) &&
        isDateRangePanelActive
      ) {
        setIsDateRangePanelActive(false);
      }
    };

    document.addEventListener("click", handleRangePickerBlur);
    return () => {
      document.removeEventListener("click", handleRangePickerBlur);
    };
  }, [isDateRangePanelActive]);

  useEffect(() => {
    const start =
      props.date[0] === undefined || props.date[0] === ""
        ? null
        : dayjs(props.date[0], "YYYY-MM-DD");
    const end =
      props.date[1] === undefined || props.date[1] === ""
        ? null
        : dayjs(props.date[1], "YYYY-MM-DD");
    // setValue([start,end])
    if (start === null && end === null) {
      setAppliedDates(null);
    } else {
      setAppliedDates([start, end]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date[0], props.date[1]]);

  const isPresetActive = (activeDates, presetValue) => {
    if (!activeDates || !presetValue) {
      return false;
    }
    const activeDateStart = dayjs(activeDates[0]);
    const activeDateEnd = dayjs(activeDates[1]);
    const presetValueStart = dayjs(presetValue[0]);
    const presetValueEnd = dayjs(presetValue[1]);
    return (
      activeDateStart.isSame(presetValueStart, "day") &&
      activeDateEnd.isSame(presetValueEnd, "day")
    );
  };

  const handleRangePickerChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleApplyRangePickerBtn = () => {
    setAppliedDates(selectedDates);

    setIsDateRangePanelActive(false);
    props.changeTheDate(
      formatDateNumeric(selectedDates[0]["$d"]),
      formatDateNumeric(selectedDates[1]["$d"])
    );
  };
  const renderExtraFooter = () => {
    return (
      <div style={{ float: "right", margin: "1rem" }}>
        <Button
          type="primary"
          disabled={!selectedDates?.[0] && !selectedDates?.[1]}
          onClick={handleApplyRangePickerBtn}
          style={{ marginRight: "1rem" }}
        >
          Apply
        </Button>
        <Button onClick={() => setIsDateRangePanelActive(false)}>Close</Button>
      </div>
    );
  };

  const handleOnOpenChange = () =>
    !isDateRangePanelActive && setIsDateRangePanelActive(true);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4F44E0",
          },
        }}
      >
        <div className="range-picker-container" style={{ display: "flex" }}>
          <RangePicker
            changeOnBlur={true}
            value={appliedDates}
            onChange={handleRangePickerChange}
            renderExtraFooter={renderExtraFooter}
            open={isDateRangePanelActive}
            onOpenChange={handleOnOpenChange}
            format={"DD MMM, YYYY"}
            separator={false}
            allowClear={false}
            disabledDate={(current) => {
              return (
                (current && current < dayjs().add(-365, "d")) ||
                current > dayjs().add(0, "d")
              );
            }}
            inputReadOnly={true}
            style={{ borderRadius: 0 }}
          />

          {rangePresets.map((preset) => {
            const { label, value } = preset;
            return (
              <Button
                onClick={() => {
                  props.changeTheDate(
                    formatDateNumeric(value[0]["$d"]),
                    formatDateNumeric(value[1]["$d"])
                  );
                  setAppliedDates(value);
                }}
                key={Math.random()}
                style={{ borderRadius: "0" }}
                type={
                  isPresetActive(appliedDates, value) ? "primary" : "default"
                }
              >
                {label}
              </Button>
            );
          })}
        </div>
      </ConfigProvider>
    </>
  );
};
export default AntDatePickerReports;
