const socFilter = [
  { text: "0% to 33%", value: "0% to 33%", isChecked: false },
  { text: "34% to 70%", value: "34% to 70%", isChecked: false },
  { text: "71% to 100%", value: "71% to 100%", isChecked: false },
];
const socFilter1 = [
  { text: "0% to 33%", value: "0% to 33%", isChecked: false },
  { text: "34% to 70%", value: "34% to 70%", isChecked: false },
  { text: "71% to 100%", value: "71% to 100%", isChecked: false },
];
const socFilter2 = [
  { text: "0% to 33%", value: "0% to 33%", isChecked: false },
  { text: "34% to 70%", value: "34% to 70%", isChecked: false },
  { text: "71% to 100%", value: "71% to 100%", isChecked: false },
];
const socFilterDiag = [
  { text: "0% to 33%", value: "0% to 33%", isChecked: false },
  { text: "34% to 70%", value: "34% to 70%", isChecked: false },
  { text: "71% to 100%", value: "71% to 100%", isChecked: false },
];
const partner = [
  { text: "1 to 2 years", value: "1 to 2 years", isChecked: false },
  { text: "2 to 3 years", value: "2 to 3 years", isChecked: false },
  { text: "3 to 4 years", value: "3 to 4 years", isChecked: false },
];
const partner1 = [
  {
    text: "Theft Batteries - ZNK",
    value: "Theft Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Export Activity - Dubai - ZNK",
    value: "Export Activity - Dubai - ZNK",
    isChecked: false,
  },
  { text: "Shiva Nilaya - ZNK", value: "Shiva Nilaya - ZNK", isChecked: false },
  { text: "Ravi Motors - ZNK", value: "Ravi Motors - ZNK", isChecked: false },
  {
    text: "E-Chakra Automotive - Replace ZNK",
    value: "E-Chakra Automotive - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering - LTR - ZNK",
    value: "Nexgen Engineering - LTR - ZNK",
    isChecked: false,
  },
  { text: "BRK-Syed - ZNK", value: "BRK-Syed - ZNK", isChecked: false },
  {
    text: "Sangeetha_Sheshadripuram- ZNK",
    value: "Sangeetha_Sheshadripuram- ZNK",
    isChecked: false,
  },
  {
    text: "E-Chakra Automotive - ZNK",
    value: "E-Chakra Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    value: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    isChecked: false,
  },
  { text: "Exicom - ZNK", value: "Exicom - ZNK", isChecked: false },
  { text: "watts auto - ZNK", value: "watts auto - ZNK", isChecked: false },
  { text: "Dreamz Car - ZNK", value: "Dreamz Car - ZNK", isChecked: false },
  {
    text: "Shree Venkateswara Service Station - ZNK",
    value: "Shree Venkateswara Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Auroville pilot Test Rides - ZNK",
    value: "Auroville pilot Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Moto Chronicles LLP - ZNK",
    value: "Moto Chronicles LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Harikrishna Fuel Station - ZNK",
    value: "Harikrishna Fuel Station - ZNK",
    isChecked: false,
  },
  { text: "BRK Abhilash - ZNK", value: "BRK Abhilash - ZNK", isChecked: false },
  {
    text: "Retrofit - Cochin - ZNK",
    value: "Retrofit - Cochin - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_HSR Layout-1- Replace ZNK",
    value: "SNG_HSR Layout-1- Replace ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Bank Layout- Replace ZNK",
    value: "SNG_Vijaya Bank Layout- Replace ZNK",
    isChecked: false,
  },
  { text: "Avinash - ZNK", value: "Avinash - ZNK", isChecked: false },
  {
    text: "Zeal Industries - ZNK",
    value: "Zeal Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Bgauss Homologation - ZNK",
    value: "Bgauss Homologation - ZNK",
    isChecked: false,
  },
  {
    text: "Batteries for Service Team-BAAS - ZNK",
    value: "Batteries for Service Team-BAAS - ZNK",
    isChecked: false,
  },
  {
    text: "Sri mukambika enterprises - ZNK",
    value: "Sri mukambika enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Coimbatore - ZNK",
    value: "Booma Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Rajajinagar EV Store - ZNK",
    value: "Rajajinagar EV Store - ZNK",
    isChecked: false,
  },
  {
    text: "JAYANAGAR METRO STATION - ZNK",
    value: "JAYANAGAR METRO STATION - ZNK",
    isChecked: false,
  },
  { text: "Marvel - ZNK", value: "Marvel - ZNK", isChecked: false },
  {
    text: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    value: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kottayam - ZNK",
    value: "Infinity Test Ride@Kottayam - ZNK",
    isChecked: false,
  },
  {
    text: "Metro Mobikes - ZNK",
    value: "Metro Mobikes - ZNK",
    isChecked: false,
  },
  {
    text: "Malleshwaram Car - ZNK",
    value: "Malleshwaram Car - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Ramamurthy nagar- Replace ZNK",
    value: "SNG_Ramamurthy nagar- Replace ZNK",
    isChecked: false,
  },
  { text: "Marutee - ZNK", value: "Marutee - ZNK", isChecked: false },
  { text: "Dominos - ZNK", value: "Dominos - ZNK", isChecked: false },
  {
    text: "Sangeetha_Gharepalya- ZNK",
    value: "Sangeetha_Gharepalya- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Chandra Layout-2- ZNK",
    value: "Sangeetha_Chandra Layout-2- ZNK",
    isChecked: false,
  },
  {
    text: "Celebration of Digital India Week - ZNK",
    value: "Celebration of Digital India Week - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ullal Main Road- ZNK",
    value: "Sangeetha_Ullal Main Road- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Girinagar - ZNK",
    value: "Sangeetha_Girinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    value: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    isChecked: false,
  },
  {
    text: "Gurukrupa Auto Service - ZNK",
    value: "Gurukrupa Auto Service - ZNK",
    isChecked: false,
  },
  { text: "R&D Zip - ZNK", value: "R&D Zip - ZNK", isChecked: false },
  {
    text: "OYO Akshaya Bunder Road - ZNK",
    value: "OYO Akshaya Bunder Road - ZNK",
    isChecked: false,
  },
  { text: "MOTO MECH - ZNK", value: "MOTO MECH - ZNK", isChecked: false },
  {
    text: "Nithin Enterprises - ZNK",
    value: "Nithin Enterprises - ZNK",
    isChecked: false,
  },
  { text: "E Wheels - ZNK", value: "E Wheels - ZNK", isChecked: false },
  {
    text: "Orissa Testing - ZNK",
    value: "Orissa Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kundapur - ZNK",
    value: "Infinity Test Ride@Kundapur - ZNK",
    isChecked: false,
  },
  { text: "Arya Motors - ZNK", value: "Arya Motors - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Abbigere - ZNK",
    value: "T11 E Mobility Abbigere - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sarakki- ZNK",
    value: "Sangeetha_Sarakki- ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub QC - ZNK",
    value: "Brookfield Hub QC - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ramamurthy nagar- ZNK",
    value: "Sangeetha_Ramamurthy nagar- ZNK",
    isChecked: false,
  },
  { text: "Bounce RnD - ZNK", value: "Bounce RnD - ZNK", isChecked: false },
  {
    text: "Pratham Motors 2 - ZNK",
    value: "Pratham Motors 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Project - ZNK",
    value: "Jaipur Project - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub-Consumer Swapping - ZNK",
    value: "Brookfield Hub-Consumer Swapping - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Nandini Layout - ZNK",
    value: "Sangeetha_Nandini Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Varthur - ZNK",
    value: "Sangeetha_Varthur - ZNK",
    isChecked: false,
  },
  {
    text: "Microcon Jayanagar - ZNK",
    value: "Microcon Jayanagar - ZNK",
    isChecked: false,
  },
  {
    text: "Offline Partners - ZNK",
    value: "Offline Partners - ZNK",
    isChecked: false,
  },
  { text: "ARAI - ZNK", value: "ARAI - ZNK", isChecked: false },
  { text: "22 Motors - ZNK", value: "22 Motors - ZNK", isChecked: false },
  {
    text: "Goodera Testing - ZNK",
    value: "Goodera Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Amoggh E Motors - ZNK",
    value: "Amoggh E Motors - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Mallasandra- Replace ZNK",
    value: "SNG_Mallasandra- Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    value: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajajinagar-1- ZNK",
    value: "Sangeetha_Rajajinagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride Even @ Pune - ZNK",
    value: "Infinity Test Ride Even @ Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-1- ZNK",
    value: "Sangeetha_HSR Layout-1- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity BTM - ZNK",
    value: "Bounce Infinity BTM - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    value: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    value: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mahadevapura - ZNK",
    value: "Sangeetha_Mahadevapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    value: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Low speed Project - ZNK",
    value: "Low speed Project - ZNK",
    isChecked: false,
  },
  {
    text: "Virya Mobility - ZNK",
    value: "Virya Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E Mobility Solutions 2 - ZNK",
    value: "T11 E Mobility Solutions 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Shankar - ZNK",
    value: "Jaipur Shankar - ZNK",
    isChecked: false,
  },
  {
    text: "Orient Auto Spares - ZNK",
    value: "Orient Auto Spares - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Puram - ZNK",
    value: "Sangeetha_KR Puram - ZNK",
    isChecked: false,
  },
  {
    text: "Surya Candles & Camphor Industries - ZNK",
    value: "Surya Candles & Camphor Industries - ZNK",
    isChecked: false,
  },
  {
    text: "S B Enterprises - ZNK",
    value: "S B Enterprises - ZNK",
    isChecked: false,
  },
  { text: "Paris Event - ZNK", value: "Paris Event - ZNK", isChecked: false },
  {
    text: "Popular Fuel Center - ZNK",
    value: "Popular Fuel Center - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kogilu- ZNK",
    value: "Sangeetha_Kogilu- ZNK",
    isChecked: false,
  },
  {
    text: "SNG_KR Puram - Replace ZNK",
    value: "SNG_KR Puram - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    value: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    isChecked: false,
  },
  {
    text: "COCOCANE Bilekalli - ZNK",
    value: "COCOCANE Bilekalli - ZNK",
    isChecked: false,
  },
  {
    text: "Ravi Kumar chief secretary - ZNK",
    value: "Ravi Kumar chief secretary - ZNK",
    isChecked: false,
  },
  { text: "Turkey - ZNK", value: "Turkey - ZNK", isChecked: false },
  {
    text: "Gurukrupa Service Station - ZNK",
    value: "Gurukrupa Service Station - ZNK",
    isChecked: false,
  },
  { text: "NFN Labs - ZNK", value: "NFN Labs - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Sirsi - ZNK",
    value: "Infinity Test Ride@Sirsi - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Road- ZNK",
    value: "Sangeetha_KR Road- ZNK",
    isChecked: false,
  },
  {
    text: "Collection O Halcyon Hitech  city - ZNK",
    value: "Collection O Halcyon Hitech  city - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Madiwala-2 - ZNK",
    value: "Sangeetha_Madiwala-2 - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    value: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Anjanadri Petro Services - ZNK",
    value: "Anjanadri Petro Services - ZNK",
    isChecked: false,
  },
  {
    text: "Peenya Parking - ZNK",
    value: "Peenya Parking - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Godown Goodera. - ZNK",
    value: "Bounce Godown Goodera. - ZNK",
    isChecked: false,
  },
  {
    text: "Arya Motors - Replace ZNK",
    value: "Arya Motors - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Fraser Town-2",
    value: "Sangeetha_Fraser Town-2",
    isChecked: false,
  },
  {
    text: "Sangeetha_Jayanagar 9th Block - ZNK",
    value: "Sangeetha_Jayanagar 9th Block - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Brigade Road - ZNK",
    value: "Sangeetha_Brigade Road - ZNK",
    isChecked: false,
  },
  { text: "Amazon - ZNK", value: "Amazon - ZNK", isChecked: false },
  {
    text: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    value: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    isChecked: false,
  },
  {
    text: "Charam Motors - ZNK",
    value: "Charam Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Demo Pune PCMC - ZNK",
    value: "Demo Pune PCMC - ZNK",
    isChecked: false,
  },
  {
    text: "Paras Industries - ZNK",
    value: "Paras Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Manikantha sarathy jewellers - ZNK",
    value: "Manikantha sarathy jewellers - ZNK",
    isChecked: false,
  },
  {
    text: "Siddappa Enterprise - ZNK",
    value: "Siddappa Enterprise - ZNK",
    isChecked: false,
  },
  { text: "Natrax - ZNK", value: "Natrax - ZNK", isChecked: false },
  {
    text: "Infinity service mumbai - ZNK",
    value: "Infinity service mumbai - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_8th Mile Circle - ZNK",
    value: "Sangeetha_8th Mile Circle - ZNK",
    isChecked: false,
  },
  { text: "ICAT - ZNK", value: "ICAT - ZNK", isChecked: false },
  {
    text: "T 11 E Mobility Solutions - ZNK",
    value: "T 11 E Mobility Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Zero 21 - Nagpur - ZNK",
    value: "Zero 21 - Nagpur - ZNK",
    isChecked: false,
  },
  { text: "EMF - ZNK", value: "EMF - ZNK", isChecked: false },
  {
    text: "Flow Mobility - ZNK",
    value: "Flow Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service ahmedabad - ZNK",
    value: "Infinity service ahmedabad - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_MS Palya - ZNK",
    value: "Sangeetha_MS Palya - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    value: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    isChecked: false,
  },
  {
    text: "Platinum Petro Park - ZNK",
    value: "Platinum Petro Park - ZNK",
    isChecked: false,
  },
  { text: "Cococane - ZNK", value: "Cococane - ZNK", isChecked: false },
  {
    text: "Sangeetha_HBR Layout - ZNK",
    value: "Sangeetha_HBR Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Batteries Nagarjun - ZNK",
    value: "Testing Batteries Nagarjun - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Uttarahalli-1- ZNK",
    value: "Sangeetha_Uttarahalli-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    value: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar - ZNK",
    value: "Bounce Infinity Rajajinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Byadarahalli- ZNK",
    value: "Sangeetha_Byadarahalli- ZNK",
    isChecked: false,
  },
  { text: "Tusha Motors - ZNK", value: "Tusha Motors - ZNK", isChecked: false },
  {
    text: "Sangeetha_Mallasandra- ZNK",
    value: "Sangeetha_Mallasandra- ZNK",
    isChecked: false,
  },
  {
    text: "Charging Station Warehouse - ZNK",
    value: "Charging Station Warehouse - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bank Colony - ZNK",
    value: "Sangeetha_Bank Colony - ZNK",
    isChecked: false,
  },
  { text: "K2K Ride - ZNK", value: "K2K Ride - ZNK", isChecked: false },
  {
    text: "SNG_Byadarahalli- Replace ZNK",
    value: "SNG_Byadarahalli- Replace ZNK",
    isChecked: false,
  },
  { text: "Smart Cars - ZNK", value: "Smart Cars - ZNK", isChecked: false },
  {
    text: "S Anand Kumar - ZNK",
    value: "S Anand Kumar - ZNK",
    isChecked: false,
  },
  { text: "ASI Jigani - ZNK", value: "ASI Jigani - ZNK", isChecked: false },
  { text: "ARN Office - ZNK", value: "ARN Office - ZNK", isChecked: false },
  {
    text: "Infinity E1 Deliveries-Customer - ZNK",
    value: "Infinity E1 Deliveries-Customer - ZNK",
    isChecked: false,
  },
  {
    text: "SDM Enterprises - ZNK",
    value: "SDM Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bannerghatta-2- ZNK",
    value: "Sangeetha_Bannerghatta-2- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    value: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    isChecked: false,
  },
  {
    text: "Dhobiwala LTR - ZNK",
    value: "Dhobiwala LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Retrofit R&D project - ZNK",
    value: "Retrofit R&D project - ZNK",
    isChecked: false,
  },
  {
    text: "My Tire store - ZNK",
    value: "My Tire store - ZNK",
    isChecked: false,
  },
  {
    text: "Pratham Motors Bellandur - ZNK",
    value: "Pratham Motors Bellandur - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Gurukrupa Fuel Junction - ZNK",
    value: "Sri Gurukrupa Fuel Junction - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Bank Layout- ZNK",
    value: "Sangeetha_Vijaya Bank Layout- ZNK",
    isChecked: false,
  },
  {
    text: "BER Batteries - ZNK",
    value: "BER Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Hyderabad LTR - ZNK",
    value: "Hyderabad LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Horamavu - ZNK",
    value: "Sangeetha_Horamavu - ZNK",
    isChecked: false,
  },
  { text: "Hassan - ZNK", value: "Hassan - ZNK", isChecked: false },
  {
    text: "Savani Service Station - ZNK",
    value: "Savani Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides - ZNK",
    value: "Infinity Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Malleswaram - ZNK",
    value: "Sangeetha_Malleswaram - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    value: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_8th Mile Circle Replace - ZNK",
    value: "SNG_8th Mile Circle Replace - ZNK",
    isChecked: false,
  },
  {
    text: "Mayya dose camp - ZNK",
    value: "Mayya dose camp - ZNK",
    isChecked: false,
  },
  {
    text: "South-Africa-NKN - ZNK",
    value: "South-Africa-NKN - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub - ZNK",
    value: "Brookfield Hub - ZNK",
    isChecked: false,
  },
  { text: "Cygni - ZNK", value: "Cygni - ZNK", isChecked: false },
  {
    text: "DD Supermarket - ZNK",
    value: "DD Supermarket - ZNK",
    isChecked: false,
  },
  { text: "EZ Auto Care - ZNK", value: "EZ Auto Care - ZNK", isChecked: false },
  {
    text: "Charger House Facility Hyd - ZNK",
    value: "Charger House Facility Hyd - ZNK",
    isChecked: false,
  },
  {
    text: "B R Automotive - ZNK",
    value: "B R Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "KB Moto world - ZNK",
    value: "KB Moto world - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Pune - ZNK",
    value: "Infinity service Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Kumaran Engineering Works - ZNK",
    value: "Kumaran Engineering Works - ZNK",
    isChecked: false,
  },
  { text: "Coulomb AI - ZNK", value: "Coulomb AI - ZNK", isChecked: false },
  {
    text: "Silverkey Executive Suites - ZNK",
    value: "Silverkey Executive Suites - ZNK",
    isChecked: false,
  },
  { text: "Tyres World - ZNK", value: "Tyres World - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Coimbatore - ZNK",
    value: "Infinity Test Ride@Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Mohammad Ali Mulla - ZNK",
    value: "Mohammad Ali Mulla - ZNK",
    isChecked: false,
  },
  {
    text: "MG ROAD METRO STATION - ZNK",
    value: "MG ROAD METRO STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - London - ZNK",
    value: "Export Acivity - London - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kathriguppe- ZNK",
    value: "Sangeetha_Kathriguppe- ZNK",
    isChecked: false,
  },
  { text: "Ampere - ZNK", value: "Ampere - ZNK", isChecked: false },
  { text: "GOA CSC - ZNK", value: "GOA CSC - ZNK", isChecked: false },
  {
    text: "Sangeetha_JC Road - ZNK",
    value: "Sangeetha_JC Road - ZNK",
    isChecked: false,
  },
  {
    text: "Green Drive motors - ZNK",
    value: "Green Drive motors - ZNK",
    isChecked: false,
  },
  {
    text: "Moon Engineering services Shop - ZNK",
    value: "Moon Engineering services Shop - ZNK",
    isChecked: false,
  },
  { text: "Metro One - ZNK", value: "Metro One - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    value: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "Powertronix systems limited - ZNK",
    value: "Powertronix systems limited - ZNK",
    isChecked: false,
  },
  { text: "My EV Store - ZNK", value: "My EV Store - ZNK", isChecked: false },
  {
    text: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    value: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    isChecked: false,
  },
  { text: "VOGO - ZNK", value: "VOGO - ZNK", isChecked: false },
  {
    text: "Lico Materials Pvt Ltd - ZNK",
    value: "Lico Materials Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering Pvt Ltd - ZNK",
    value: "Nexgen Engineering Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar Replace - ZNK",
    value: "Bounce Infinity Rajajinagar Replace - ZNK",
    isChecked: false,
  },
  { text: "Motostrokes - ZNK", value: "Motostrokes - ZNK", isChecked: false },
  {
    text: "Sangeetha_Whitefield-1 - ZNK",
    value: "Sangeetha_Whitefield-1 - ZNK",
    isChecked: false,
  },
  { text: "Ather - ZNK", value: "Ather - ZNK", isChecked: false },
  { text: "Helloworld - ZNK", value: "Helloworld - ZNK", isChecked: false },
  {
    text: "Divish Mobility Pune - ZNK",
    value: "Divish Mobility Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Bhiwadi Plant - ZNK",
    value: "Bhiwadi Plant - ZNK",
    isChecked: false,
  },
  {
    text: "T11 Mobility Vidyaranyapura - ZNK",
    value: "T11 Mobility Vidyaranyapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_RT Nagar-1- ZNK",
    value: "Sangeetha_RT Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sahakarnagar- ZNK",
    value: "Sangeetha_Sahakarnagar- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Gandhibazar - ZNK",
    value: "Sangeetha_Gandhibazar - ZNK",
    isChecked: false,
  },
  {
    text: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    value: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "SO Infinity Test Rides - Venu - ZNK",
    value: "SO Infinity Test Rides - Venu - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom trials - ZNK",
    value: "Exicom trials - ZNK",
    isChecked: false,
  },
  {
    text: "Prashanth tyres - ZNK",
    value: "Prashanth tyres - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom Gurgaon - ZNK",
    value: "Exicom Gurgaon - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kempapura- ZNK",
    value: "Sangeetha_Kempapura- ZNK",
    isChecked: false,
  },
  {
    text: "Warehouse Non Operational - ZNK",
    value: "Warehouse Non Operational - ZNK",
    isChecked: false,
  },
  {
    text: "Cococane RR Nagar - ZNK",
    value: "Cococane RR Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "BSS Fire Suppression Test - ZNK",
    value: "BSS Fire Suppression Test - ZNK",
    isChecked: false,
  },
  {
    text: "FPI 48V Batteries - ZNK",
    value: "FPI 48V Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Aniket (IIITB) - ZNK",
    value: "Aniket (IIITB) - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hebbagodi- ZNK",
    value: "Sangeetha_Hebbagodi- ZNK",
    isChecked: false,
  },
  {
    text: "Lectrix India - ZNK",
    value: "Lectrix India - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Testing - ZNK",
    value: "Booma Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kumaraswamy Layout- ZNK",
    value: "Sangeetha_Kumaraswamy Layout- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Konanakunte - ZNK",
    value: "Sangeetha_Konanakunte - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Ganesh Battery - ZNK",
    value: "Sri Ganesh Battery - ZNK",
    isChecked: false,
  },
  {
    text: "Meenakshi Motors - ZNK",
    value: "Meenakshi Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Banashankari - ZNK",
    value: "Sangeetha_Banashankari - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    value: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    isChecked: false,
  },
  {
    text: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    value: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    isChecked: false,
  },
  {
    text: "Napino Auto Testing - ZNK",
    value: "Napino Auto Testing - ZNK",
    isChecked: false,
  },
  {
    text: "EV Infra Testing - ZNK",
    value: "EV Infra Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mathikere- ZNK",
    value: "Sangeetha_Mathikere- ZNK",
    isChecked: false,
  },
  {
    text: "G.R.Subramani & Company - ZNK",
    value: "G.R.Subramani & Company - ZNK",
    isChecked: false,
  },
  { text: "Aventors - ZNK", value: "Aventors - ZNK", isChecked: false },
  {
    text: "Azn-Domlur Battery Station - ZNK",
    value: "Azn-Domlur Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Advandes Design Engineering Services LLP - ZNK",
    value: "Advandes Design Engineering Services LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Esmito Solutions - ZNK",
    value: "Esmito Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Padmaprakash Fuel Park - ZNK",
    value: "Padmaprakash Fuel Park - ZNK",
    isChecked: false,
  },
  {
    text: "SAMAHITA FUELING STATION - ZNK",
    value: "SAMAHITA FUELING STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Srikhantan - ZNK",
    value: "Testing Srikhantan - ZNK",
    isChecked: false,
  },
  {
    text: "Ganesh General Store - ZNK",
    value: "Ganesh General Store - ZNK",
    isChecked: false,
  },
  {
    text: "ARK Enterprises - ZNK",
    value: "ARK Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub Non Operational Inventory - ZNK",
    value: "Brookfield Hub Non Operational Inventory - ZNK",
    isChecked: false,
  },
  {
    text: "Privian Compuforms - ZNK",
    value: "Privian Compuforms - ZNK",
    isChecked: false,
  },
  { text: "Test001 - ZNK", value: "Test001 - ZNK", isChecked: false },
  {
    text: "Varsha Farm Equipment - ZNK",
    value: "Varsha Farm Equipment - ZNK",
    isChecked: false,
  },
  { text: "THRISHULAVEL - ZNK", value: "THRISHULAVEL - ZNK", isChecked: false },
  {
    text: "Go Native LTR - ZNK",
    value: "Go Native LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sree Ganga Firms - ZNK",
    value: "Sree Ganga Firms - ZNK",
    isChecked: false,
  },
  { text: "56 Secure - ZNK", value: "56 Secure - ZNK", isChecked: false },
  {
    text: "Sri Sai Sirish Enterprises - ZNK",
    value: "Sri Sai Sirish Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Azn-HSR Battery Station - ZNK",
    value: "Azn-HSR Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ananthnagar - ZNK",
    value: "Sangeetha_Ananthnagar - ZNK",
    isChecked: false,
  },
  {
    text: "Active Motors - ZNK",
    value: "Active Motors - ZNK",
    isChecked: false,
  },
  { text: "Jaipur Ajay - ZNK", value: "Jaipur Ajay - ZNK", isChecked: false },
  {
    text: "Infinity service hyderabad - ZNK",
    value: "Infinity service hyderabad - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Delhi - ZNK",
    value: "Infinity service Delhi - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - Turkey - ZNK",
    value: "Export Acivity - Turkey - ZNK",
    isChecked: false,
  },
  { text: "Battery Pool - ZNK", value: "Battery Pool - ZNK", isChecked: false },
  {
    text: "Maxcel Rental - ZNK",
    value: "Maxcel Rental - ZNK",
    isChecked: false,
  },
  {
    text: "Samakya Elecrtical - ZNK",
    value: "Samakya Elecrtical - ZNK",
    isChecked: false,
  },
];

const partner2 = [
  {
    text: "Theft Batteries - ZNK",
    value: "Theft Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Export Activity - Dubai - ZNK",
    value: "Export Activity - Dubai - ZNK",
    isChecked: false,
  },
  { text: "Shiva Nilaya - ZNK", value: "Shiva Nilaya - ZNK", isChecked: false },
  { text: "Ravi Motors - ZNK", value: "Ravi Motors - ZNK", isChecked: false },
  {
    text: "E-Chakra Automotive - Replace ZNK",
    value: "E-Chakra Automotive - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering - LTR - ZNK",
    value: "Nexgen Engineering - LTR - ZNK",
    isChecked: false,
  },
  { text: "BRK-Syed - ZNK", value: "BRK-Syed - ZNK", isChecked: false },
  {
    text: "Sangeetha_Sheshadripuram- ZNK",
    value: "Sangeetha_Sheshadripuram- ZNK",
    isChecked: false,
  },
  {
    text: "E-Chakra Automotive - ZNK",
    value: "E-Chakra Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    value: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    isChecked: false,
  },
  { text: "Exicom - ZNK", value: "Exicom - ZNK", isChecked: false },
  { text: "watts auto - ZNK", value: "watts auto - ZNK", isChecked: false },
  { text: "Dreamz Car - ZNK", value: "Dreamz Car - ZNK", isChecked: false },
  {
    text: "Shree Venkateswara Service Station - ZNK",
    value: "Shree Venkateswara Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Auroville pilot Test Rides - ZNK",
    value: "Auroville pilot Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Moto Chronicles LLP - ZNK",
    value: "Moto Chronicles LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Harikrishna Fuel Station - ZNK",
    value: "Harikrishna Fuel Station - ZNK",
    isChecked: false,
  },
  { text: "BRK Abhilash - ZNK", value: "BRK Abhilash - ZNK", isChecked: false },
  {
    text: "Retrofit - Cochin - ZNK",
    value: "Retrofit - Cochin - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_HSR Layout-1- Replace ZNK",
    value: "SNG_HSR Layout-1- Replace ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Bank Layout- Replace ZNK",
    value: "SNG_Vijaya Bank Layout- Replace ZNK",
    isChecked: false,
  },
  { text: "Avinash - ZNK", value: "Avinash - ZNK", isChecked: false },
  {
    text: "Zeal Industries - ZNK",
    value: "Zeal Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Bgauss Homologation - ZNK",
    value: "Bgauss Homologation - ZNK",
    isChecked: false,
  },
  {
    text: "Batteries for Service Team-BAAS - ZNK",
    value: "Batteries for Service Team-BAAS - ZNK",
    isChecked: false,
  },
  {
    text: "Sri mukambika enterprises - ZNK",
    value: "Sri mukambika enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Coimbatore - ZNK",
    value: "Booma Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Rajajinagar EV Store - ZNK",
    value: "Rajajinagar EV Store - ZNK",
    isChecked: false,
  },
  {
    text: "JAYANAGAR METRO STATION - ZNK",
    value: "JAYANAGAR METRO STATION - ZNK",
    isChecked: false,
  },
  { text: "Marvel - ZNK", value: "Marvel - ZNK", isChecked: false },
  {
    text: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    value: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kottayam - ZNK",
    value: "Infinity Test Ride@Kottayam - ZNK",
    isChecked: false,
  },
  {
    text: "Metro Mobikes - ZNK",
    value: "Metro Mobikes - ZNK",
    isChecked: false,
  },
  {
    text: "Malleshwaram Car - ZNK",
    value: "Malleshwaram Car - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Ramamurthy nagar- Replace ZNK",
    value: "SNG_Ramamurthy nagar- Replace ZNK",
    isChecked: false,
  },
  { text: "Marutee - ZNK", value: "Marutee - ZNK", isChecked: false },
  { text: "Dominos - ZNK", value: "Dominos - ZNK", isChecked: false },
  {
    text: "Sangeetha_Gharepalya- ZNK",
    value: "Sangeetha_Gharepalya- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Chandra Layout-2- ZNK",
    value: "Sangeetha_Chandra Layout-2- ZNK",
    isChecked: false,
  },
  {
    text: "Celebration of Digital India Week - ZNK",
    value: "Celebration of Digital India Week - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ullal Main Road- ZNK",
    value: "Sangeetha_Ullal Main Road- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Girinagar - ZNK",
    value: "Sangeetha_Girinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    value: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    isChecked: false,
  },
  {
    text: "Gurukrupa Auto Service - ZNK",
    value: "Gurukrupa Auto Service - ZNK",
    isChecked: false,
  },
  { text: "R&D Zip - ZNK", value: "R&D Zip - ZNK", isChecked: false },
  {
    text: "OYO Akshaya Bunder Road - ZNK",
    value: "OYO Akshaya Bunder Road - ZNK",
    isChecked: false,
  },
  { text: "MOTO MECH - ZNK", value: "MOTO MECH - ZNK", isChecked: false },
  {
    text: "Nithin Enterprises - ZNK",
    value: "Nithin Enterprises - ZNK",
    isChecked: false,
  },
  { text: "E Wheels - ZNK", value: "E Wheels - ZNK", isChecked: false },
  {
    text: "Orissa Testing - ZNK",
    value: "Orissa Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kundapur - ZNK",
    value: "Infinity Test Ride@Kundapur - ZNK",
    isChecked: false,
  },
  { text: "Arya Motors - ZNK", value: "Arya Motors - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Abbigere - ZNK",
    value: "T11 E Mobility Abbigere - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sarakki- ZNK",
    value: "Sangeetha_Sarakki- ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub QC - ZNK",
    value: "Brookfield Hub QC - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ramamurthy nagar- ZNK",
    value: "Sangeetha_Ramamurthy nagar- ZNK",
    isChecked: false,
  },
  { text: "Bounce RnD - ZNK", value: "Bounce RnD - ZNK", isChecked: false },
  {
    text: "Pratham Motors 2 - ZNK",
    value: "Pratham Motors 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Project - ZNK",
    value: "Jaipur Project - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub-Consumer Swapping - ZNK",
    value: "Brookfield Hub-Consumer Swapping - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Nandini Layout - ZNK",
    value: "Sangeetha_Nandini Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Varthur - ZNK",
    value: "Sangeetha_Varthur - ZNK",
    isChecked: false,
  },
  {
    text: "Microcon Jayanagar - ZNK",
    value: "Microcon Jayanagar - ZNK",
    isChecked: false,
  },
  {
    text: "Offline Partners - ZNK",
    value: "Offline Partners - ZNK",
    isChecked: false,
  },
  { text: "ARAI - ZNK", value: "ARAI - ZNK", isChecked: false },
  { text: "22 Motors - ZNK", value: "22 Motors - ZNK", isChecked: false },
  {
    text: "Goodera Testing - ZNK",
    value: "Goodera Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Amoggh E Motors - ZNK",
    value: "Amoggh E Motors - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Mallasandra- Replace ZNK",
    value: "SNG_Mallasandra- Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    value: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajajinagar-1- ZNK",
    value: "Sangeetha_Rajajinagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride Even @ Pune - ZNK",
    value: "Infinity Test Ride Even @ Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-1- ZNK",
    value: "Sangeetha_HSR Layout-1- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity BTM - ZNK",
    value: "Bounce Infinity BTM - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    value: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    value: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mahadevapura - ZNK",
    value: "Sangeetha_Mahadevapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    value: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Low speed Project - ZNK",
    value: "Low speed Project - ZNK",
    isChecked: false,
  },
  {
    text: "Virya Mobility - ZNK",
    value: "Virya Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E Mobility Solutions 2 - ZNK",
    value: "T11 E Mobility Solutions 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Shankar - ZNK",
    value: "Jaipur Shankar - ZNK",
    isChecked: false,
  },
  {
    text: "Orient Auto Spares - ZNK",
    value: "Orient Auto Spares - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Puram - ZNK",
    value: "Sangeetha_KR Puram - ZNK",
    isChecked: false,
  },
  {
    text: "Surya Candles & Camphor Industries - ZNK",
    value: "Surya Candles & Camphor Industries - ZNK",
    isChecked: false,
  },
  {
    text: "S B Enterprises - ZNK",
    value: "S B Enterprises - ZNK",
    isChecked: false,
  },
  { text: "Paris Event - ZNK", value: "Paris Event - ZNK", isChecked: false },
  {
    text: "Popular Fuel Center - ZNK",
    value: "Popular Fuel Center - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kogilu- ZNK",
    value: "Sangeetha_Kogilu- ZNK",
    isChecked: false,
  },
  {
    text: "SNG_KR Puram - Replace ZNK",
    value: "SNG_KR Puram - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    value: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    isChecked: false,
  },
  {
    text: "COCOCANE Bilekalli - ZNK",
    value: "COCOCANE Bilekalli - ZNK",
    isChecked: false,
  },
  {
    text: "Ravi Kumar chief secretary - ZNK",
    value: "Ravi Kumar chief secretary - ZNK",
    isChecked: false,
  },
  { text: "Turkey - ZNK", value: "Turkey - ZNK", isChecked: false },
  {
    text: "Gurukrupa Service Station - ZNK",
    value: "Gurukrupa Service Station - ZNK",
    isChecked: false,
  },
  { text: "NFN Labs - ZNK", value: "NFN Labs - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Sirsi - ZNK",
    value: "Infinity Test Ride@Sirsi - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Road- ZNK",
    value: "Sangeetha_KR Road- ZNK",
    isChecked: false,
  },
  {
    text: "Collection O Halcyon Hitech  city - ZNK",
    value: "Collection O Halcyon Hitech  city - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Madiwala-2 - ZNK",
    value: "Sangeetha_Madiwala-2 - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    value: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Anjanadri Petro Services - ZNK",
    value: "Anjanadri Petro Services - ZNK",
    isChecked: false,
  },
  {
    text: "Peenya Parking - ZNK",
    value: "Peenya Parking - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Godown Goodera. - ZNK",
    value: "Bounce Godown Goodera. - ZNK",
    isChecked: false,
  },
  {
    text: "Arya Motors - Replace ZNK",
    value: "Arya Motors - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Fraser Town-2",
    value: "Sangeetha_Fraser Town-2",
    isChecked: false,
  },
  {
    text: "Sangeetha_Jayanagar 9th Block - ZNK",
    value: "Sangeetha_Jayanagar 9th Block - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Brigade Road - ZNK",
    value: "Sangeetha_Brigade Road - ZNK",
    isChecked: false,
  },
  { text: "Amazon - ZNK", value: "Amazon - ZNK", isChecked: false },
  {
    text: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    value: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    isChecked: false,
  },
  {
    text: "Charam Motors - ZNK",
    value: "Charam Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Demo Pune PCMC - ZNK",
    value: "Demo Pune PCMC - ZNK",
    isChecked: false,
  },
  {
    text: "Paras Industries - ZNK",
    value: "Paras Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Manikantha sarathy jewellers - ZNK",
    value: "Manikantha sarathy jewellers - ZNK",
    isChecked: false,
  },
  {
    text: "Siddappa Enterprise - ZNK",
    value: "Siddappa Enterprise - ZNK",
    isChecked: false,
  },
  { text: "Natrax - ZNK", value: "Natrax - ZNK", isChecked: false },
  {
    text: "Infinity service mumbai - ZNK",
    value: "Infinity service mumbai - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_8th Mile Circle - ZNK",
    value: "Sangeetha_8th Mile Circle - ZNK",
    isChecked: false,
  },
  { text: "ICAT - ZNK", value: "ICAT - ZNK", isChecked: false },
  {
    text: "T 11 E Mobility Solutions - ZNK",
    value: "T 11 E Mobility Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Zero 21 - Nagpur - ZNK",
    value: "Zero 21 - Nagpur - ZNK",
    isChecked: false,
  },
  { text: "EMF - ZNK", value: "EMF - ZNK", isChecked: false },
  {
    text: "Flow Mobility - ZNK",
    value: "Flow Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service ahmedabad - ZNK",
    value: "Infinity service ahmedabad - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_MS Palya - ZNK",
    value: "Sangeetha_MS Palya - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    value: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    isChecked: false,
  },
  {
    text: "Platinum Petro Park - ZNK",
    value: "Platinum Petro Park - ZNK",
    isChecked: false,
  },
  { text: "Cococane - ZNK", value: "Cococane - ZNK", isChecked: false },
  {
    text: "Sangeetha_HBR Layout - ZNK",
    value: "Sangeetha_HBR Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Batteries Nagarjun - ZNK",
    value: "Testing Batteries Nagarjun - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Uttarahalli-1- ZNK",
    value: "Sangeetha_Uttarahalli-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    value: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar - ZNK",
    value: "Bounce Infinity Rajajinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Byadarahalli- ZNK",
    value: "Sangeetha_Byadarahalli- ZNK",
    isChecked: false,
  },
  { text: "Tusha Motors - ZNK", value: "Tusha Motors - ZNK", isChecked: false },
  {
    text: "Sangeetha_Mallasandra- ZNK",
    value: "Sangeetha_Mallasandra- ZNK",
    isChecked: false,
  },
  {
    text: "Charging Station Warehouse - ZNK",
    value: "Charging Station Warehouse - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bank Colony - ZNK",
    value: "Sangeetha_Bank Colony - ZNK",
    isChecked: false,
  },
  { text: "K2K Ride - ZNK", value: "K2K Ride - ZNK", isChecked: false },
  {
    text: "SNG_Byadarahalli- Replace ZNK",
    value: "SNG_Byadarahalli- Replace ZNK",
    isChecked: false,
  },
  { text: "Smart Cars - ZNK", value: "Smart Cars - ZNK", isChecked: false },
  {
    text: "S Anand Kumar - ZNK",
    value: "S Anand Kumar - ZNK",
    isChecked: false,
  },
  { text: "ASI Jigani - ZNK", value: "ASI Jigani - ZNK", isChecked: false },
  { text: "ARN Office - ZNK", value: "ARN Office - ZNK", isChecked: false },
  {
    text: "Infinity E1 Deliveries-Customer - ZNK",
    value: "Infinity E1 Deliveries-Customer - ZNK",
    isChecked: false,
  },
  {
    text: "SDM Enterprises - ZNK",
    value: "SDM Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bannerghatta-2- ZNK",
    value: "Sangeetha_Bannerghatta-2- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    value: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    isChecked: false,
  },
  {
    text: "Dhobiwala LTR - ZNK",
    value: "Dhobiwala LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Retrofit R&D project - ZNK",
    value: "Retrofit R&D project - ZNK",
    isChecked: false,
  },
  {
    text: "My Tire store - ZNK",
    value: "My Tire store - ZNK",
    isChecked: false,
  },
  {
    text: "Pratham Motors Bellandur - ZNK",
    value: "Pratham Motors Bellandur - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Gurukrupa Fuel Junction - ZNK",
    value: "Sri Gurukrupa Fuel Junction - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Bank Layout- ZNK",
    value: "Sangeetha_Vijaya Bank Layout- ZNK",
    isChecked: false,
  },
  {
    text: "BER Batteries - ZNK",
    value: "BER Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Hyderabad LTR - ZNK",
    value: "Hyderabad LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Horamavu - ZNK",
    value: "Sangeetha_Horamavu - ZNK",
    isChecked: false,
  },
  { text: "Hassan - ZNK", value: "Hassan - ZNK", isChecked: false },
  {
    text: "Savani Service Station - ZNK",
    value: "Savani Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides - ZNK",
    value: "Infinity Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Malleswaram - ZNK",
    value: "Sangeetha_Malleswaram - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    value: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_8th Mile Circle Replace - ZNK",
    value: "SNG_8th Mile Circle Replace - ZNK",
    isChecked: false,
  },
  {
    text: "Mayya dose camp - ZNK",
    value: "Mayya dose camp - ZNK",
    isChecked: false,
  },
  {
    text: "South-Africa-NKN - ZNK",
    value: "South-Africa-NKN - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub - ZNK",
    value: "Brookfield Hub - ZNK",
    isChecked: false,
  },
  { text: "Cygni - ZNK", value: "Cygni - ZNK", isChecked: false },
  {
    text: "DD Supermarket - ZNK",
    value: "DD Supermarket - ZNK",
    isChecked: false,
  },
  { text: "EZ Auto Care - ZNK", value: "EZ Auto Care - ZNK", isChecked: false },
  {
    text: "Charger House Facility Hyd - ZNK",
    value: "Charger House Facility Hyd - ZNK",
    isChecked: false,
  },
  {
    text: "B R Automotive - ZNK",
    value: "B R Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "KB Moto world - ZNK",
    value: "KB Moto world - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Pune - ZNK",
    value: "Infinity service Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Kumaran Engineering Works - ZNK",
    value: "Kumaran Engineering Works - ZNK",
    isChecked: false,
  },
  { text: "Coulomb AI - ZNK", value: "Coulomb AI - ZNK", isChecked: false },
  {
    text: "Silverkey Executive Suites - ZNK",
    value: "Silverkey Executive Suites - ZNK",
    isChecked: false,
  },
  { text: "Tyres World - ZNK", value: "Tyres World - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Coimbatore - ZNK",
    value: "Infinity Test Ride@Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Mohammad Ali Mulla - ZNK",
    value: "Mohammad Ali Mulla - ZNK",
    isChecked: false,
  },
  {
    text: "MG ROAD METRO STATION - ZNK",
    value: "MG ROAD METRO STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - London - ZNK",
    value: "Export Acivity - London - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kathriguppe- ZNK",
    value: "Sangeetha_Kathriguppe- ZNK",
    isChecked: false,
  },
  { text: "Ampere - ZNK", value: "Ampere - ZNK", isChecked: false },
  { text: "GOA CSC - ZNK", value: "GOA CSC - ZNK", isChecked: false },
  {
    text: "Sangeetha_JC Road - ZNK",
    value: "Sangeetha_JC Road - ZNK",
    isChecked: false,
  },
  {
    text: "Green Drive motors - ZNK",
    value: "Green Drive motors - ZNK",
    isChecked: false,
  },
  {
    text: "Moon Engineering services Shop - ZNK",
    value: "Moon Engineering services Shop - ZNK",
    isChecked: false,
  },
  { text: "Metro One - ZNK", value: "Metro One - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    value: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "Powertronix systems limited - ZNK",
    value: "Powertronix systems limited - ZNK",
    isChecked: false,
  },
  { text: "My EV Store - ZNK", value: "My EV Store - ZNK", isChecked: false },
  {
    text: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    value: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    isChecked: false,
  },
  { text: "VOGO - ZNK", value: "VOGO - ZNK", isChecked: false },
  {
    text: "Lico Materials Pvt Ltd - ZNK",
    value: "Lico Materials Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering Pvt Ltd - ZNK",
    value: "Nexgen Engineering Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar Replace - ZNK",
    value: "Bounce Infinity Rajajinagar Replace - ZNK",
    isChecked: false,
  },
  { text: "Motostrokes - ZNK", value: "Motostrokes - ZNK", isChecked: false },
  {
    text: "Sangeetha_Whitefield-1 - ZNK",
    value: "Sangeetha_Whitefield-1 - ZNK",
    isChecked: false,
  },
  { text: "Ather - ZNK", value: "Ather - ZNK", isChecked: false },
  { text: "Helloworld - ZNK", value: "Helloworld - ZNK", isChecked: false },
  {
    text: "Divish Mobility Pune - ZNK",
    value: "Divish Mobility Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Bhiwadi Plant - ZNK",
    value: "Bhiwadi Plant - ZNK",
    isChecked: false,
  },
  {
    text: "T11 Mobility Vidyaranyapura - ZNK",
    value: "T11 Mobility Vidyaranyapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_RT Nagar-1- ZNK",
    value: "Sangeetha_RT Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sahakarnagar- ZNK",
    value: "Sangeetha_Sahakarnagar- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Gandhibazar - ZNK",
    value: "Sangeetha_Gandhibazar - ZNK",
    isChecked: false,
  },
  {
    text: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    value: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "SO Infinity Test Rides - Venu - ZNK",
    value: "SO Infinity Test Rides - Venu - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom trials - ZNK",
    value: "Exicom trials - ZNK",
    isChecked: false,
  },
  {
    text: "Prashanth tyres - ZNK",
    value: "Prashanth tyres - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom Gurgaon - ZNK",
    value: "Exicom Gurgaon - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kempapura- ZNK",
    value: "Sangeetha_Kempapura- ZNK",
    isChecked: false,
  },
  {
    text: "Warehouse Non Operational - ZNK",
    value: "Warehouse Non Operational - ZNK",
    isChecked: false,
  },
  {
    text: "Cococane RR Nagar - ZNK",
    value: "Cococane RR Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "BSS Fire Suppression Test - ZNK",
    value: "BSS Fire Suppression Test - ZNK",
    isChecked: false,
  },
  {
    text: "FPI 48V Batteries - ZNK",
    value: "FPI 48V Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Aniket (IIITB) - ZNK",
    value: "Aniket (IIITB) - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hebbagodi- ZNK",
    value: "Sangeetha_Hebbagodi- ZNK",
    isChecked: false,
  },
  {
    text: "Lectrix India - ZNK",
    value: "Lectrix India - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Testing - ZNK",
    value: "Booma Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kumaraswamy Layout- ZNK",
    value: "Sangeetha_Kumaraswamy Layout- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Konanakunte - ZNK",
    value: "Sangeetha_Konanakunte - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Ganesh Battery - ZNK",
    value: "Sri Ganesh Battery - ZNK",
    isChecked: false,
  },
  {
    text: "Meenakshi Motors - ZNK",
    value: "Meenakshi Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Banashankari - ZNK",
    value: "Sangeetha_Banashankari - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    value: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    isChecked: false,
  },
  {
    text: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    value: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    isChecked: false,
  },
  {
    text: "Napino Auto Testing - ZNK",
    value: "Napino Auto Testing - ZNK",
    isChecked: false,
  },
  {
    text: "EV Infra Testing - ZNK",
    value: "EV Infra Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mathikere- ZNK",
    value: "Sangeetha_Mathikere- ZNK",
    isChecked: false,
  },
  {
    text: "G.R.Subramani & Company - ZNK",
    value: "G.R.Subramani & Company - ZNK",
    isChecked: false,
  },
  { text: "Aventors - ZNK", value: "Aventors - ZNK", isChecked: false },
  {
    text: "Azn-Domlur Battery Station - ZNK",
    value: "Azn-Domlur Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Advandes Design Engineering Services LLP - ZNK",
    value: "Advandes Design Engineering Services LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Esmito Solutions - ZNK",
    value: "Esmito Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Padmaprakash Fuel Park - ZNK",
    value: "Padmaprakash Fuel Park - ZNK",
    isChecked: false,
  },
  {
    text: "SAMAHITA FUELING STATION - ZNK",
    value: "SAMAHITA FUELING STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Srikhantan - ZNK",
    value: "Testing Srikhantan - ZNK",
    isChecked: false,
  },
  {
    text: "Ganesh General Store - ZNK",
    value: "Ganesh General Store - ZNK",
    isChecked: false,
  },
  {
    text: "ARK Enterprises - ZNK",
    value: "ARK Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub Non Operational Inventory - ZNK",
    value: "Brookfield Hub Non Operational Inventory - ZNK",
    isChecked: false,
  },
  {
    text: "Privian Compuforms - ZNK",
    value: "Privian Compuforms - ZNK",
    isChecked: false,
  },
  { text: "Test001 - ZNK", value: "Test001 - ZNK", isChecked: false },
  {
    text: "Varsha Farm Equipment - ZNK",
    value: "Varsha Farm Equipment - ZNK",
    isChecked: false,
  },
  { text: "THRISHULAVEL - ZNK", value: "THRISHULAVEL - ZNK", isChecked: false },
  {
    text: "Go Native LTR - ZNK",
    value: "Go Native LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sree Ganga Firms - ZNK",
    value: "Sree Ganga Firms - ZNK",
    isChecked: false,
  },
  { text: "56 Secure - ZNK", value: "56 Secure - ZNK", isChecked: false },
  {
    text: "Sri Sai Sirish Enterprises - ZNK",
    value: "Sri Sai Sirish Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Azn-HSR Battery Station - ZNK",
    value: "Azn-HSR Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ananthnagar - ZNK",
    value: "Sangeetha_Ananthnagar - ZNK",
    isChecked: false,
  },
  {
    text: "Active Motors - ZNK",
    value: "Active Motors - ZNK",
    isChecked: false,
  },
  { text: "Jaipur Ajay - ZNK", value: "Jaipur Ajay - ZNK", isChecked: false },
  {
    text: "Infinity service hyderabad - ZNK",
    value: "Infinity service hyderabad - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Delhi - ZNK",
    value: "Infinity service Delhi - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - Turkey - ZNK",
    value: "Export Acivity - Turkey - ZNK",
    isChecked: false,
  },
  { text: "Battery Pool - ZNK", value: "Battery Pool - ZNK", isChecked: false },
  {
    text: "Maxcel Rental - ZNK",
    value: "Maxcel Rental - ZNK",
    isChecked: false,
  },
  {
    text: "Samakya Elecrtical - ZNK",
    value: "Samakya Elecrtical - ZNK",
    isChecked: false,
  },
];

const partnerDiag = [
  {
    text: "Theft Batteries - ZNK",
    value: "Theft Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Export Activity - Dubai - ZNK",
    value: "Export Activity - Dubai - ZNK",
    isChecked: false,
  },
  { text: "Shiva Nilaya - ZNK", value: "Shiva Nilaya - ZNK", isChecked: false },
  { text: "Ravi Motors - ZNK", value: "Ravi Motors - ZNK", isChecked: false },
  {
    text: "E-Chakra Automotive - Replace ZNK",
    value: "E-Chakra Automotive - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering - LTR - ZNK",
    value: "Nexgen Engineering - LTR - ZNK",
    isChecked: false,
  },
  { text: "BRK-Syed - ZNK", value: "BRK-Syed - ZNK", isChecked: false },
  {
    text: "Sangeetha_Sheshadripuram- ZNK",
    value: "Sangeetha_Sheshadripuram- ZNK",
    isChecked: false,
  },
  {
    text: "E-Chakra Automotive - ZNK",
    value: "E-Chakra Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    value: "Sangeetha_Kadubisanahahalli Junction - ZNK",
    isChecked: false,
  },
  { text: "Exicom - ZNK", value: "Exicom - ZNK", isChecked: false },
  { text: "watts auto - ZNK", value: "watts auto - ZNK", isChecked: false },
  { text: "Dreamz Car - ZNK", value: "Dreamz Car - ZNK", isChecked: false },
  {
    text: "Shree Venkateswara Service Station - ZNK",
    value: "Shree Venkateswara Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Auroville pilot Test Rides - ZNK",
    value: "Auroville pilot Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Moto Chronicles LLP - ZNK",
    value: "Moto Chronicles LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Harikrishna Fuel Station - ZNK",
    value: "Harikrishna Fuel Station - ZNK",
    isChecked: false,
  },
  { text: "BRK Abhilash - ZNK", value: "BRK Abhilash - ZNK", isChecked: false },
  {
    text: "Retrofit - Cochin - ZNK",
    value: "Retrofit - Cochin - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_HSR Layout-1- Replace ZNK",
    value: "SNG_HSR Layout-1- Replace ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Bank Layout- Replace ZNK",
    value: "SNG_Vijaya Bank Layout- Replace ZNK",
    isChecked: false,
  },
  { text: "Avinash - ZNK", value: "Avinash - ZNK", isChecked: false },
  {
    text: "Zeal Industries - ZNK",
    value: "Zeal Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Bgauss Homologation - ZNK",
    value: "Bgauss Homologation - ZNK",
    isChecked: false,
  },
  {
    text: "Batteries for Service Team-BAAS - ZNK",
    value: "Batteries for Service Team-BAAS - ZNK",
    isChecked: false,
  },
  {
    text: "Sri mukambika enterprises - ZNK",
    value: "Sri mukambika enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Coimbatore - ZNK",
    value: "Booma Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Rajajinagar EV Store - ZNK",
    value: "Rajajinagar EV Store - ZNK",
    isChecked: false,
  },
  {
    text: "JAYANAGAR METRO STATION - ZNK",
    value: "JAYANAGAR METRO STATION - ZNK",
    isChecked: false,
  },
  { text: "Marvel - ZNK", value: "Marvel - ZNK", isChecked: false },
  {
    text: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    value: "Sangeetha_Jalahalli-2 (Opp Rockline Mall) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kottayam - ZNK",
    value: "Infinity Test Ride@Kottayam - ZNK",
    isChecked: false,
  },
  {
    text: "Metro Mobikes - ZNK",
    value: "Metro Mobikes - ZNK",
    isChecked: false,
  },
  {
    text: "Malleshwaram Car - ZNK",
    value: "Malleshwaram Car - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Ramamurthy nagar- Replace ZNK",
    value: "SNG_Ramamurthy nagar- Replace ZNK",
    isChecked: false,
  },
  { text: "Marutee - ZNK", value: "Marutee - ZNK", isChecked: false },
  { text: "Dominos - ZNK", value: "Dominos - ZNK", isChecked: false },
  {
    text: "Sangeetha_Gharepalya- ZNK",
    value: "Sangeetha_Gharepalya- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Chandra Layout-2- ZNK",
    value: "Sangeetha_Chandra Layout-2- ZNK",
    isChecked: false,
  },
  {
    text: "Celebration of Digital India Week - ZNK",
    value: "Celebration of Digital India Week - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ullal Main Road- ZNK",
    value: "Sangeetha_Ullal Main Road- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Girinagar - ZNK",
    value: "Sangeetha_Girinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    value: "Sangeetha_BTM Layout-3 (Gurappana Palya) - ZNK",
    isChecked: false,
  },
  {
    text: "Gurukrupa Auto Service - ZNK",
    value: "Gurukrupa Auto Service - ZNK",
    isChecked: false,
  },
  { text: "R&D Zip - ZNK", value: "R&D Zip - ZNK", isChecked: false },
  {
    text: "OYO Akshaya Bunder Road - ZNK",
    value: "OYO Akshaya Bunder Road - ZNK",
    isChecked: false,
  },
  { text: "MOTO MECH - ZNK", value: "MOTO MECH - ZNK", isChecked: false },
  {
    text: "Nithin Enterprises - ZNK",
    value: "Nithin Enterprises - ZNK",
    isChecked: false,
  },
  { text: "E Wheels - ZNK", value: "E Wheels - ZNK", isChecked: false },
  {
    text: "Orissa Testing - ZNK",
    value: "Orissa Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride@Kundapur - ZNK",
    value: "Infinity Test Ride@Kundapur - ZNK",
    isChecked: false,
  },
  { text: "Arya Motors - ZNK", value: "Arya Motors - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Abbigere - ZNK",
    value: "T11 E Mobility Abbigere - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sarakki- ZNK",
    value: "Sangeetha_Sarakki- ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub QC - ZNK",
    value: "Brookfield Hub QC - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ramamurthy nagar- ZNK",
    value: "Sangeetha_Ramamurthy nagar- ZNK",
    isChecked: false,
  },
  { text: "Bounce RnD - ZNK", value: "Bounce RnD - ZNK", isChecked: false },
  {
    text: "Pratham Motors 2 - ZNK",
    value: "Pratham Motors 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Project - ZNK",
    value: "Jaipur Project - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub-Consumer Swapping - ZNK",
    value: "Brookfield Hub-Consumer Swapping - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Nandini Layout - ZNK",
    value: "Sangeetha_Nandini Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Varthur - ZNK",
    value: "Sangeetha_Varthur - ZNK",
    isChecked: false,
  },
  {
    text: "Microcon Jayanagar - ZNK",
    value: "Microcon Jayanagar - ZNK",
    isChecked: false,
  },
  {
    text: "Offline Partners - ZNK",
    value: "Offline Partners - ZNK",
    isChecked: false,
  },
  { text: "ARAI - ZNK", value: "ARAI - ZNK", isChecked: false },
  { text: "22 Motors - ZNK", value: "22 Motors - ZNK", isChecked: false },
  {
    text: "Goodera Testing - ZNK",
    value: "Goodera Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Amoggh E Motors - ZNK",
    value: "Amoggh E Motors - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Mallasandra- Replace ZNK",
    value: "SNG_Mallasandra- Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    value: "Sangeetha_Vijaya Nagar-3 (M C Road)- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajajinagar-1- ZNK",
    value: "Sangeetha_Rajajinagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Ride Even @ Pune - ZNK",
    value: "Infinity Test Ride Even @ Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-1- ZNK",
    value: "Sangeetha_HSR Layout-1- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity BTM - ZNK",
    value: "Bounce Infinity BTM - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    value: "Sangeetha_HSR Layout-3 (Ontrack) - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    value: "Infinity Test Rides@Vijayawada Dealer - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mahadevapura - ZNK",
    value: "Sangeetha_Mahadevapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    value: "Sangeetha_Rajarajeshwari Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Low speed Project - ZNK",
    value: "Low speed Project - ZNK",
    isChecked: false,
  },
  {
    text: "Virya Mobility - ZNK",
    value: "Virya Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E Mobility Solutions 2 - ZNK",
    value: "T11 E Mobility Solutions 2 - ZNK",
    isChecked: false,
  },
  {
    text: "Jaipur Shankar - ZNK",
    value: "Jaipur Shankar - ZNK",
    isChecked: false,
  },
  {
    text: "Orient Auto Spares - ZNK",
    value: "Orient Auto Spares - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Puram - ZNK",
    value: "Sangeetha_KR Puram - ZNK",
    isChecked: false,
  },
  {
    text: "Surya Candles & Camphor Industries - ZNK",
    value: "Surya Candles & Camphor Industries - ZNK",
    isChecked: false,
  },
  {
    text: "S B Enterprises - ZNK",
    value: "S B Enterprises - ZNK",
    isChecked: false,
  },
  { text: "Paris Event - ZNK", value: "Paris Event - ZNK", isChecked: false },
  {
    text: "Popular Fuel Center - ZNK",
    value: "Popular Fuel Center - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kogilu- ZNK",
    value: "Sangeetha_Kogilu- ZNK",
    isChecked: false,
  },
  {
    text: "SNG_KR Puram - Replace ZNK",
    value: "SNG_KR Puram - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    value: "Sangeetha_Yeshwanthpur-2 (RTO Road)- ZNK",
    isChecked: false,
  },
  {
    text: "COCOCANE Bilekalli - ZNK",
    value: "COCOCANE Bilekalli - ZNK",
    isChecked: false,
  },
  {
    text: "Ravi Kumar chief secretary - ZNK",
    value: "Ravi Kumar chief secretary - ZNK",
    isChecked: false,
  },
  { text: "Turkey - ZNK", value: "Turkey - ZNK", isChecked: false },
  {
    text: "Gurukrupa Service Station - ZNK",
    value: "Gurukrupa Service Station - ZNK",
    isChecked: false,
  },
  { text: "NFN Labs - ZNK", value: "NFN Labs - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Sirsi - ZNK",
    value: "Infinity Test Ride@Sirsi - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_KR Road- ZNK",
    value: "Sangeetha_KR Road- ZNK",
    isChecked: false,
  },
  {
    text: "Collection O Halcyon Hitech  city - ZNK",
    value: "Collection O Halcyon Hitech  city - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Madiwala-2 - ZNK",
    value: "Sangeetha_Madiwala-2 - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    value: "SNG_Vijaya Nagar-2 (Club Road) - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Anjanadri Petro Services - ZNK",
    value: "Anjanadri Petro Services - ZNK",
    isChecked: false,
  },
  {
    text: "Peenya Parking - ZNK",
    value: "Peenya Parking - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Godown Goodera. - ZNK",
    value: "Bounce Godown Goodera. - ZNK",
    isChecked: false,
  },
  {
    text: "Arya Motors - Replace ZNK",
    value: "Arya Motors - Replace ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Fraser Town-2",
    value: "Sangeetha_Fraser Town-2",
    isChecked: false,
  },
  {
    text: "Sangeetha_Jayanagar 9th Block - ZNK",
    value: "Sangeetha_Jayanagar 9th Block - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Brigade Road - ZNK",
    value: "Sangeetha_Brigade Road - ZNK",
    isChecked: false,
  },
  { text: "Amazon - ZNK", value: "Amazon - ZNK", isChecked: false },
  {
    text: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    value: "Sangeetha_Laggere (Ring Road Bridge) - ZNK",
    isChecked: false,
  },
  {
    text: "Charam Motors - ZNK",
    value: "Charam Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Demo Pune PCMC - ZNK",
    value: "Demo Pune PCMC - ZNK",
    isChecked: false,
  },
  {
    text: "Paras Industries - ZNK",
    value: "Paras Industries - ZNK",
    isChecked: false,
  },
  {
    text: "Manikantha sarathy jewellers - ZNK",
    value: "Manikantha sarathy jewellers - ZNK",
    isChecked: false,
  },
  {
    text: "Siddappa Enterprise - ZNK",
    value: "Siddappa Enterprise - ZNK",
    isChecked: false,
  },
  { text: "Natrax - ZNK", value: "Natrax - ZNK", isChecked: false },
  {
    text: "Infinity service mumbai - ZNK",
    value: "Infinity service mumbai - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_8th Mile Circle - ZNK",
    value: "Sangeetha_8th Mile Circle - ZNK",
    isChecked: false,
  },
  { text: "ICAT - ZNK", value: "ICAT - ZNK", isChecked: false },
  {
    text: "T 11 E Mobility Solutions - ZNK",
    value: "T 11 E Mobility Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Zero 21 - Nagpur - ZNK",
    value: "Zero 21 - Nagpur - ZNK",
    isChecked: false,
  },
  { text: "EMF - ZNK", value: "EMF - ZNK", isChecked: false },
  {
    text: "Flow Mobility - ZNK",
    value: "Flow Mobility - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service ahmedabad - ZNK",
    value: "Infinity service ahmedabad - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_MS Palya - ZNK",
    value: "Sangeetha_MS Palya - ZNK",
    isChecked: false,
  },
  {
    text: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    value: "T11 E-Mobility Solutions (yeshwanthpur) - ZNK",
    isChecked: false,
  },
  {
    text: "Platinum Petro Park - ZNK",
    value: "Platinum Petro Park - ZNK",
    isChecked: false,
  },
  { text: "Cococane - ZNK", value: "Cococane - ZNK", isChecked: false },
  {
    text: "Sangeetha_HBR Layout - ZNK",
    value: "Sangeetha_HBR Layout - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Batteries Nagarjun - ZNK",
    value: "Testing Batteries Nagarjun - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Uttarahalli-1- ZNK",
    value: "Sangeetha_Uttarahalli-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    value: "Sangeetha_Hongasandra (Begur main road)- ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar - ZNK",
    value: "Bounce Infinity Rajajinagar - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Byadarahalli- ZNK",
    value: "Sangeetha_Byadarahalli- ZNK",
    isChecked: false,
  },
  { text: "Tusha Motors - ZNK", value: "Tusha Motors - ZNK", isChecked: false },
  {
    text: "Sangeetha_Mallasandra- ZNK",
    value: "Sangeetha_Mallasandra- ZNK",
    isChecked: false,
  },
  {
    text: "Charging Station Warehouse - ZNK",
    value: "Charging Station Warehouse - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bank Colony - ZNK",
    value: "Sangeetha_Bank Colony - ZNK",
    isChecked: false,
  },
  { text: "K2K Ride - ZNK", value: "K2K Ride - ZNK", isChecked: false },
  {
    text: "SNG_Byadarahalli- Replace ZNK",
    value: "SNG_Byadarahalli- Replace ZNK",
    isChecked: false,
  },
  { text: "Smart Cars - ZNK", value: "Smart Cars - ZNK", isChecked: false },
  {
    text: "S Anand Kumar - ZNK",
    value: "S Anand Kumar - ZNK",
    isChecked: false,
  },
  { text: "ASI Jigani - ZNK", value: "ASI Jigani - ZNK", isChecked: false },
  { text: "ARN Office - ZNK", value: "ARN Office - ZNK", isChecked: false },
  {
    text: "Infinity E1 Deliveries-Customer - ZNK",
    value: "Infinity E1 Deliveries-Customer - ZNK",
    isChecked: false,
  },
  {
    text: "SDM Enterprises - ZNK",
    value: "SDM Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Bannerghatta-2- ZNK",
    value: "Sangeetha_Bannerghatta-2- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    value: "Sangeetha_Vijaya Nagar-2 (Club Road) - ZNK",
    isChecked: false,
  },
  {
    text: "Dhobiwala LTR - ZNK",
    value: "Dhobiwala LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Retrofit R&D project - ZNK",
    value: "Retrofit R&D project - ZNK",
    isChecked: false,
  },
  {
    text: "My Tire store - ZNK",
    value: "My Tire store - ZNK",
    isChecked: false,
  },
  {
    text: "Pratham Motors Bellandur - ZNK",
    value: "Pratham Motors Bellandur - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Gurukrupa Fuel Junction - ZNK",
    value: "Sri Gurukrupa Fuel Junction - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Vijaya Bank Layout- ZNK",
    value: "Sangeetha_Vijaya Bank Layout- ZNK",
    isChecked: false,
  },
  {
    text: "BER Batteries - ZNK",
    value: "BER Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Hyderabad LTR - ZNK",
    value: "Hyderabad LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Horamavu - ZNK",
    value: "Sangeetha_Horamavu - ZNK",
    isChecked: false,
  },
  { text: "Hassan - ZNK", value: "Hassan - ZNK", isChecked: false },
  {
    text: "Savani Service Station - ZNK",
    value: "Savani Service Station - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity Test Rides - ZNK",
    value: "Infinity Test Rides - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Malleswaram - ZNK",
    value: "Sangeetha_Malleswaram - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    value: "Shri Vijayalakshmi Automobile And Hardware - ZNK",
    isChecked: false,
  },
  {
    text: "SNG_8th Mile Circle Replace - ZNK",
    value: "SNG_8th Mile Circle Replace - ZNK",
    isChecked: false,
  },
  {
    text: "Mayya dose camp - ZNK",
    value: "Mayya dose camp - ZNK",
    isChecked: false,
  },
  {
    text: "South-Africa-NKN - ZNK",
    value: "South-Africa-NKN - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub - ZNK",
    value: "Brookfield Hub - ZNK",
    isChecked: false,
  },
  { text: "Cygni - ZNK", value: "Cygni - ZNK", isChecked: false },
  {
    text: "DD Supermarket - ZNK",
    value: "DD Supermarket - ZNK",
    isChecked: false,
  },
  { text: "EZ Auto Care - ZNK", value: "EZ Auto Care - ZNK", isChecked: false },
  {
    text: "Charger House Facility Hyd - ZNK",
    value: "Charger House Facility Hyd - ZNK",
    isChecked: false,
  },
  {
    text: "B R Automotive - ZNK",
    value: "B R Automotive - ZNK",
    isChecked: false,
  },
  {
    text: "KB Moto world - ZNK",
    value: "KB Moto world - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Pune - ZNK",
    value: "Infinity service Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Kumaran Engineering Works - ZNK",
    value: "Kumaran Engineering Works - ZNK",
    isChecked: false,
  },
  { text: "Coulomb AI - ZNK", value: "Coulomb AI - ZNK", isChecked: false },
  {
    text: "Silverkey Executive Suites - ZNK",
    value: "Silverkey Executive Suites - ZNK",
    isChecked: false,
  },
  { text: "Tyres World - ZNK", value: "Tyres World - ZNK", isChecked: false },
  {
    text: "Infinity Test Ride@Coimbatore - ZNK",
    value: "Infinity Test Ride@Coimbatore - ZNK",
    isChecked: false,
  },
  {
    text: "Mohammad Ali Mulla - ZNK",
    value: "Mohammad Ali Mulla - ZNK",
    isChecked: false,
  },
  {
    text: "MG ROAD METRO STATION - ZNK",
    value: "MG ROAD METRO STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - London - ZNK",
    value: "Export Acivity - London - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kathriguppe- ZNK",
    value: "Sangeetha_Kathriguppe- ZNK",
    isChecked: false,
  },
  { text: "Ampere - ZNK", value: "Ampere - ZNK", isChecked: false },
  { text: "GOA CSC - ZNK", value: "GOA CSC - ZNK", isChecked: false },
  {
    text: "Sangeetha_JC Road - ZNK",
    value: "Sangeetha_JC Road - ZNK",
    isChecked: false,
  },
  {
    text: "Green Drive motors - ZNK",
    value: "Green Drive motors - ZNK",
    isChecked: false,
  },
  {
    text: "Moon Engineering services Shop - ZNK",
    value: "Moon Engineering services Shop - ZNK",
    isChecked: false,
  },
  { text: "Metro One - ZNK", value: "Metro One - ZNK", isChecked: false },
  {
    text: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    value: "T11 E Mobility Solutions Ramurthy Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "Powertronix systems limited - ZNK",
    value: "Powertronix systems limited - ZNK",
    isChecked: false,
  },
  { text: "My EV Store - ZNK", value: "My EV Store - ZNK", isChecked: false },
  {
    text: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    value: "Sangeetha_Kengeri-4 (satellite town) - ZNK",
    isChecked: false,
  },
  { text: "VOGO - ZNK", value: "VOGO - ZNK", isChecked: false },
  {
    text: "Lico Materials Pvt Ltd - ZNK",
    value: "Lico Materials Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Nexgen Engineering Pvt Ltd - ZNK",
    value: "Nexgen Engineering Pvt Ltd - ZNK",
    isChecked: false,
  },
  {
    text: "Bounce Infinity Rajajinagar Replace - ZNK",
    value: "Bounce Infinity Rajajinagar Replace - ZNK",
    isChecked: false,
  },
  { text: "Motostrokes - ZNK", value: "Motostrokes - ZNK", isChecked: false },
  {
    text: "Sangeetha_Whitefield-1 - ZNK",
    value: "Sangeetha_Whitefield-1 - ZNK",
    isChecked: false,
  },
  { text: "Ather - ZNK", value: "Ather - ZNK", isChecked: false },
  { text: "Helloworld - ZNK", value: "Helloworld - ZNK", isChecked: false },
  {
    text: "Divish Mobility Pune - ZNK",
    value: "Divish Mobility Pune - ZNK",
    isChecked: false,
  },
  {
    text: "Bhiwadi Plant - ZNK",
    value: "Bhiwadi Plant - ZNK",
    isChecked: false,
  },
  {
    text: "T11 Mobility Vidyaranyapura - ZNK",
    value: "T11 Mobility Vidyaranyapura - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_RT Nagar-1- ZNK",
    value: "Sangeetha_RT Nagar-1- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Sahakarnagar- ZNK",
    value: "Sangeetha_Sahakarnagar- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Gandhibazar - ZNK",
    value: "Sangeetha_Gandhibazar - ZNK",
    isChecked: false,
  },
  {
    text: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    value: "OYO 79550 Townhouse 397 RCC Hospitality LB Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "SO Infinity Test Rides - Venu - ZNK",
    value: "SO Infinity Test Rides - Venu - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom trials - ZNK",
    value: "Exicom trials - ZNK",
    isChecked: false,
  },
  {
    text: "Prashanth tyres - ZNK",
    value: "Prashanth tyres - ZNK",
    isChecked: false,
  },
  {
    text: "Exicom Gurgaon - ZNK",
    value: "Exicom Gurgaon - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kempapura- ZNK",
    value: "Sangeetha_Kempapura- ZNK",
    isChecked: false,
  },
  {
    text: "Warehouse Non Operational - ZNK",
    value: "Warehouse Non Operational - ZNK",
    isChecked: false,
  },
  {
    text: "Cococane RR Nagar - ZNK",
    value: "Cococane RR Nagar - ZNK",
    isChecked: false,
  },
  {
    text: "BSS Fire Suppression Test - ZNK",
    value: "BSS Fire Suppression Test - ZNK",
    isChecked: false,
  },
  {
    text: "FPI 48V Batteries - ZNK",
    value: "FPI 48V Batteries - ZNK",
    isChecked: false,
  },
  {
    text: "Aniket (IIITB) - ZNK",
    value: "Aniket (IIITB) - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Hebbagodi- ZNK",
    value: "Sangeetha_Hebbagodi- ZNK",
    isChecked: false,
  },
  {
    text: "Lectrix India - ZNK",
    value: "Lectrix India - ZNK",
    isChecked: false,
  },
  {
    text: "Booma Testing - ZNK",
    value: "Booma Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Kumaraswamy Layout- ZNK",
    value: "Sangeetha_Kumaraswamy Layout- ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Konanakunte - ZNK",
    value: "Sangeetha_Konanakunte - ZNK",
    isChecked: false,
  },
  {
    text: "Sri Ganesh Battery - ZNK",
    value: "Sri Ganesh Battery - ZNK",
    isChecked: false,
  },
  {
    text: "Meenakshi Motors - ZNK",
    value: "Meenakshi Motors - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Banashankari - ZNK",
    value: "Sangeetha_Banashankari - ZNK",
    isChecked: false,
  },
  {
    text: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    value: "Shri Gurukrupa Fuel Junction Papreddy Palya - ZNK",
    isChecked: false,
  },
  {
    text: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    value: "NEW INTERPORT SHIPPING SERVICE - ZNK",
    isChecked: false,
  },
  {
    text: "Napino Auto Testing - ZNK",
    value: "Napino Auto Testing - ZNK",
    isChecked: false,
  },
  {
    text: "EV Infra Testing - ZNK",
    value: "EV Infra Testing - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Mathikere- ZNK",
    value: "Sangeetha_Mathikere- ZNK",
    isChecked: false,
  },
  {
    text: "G.R.Subramani & Company - ZNK",
    value: "G.R.Subramani & Company - ZNK",
    isChecked: false,
  },
  { text: "Aventors - ZNK", value: "Aventors - ZNK", isChecked: false },
  {
    text: "Azn-Domlur Battery Station - ZNK",
    value: "Azn-Domlur Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Advandes Design Engineering Services LLP - ZNK",
    value: "Advandes Design Engineering Services LLP - ZNK",
    isChecked: false,
  },
  {
    text: "Esmito Solutions - ZNK",
    value: "Esmito Solutions - ZNK",
    isChecked: false,
  },
  {
    text: "Padmaprakash Fuel Park - ZNK",
    value: "Padmaprakash Fuel Park - ZNK",
    isChecked: false,
  },
  {
    text: "SAMAHITA FUELING STATION - ZNK",
    value: "SAMAHITA FUELING STATION - ZNK",
    isChecked: false,
  },
  {
    text: "Testing Srikhantan - ZNK",
    value: "Testing Srikhantan - ZNK",
    isChecked: false,
  },
  {
    text: "Ganesh General Store - ZNK",
    value: "Ganesh General Store - ZNK",
    isChecked: false,
  },
  {
    text: "ARK Enterprises - ZNK",
    value: "ARK Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Brookfield Hub Non Operational Inventory - ZNK",
    value: "Brookfield Hub Non Operational Inventory - ZNK",
    isChecked: false,
  },
  {
    text: "Privian Compuforms - ZNK",
    value: "Privian Compuforms - ZNK",
    isChecked: false,
  },
  { text: "Test001 - ZNK", value: "Test001 - ZNK", isChecked: false },
  {
    text: "Varsha Farm Equipment - ZNK",
    value: "Varsha Farm Equipment - ZNK",
    isChecked: false,
  },
  { text: "THRISHULAVEL - ZNK", value: "THRISHULAVEL - ZNK", isChecked: false },
  {
    text: "Go Native LTR - ZNK",
    value: "Go Native LTR - ZNK",
    isChecked: false,
  },
  {
    text: "Sree Ganga Firms - ZNK",
    value: "Sree Ganga Firms - ZNK",
    isChecked: false,
  },
  { text: "56 Secure - ZNK", value: "56 Secure - ZNK", isChecked: false },
  {
    text: "Sri Sai Sirish Enterprises - ZNK",
    value: "Sri Sai Sirish Enterprises - ZNK",
    isChecked: false,
  },
  {
    text: "Azn-HSR Battery Station - ZNK",
    value: "Azn-HSR Battery Station - ZNK",
    isChecked: false,
  },
  {
    text: "Sangeetha_Ananthnagar - ZNK",
    value: "Sangeetha_Ananthnagar - ZNK",
    isChecked: false,
  },
  {
    text: "Active Motors - ZNK",
    value: "Active Motors - ZNK",
    isChecked: false,
  },
  { text: "Jaipur Ajay - ZNK", value: "Jaipur Ajay - ZNK", isChecked: false },
  {
    text: "Infinity service hyderabad - ZNK",
    value: "Infinity service hyderabad - ZNK",
    isChecked: false,
  },
  {
    text: "Infinity service Delhi - ZNK",
    value: "Infinity service Delhi - ZNK",
    isChecked: false,
  },
  {
    text: "Export Acivity - Turkey - ZNK",
    value: "Export Acivity - Turkey - ZNK",
    isChecked: false,
  },
  { text: "Battery Pool - ZNK", value: "Battery Pool - ZNK", isChecked: false },
  {
    text: "Maxcel Rental - ZNK",
    value: "Maxcel Rental - ZNK",
    isChecked: false,
  },
  {
    text: "Samakya Elecrtical - ZNK",
    value: "Samakya Elecrtical - ZNK",
    isChecked: false,
  },
];
const socFilter1Diag = [
  { text: "0% to 33%", value: "0% to 33%", isChecked: false },
  { text: "34% to 70%", value: "34% to 70%", isChecked: false },
  { text: "71% to 100%", value: "71% to 100%", isChecked: false },
];

export {
  socFilter,
  socFilter1,
  socFilter2,
  socFilterDiag,
  socFilter1Diag,
  partner,
  partner1,
  partner2,
  partnerDiag
};
