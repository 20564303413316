export const SetDashboardType = (value) =>
{
    return {
        type: "DashboardType",
        value: value
    }
}
export const SetDashboardColumns = (columns) =>
{
    return {
        type: "DashboardColumns",
        value: columns
    }
}
export const SetDashboardHiddenColumns = (hidden) =>
{
    return {
        type: "DashboardHiddenColumns",
        value: hidden
    }
}
export const SetIsBatteryIsPartnerIsNull = (value) =>
{
    return {
        type: "IsBatteryIsPartnerIsNull",
        value: value
    }
}
export const SetDashboardBatteryPartnerSelect = (value) =>
{
    return {
        type: "DashboardBatteryPartnerSelect",
        select: value
    }
}
export const SetDashboardBatteryID = (batteryID) =>
{
    return {
        type: "DashboardBatteryID",
        batteryID: batteryID
    }
}
export const SetDashboardDeviceID = (deviceID) =>
{
    return {
        type: "DashboardDeviceID",
        deviceID: deviceID
    }
}
export const SetDashboardPartner = (partner) =>
{
    return {
        type: "DashboardPartner",
        partner: partner
    }
}

export const SetDashboardVolt = (volt) =>
{
    return {
        type: "DashboardVolt",
        volt: volt
    }
}
export const SetDashboardCity = (city) =>
{
    return {
        type: "DashboardCity",
        city: city
    }
}
export const SetDashboardZone = (zone) =>
{
    return {
        type: "DashboardZone",
        zone: zone
    }
}
export const SetDashboardStatus = (status) =>
{
    return {
        type: "DashboardStatus",
        status: status
    }
}

export const SetDashboardSeverity = (severity) =>
{
    return {
        type: "DashboardSeverity",
        severity: severity
    }
}

export const SetDashboardIOT = (Iot) =>
{
    return {
        type: "DashboardIOT",
        Iot: Iot
    }
}

export const SetDashboardPack = (pack) =>
{
    return {
        type: "DashboardPack",
        pack: pack
    }
}

export const SetDashboardCategory = (category) =>
{
    return {
        type: "DashboardCategory",
        category: category
    }
}

export const SetDashboardTabValue = (tab) =>
{
    return {
        type: "DashboardTabValue",
        tab: tab
    }
}

export const SetDashboardAlertTypeIndex = (index) =>
{
    return {
        type: "DashboardAlertTypeIndex",
        index: index
    }
}

export const SetDashboardAlertTypeCode = (code) =>
{
    return {
        type: "DashboardAlertTypeCode",
        code: code
    }
}

export const SetDashboardTotal = (total) =>
{
    return {
        type: "DashboardTotal",
        total: total
    }
}

export const SetDashboardStartDate = (start) =>
{
    return {
        type: "DashboardStartDate",
        start: start
    }
}

export const SetDashboardEndDate = (end) =>
{
    return {
        type: "DashboardEndDate",
        end: end
    }
}

export const SetDashboardAlertTypeList = (list) =>
{
    return {
        type: "DashboardAlertTypeList",
        list: list
    }
}

export const SetDashboardListOfBatteries = (list) =>
{
    return {
        type: "DashboardListOfBatteries",
        list: list
    }
}

 //--------------------//

export const SetDashboardStack = (batteries) =>
{
    return {
        type: "DashboardStack",
        batteries: batteries
    }
}

export const DashboardExistingBatteries = (batteries) =>
{
    return {
        type: "DashboardExistingBatteries",
        batteries: batteries
    }
}

export const SetDashboardColumnSort = (sort) =>
{
    return{
        type: "DashboardColumnSort",
        sort:sort
    }
}

export const SetDashboardPageNumber = (page) =>
{
    return {
        type: "DashboardPageNumber",
        page: page
    }
}

export const SetDashboardRowsPerPage = (rows) =>
{
    return {
        type: "DashboardRowsPerPage",
        rows: rows
    }
}

export const SetDashboardOpenAddSelectedBatteries = (addBatteries) =>
{
    return {
        type: "DashboardOpenAddSelectedBatteries",
        addBatteries: addBatteries
    }
}
export const SetDashboardSelectedBatteriesAction = (performAction) =>
{
    return {
        type: "DashboardSelectedBatteriesAction",
        performAction: performAction
    }
}
