import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid,Box,Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetRefreshAPICalled, SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import moment from "moment";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";
import { FindNearestMaxAnalytics, FindNextHighestMultipleOfNum } from "../../../../../Helper/Operations/FindNearestMaxAnalytics";

function AllAlerts(props) {
  const dispatch = useDispatch();
  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const [severityDistribution, setSeverityDistribution] = useState({
    superCritical: [],

    critical: [],
    high: [],
    low: [],
    max:0,
    categories: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [statusClickedDevices, setStatusClickedDevices] = useState({
    SuperCritical: true,
    Critical: true,
    High: true,
    Low: true,
  });
  function handleStatusClickedDevices(status) {
    if (
      statusClickedDevices.SuperCritical &&
      statusClickedDevices.Critical &&
      statusClickedDevices.High &&
      statusClickedDevices.Low
    ) {
      if (status === "Super Critical") {
        setStatusClickedDevices({
          SuperCritical: statusClickedDevices.SuperCritical,
          Critical: !statusClickedDevices.Critical,
          High: !statusClickedDevices.High,
          Low: !statusClickedDevices.Low,
        });
      } else if (status === "Critical") {
        setStatusClickedDevices({
          SuperCritical: !statusClickedDevices.SuperCritical,
          Critical: statusClickedDevices.Critical,
          High: !statusClickedDevices.High,
          Low: !statusClickedDevices.Low,
        });
      } else if (status === "High") {
        setStatusClickedDevices({
          SuperCritical: !statusClickedDevices.SuperCritical,
          Critical: !statusClickedDevices.Critical,
          High: statusClickedDevices.High,
          Low: !statusClickedDevices.Low,
        });
      } else if (status === "Low") {
        setStatusClickedDevices({
          SuperCritical: !statusClickedDevices.SuperCritical,
          Critical: !statusClickedDevices.Critical,
          High: !statusClickedDevices.High,
          Low: statusClickedDevices.Low,
        });
      }
    } else {
      if (status === "Super Critical") {
        if (
          statusClickedDevices.Critical ||
          statusClickedDevices.High ||
          statusClickedDevices.Low
        )
        setStatusClickedDevices({
            SuperCritical: !statusClickedDevices.SuperCritical,
            Critical: statusClickedDevices.Critical,
            High: statusClickedDevices.High,
            Low: statusClickedDevices.Low,
          });
      } else if (status === "Critical") {
        if (
          statusClickedDevices.SuperCritical ||
          statusClickedDevices.High ||
          statusClickedDevices.Low
        )
        setStatusClickedDevices({
            SuperCritical: statusClickedDevices.SuperCritical,
            Critical: !statusClickedDevices.Critical,
            High: statusClickedDevices.High,
            Low: statusClickedDevices.Low,
          });
      } else if (status === "High") {
        if (
          statusClickedDevices.SuperCritical ||
          statusClickedDevices.Critical ||
          statusClickedDevices.Low
        )
        setStatusClickedDevices({
            SuperCritical: statusClickedDevices.SuperCritical,
            Critical: statusClickedDevices.Critical,
            High: !statusClickedDevices.High,
            Low: statusClickedDevices.Low,
          });
      } else if (status === "Low") {
        if (statusClickedDevices.SuperCritical || statusClickedDevices.Critical || statusClickedDevices.High)
        setStatusClickedDevices({
            SuperCritical: statusClickedDevices.SuperCritical,
            Critical: statusClickedDevices.Critical,
            High: statusClickedDevices.High,
            Low: !statusClickedDevices.Low,
          });
      }
    }
  }
  function handleAlertsStatusClickedDevices()
  {
    let tempSeries = [],tempColors=[],max=0;
    if (statusClickedDevices.SuperCritical) {
      tempSeries.push({
        name: "Super Critical",
        data: severityDevicesDistribution.superCritical,
      });
      tempColors.push(ThemeProperties.map_critical);
      if(Math.max(...severityDevicesDistribution.superCritical) > max)
      max = Math.max(...severityDevicesDistribution.superCritical)
    } 
    if(statusClickedDevices.Critical)
    {
      tempSeries.push({
        name: "Critical",
        data: severityDevicesDistribution.critical,
      });
      tempColors.push(ThemeProperties.map_high)
      if(Math.max(...severityDevicesDistribution.critical) > max)
      max = Math.max(...severityDevicesDistribution.critical)
    } 
    if(statusClickedDevices.High)
    {
      tempSeries.push({
        name: "High",
        data: severityDevicesDistribution.high,
      })
      tempColors.push(ThemeProperties.map_medium)
      if(Math.max(...severityDevicesDistribution.high) > max)
      max = Math.max(...severityDevicesDistribution.high)
    }
    if(statusClickedDevices.Low)
    {
      tempSeries.push({
        name: "Low",
        data: severityDevicesDistribution.low,
      })
      tempColors.push(ThemeProperties.map_low)
      if(Math.max(...severityDevicesDistribution.low) > max)
      max = Math.max(...severityDevicesDistribution.low)
    }

    console.log(tempSeries,max)

    // setSeverityDevicesDistribution((prevData)=>({
    //   ...prevData,
    //   max:max
    // }))

    return [tempSeries,tempColors,FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(max), 1000)]
  }
  function LegendsDevices() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml:2.5
        }}
      >
        {[
          {
            status: "Super Critical",
            color: ThemeProperties.map_critical,
            target: statusClickedDevices.SuperCritical,
          },
          {
            status: "Critical",
            color: ThemeProperties.map_high,
            target: statusClickedDevices.Critical,
          },
          {
            status: "High",
            color: ThemeProperties.map_medium,
            target: statusClickedDevices.High,
          },
          {
            status: "Low",
            color: ThemeProperties.map_low,
            target: statusClickedDevices.Low,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedDevices(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
  const [severityDevicesDistribution, setSeverityDevicesDistribution] =
    useState({
      superCritical: [],

      critical: [],
      high: [],
      low: [],
      categories: [],
      max:0,
      responseStatus: { code: -1, msg: "" },
    });

  const SetTheSeverityData = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempSuperCritical = [],
        tempCritical = [],
        tempHigh = [],
        tempLow = [],
        tempCategories = [],
        tempSuperCriticalDevices = [],
        tempCriticalDevices = [],
        tempHighDevices = [],
        tempLowDevices = [];

      let tempAlertList = [];
      data.response.alertList.map((item) => {
        tempAlertList.push({
          deviceCount: item.deviceCount,
          alertCount: item.alertCount,
          severity: item.severity,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempAlertList, "startDate");
      let max = 0;
      gy.map((item) => {
       
        if (props.granular === "Weekly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
        } else if (props.granular === "Monthly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("MMM")
          );
        } else tempCategories.push(item["key"] + " GMT");

        tempSuperCritical.push(
          item["values"].find((i) => i.severity === 4)
            ? item["values"].find((i) => i.severity === 4)["alertCount"]
            : 0
        );
        tempCritical.push(
          item["values"].find((i) => i.severity === 3)
            ? item["values"].find((i) => i.severity === 3)["alertCount"]
            : 0
        );
        tempHigh.push(
          item["values"].find((i) => i.severity === 2)
            ? item["values"].find((i) => i.severity === 2)["alertCount"]
            : 0
        );
        tempLow.push(
          item["values"].find((i) => i.severity === 1)
            ? item["values"].find((i) => i.severity === 1)["alertCount"]
            : 0
        );

       
        item['values'].map((item)=>{
         
          if(item["deviceCount"]>max)
          max=item["deviceCount"];
        })


        tempSuperCriticalDevices.push(
          item["values"].find((i) => i.severity === 4)
            ? item["values"].find((i) => i.severity === 4)["deviceCount"]
            : 0
        );

        tempCriticalDevices.push(
          item["values"].find((i) => i.severity === 3)
            ? item["values"].find((i) => i.severity === 3)["deviceCount"]
            : 0
        );

        tempHighDevices.push(
          item["values"].find((i) => i.severity === 2)
            ? item["values"].find((i) => i.severity === 2)["deviceCount"]
            : 0
        );

        tempLowDevices.push(
          item["values"].find((i) => i.severity === 1)
            ? item["values"].find((i) => i.severity === 1)["deviceCount"]
            : 0
        );
      });
      setSeverityDistribution({
        superCritical: tempSuperCritical,
        critical: tempCritical,
        high: tempHigh,
        low: tempLow,
        
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });

      setSeverityDevicesDistribution({
        superCritical: tempSuperCriticalDevices,
        critical: tempCriticalDevices,
        high: tempHighDevices,
        low: tempLowDevices,
        max:max,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      setSeverityDistribution({
        superCritical: [],
        critical: [],
        high: [],
        low: [],
        max:0,
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setSeverityDevicesDistribution({
        superCritical: [],
        critical: [],
        high: [],
        low: [],
        max:0,
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setSeverityDistribution({
      superCritical: [],
      critical: [],
      high: [],
      low: [],
      max:0,
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });
    setSeverityDevicesDistribution({
      superCritical: [],
      critical: [],
      high: [],
      low: [],
      max:0,
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const getSeverityAlertsDistribution = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),

        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/severity_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheSeverityData(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setSeverityDistribution({
            ...severityDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setSeverityDevicesDistribution({
            ...severityDevicesDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setSeverityDistribution({
            ...severityDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setSeverityDevicesDistribution({
            ...severityDevicesDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
            // let refresh = await axios.get("/api/refresh");
            if (isRefreshAPICalled) {
              // dispatch(SetRefreshAPICalled(true));
              try {
                let res = await axios.get(`/api/severity_alerts`, {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                });
                const data = callTheAPI(res, true);
                SetTheSeverityData(data);
              } catch (error) {
                if (error.response?.status === CODE.URL_NODATA) {
                  setSeverityDistribution({
                    ...severityDistribution,
                    responseStatus: {
                      code: CODE.NODATA,
                      msg: "No Data",
                    },
                  });
                  setSeverityDevicesDistribution({
                    ...severityDevicesDistribution,
                    responseStatus: {
                      code: CODE.NODATA,
                      msg: "No Data",
                    },
                  });
                } else if (error.response?.status === CODE.UNAUTHORIZED) {
                  // removeAccessToken();
                  return null;
                }
              }
            }
          // } catch (error) {
          //   // Logout();
          //   SetSessionExpired();
          // }
        }
      }
      //   let data = GetData(config);
      //   return data;
    };
    getSeverityAlertsDistribution();
    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack,
    isRefreshAPICalled
  ]);
  ScrollToTheElement();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() =>
            Events("AA Hovered Distribution Of Devices Based on Severity")
          }
        >
          <Header
            heading="Distribution of batteries based on severity"
            description="Distribution Of Devices Based on Severity"
            showIButton={true}
            showThreeDots={false}
            id="Distribution_Of_Alerts_Based_on_Severity"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Distribution_Of_Alerts_Based_on_Severity"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{height:"calc(100% - 20px)"}}>
            <TestApexBar
              data={severityDevicesDistribution}
              horizontal={false}
              barHeight={"90%"}
              yaxisTitle={"Number of batteries"}
              xaxisTitle={undefined}
              tickAmount={8}
              granularity={props.granular}
              ymax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                 severityDevicesDistribution.max
                 ),500)}
              columnWidth={severityDevicesDistribution.categories.length>=1 && severityDevicesDistribution.categories.length<9 ? 10 * severityDevicesDistribution.categories.length + "%" : "80%"}
              stacked={false}
              showDataLabels = {false}
              categories={severityDevicesDistribution.categories}
              series={[
                {
                  name: "Super Critical",
                  data: severityDevicesDistribution.superCritical,
                },
                {
                  name: "Critical",
                  data: severityDevicesDistribution.critical,
                },
                {
                  name: "High",
                  data: severityDevicesDistribution.high,
                },
                {
                  name: "Low",
                  data: severityDevicesDistribution.low,
                },
              ]}
              offsetX={20}
              offsetY={0}
              customLegend={true}
              statusClicked={statusClickedDevices}
              handleLegendClick={handleAlertsStatusClickedDevices}
              colors={[
                ThemeProperties.map_critical,
                ThemeProperties.map_high,
                ThemeProperties.map_medium,
                ThemeProperties.map_low,
              ]}
              width={"100%"}
            />
            </div>
            <div style={{paddingLeft:"20px"}}>
            {severityDevicesDistribution.responseStatus.code === CODE.SUCCESS && <LegendsDevices/>}
            </div>
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default AllAlerts;
