import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, CircularProgress, Typography } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OutlinedTextField } from "../Components/CustomTextField";
import { SetPassChangedSuccess } from "../Actions";
import { useDispatch } from "react-redux";

import {
  getIfLinkIsValid,
  sendRequestforChangePassword,
  sendRequestforPasswordReset,
} from "../Api/Api";
import "./Login.css";
import { ThemeProperties } from "../Theme/ThemeProperties";
import CoulombLogo from "../Assets/Icons/CoulombLogo.svg";

export default function ResetPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState();

  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [password, setPassword] = useState("");
  const [emailID, setEmailID] = useState("");
  const [error, setError] = useState();
  const [username, setUsername] = useState("");
  const [tab, setTab] = useState(3);
  const [invalidLink, setInvalidLink] = useState(false);
  const [linkSend, setLinkSend] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    if (!password || !confirmPassword) {
      setError("Password must not be empty");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords doesn't match");

      return;
    } else {
      if (!validatePassword(password)) {
        setError("Entered password isn't valid");
      } else {
        setError("");
        setLoading(true);
        let temp = window.location.search.slice(1);
        let token = temp.split("=");
        sendRequestforChangePassword(token[1], password).then((res) => {
          if (res.responseStatus.code === 200) {
            dispatch(SetPassChangedSuccess(true));
            navigate("/");
            setLoading(false);
          } else if (res.responseStatus.code === 1998) {
            setLoading(false);
            navigate("/password_reset");
            setTab(2);
            setInvalidLink(true);
          } else {
            setError("Something went wrong! Please try again later");
            setLoading(false);
          }
        });
      }
    }
  };

  const handlePasswordReset = () => {
    setInvalidLink(false);
    if (!username) {
      setError("Please enter a valid Email ID");
    } else {
      if (!validateEmail(username)) {
        setError("Please enter a valid Email ID");
      } else {
        setLoading(true);
        sendRequestforPasswordReset(username).then((res) => {
          if (res.responseStatus.code === 200) {
            setError("");
            setMessage({
              type: "info",
              body: "Check your email for a link to reset your password. If it doesn’t appear within few minutes, check your spam folder.",
            });
            setLinkSend(true);
            setLoading(false);
          } else if (res.responseStatus.code === 400) {
            setError(res.responseStatus.msg);
            setLoading(false);
          } else {
            setError("Something went wrong! Please try again later");
            setLoading(false);
          }
        });
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return String(password).match(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    );
  };

  useEffect(() => {
    let temp = window.location.search.slice(1);

    if (temp) {
      const token = window.location.search.split("=")[1];

      getIfLinkIsValid(token).then((res) => {
        if (res.responseStatus.code === 200) {
          setEmailID(res.response.emailID);
          setTab(3);
        } else if (
          res.responseStatus.code === 400 ||
          res.responseStatus.code === 1998
        ) {
          setInvalidLink(true);
          setTab(2);
          navigate("/password_reset");
        } else {
          setError("Something went wrong! Please try again later");
          // setLoading(false);
        }
      });
    } else {
      setTab(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  return (
    <>
      <Box
        sx={{
          display: "table",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          bgcolor: "#F2F2F4",
        }}
      >
        <Box
          sx={{
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "500px",
              //   padding: "0px 56px ",
              mb: 2,
            }}
          >
            {invalidLink && (
              <div className={`invalidLink`}>
                <Typography variant="textPasswordReset" align="center">
                  Link is expired/invalid! Please request for a new link.
                </Typography>
              </div>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              ml: -0.8,
            }}
          >
            <img src={CoulombLogo} alt="not found" height={"40px"} />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "450px",
              border: "1px solid #DDD7FF",
              borderRadius: "1.2rem",
              backgroundColor: "#fff",
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

              {tab === 2 && (
                <>
                  {message ? (
                    <>
                      <Typography
                      variant="paperHeader"
                        sx={{
                          fontSize: "20px",
                          
                        }}
                      >
                        Reset link sent
                      </Typography>
                      <br></br>
                      <div className={`messageBox`}>
                        <InfoIcon
                          sx={{
                            fontSize: "20px",
                            color: ThemeProperties.purple,
                          }}
                        />
                        <br></br>
                        <Typography variant="textPasswordReset" align="center">
                          {message.body}
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          mt: 0.5,
                          mb: 2,
                          fontSize: 13,
                          borderRadius: "1rem",
                        }}
                        onClick={() => navigate("/")}
                      >
                        Go back to log in
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography
                      variant="paperHeader"
                        sx={{
                          fontSize: "20px",
                        }}
                      >
                        Forgot your password?
                      </Typography>
                      <br></br>
                      <div className={`messageBox`}>
                        <InfoIcon
                          sx={{
                            fontSize: "20px",
                            color: ThemeProperties.purple,
                          }}
                        />
                        <br></br>
                        <Typography variant="textPasswordReset" align="center">
                          Enter your user account's verified email address and
                          we will send you a password reset link
                        </Typography>
                      </div>
                    </>
                  )}

                  {!linkSend && (
                    <>
                      <FormControl
                        className="formBox"
                        sx={{ m: 1, width: "25ch" }}
                        variant="outlined"
                        style={{ width: "100%" }}
                      >
                        <OutlinedTextField
                          // margin="normal"
                          id="emailId"
                          type={"text"}
                          value={username}
                          onChange={(e) => setUsername(e.target.value.trim())}
                          error={error}
                          autoComplete="email"
                          label="Email ID"
                        />
                        <div className="forgot">
                          <Typography variant="textSubtitleRed">
                            {error}
                          </Typography>
                        </div>
                      </FormControl>
                      {loading ? (
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            mt: 3,
                            mb: 2,
                            fontSize: 13,
                            borderRadius: "1rem",
                          }}
                          disabled
                        >
                          <CircularProgress size={24} />
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            mt: 3,
                            mb: 2,
                            fontSize: 13,
                            borderRadius: "1rem",
                          }}
                          onClick={() => handlePasswordReset()}
                        >
                          Send password reset mail
                        </Button>
                      )}

                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          mt: 0.5,
                          mb: 2,
                          fontSize: 13,
                          borderRadius: "1rem",
                        }}
                        onClick={() => navigate("/")}
                      >
                        Go back to log in
                      </Button>
                    </>
                  )}
                </>
              )}

              {tab === 3 && (
                <>
                  <Typography variant="textSubtitle" align="center">
                    Change password for
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      fontFamily: ThemeProperties.primaryFont,
                    }}
                    align="center"
                  >
                    <b>{emailID}</b>
                  </Typography>
                  <br></br>

                  <FormControl
                    className="formBox"
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                  >
                    <OutlinedTextField
                      className="input"
                      id="new-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      error={error}
                      onChange={(e) => setPassword(e.target.value.trim())}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label=" New Password"
                    />
                  </FormControl>

                  <FormControl
                    className="formBox"
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                  >
                    <OutlinedTextField
                      className="input"
                      error={error}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) =>
                        setConfirmPassword(e.target.value.trim())
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConfirmShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label=" Confirm Password"
                    />
                  </FormControl>
                  <div className="forgot">
                    <Typography variant="textSubtitleRed">{error}</Typography>
                  </div>

                  <div className={`messageBox`}>
                    <InfoIcon
                      sx={{ fontSize: "20px", color: ThemeProperties.purple }}
                    />
                    <br></br>
                    <Typography variant="textPasswordReset" align="center">
                      Make sure your new password has atleast 8 characters which
                      includes an upper case letter, a lower case letter, a
                      number and a special character
                    </Typography>
                  </div>

                  {loading ? (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
                      disabled
                    >
                      <CircularProgress />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
                      onClick={() => handleChangePassword()}
                    >
                      Change Password
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 0.5,
                      mb: 2,
                      fontSize: 13,
                      borderRadius: "1rem",
                    }}
                    onClick={() => navigate("/")}
                  >
                    Go back to log in
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
