import React from "react";
import { Box } from "@mui/material";
import NoData from "../../Assets/Icons/Map/NoData.svg";

function NoDataMap(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <Box sx={{ ml: -15, mt: -10 }}>
          <img src={NoData} alt="not found" width={250}></img>
        </Box>
      </div>
    </div>
  );
}

export default NoDataMap;
