import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useNavigate, useLocation } from "react-router-dom";
import Events from "../../Analytics/Events";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import Loader from "../../Helper/Loaders/Loader";
import "./chart.css";

function closestMultiple(n, x) {
  if (x > n) return x;
  let num = n;
  while (num % x !== 0) {
    num = num + 1;
  }
  // console.log(num)
  return num;
}

function ApexScatterPlusLine(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();

  const [mounted, setMounted] = useState(false);
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  function mountTheChart() {
    // console.log(props.scatter)
    const newColors = new Array(30).fill(props.xAxisLabelColor);
    setChartState({
      text: "",
      series: [
        {
          name: "SOH",
          type: "scatter",

          //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
          data: props.scatter,
        },
      ],
      options: {
        chart: {
          width: 500,
          type: "line",
          zoom: {
            enabled: true,
            type: "xy",
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                // dateFormatter(timestamp) {
                //   return new Date(timestamp).toDateString();
                // },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
            // autoSelected: false,
          },
          animations: {
            enabled: false,
          },
          events: {
            beforeZoom: function (event, chartContext, config) {
              const MIN_ZOOM_RANGE = 1; // 15 minutes in milliseconds
              const { xaxis } = chartContext;

              let { yaxis } = chartContext;
           

              let min = xaxis.min;
              let max = xaxis.max;


              if (max - min >= props.xmax) {
                return {
                  xaxis: {
                    min: props.xmin,
                    max: closestMultiple(props.xmax, 5),

                    labels: {
                      formatter: function (value) {
                        return value?.toFixed(0);
                      },
                    },
                  },
                  yaxis: {
                    tickAmount: 4,
                    min: yaxis && yaxis[0]? yaxis[0].min: props.ymin,
                    max: yaxis && yaxis[0]? yaxis[0].max: props.ymax,
                    title: {
                      text: "SOH (%)",
                      // offsetX: 6,
                      offsetY: 0,
                      style: {
                        color: ThemeProperties.black,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    labels: {
                      show: true,
                      rotateAlways: false,
                      align: "center",
                      minWidth: 50,
                      maxWidth: 50,
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      formatter: function (value) {
                        return parseFloat(value)?.toFixed(0);
                      },
                      // offsetX: -13,
                      offsetY: 0,
                    },
                  },
                };
              }

              if (max - min < MIN_ZOOM_RANGE) {

                let newmax = min + MIN_ZOOM_RANGE;

                return {
                  xaxis: {
                    min: min,
                    max: newmax,
                    labels: {
                      formatter: function (value) {
                        return value?.toFixed(1);
                      },
                    },
                  },
                  yaxis: {
                    tickAmount: 4,
                    min: yaxis && yaxis[0]? yaxis[0].min: props.ymin,
                    max: yaxis && yaxis[0]? yaxis[0].max: props.ymax,
                    title: {
                      text: "SOH (%)",
                      // offsetX: 6,
                      offsetY: 0,
                      style: {
                        color: ThemeProperties.black,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    labels: {
                      show: true,
                      rotateAlways: false,
                      align: "center",
                      minWidth: 50,
                      maxWidth: 50,
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      formatter: function (value) {
                        return parseFloat(value)?.toFixed(1);
                      },
                      // offsetX: -13,
                      offsetY: 0,
                    },
                  },
                };
              } else {
                return {
                  xaxis: {
                    min: min,
                    max: max,

                    labels: {
                      formatter: function (value) {
                        return value?.toFixed(1);
                      },
                    },
                  },
                  yaxis: {
                    tickAmount: 4,
                    min: yaxis && yaxis[0]? yaxis[0].min: props.ymin,
                    max: yaxis && yaxis[0]? yaxis[0].max: props.ymax,
                    title: {
                      text: "SOH (%)",
                      // offsetX: 6,
                      offsetY: 0,
                      style: {
                        color: ThemeProperties.black,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    labels: {
                      show: true,
                      rotateAlways: false,
                      align: "center",
                      minWidth: 50,
                      maxWidth: 50,
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      formatter: function (value) {
                        return parseFloat(value)?.toFixed(1);
                      },
                      // offsetX: -13,
                      offsetY: 0,
                    },
                  },
                };
              }
            },
            beforeResetZoom: function (chartContext, opts) {
              return {
                xaxis: {
                  min: props.xmin,
                  max: closestMultiple(props.xmax, 5),

                  labels: {
                    formatter: function (value) {
                      return Math.round(value);
                    },
                  },
                },
                yaxis: {
                  tickAmount: 4,
                  title: {
                    text: "SOH (%)",
                    // offsetX: 6,
                    offsetY: 0,
                    style: {
                      color: ThemeProperties.black,
                      fontSize: ThemeProperties.c_axis_title,
                      fontFamily: "Roboto",
                      fontWeight: ThemeProperties.c_axis_title_weight,
                    },
                  },
                  labels: {
                    show: true,
                    rotateAlways: false,
                    align: "center",
                    minWidth: 50,
                    maxWidth: 50,
                    style: {
                      colors: [ThemeProperties.c_axis_labels_color],
                      fontSize: ThemeProperties.c_axis_labels,
                      fontFamily: "Roboto",
                      fontWeight: ThemeProperties.c_axis_labels_weight,
                    },
                    formatter: function (value) {
                      return parseFloat(value)?.toFixed(0);
                    },
                    // offsetX: -13,
                    offsetY: 0,
                  },
                },
              };
            },
            dataPointSelection: (event, chartContext, config) => {
              Events("clicked Battery Id Fleet Battery Health");
              const batteryId =
                config.w.globals.initialSeries[0].data[config.dataPointIndex]
                  .deviceID;
              let temp;
              temp = {
                ...queryParams,
                batteryID: batteryId,
                batterySearchedGlobal:"false",
                tabValue: 0,
                tabName: "Health",
                prevLink: location.pathname + location.search,
              };
              encryptTheParams(temp, navigate, true);
              // navigate(`/specificBattery?batteryID=${batteryId}`, {
              //   state: { detail: batteryId, tabValue: 5, tabName:"Degradation" },
              // });
            },

            // dataPointMouseEnter: function(event) {
            //   // event.path[0].style.cursor = "pointer";
            // }
          },
        },
        grid: {
          show: true,
          position: "back",
          clipMarkers: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 20,
          },
        },
        stroke: {
          show: false,
          // curve: 'smooth',
          lineCap: "butt",
          colors: undefined,
          width: 2.5,
          dashArray: 0,
        },
        fill: {
          type: "solid",
        },
        markers: {
          size: [3],
          strokeWidth: 1,
          hover: {
            size: 3,
            sizeOffset: 0.5,
          },
        },
        tooltip: {
          shared: false,
          intersect: false,
          followCursor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            var ageInMonths = props.cycleAge ? data.secondaryX : data.x;
            var eqCycles = props.cycleAge ? data.x : data.secondaryX;
            return (
              '<div class="tooltip_container">' +
              `<span class="flex_center">` +
              // `<span class="dot_purple"></span>` +
              `<span class="text_container">Battery ID <b>: ` +
              data.batteryID +
              "</b></span>" +
              "</span>" +
              '<hr class="horizontal_rule"></hr>' +

              `<span class="time_container">Device ID <b>: ` +
              data.deviceID +
              "</b></span>" +
              `<br></br>` +


              `<span class="time_container">SOH (%) <b>: ` +
              data.y?.toFixed(1) +
              "</b></span>" +
              `<br></br>` +
              // `<span class="time_container">BMS SOH (%) <b>: ` +
              // data.sohBMS?.toFixed(1) +
              // "</b></span>" +
              //`<br></br>` +
              `<span class="time_container">Age (months) <b>: ` +
              ageInMonths?.toFixed(1) +
              "</b></span>" +
              `<br></br>` +

              `<span class="time_container">Eq. Cycle <b>: ` +
              eqCycles?.toFixed(1) +
              "</b></span>" +
              // `<span class="time_container">Eq. Cycle BMS <b>: ` +
              // data.eqCycleBMS?.toFixed(1) +
              // "</b></span>" +
              // `<br></br>` +
              "</div>"
            );
          },
        },
        colors: [ThemeProperties.purple, ThemeProperties.c_orange],
        xaxis: {
          type: "numeric",
          min: props.xmin,
          max: closestMultiple(props.xmax, 5),
          tickAmount: 10,
          title: {
            text: props.xAxisTitle,
            offsetX: 3.7,
            offsetY: 0,
            style: {
              color: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_title_weight,
            },
          },
          labels: {
            show: true,
            rotateAlways: false,
            align: "center",
            style: {
              colors: newColors,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            formatter: function (value) {
              return Math.round(value);
            },
            // offsetX: -5,
            offsetY: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: props.ymin,
          max: props.ymax,
          tickAmount: 4,
          title: {
            text: "SOH (%)",
            // offsetX: 6,
            offsetY: 0,
            style: {
              color: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_title_weight,
            },
          },
          labels: {
            show: true,
            rotateAlways: false,
            align: "center",
            minWidth: 50,
            maxWidth: 50,
            style: {
              colors: [ThemeProperties.c_axis_labels_color],
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            formatter: function (value) {
              return value?.toFixed(0);
            },
            // offsetX: -13,
            offsetY: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        legend: {
          show: false,
          colors: [ThemeProperties.c_legend_labels_color],
          fontSize: ThemeProperties.c_legend_labels,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_legend_labels_weight,
          //   offsetY: 8,
        },
      },
    });
    setMounted(true);
  }

  useEffect(() => {
    setMounted(false);
    mountTheChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.line, props.scatter, props.cycleAge]);

  return (
    <>
      {mounted ? (
        <>
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="line"
            height="100%"
            width="100%"
            id={props.id}
          />
          {chartState.series[0]["data"].length > 0 && (
            <>
              <div className="sideBox left"></div>
              <div className="sideBox right"></div>
              <div className="topBox top"></div>
              <div className="topBox bottom"></div>
            </>
          )}
        </>
      ) : (
        <div style={{ height: "100%" }}>
          <Loader />
        </div>
      )}
    </>
  );
}

export default ApexScatterPlusLine;
