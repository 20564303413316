import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch } from "react-redux";
import Events from "../../Analytics/Events";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  SetDashboardAlertTypeCode,
  SetDashboardAlertTypeIndex,
} from "../../Actions/Dashboard";
export default function AlertType(props) {
  const dispatch = useDispatch();

  const ChangeTheSelect = (event) => {
    if (props.filter.itemName === "Alert Type") {
      let obj =
        props.filter.items[event.target.value].value === ""
          ? props.alertType.find((o) => o.itemName === "All")
          : props.alertType.find(
              (o) => o.itemName === props.filter.items[event.target.value].value
            );
      let newArr = obj.items;

      dispatch(SetDashboardAlertTypeCode(newArr[0]["alertCode"]));
      dispatch(SetDashboardAlertTypeIndex(0));
    }
    props.handleSetFilter(
      props.filter.itemName,
      props.filter.items[event.target.value].value
    );
    Events(
      "clicked " + props.pageName + " " + props.filter.itemName + " filter"
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <FormControl size="small" variant="standard">
          <Select
            variant="standard"
            sx={{
              ":before": { borderBottomColor: "transparent" },
              ":after": { borderBottomColor: "transparent" },
              "&:hover:after": {
                borderBottomColor: "#ececec",
              },
            }}
            labelId="demo-select-small"
            MenuProps={{
              style: { zIndex: 10001 },
            }}
            inputProps={{ IconComponent: () => <ArrowDropDownIcon sx={{fontSize:"20px",mt:0.5}}/> }}
            // id="overflow-text"
            value={props.filter.items
              .map((o) => o.value)
              .indexOf(props.selected)}
            label="Age"
            name={props.filter.itemName}
            onChange={ChangeTheSelect}
            renderValue={(selected) => {
              return (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    mt: 1,
                    color: ThemeProperties.purple,
                  }}
                >
                  {props.filter.items[selected] !== undefined &&
                    props.filter.items[selected].key}
                </Typography>
              );
            }}
          >
            {props.filter.items.map((item, index) => (
              <MenuItem value={index} key={index}>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                >
                  {item.key}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
