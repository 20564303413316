export const SetMapFullScreen = (fullScreen) =>
{
    return {
        type: "MapFullScreen",
        fullScreen: fullScreen
    }
}
export const SetMapSearch = (search) =>
{
    return {
        type: "MapSearch",
        search: search
    }
}
export const SetMapClusterClick = (clusterClick) =>
{
    return {
        type: "MapClusterClick",
        clusterClick: clusterClick
    }
}
export const SetMapSearchedBattery = (searchBattery) =>
{
    return {
        type: "MapSearchedBattery",
        searchBattery: searchBattery
    }
}
export const SetMapBatteryPartnerSelect = (select) =>
{
    return {
        type: "MapBatteryPartnerSelect",
        select: select
    }
}
export const SetMapUserInputBatteries = (batteries) =>
{
    return {
        type: "MapUserInputBatteries",
        batteries: batteries
    }
}
export const SetMapFoundBatteries = (batteries) =>
{
    return {
        type: "MapFoundBatteries",
        batteries: batteries
    }
}
export const SetMapNotFoundBatteries = (batteries) =>
{
    return {
        type: "MapNotFoundBatteries",
        batteries: batteries
    }
}
export const SetMapMultiplePartners = (partners) =>
{
    return {
        type: "MapMultiplePartners",
        partners: partners
    }
}
export const SetMapVolt = (volt) =>
{
    return {
        type: "MapVolt",
        volt: volt
    }
}
export const SetMapCity = (city) =>
{
    return {
        type: "MapCity",
        city: city
    }
}
export const SetMapStatus = (status) =>
{
    return {
        type: "MapStatus",
        status: status
    }
}
export const SetMapSeverity = (severity) =>
{
    return {
        type: "MapSeverity",
        severity: severity
    }
}
export const SetMapZone = (zone) =>
{
    return {
        type: "MapZone",
        zone: zone
    }
}
export const SetMapAlertType = (alertType) =>
{
    return {
        type: "MapAlertType",
        alertType: alertType
    }
}
export const SetMapIotVendor = (IotVendor) =>
{
    return {
        type: "MapIotVendor",
        IotVendor: IotVendor
    }
}
export const SetMapBatteryVendor = (BatteryVendor) =>
{
    return {
        type: "MapBatteryVendor",
        BatteryVendor: BatteryVendor
    }
}