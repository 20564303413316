import React from "react";
import Popover from "@mui/material/Popover";
import { SimpleBox } from "../../Components/CustomBox";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function SimplePopover(props) {
  return (
    <Popover
      id={props.idProfilePopup}
      open={props.openProfilePopup}
      anchorEl={props.anchorElProfilePopup}
      onClose={props.handleCloseProfilePopup}
      container={props.anchorElProfilePopup.parentNode}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      elevation={2}
    >
      <SimpleBox sx={{ p: 2,maxHeight:400 }}>
        <Box sx={{ width: "100%", minWidth: 400, bgcolor: "background.paper",display:"inline" }}>
          <Typography variant="textSubtitle">Batteries not found : <b>{props.data.length}</b></Typography>
          <div style={{borderBottom:"1px solid #ececec",margin:"10px 0px"}}></div>
          {props.data.map((item, index) => 
          <Box sx={{mb:1,display:"flex",alignItems:"center",justifyContent:"center"}} key={index}>
            <Typography variant="textSubtitle" key={index}>{item}</Typography>
            </Box>
          )}
        </Box>
      </SimpleBox>
    </Popover>
  );
}
