import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import { InnerBox, OuterBox, SimpleBox } from "../../Components/CustomBox";
import { Brightness1, KeyboardBackspace } from "@mui/icons-material";

import Header from "../../Features/Header/Header";
import SimplePageHeader from "../../Features/PageHeader/SimplePageHeader";
import BasicTabs from "../../Features/Tabs/BasicTabs";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import {
  PaperHighlight,
  PaperWithBottomRadius,
} from "../../Components/CustomPaper";

import ApexBasicBar from "../../Charts/Bar/ApexBasicBar";
// import ApexColumnPlusLine from "../../Charts/Mixed/ApexColumnPlusLine";
import ApexBasicPie from "../../Charts/Pie/ApexBasicPie";
import BatteryPackCarousel from "../../PagesContent/SpecificBattery/BatteryPackCarousel";
import ApexBasicMultiLine from "../../Charts/Line/ApexBasicMultiLine";

import { columnsNew as ErrorNew } from "../../Static/Data/SpecificBattery/SpecificBatteryError";
import { columnsNew as AlertNew } from "../../Static/Data/SpecificBattery/SpecificBatteryAlert";
import { columnsNew as ConfigNew } from "../../Static/Data/SpecificBattery/SpecificBatteryConfig";
import { columnsNew as AnomalyNew } from "../../Static/Data/SpecificBattery/SpecificBatteryAnomaly";

import {
  getBatteryAlerts,
  getBatteryAlertsList,
  getBatteryErrors,
  getBatteryErrorsList,
  // getBatteryHealth,
  getConfigurationList,
  getBatteryErrorsHistoric,
  getSOHDegradation,
  getBatteryAnomaly,
  getBatteryAnomalyList,
  getRealTimeStatus,
  postTagTheBattery,
  getSohRUL,
} from "../../Api/SpecificBatteryApi";
import { SetSessionExpired } from "../../Actions";

import {
  formatDate,
  formatDateNumeric,
  maxDate,
} from "../../Helper/DatePicker/DateFormatters";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// import CloseIcon from "@mui/icons-material/Close";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import RealtimeKPI from "../../PagesContent/SpecificBattery/RealtimeKPI";
// import DownloadCSVButton from "../../Helper/CSVOperations/DownloadCSVButton";
import Events from "../../Analytics/Events";
import {
  SetSpecificBatteryDateDisable,
  SetSpecificBatteryDateInvalid,
} from "../../Actions/SpecificBattery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicTableNoPagination from "../../Features/Table/AntDesign/BasicTableNoPagination";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import ScrollToTheElement from "../../Helper/Operations/ScrollToTheElement";
// import Slide from '@mui/material/Slide';
import { notification } from "antd";
import WarrantyTabs from "../../PagesContent/SpecificBattery/WarrantyTabs";
import CODE from "../../Static/Constants/StatusCodes";
const Context = React.createContext({
  name: "Default",
});

const alerts = [
  {
    alertDescription: "High temperature during charging",
    color: ThemeProperties.safety_1,
  },
  {
    alertDescription: "High temperature inside vehicle",
    color: ThemeProperties.safety_2,
  },
  {
    alertDescription: "High temperature in warehouse",
    color: ThemeProperties.safety_3,
  },
  {
    alertDescription: "Over voltage protection breach",
    color: ThemeProperties.safety_4,
  },
  {
    alertDescription: "Cold charging",
    color: ThemeProperties.safety_5,
  },
  {
    alertDescription: "Abnormally high current during charging",
    color: ThemeProperties.safety_6,
  },
  {
    alertDescription: "Abnormally high current during driving",
    color: ThemeProperties.safety_7,
  },
  {
    alertDescription: "High current fluctuation during charging",
    color: ThemeProperties.safety_8,
  },
  {
    alertDescription: "High current at full voltage during charging",
    color: ThemeProperties.safety_9,
  },

  {
    alertDescription: "Low voltage in vehicle",
    color: ThemeProperties.operational_1,
  },
  {
    alertDescription: "Low voltage in warehouse",
    color: ThemeProperties.operational_2,
  },
  {
    alertDescription: "Potential deep discharge",
    color: ThemeProperties.operational_3,
  },
  {
    alertDescription: "Battery not pinging",
    color: ThemeProperties.operational_4,
  },

  {
    alertDescription: "Field charging",
    color: ThemeProperties.violation_1,
  },
  {
    alertDescription: "Night charging",
    color: ThemeProperties.violation_2,
  },
  {
    alertDescription: "Unauthorised charging",
    color: ThemeProperties.violation_3,
  },
  {
    alertDescription: "Unauthorised field charging",
    color: ThemeProperties.violation_3,
  },
  // {
  //   alertCode: "vln_ic",
  //   alertDescription: "Incomplete Charging",
  //   itemIDs: [],
  // },
];

function SpecificBatteryMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const queryParams = decryptTheParams();
  let start = queryParams["startDateSB"]
    ? formatDateNumeric(new Date(queryParams["startDateSB"]))
    : formatDateNumeric(new Date());
  let end = queryParams["endDateSB"]
    ? formatDateNumeric(new Date(queryParams["endDateSB"]))
    : formatDateNumeric(new Date());

  // let finalTab = props.pagesContent.metaInfo.tabs.find((o) => o.name === queryParams["tabName"]).id

  const [date, setDate] = useState([start, end]);
  const [dateString, setDateString] = useState(["", ""]);
  const [tabvalue, setTabValue, refTabValue] = useState(0);
  const [showHistoricalData, setShowHistoricalData] = useState(false);
  const [cycleAge, showCycleAge] = React.useState(false);
  const invalid = useSelector(
    (state) => state.SpecificBatteryDateInvalid.value
  );
  const [flag, setFlag] = useState({
    value: -1,
    response: {
      code: -1,
      msg: "Flag",
    },
  });
  const [packMetricsCSV, setPackMetricsCSV] = useState([]);

  const [realTimeStatus, setRealTimeStatus] = useState({
    batteryType: "",
    partner: "",
    status: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryAlerts, setBatteryAlerts] = useState({
    alerts: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [batteryAlertsList, setBatteryAlertsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryErrors, setBatteryErrors] = useState({
    number: [],
    labels: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [batteryErrorsList, setBatteryErrorsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [batteryErrorsHistoric, setBatteryErrorsHistoric] = useState({
    errorDescription: [],
    errorCount: [],
    errorCode: [],
    responseStatus: { code: -1, msg: "" },
  });

  // const [batteryHealth, setBatteryHealth] = useState({
  //   batteryDate: [],
  //   cycles: [],
  //   soh: [],
  //   responseStatus: { code: -1, msg: "" },
  // });

  const [batteryConfigList, setBatteryConfigList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [sohDegradation, setSohDegradation] = useState({
    data: [],
    csvData: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [rul, setRUL] = useState({
    data: [],
    csvData: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [finalData, setFinalData] = useState({
    data: [],
    data2: [],
    csvData: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryAnomaly, setBatteryAnomaly] = useState({
    number: [],
    labels: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [batteryAnomalyList, setBatteryAnomalyList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const handleToggleCycleAge = (event) => {
    Events("SB Toggled Show Eq. Cycle Degradation Curve");
    showCycleAge(event.target.checked);
  };

  function handleBack() {
    let queryParams = decryptTheParams();
    // console.log(queryParams["prevLink"])
    if (queryParams["copiedLink"]) navigate(`${queryParams["copiedLink"]}`);
    else navigate(`${queryParams["prevLink"]}`);
  }

  function SetCSVData(data) {
    setPackMetricsCSV(data);
  }

  useEffect(() => {
    let finalType, finalValue;

    if (queryParams["batteryID"] && queryParams["batteryID"] !== "undefined") {
      finalType = "Device";
      finalValue = queryParams["batteryID"];
    } else if (
      queryParams["batteryIDGlobal"] &&
      queryParams["batteryIDGlobal"] !== "undefined"
    ) {
      finalType = "Battery";
      finalValue = queryParams["batteryIDGlobal"];
    }

    getRealTimeStatus(finalType, finalValue).then((res) => {
      if (res.responseStatus.code === 200) {
        setRealTimeStatus({
          batteryType:
            res.response.batteryData.batteryType === undefined
              ? "Battery ID"
              : res.response.batteryData.batteryType,
          partner: res.response.batteryData.partner,
          status: res.response.batteryData,
        });
        const val = res.response.batteryData.battery_flag;
        setFlag({
          value: val,
          response: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
    setTabValue(
      props.pagesContent.metaInfo.tabs.find((o) => o.name === props.tabName).id
    );
    window.scrollTo(0, 0);
    showHistoricalData && setShowHistoricalData(false);
    cycleAge && showCycleAge(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID]);

  // useEffect(() => {
  //   let queryParams = decryptTheParams();
  //   if (queryParams["tabName"]) {
  //     setTabValue(
  //       props.pagesContent.metaInfo.tabs.find(
  //         (o) => o.name === queryParams["tabName"]
  //       ).id
  //     );
  //   }
  //   if (queryParams["startDate"] && queryParams["endDate"]) {
  //     setDate([
  //       formatDateNumeric(new Date(queryParams["startDate"])),
  //       formatDateNumeric(new Date(queryParams["endDate"])),
  //     ]);
  //   }
  // }, [window.location.search]);

  const confirmBatteryFlag = (tag) => {
    Events("clicked SB flag confirm button");

    let queryParams = decryptTheParams();

    let finalType, finalValue;

    if (queryParams["batteryID"] && queryParams["batteryID"] !== "undefined") {
      finalType = "Device";
      finalValue = queryParams["batteryID"];
    } else if (
      queryParams["batteryIDGlobal"] &&
      queryParams["batteryIDGlobal"] !== "undefined"
    ) {
      finalType = "Battery";
      finalValue = queryParams["batteryIDGlobal"];
    }

    postTagTheBattery(tag, finalType, finalValue).then((res) => {
      // console.log(res.response)
      if (res.responseStatus.code === 201) {
        setFlag({
          value: tag,
          response: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        window.location.reload();
      } else {
        if (res.responseStatus.code === 403) {
          setFlag((prev) => ({
            value: prev.value,
            response: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          }));
        }
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
  };

  useEffect(() => {
    let finalDate = ["", ""];
    finalDate[0] = date[0];
    finalDate[1] = date[1];

    setDateString([finalDate[0], finalDate[1]]);
    dispatch(SetSpecificBatteryDateDisable(false));

    let queryParams = decryptTheParams();

    let finalType, finalValue;

    if (queryParams["batteryID"] && queryParams["batteryID"] !== "undefined") {
      finalType = "Device";
      finalValue = queryParams["batteryID"];
    } else if (
      queryParams["batteryIDGlobal"] &&
      queryParams["batteryIDGlobal"] !== "undefined"
    ) {
      finalType = "Battery";
      finalValue = queryParams["batteryIDGlobal"];
    }

    // console.log(" the values are ",finalType,finalValue)

    if (
      props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
      "Alerts"
    ) {
      getBatteryAlerts(finalDate, finalType, finalValue).then((res) => {
        if (res.responseStatus.code === 200) {
          alerts.forEach((item, index) => {
            let tempObj = res.response.alertsData.find(
              (o) => o.alertDescription === item.alertDescription
            );
            item.alertCount = tempObj === undefined ? 0 : tempObj.alertCount;
          });

          setBatteryAlerts({
            alerts: alerts,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) {
            dispatch(SetSessionExpired(true));
          }
          setBatteryAlerts({
            alerts: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
      getBatteryAlertsList(finalDate, finalType, finalValue).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryAlertsList({
            data: res.response.alertData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteryAlertsList({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }

    if (
      props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
      "BMS Errors"
    ) {
      showHistoricalData
        ? getBatteryErrorsHistoric(props.batteryID).then((res) => {
            if (res.responseStatus.code === 200) {
              const description = [],
                count = [],
                code = [];
              res.response.batteryData.forEach((item) => {
                item["errorDescription"]
                  ? description.push(item["errorDescription"])
                  : description.push("");
                count.push(item["errorCount"]);
                code.push(item["errorCode"]);
              });
              setBatteryErrorsHistoric({
                errorDescription: description,
                errorCount: count,
                errorCode: code,
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              });
            } else {
              if (res.responseStatus.code === -2)
                dispatch(SetSessionExpired(true));

              setBatteryErrorsHistoric({
                errorDescription: [],
                errorCount: [],
                errorCode: [],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              });
            }
          })
        : getBatteryErrors(finalDate, props.batteryID).then((res) => {
            if (res.responseStatus.code === 200) {
              let ad = [],
                ac = [];
              res.response.errorData.forEach((item) => {
                ad.push(item["errorCode"]);
                ac.push(item["errorCount"]);
              });
              setBatteryErrors({
                number: ac,
                labels: ad,
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              });
            } else {
              if (res.responseStatus.code === -2) {
                dispatch(SetSessionExpired(true));
              }
              setBatteryErrors({
                number: [],
                labels: [],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              });
            }
          });

      getBatteryErrorsList(finalDate, props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryErrorsList({
            data: res.response.errorData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteryErrorsList({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }

    if (
      props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
      "Health"
    ) {
      setSohDegradation({
        data: [],
        csvData: [],
        responseStatus: { code: -1, msg: "" },
      });

      setRUL({
        data: [],
        csvData: [],
        responseStatus: { code: -1, msg: "" },
      });
      getSOHDegradation(finalType, finalValue).then((res) => {
        if (res.responseStatus.code === 200) {
          let tempData = [],
            tempCSV = [],
            cart = [];

          const nullish = res.response.specificBatteryHealth.every(
            (item) => item.sohEst === null
          );
          if (!nullish) {
            res.response.specificBatteryHealth.forEach((item) => {
              tempCSV.push({
                "Eq. Cycle": item.eqCycleEst?.toFixed(2),
                "SOH (%)": item.sohEst,
                "Age (months)": item.age ? (item.age / 30).toFixed(2) : 0,
                Date: formatDate(new Date(item.date)),
                Forecasted: false,
              });
            });

            if (cycleAge) {
              res.response.specificBatteryHealth.forEach((item) => {
                item.eqCycleEst &&
                  item.sohEst &&
                  cart.push({
                    x: item.eqCycleEst,
                    y: item.sohEst,
                    secondaryX: item.age / 30,
                    date: item.date,
                  });
              });
              cart = cart.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.x === value.x && t.y === value.y)
              );
              tempData = cart;
            } else {
              res.response.specificBatteryHealth.forEach((item) => {
                item.age &&
                  item.sohEst &&
                  cart.push({
                    x: item.age / 30,
                    y: item.sohEst,
                    secondaryX: item.eqCycleEst,
                    date: item.date,
                  });
              });
              cart = cart.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.x === value.x && t.y === value.y)
              );
              tempData = cart;
            }
          } else {
            setSohDegradation({
              data: [],
              csvData: [
                {
                  "Eq. Cycle": "",
                  "SOH (%)": "",
                  "Age (months)": "",
                  Date: "",
                },
              ],
              responseStatus: {
                code: CODE.NODATA,
                msg: "No Data Found",
              },
            });
          }

          setSohDegradation({
            data: tempData,
            csvData: tempCSV,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          // if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setSohDegradation({
            data: [],
            csvData: [
              {
                "Eq. Cycle": "",
                "SOH (%)": "",
                "Age (months)": "",
                Date: "",
              },
            ],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
      getSohRUL(finalType, finalValue).then((res) => {
        // console.log(res.response)
        if (res.responseStatus.code === 200) {
          let tempData = [],
            tempCSV = [],
            cart = [];

          res.response.specificBatteryRUL.forEach((item) => {
            tempCSV.push({
              "Eq. Cycle": item.eqCycleEst?.toFixed(2),
              "SOH (%)": item.sohForecast,
              "Age (months)": item.calendarAgeInDays
                ? (item.calendarAgeInDays / 30).toFixed(2)
                : 0,
              Date: "",
              Forecasted: true,
            });
          });

          if (cycleAge) {
            res.response.specificBatteryRUL.forEach((item) => {
              item.eqCycleEst &&
                item.sohForecast &&
                cart.push({
                  x: item.eqCycleEst,
                  y: item.sohForecast,
                  secondaryX: item.calendarAgeInDays / 30,
                });
            });
            tempData = cart;
          } else {
            res.response.specificBatteryRUL.forEach((item) => {
              item.calendarAgeInDays &&
                item.sohForecast &&
                cart.push({
                  x: item.calendarAgeInDays / 30,
                  y: item.sohForecast,
                  secondaryX: item.eqCycleEst,
                });
            });
            tempData = cart;
          }
          setRUL({
            data: tempData,
            csvData: tempCSV,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          setRUL({
            data: [],
            csvData: [
              {
                "Eq. Cycle": "",
                "SOH (%)": "",
                "Age (months)": "",
                Date: "",
              },
            ],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }

    if (
      props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
      "Configuration"
    )
      getConfigurationList(finalDate, props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryConfigList({
            data: res.response.batteryData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setBatteryConfigList({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });

    if (
      props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
      "Anomaly"
    ) {
      getBatteryAnomaly(finalDate, props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {
          let ad = [],
            ac = [];
          res.response.batteryData.forEach((item) => {
            ad.push(item["anomalyDescription"]);
            ac.push(item["anomalyCount"]);
          });
          setBatteryAnomaly({
            number: ac,
            labels: ad,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteryAnomaly({
            number: [],
            labels: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
      getBatteryAnomalyList(finalDate, props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryAnomalyList({
            data: res.response.batteryData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setBatteryAnomalyList({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }

    // const timer = setTimeout(() => {
    //   setTabIndex(0);
    // }, 1000);
    // return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, tabvalue, showHistoricalData, cycleAge]);

  useEffect(() => {
    setFinalData({
      data: [],
      data2: [],
      csvData: [],
      responseStatus: { code: -1, msg: "" },
    });
    if (
      sohDegradation.responseStatus.code === CODE.SUCCESS &&
      rul.responseStatus.code === CODE.SUCCESS
    ) {
      const newCSV = sohDegradation.csvData.concat(rul.csvData);
      setFinalData({
        data: sohDegradation.data,
        data2: rul.data,
        csvData: newCSV,
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    } else if (
      sohDegradation.responseStatus.code === CODE.SUCCESS &&
      rul.responseStatus.code !== CODE.SUCCESS &&
      rul.responseStatus.code !== CODE.LOADING
    ) {
      console.log(rul.responseStatus);
      const newCSV = sohDegradation.csvData;
      setFinalData({
        data: sohDegradation.data,
        data2: [],
        csvData: newCSV,
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    } else if (
      sohDegradation.responseStatus.code === CODE.NODATA &&
      rul.responseStatus.code === CODE.NODATA
    ) {
      setFinalData({
        data: [],
        data2: [],
        csvData: [...sohDegradation.csvData, ...rul.csvData],
        responseStatus: {
          code: sohDegradation.responseStatus.code,
          msg: sohDegradation.responseStatus.msg,
        },
      });
    }
  }, [sohDegradation.responseStatus.code, rul.responseStatus.code]);

  // useEffect(() => {
  // // if (
  // // props.pagesContent.metaInfo.tabs.find((o) => o.id === tabvalue).name ===
  // // "Degradation"
  // // ) {
  // // dispatch(SetSpecificBatteryDateDisable(true));

  // // setSohDegradation({
  // // data: [],

  // // responseStatus: {
  // // code: -1,
  // // msg: "",
  // // },
  // // });

  // //}
  // }, [cycleAge,tabvalue]);

  function changeTheDate(startDate, endDate) {
    let temp;
    // const queryParams = decryptTheParams();

    if (startDate === null && endDate === null) {
      setDate(["", ""]);
      temp = {
        ...queryParams,
        startDateSB: "",
        endDateSB: "",
        batteryPackMetrics: "",
        batteryStatus: "",
        supplementVitals: "",
      };
    } else if (endDate === null) {
      setDate([startDate, startDate]);
      temp = {
        ...queryParams,
        startDateSB: startDate,
        endDateSB: startDate,
        batteryPackMetrics: startDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    } else if (startDate === null) {
      setDate([endDate, endDate]);
      temp = {
        ...queryParams,
        startDateSB: endDate,
        endDateSB: endDate,
        batteryPackMetrics: endDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    } else {
      setDate([startDate, endDate]);
      temp = {
        ...queryParams,
        startDateSB: startDate,
        endDateSB: endDate,
        batteryPackMetrics: startDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    }

    encryptTheParams(temp, navigate, true);
    // setDate([startDate, endDate]);
  }

  const ChangeTheTab = (newValue) => {
    let temp, tabValue, tabName;

    tabValue = props.pagesContent.metaInfo.tabs.find(
      (o) => o.name === newValue
    ).id;
    tabName = props.pagesContent.metaInfo.tabs.find(
      (o) => o.name === newValue
    ).name;
    setTabValue(tabValue);
    temp = {
      ...queryParams,
      tabName: tabName,
      id: "",
    };
    encryptTheParams(temp, navigate, true);
    showHistoricalData && setShowHistoricalData(false);
    cycleAge && showCycleAge(false);
    if (invalid) {
      setDateString([
        formatDateNumeric(new Date(maxDate)),
        formatDateNumeric(new Date(maxDate)),
      ]);
      // setDate([new Date(maxDate), new Date(maxDate)]);
      dispatch(SetSpecificBatteryDateInvalid(false));
    }
  };

  function SwitchTheTab(tabvalue) {
    switch (tabvalue) {
      case "Alerts":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "475px" }}
                  onMouseEnter={() => Events("SB Hovered Alerts Table")}
                >
                  <Header
                    heading="Alerts Log"
                    description="Alerts log over time during the selected calendar period"
                    showIButton={true}
                    showThreeDots={false}
                    id="Alerts_Timeline"
                    page="specificBattery"
                  />
                  <div className="section" id="Alerts_Timeline">
                    <BasicTableNoPagination
                      data={batteryAlertsList}
                      columns={AlertNew}
                      dateString={dateString}
                      setBatteryConfigList={setBatteryAlertsList}
                      changeTheDate={changeTheDate}
                      ChangeTheTab={ChangeTheTab}
                      openNotification={openNotification}
                      onClickColumn={"insertionTime"}
                      height={340}
                      pagePerSize={8}
                      id="CanBeClicked"
                    />
                  </div>
                </PaperHighlight>
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  onMouseEnter={() => Events("SB Hovered Alerts Pie chart")}
                >
                  <Header
                    heading="Alerts Distribution"
                    description="Different types of alerts appeared during selected calendar period and their number of occurrences"
                    showIButton={true}
                    showThreeDots={false}
                    id="Alerts_Distribution"
                    page="specificBattery"
                  />
                  <div className="section" id="Alerts_Distribution">
                    <ApexBasicPie
                      data={batteryAlerts}
                      dateString={dateString}
                      setBatteryAlerts={setBatteryAlerts}
                      dataLabelsColors={[
                        ThemeProperties.white,
                        ThemeProperties.white,
                        ThemeProperties.c_axis_title_color,
                        ThemeProperties.c_axis_title_color,
                        ThemeProperties.c_axis_title_color,
                      ]}
                      unit="Alerts"
                      showLegends={true}
                      height={"100%"}
                      id="pie-legends-largeWidth"
                    />
                  </div>
                </PaperHighlight>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "475px" }}
                  onMouseEnter={() => Events("SB Hovered Alerts Table")}
                >
                  <Header
                    heading="Alerts' Timeline"
                    description="Alerts log over time during the selected calendar period"
                    showIButton={true}
                    showThreeDots={false}
                    id="Alerts_Timeline"
                    page="specificBattery"
                  />
                  <div className="section" id="Alerts_Timeline">
                    <BasicTableNoPagination
                      data={batteryAlertsList}
                      columns={AlertNew}
                      noDataMsg={"No alerts found"}
                      dateString={dateString}
                      setBatteryConfigList={setBatteryAlertsList}
                      changeTheDate={changeTheDate}
                      ChangeTheTab={ChangeTheTab}
                      openNotification={openNotification}
                      onClickColumn={"insertionTime"}
                      height={340}
                      pagePerSize={8}
                      id="CanBeClicked"
                    />
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid>
          </>
        );
      case "BMS Errors":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  onMouseEnter={() =>
                    showHistoricalData
                      ? Events(
                          "SB Hovered BMS Errors Historical Errors Distribution"
                        )
                      : Events("SB Hovered BMS Errors Pie chart")
                  }
                >
                  <Header
                    heading={
                      showHistoricalData
                        ? "Historical Errors Distribution"
                        : "Errors Distribution"
                    }
                    description="Different types of errors appeared during selected calendar period and their number of occurrences "
                    showIButton={false}
                    showThreeDots={true}
                    setShowHistoricalData={setShowHistoricalData}
                    id="Errors_Distribution"
                    page="specificBattery"
                  />
                  <div className="section" id="Errors_Distribution">
                    {showHistoricalData ? (
                      <>
                        <IconButton
                          onClick={() => setShowHistoricalData(false)}
                          fontSize="small"
                          sx={{ mr: 1.5 }}
                        >
                          <KeyboardBackspace
                            fontSize="small"
                            cursor="pointer"
                            sx={{
                              fontWeight: 200,
                              color: ThemeProperties.purple,
                              fontSize: "18px",
                            }}
                          />
                        </IconButton>
                        <Box sx={{ mt: -1.5 }}>
                          <ApexBasicBar
                            data={batteryErrorsHistoric}
                            setBatteryErrorsHistoric={setBatteryErrorsHistoric}
                            height="377px"
                            colors={[
                              "#D64174",
                              "#db5986",
                              "#df6a92",
                              "#e68dac",
                              "#ea9fb9",
                              "#eeb0c5",
                              "#f2c2d2",
                              "#f2c2d2",
                              "#f9e5ec",
                              "#f9e5ec",
                            ]}
                          />
                        </Box>
                      </>
                    ) : (
                      <ApexBasicPie
                        data={batteryErrors}
                        dateString={dateString}
                        setBatteryAlerts={setBatteryErrors}
                        colors={[
                          ThemeProperties.Error1,
                          ThemeProperties.Error2,
                          ThemeProperties.Error3,
                          ThemeProperties.Error4,
                          ThemeProperties.Error5,
                        ]}
                        dataLabelsColors={[
                          ThemeProperties.white,
                          ThemeProperties.white,
                          ThemeProperties.c_axis_title_color,
                          ThemeProperties.c_axis_title_color,
                          ThemeProperties.c_axis_title_color,
                        ]}
                        unit="Errors"
                        showLegends={true}
                        height={"100%"}
                        id="pie-legends-largeWidth"
                      />
                    )}
                  </div>
                </PaperHighlight>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "475px" }}
                  onMouseEnter={() => Events("SB Hovered BMS Errors Table")}
                >
                  <Header
                    heading="Errors' Timeline"
                    description="Errors log over time during the selected calendar period"
                    showIButton={true}
                    showThreeDots={false}
                    id="Errors_Timeline"
                    page="specificBattery"
                  />
                  <div className="section" id="Errors_Timeline">
                    <BasicTableNoPagination
                      data={batteryErrorsList}
                      columns={ErrorNew}
                      noDataMsg={"No errors found"}
                      dateString={dateString}
                      setBatteryConfigList={setBatteryErrorsList}
                      changeTheDate={changeTheDate}
                      ChangeTheTab={ChangeTheTab}
                      openNotification={openNotification}
                      onClickColumn={"insertionTime"}
                      height={340}
                      pagePerSize={8}
                      id="CanBeClicked"
                    />
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid>
          </>
        );
      case "Battery Vitals":
        // if (tabIndex === 7) {
        //   const section = document.querySelector("#battery_status");
        //   if (section !== null) {
        //     section.scrollIntoView({ behavior: "smooth", block: "start" });
        //   }
        // }
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <PaperHighlight
              elevation={0}
              onMouseEnter={() => Events("SB Hovered Battery Pack Metrics")}
            >
              <Header
                heading="Battery Pack Metrics"
                description="Showing how battery signals (Voltage, Current, Temperature, SOC) are fluctuating over time within a day"
                showIButton={true}
                showThreeDots={false}
                showCSVDownload={true}
                csvData={packMetricsCSV}
                id="Battery_Pack_Metrics"
                page="specificBattery"
              />

              <div className="section" id="Battery_Pack_Metrics">
                <BatteryPackCarousel
                  batteryID={props.batteryID}
                  dateString={dateString}
                  alertFirstSeenOn={queryParams["alertFirstSeenOn"]}
                  SetCSVData={SetCSVData}
                />
              </div>
            </PaperHighlight>
            {/* <Box sx={{ mt: 2 }}></Box>
            <PaperHighlight
              elevation={0}
              onMouseEnter={() => Events("SB Hovered Battery Status")}
              id="battery_status"
            >
              <Header
                heading="Battery Status"
                description="Changes in battery status within a day"
                showIButton={true}
                showThreeDots={false}
                id="Battery_Status"
              />
              <div className="section" id="Battery_Status">
                <ReactGoogleTimelineState
                  data={batteryStatus}
                  dateString={dateString}
                  setBatteryStatus={setBatteryStatus}
                /> 
                <BatteryStatusCarousel
                  batteryID={props.batteryID}
                  dateString={dateString}
                /> 
              </div>
            </PaperHighlight> */}
            {/* <Box sx={{ mt: 2 }}></Box>
            <PaperHighlight
              elevation={0}
              onMouseEnter={() => Events("SB Hovered Supplementary Vitals")}
            >
              <Header
                heading="Supplementary Vitals"
                description="Showing how other battery signals (Cell Voltage Difference, Min Voltage, Pack Capacity, SOC) are fluctuating over time within a day"
                showIButton={true}
                showThreeDots={false}
                id="Supp_Vitals"
              />

              <div className="section" id="Supp_Vitals">
                <VoltageCarousel
                  batteryID={props.batteryID}
                  dateString={dateString}
                />
              </div>
            </PaperHighlight> */}
          </>
        );
      case "Health":
        ScrollToTheElement();
        return (
          <>
            {/* <Box sx={{ mt: 2 }}></Box>
            <PaperHighlight
              elevation={0}
              onMouseEnter={() =>
                Events("SB Hovered SOH and Equivalent Cycles")
              }
            >
              <Header
                heading="SOH and Equivalent Cycles"
                description="Shows how SOH and Equivalent Cycles estimation from BMS changes daywise"
                showIButton={true}
                showThreeDots={false}
                id="SOH_and_Equivalent_Cycles"
                page="specificBattery"
              />
              <div className="section" id="SOH_and_Equivalent_Cycles">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ApexColumnPlusLine
                      data={batteryHealth}
                      dateString={dateString}
                      setBatteryHealth={setBatteryHealth}
                    />
                  </Grid>
                </Grid>
              </div>
            </PaperHighlight> */}
            <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "480px" }}
                  onMouseEnter={() => Events("SB Hovered Degradation Curve")}
                >
                  <Header
                    heading="Degradation Curve"
                    description="Degradation of battery over its Age (months)/Eq. Cycle"
                    showIButton={true}
                    showThreeDots={false}
                    showCSVDownload={true}
                    csvData={finalData.csvData}
                    id="Degradation_Curve"
                    page="specificBattery"
                  />
                  <div className="section" id="Degradation_Curve">
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          ml: 0,
                          mr: 0,
                          width: "max-content",
                          opacity:
                            finalData.responseStatus.code === -1 ? "0.5" : 1,
                          pointerEvents:
                            finalData.responseStatus.code === -1
                              ? "none"
                              : "auto",
                        }}
                        control={
                          <Switch
                            checked={cycleAge}
                            onChange={handleToggleCycleAge}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        }
                        label={
                          <Typography variant="textSubtitle">
                            Show Eq. Cycle
                          </Typography>
                        }
                      />
                    </FormGroup>
                    <SimpleBox sx={{ height: "480px" }}>
                      <ApexBasicMultiLine
                        data={finalData}
                        setData={setFinalData}
                        cycleAge={cycleAge}
                        colors={[ThemeProperties.Purple1]}
                        xTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
                        yTitle={"SOH (%)"}
                        yTitleColor={ThemeProperties.Purple1}
                      />
                    </SimpleBox>
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid>
          </>
        );
      case "Configuration":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "475px" }}
                  onMouseEnter={() => Events("SB Hovered Build Configuration")}
                >
                  <Header
                    heading="Configuration Change Summary"
                    description="Change log of BMS/ TCU Configuration"
                    showIButton={true}
                    showThreeDots={false}
                    id="Configuration_Change_Summary"
                    page="specificBattery"
                  />
                  <div className="section" id="Configuration_Change_Summary">
                    <BasicTableNoPagination
                      data={batteryConfigList}
                      selection={false}
                      noDataMsg={"No configuration found"}
                      columns={ConfigNew}
                      dateString={dateString}
                      setBatteryConfigList={setBatteryConfigList}
                      onClickColumn={"insertionTime"}
                      height={340}
                      pagePerSize={8}
                      id="CannotBeClicked"
                    />
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid>
          </>
        );
      case "Degradation":
        ScrollToTheElement();
        return (
          <>
            {/* <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "480px" }}
                  onMouseEnter={() => Events("SB Hovered Degradation Curve")}
                >
                  <Header
                    heading="Degradation Curve"
                    description="Degradation of battery over its Age (months)/Eq. Cycle"
                    showIButton={true}
                    showThreeDots={false}
                    id="Degradation_Curve"
                    page="specificBattery"
                  />
                  <div className="section" id="Degradation_Curve">
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          ml: 0,
                          mr: 0,
                          width: "max-content",
                        }}
                        control={
                          <Switch
                            checked={cycleAge}
                            onChange={handleToggleCycleAge}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        }
                        label={
                          <Typography variant="textSubtitle">
                            Show Eq. Cycle
                          </Typography>
                        }
                      />
                    </FormGroup>
                    <SimpleBox sx={{ height: "400px" }}>
                      <ApexBasicMultiLine
                        data={sohDegradation}
                        setData={setSohDegradation}
                        cycleAge={cycleAge}
                        colors={[ThemeProperties.Purple1]}
                        xTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
                        yTitle={"SOH (%)"}
                        yTitleColor={ThemeProperties.Purple1}
                      />
                    </SimpleBox>
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid> */}
          </>
        );
      case "Anomaly":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  onMouseEnter={() => Events("SB Hovered Anomaly Pie chart")}
                >
                  <Header
                    heading="Anomaly Distribution"
                    description="Different types of anomaly appeared during selected calendar period and their number of occurrences"
                    showIButton={true}
                    showThreeDots={false}
                    id="Anomaly_Distribution"
                    page="specificBattery"
                  />
                  <div className="section" id="Anomaly_Distribution">
                    <ApexBasicPie
                      data={batteryAnomaly}
                      dateString={dateString}
                      setBatteryAlerts={setBatteryAnomaly}
                      colors={[
                        ThemeProperties.Anomaly1,
                        ThemeProperties.Anomaly2,
                        ThemeProperties.Anomaly3,
                        ThemeProperties.Anomaly4,
                        ThemeProperties.Anomaly5,
                      ]}
                      dataLabelsColors={[
                        ThemeProperties.white,
                        ThemeProperties.white,
                        ThemeProperties.c_axis_title_color,
                        ThemeProperties.c_axis_title_color,
                        ThemeProperties.c_axis_title_color,
                      ]}
                      unit="Anomaly"
                      showLegends={true}
                      height={"100%"}
                      id="pie-legends-largeWidth"
                    />
                  </div>
                </PaperHighlight>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PaperHighlight
                  elevation={0}
                  sx={{ height: "475px" }}
                  onMouseEnter={() => Events("SB Hovered Anomaly Table")}
                >
                  <Header
                    heading="Anomaly Timeline"
                    description="Anomaly log over time during the selected calendar period"
                    showIButton={true}
                    showThreeDots={false}
                    id="Anomaly_Timeline"
                    page="specificBattery"
                  />
                  <div className="section" id="Anomaly_Timeline">
                    <BasicTableNoPagination
                      data={batteryAnomalyList}
                      selection={false}
                      columns={AnomalyNew}
                      noDataMsg={"No anomaly found"}
                      dateString={dateString}
                      setBatteryConfigList={setBatteryAnomalyList}
                      changeTheDate={changeTheDate}
                      ChangeTheTab={ChangeTheTab}
                      openNotification={openNotification}
                      onClickColumn={"anomalySeen"}
                      height={340}
                      pagePerSize={8}
                      id="CanBeClicked"
                    />
                  </div>
                </PaperHighlight>
              </Grid>
            </Grid>
          </>
        );
      case "Warranty Claims":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <WarrantyTabs batteryID={queryParams["batteryID"]} />
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            <Box sx={{ mt: 0 }}></Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "150px", pr: 2 }}>
                <IconButton
                  fontSize="small"
                  onClick={handleBack}
                  sx={{ ml: -1 }}
                >
                  <KeyboardBackspace
                    fontSize="small"
                    cursor="pointer"
                    sx={{
                      fontWeight: 200,
                      color: ThemeProperties.purple,
                      fontSize: "18px",
                    }}
                  />
                </IconButton>
                <SimplePageHeader
                  value="SpecificBattery"
                  batteryID={realTimeStatus?.status?.assetID}
                  assetID={realTimeStatus?.status?.batteryID}
                  partner={realTimeStatus.partner}
                  lat={realTimeStatus?.status?.latitude}
                  long={realTimeStatus?.status?.longitude}
                  lastPinged={realTimeStatus?.status?.lastPingedTime}
                  batteryFlagTime={realTimeStatus?.status?.batteryFlagTime}
                  searchType={props.searchType}
                  lastUpdated={""}
                  flag={flag}
                  setFlag={setFlag}
                  confirmBatteryFlag={confirmBatteryFlag}
                  editFlag={props.pagesContent.metaInfo.editFlag}
                />
              </Box>

              <Box
                sx={{
                  maxWidth: "1113px",
                  overflowX: "auto",
                  width: "calc(100% - 150px)",
                  opacity: flag.value !== 0 ? 0.6 : 1,
                }}
              >
                <RealtimeKPI
                  navigate={navigate}
                  setDate={setDate}
                  changeTheDate={changeTheDate}
                  ChangeTheTab={ChangeTheTab}
                  // setTabIndex={setTabIndex}
                  realtimeKPIValues={realTimeStatus.status}
                  newRealTime={props.pagesContent.metaInfo.realtimeKPI}
                  tabs={props.pagesContent.metaInfo.tabs}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 5 }}></Box>
            <Box sx={{ position: "relative" }}>
              {/* <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 5,
              zIndex: 99,
              border: `1px solid ${ThemeProperties.purple}`,
              borderRadius: "4px",
            }}
          >
            <DownloadCSVButton batteryID={props.batteryID} date={date} />
          </Box> */}
              <Box sx={{ position: "absolute", right: 0, top: 5, zIndex: 99 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AntDatePicker
                    changeTheDate={changeTheDate}
                    date={[date[0], date[1]]}
                    allowClear={false}
                  />
                </Box>
              </Box>

              <Box>
                <BasicTabs
                  ChangeTheTab={ChangeTheTab}
                  tabs={props.pagesContent.metaInfo.tabs}
                  tabValue={refTabValue}
                />

                <BasicTabPanel value={tabvalue} index={tabvalue} key={tabvalue}>
                  <PaperWithBottomRadius
                    elevation={0}
                    sx={{ border: "1px solid #ececec" }}
                  >
                    <div>
                      <>
                        {SwitchTheTab(
                          props.pagesContent.metaInfo.tabs.find(
                            (o) => o.id === tabvalue
                          ).name
                        )}
                      </>
                    </div>
                  </PaperWithBottomRadius>
                </BasicTabPanel>
              </Box>
            </Box>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
    </>
  );
}

export default SpecificBatteryMain;
