import qs from "qs";
import { GetData } from "./Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import {
  newCity,
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../Static/Data/Dashboard/DashboardFilters";
import { areArraysEqual } from "../Helper/Operations/ArrayOps";

async function getErrorTypeList(
  tabvalue,
  city,
  zone,
  batterystatus,
  packVendor,
  iotVendor,

  startDate,
  endDate,
  batteryID,
  partner,
  queryParams
) {
  let newErrorState = tabvalue === 0 ? "Active" : "Resolved";

  let newDeviceID, newBatteryID;
  if (queryParams && queryParams["type"] === "Device") {
    newDeviceID = queryParams["deviceID"];
    newBatteryID = "";
  } else if (queryParams && queryParams["type"] === "Battery") {
    newDeviceID = "";
    newBatteryID = batteryID;
  } else {
    newDeviceID = "";
    newBatteryID = "";
  }

  var rawJson = {
    errorStatus: [newErrorState],

    location: areArraysEqual(city, newCity) ? [] : city,
    zone: areArraysEqual(zone, newZone) ? [] : zone,
    batteryVendor: areArraysEqual(packVendor, newPack) ? [] : packVendor,
    batteryStatus: areArraysEqual(batterystatus, newStatus)
      ? []
      : batterystatus,
    iotVendor: areArraysEqual(iotVendor, newIOT) ? [] : iotVendor,

    errorCode: [],
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),

    batteryID: newDeviceID === "" ? [] : [newDeviceID],
    partner: partner === "" ? [] : [partner],
    assetID: newBatteryID === "" ? [] : [newBatteryID],

    batteryFlag: [],
    voltConf: [],
  };
  var config = {
    method: "get",
    url: `/api/diagnostics_group_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getTableBatteries(
  tabvalue,
  city,
  zone,
  batterystatus,
  packVendor,
  iotVendor,

  errorCode,
  startDate,
  endDate,
  batteryID,
  partner,

  currentPage,
  sortBy,
  orderBy,
  rowsPerPage,
  queryParams
) {
  let newErrorState =
    tabvalue === 0 ? "Active" : tabvalue === 1 ? "Resolved" : "Resolved";

  let newDeviceID, newBatteryID;
  if (queryParams && queryParams["type"] === "Device") {
    newDeviceID = queryParams["deviceID"];
    newBatteryID = "";
  } else if (queryParams && queryParams["type"] === "Battery") {
    newDeviceID = "";
    newBatteryID = batteryID;
  } else {
    newDeviceID = "";
    newBatteryID = "";
  }

  var rawJson = {
    errorStatus: [newErrorState],

    location: areArraysEqual(city, newCity) ? [] : city,
    zone: areArraysEqual(zone, newZone) ? [] : zone,
    batteryVendor: areArraysEqual(packVendor, newPack) ? [] : packVendor,
    batteryStatus: areArraysEqual(batterystatus, newStatus)
      ? []
      : batterystatus,
    iotVendor: areArraysEqual(iotVendor, newIOT) ? [] : iotVendor,

    errorCode: errorCode === "" ? [] : [errorCode],
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),

    batteryID: newDeviceID === "" ? [] : [newDeviceID],
    partner: partner === "" ? [] : [partner],
    assetID: newBatteryID === "" ? [] : [newBatteryID],

    batteryFlag: [],
    voltConf: [],
    pageCount: rowsPerPage,
    sortBy: sortBy,
    order: orderBy,
  };
  var config = {
    method: "get",
    url: `/api/diagnostics_list/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getDiagnosticsBatteryPartnerList(
  columnName,
  search,
  limit,
  tabvalue
) {
  let newColumnName = columnName === "Battery ID" ? "assetID" : "partner";
  let alertState = tabvalue === 0 ? "Active" : "Resolved";

  var rawJson = {
    columnName: newColumnName,
    columnSearch: search,
    columnLimit: limit,
    errorStatus: alertState,
    // batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/diagnostics_filter`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getBuildNo() {
  let data = GetData("/api/error_filters");
  return data;
}

async function getCSVOfBatteries(tempIDs) {
  var rawJson = {
    location: [],
    zone: [],
    batteryStatus: [],
    batteryVendor: [],
    iotVendor: [],
    errorStatus: [],
    errorCode: [],
    startDate: "",
    endDate: "",
    batteryID: [],
    partner: [],
    assetID: [],
    batteryFlag: [],
    voltConf: [],
    itemID: tempIDs,
    pageCount: tempIDs.length,
    sortBy: "socBMS",
    order: "asc",
  };

  var config = {
    method: "post",
    url: `/api/diagnostics_list/1`,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries(
  tabvalue,
  city,
  zone,
  batterystatus,
  packVendor,
  iotVendor,

  errorCode,
  startDate,
  endDate,
  batteryID,
  partner,
  queryParams
) {
  let newErrorState =
    tabvalue === 0 ? "Active" : tabvalue === 1 ? "Resolved" : "Resolved";

  let newDeviceID, newBatteryID;
  if (queryParams && queryParams["type"] === "Device") {
    newDeviceID = queryParams["deviceID"];
    newBatteryID = "";
  } else if (queryParams && queryParams["type"] === "Battery") {
    newDeviceID = "";
    newBatteryID = batteryID;
  } else {
    newDeviceID = "";
    newBatteryID = "";
  }

  var rawJson = {
    errorStatus: [newErrorState],

    location: areArraysEqual(city, newCity) ? [] : city,
    zone: areArraysEqual(zone, newZone) ? [] : zone,
    batteryVendor: areArraysEqual(packVendor, newPack) ? [] : packVendor,
    batteryStatus: areArraysEqual(batterystatus, newStatus)
      ? []
      : batterystatus,
    iotVendor: areArraysEqual(iotVendor, newIOT) ? [] : iotVendor,

    errorCode: errorCode === "" ? [] : [errorCode],
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),

    batteryID: newDeviceID === "" ? [] : [newDeviceID],
    partner: partner === "" ? [] : [partner],
    assetID: newBatteryID === "" ? [] : [newBatteryID],

    batteryFlag: [],
    voltConf: [],
  };
  var config = {
    method: "get",
    url: `/api/global_selection_diagnostics`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

export {
  getErrorTypeList,
  getTableBatteries,
  getDiagnosticsBatteryPartnerList,
  getCSVOfBatteries,
  getSelectAllBatteries,
  getBuildNo,
};
