import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";
import {
  encryptTheParams,
  decryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useNavigate, useLocation } from "react-router-dom";
import CODE from "../../Static/Constants/StatusCodes";
import { decryptQueryParams, encryptQueryParams } from "query-string-hash";

const ApexBasicTreemap = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();
  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  function mountTheChart(data) {
    setChartState({
      series: [
        {
          data: data,
        },
      ],
      options: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const batteryId =
                config.w.globals.categoryLabels[config.dataPointIndex];

              let query = window.location.search.slice(1);
              const queryParams = decryptQueryParams(query);
              let temp1;
              temp1 = {
                ...queryParams,
                id: props.id,
              };
              const que = new URLSearchParams(temp1).toString();
              const hash = encryptQueryParams(que);
              const copiedLink = `/${props.page}?${hash}`;

              let temp;
              temp = {
                ...queryParams,
                batteryID: batteryId,
                batterySearchedGlobal: "false",
                tabValue: 0,
                tabName: "Alerts",
                prevLink: location.pathname + location.search,
                copiedLink: copiedLink,
                id: props.id,
              };
              encryptTheParams(temp, navigate, true);
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "30px",
            color: "#000",
          },
          formatter: function (text, op) {
            return op.value;
          },
          offsetY: -4,
        },
        title: {
          text: undefined,
        },
        colors: ["#00bcd4"],

        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            var batteryID = data.batteryID ? data.batteryID : "Not found";

            return (
              "<div>" +
              '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
              data.x +
              "</div>" +
              '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
              '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
              '<div class="apexcharts-tooltip-y-group">' +
              '<span class="apexcharts-tooltip-text-y-label"> Battery ID: </span>' +
              '<span class="apexcharts-tooltip-text-y-value">' +
              batteryID +
              "</span>" +
              "</div>" +
              '<div class="apexcharts-tooltip-goals-group">' +
              '<span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span>' +
              "</div>" +
              '<div class="apexcharts-tooltip-z-group">' +
              '<span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span>' +
              "</div>" +
              "</div>" +
              "</div>" +
              '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
              `<span class="apexcharts-tooltip-marker" style="background-color: ${props.rgbColor}"></span>` +
              '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
              '<div class="apexcharts-tooltip-y-group">' +
              '<span class="apexcharts-tooltip-text-y-label"> Number of alerts: </span>' +
              '<span class="apexcharts-tooltip-text-y-value">' +
              data.y +
              "</span>" +
              "</div>" +
              '<div class="apexcharts-tooltip-goals-group">' +
              '<span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span>' +
              "</div>" +
              '<div class="apexcharts-tooltip-z-group">' +
              '<span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span>' +
              "</div>" +
              "</div>" +
              "</div>" +
              "</div>"
            );
          },
        },
      },
    });
  }

  useEffect(() => {
    console.log(props.data);
    if (
      props.data.data !== undefined
      //   &&
      //   props.data.errorCount.length > 0
    ) {
      mountTheChart(props.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.selectedPartner]);

  useEffect(
    () => () => {
      //   props.setBatteryErrorsHistoric({
      //     errorDescription: [],
      //     errorCount: [],
      //     responseStatus: { code: -1, msg: "" },
      //   });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      style={{
        height: "100%",
        // maxHeight: props.height,
      }}
      id="treemap"
    >
      {props.data.responseStatus.code === CODE.SUCCESS ? (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="treemap"
          height="100%"
          width="100%"
          // id="lastLabel"
        />
      ) : (
        <div style={{ height: "100%" }}>
          {props.data.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <div style={{ position: "relative", height: "380px" }}>
              <div
                style={{
                  position: "absolute",
                  left: "45%",
                  top: "42%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    No partner selected
                  </Typography>
                </div>
              </div>
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [ThemeProperties.grey],
                  plotOptions: {
                    bar: {
                      distributed: true, // this line is mandatory
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        position: "top",
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        colors: ["#333"],
                      },
                      // offsetX: 100
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    distributed: true,
                    offsetX: 30,
                    style: {
                      fontSize: ThemeProperties.c_axis_labels,
                      fontWeight: ThemeProperties.c_axis_labels_weight,
                      colors: [ThemeProperties.c_axis_labels_color],
                      fontFamily: "Roboto",
                    },
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    categories: [""],
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                    },
                  },
                  yaxis: {
                    labels: {
                      show: true,
                      datetimeUTC: false,
                      rotateAlways: false,
                      // align: "right",
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },

                      offsetY: 0,
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "Count",
                    data: [null],
                  },
                ]}
                type="bar"
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApexBasicTreemap;
