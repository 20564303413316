import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { BasicSelect } from "../../Components/CustomSelect";
import { Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Events from "../../Analytics/Events";
export default function GenericSelect(props) {
  const mynewColor =
    props.selected === "All" || props.selected === "Last 7 Days"
      ? "white"
      : ThemeProperties.purpleSelected;

  // const [select, setSelect] = React.useState(props.filter.items.indexOf(props.selected));
  const ChangeTheSelect = (event) => {
    // setSelect(event.target.value);
    props.handleSetFilter(
      props.filter.itemName,
      props.filter.items[event.target.value]
    );
    // props.setFilter(props.filter.items[event.target.value]);
    Events("clicked " + event.target.name + " filter");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid rgb(189,189,189)",
          borderRadius: "4px",
          width: props.maxWidth,
          mr: 2,
          mt: 1,
          mb: 1,
          height: "35px",
          bgcolor: mynewColor,
          maxWidth: props.maxWidth,
        }}
      >
        <FormControl size="small" variant="outlined">
          <InputLabel id="demo-select-small">
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 500,
                fontFamily: "Roboto",
                mt: 0.4,
                pr: 1,
                pl: 1,
                bgcolor:
                  props.selected === "All" || props.selected === "Last 7 Days"
                    ? "white"
                    : ThemeProperties.backgroundPurple,
              }}
            >
              {props.filter.itemName}
            </Typography>
          </InputLabel>
          <BasicSelect
            variant="outlined"
            labelId="demo-select-small"
            // id="overflow-text"
            value={props.filter.items.indexOf(props.selected)}
            label="Age"
            name={props.filter.itemName}
            onChange={ChangeTheSelect}
            renderValue={(selected) => {
              return (
                <Typography
                  sx={{ fontWeight: 500, fontSize: "12px" }}
                  id={props.id}
                >
                  {props.selected}
                </Typography>
              );
            }}
            myColor={mynewColor}
            sx={{
              "&:hover": {
                "&& fieldset": {
                  border: `1px solid ${mynewColor}`,
                },
              },
            }}
          >
            {props.filter.items.map((item, index) => (
              <MenuItem value={index} name={index} key={index}>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                >
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </BasicSelect>
        </FormControl>
        {props.showClearButton && (
          <Box sx={{ ml: "-8px", mr: "1px" }}>
            <IconButton
              size="small"
              onClick={(event) => {
                if(props.selected !== "All")
                {
                props.filter.itemName === "Time"
                  ? props.handleSetFilter(props.filter.itemName, "Last 7 Days")
                  : props.handleSetFilter(props.filter.itemName, "All");

                if (event.target.name === undefined)
                  Events("Cancelled filter selection");
                else Events("clicked " + event.target.name + " filter");
                }
              }}
            >
              <HighlightOffIcon sx={{ fontSize: "19px", mt: "-1px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
