import { formatDateNumeric } from "../Helper/DatePicker/DateFormatters";
import {
  newCity,
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../Static/Data/Dashboard/DashboardFilters";
const inititalColumns = {
  value: [],
};

const inititalHiddenColumns = {
  value: [],
};
const initialIsBatteryIsPartnerIsNullDiagnostics = {
  value: null,
};
const initialBatteryPartnerSelect = {
  value: "Battery ID"
}
const initialBatteryID = {
  value: "",
};
const initialPartner = {
  value: "",
};

const initialVolt = {
  value: "",
};
const initialCity = {
  value: newCity,
};
const initialStatus = {
  value: newStatus,
};

const initialIot = {
  value: newIOT,
};

const initialPack = {
  value: newPack,
};

const initialZone = {
  value: newZone,
};

const initialVersion = {
  value: "",
};

const initialTab = {
  value: 0,
};
const initialDiagnosticsErrorTypeIndex = {
  value: 0,
};
const initialDiagnosticsErrorTypeCode = {
  value: "",
};
const initialTotal = {
  value: 0,
};
const initialStartDate = {
  value:""
  //  formatDateNumeric(
  //   new Date(new Date().setDate(new Date().getDate() - 45))
  // ),
};
const initialEndDate = {
  value: ""
  // formatDateNumeric(new Date()),
};
const initialDiagnosticsErrorTypeList = {
  value: [],
};
const initialDiagnosticsListOfBatteries = {
  value: [],
};
const initialBuildNo = {
  value: [],
};

const initialUserState = {
  arr: [],
};

const initialDiagnosticsColumnSort = {
  arr: [
    {
      sortBy: "errorTime",
    },
    {
      sortBy: "resolutionTime",
    },
  ],
  // orderBy: "asc"
};

const initialPageNumber = {
  value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const initialOpenSelectedBatteries = {
  value: false,
};

const DiagnosticsColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "DiagnosticsColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DiagnosticsHiddenColumns = (state = inititalHiddenColumns, action) => {
  switch (action.type) {
    case "DiagnosticsHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const IsBatteryIsPartnerIsNullDiagnostics = (
  state = initialIsBatteryIsPartnerIsNullDiagnostics,
  action
) => {
  switch (action.type) {
    case "IsBatteryIsPartnerIsNullDiagnostics":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};
const DiagnosticsBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "DiagnosticsBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};
const DiagnosticsBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "DiagnosticsBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const DiagnosticsPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "DiagnosticsPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const DiagnosticsVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "DiagnosticsVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const DiagnosticsCity = (state = initialCity, action) => {
  switch (action.type) {
    case "DiagnosticsCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const DiagnosticsZone = (state = initialZone, action) => {
  switch (action.type) {
    case "DiagnosticsZone":
      return {
        value: action.zone,
      };
    default:
      return state;
  }
};
const DiagnosticsVersion = (state = initialVersion, action) => {
  switch (action.type) {
    case "DiagnosticsVersion":
      return {
        value: action.version,
      };
    default:
      return state;
  }
};
const DiagnosticsBuildNo = (state = initialBuildNo, action) => {
  switch (action.type) {
    case "DiagnosticsBuildNo":
      return {
        // ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};
const DiagnosticsStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "DiagnosticsStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};

const DiagnosticsIOT = (state = initialIot, action) => {
  switch (action.type) {
    case "DiagnosticsIOT":
      return {
        value: action.Iot,
      };
    default:
      return state;
  }
};

const DiagnosticsPack = (state = initialPack, action) => {
  switch (action.type) {
    case "DiagnosticsPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const DiagnosticsTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "DiagnosticsTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const DiagnosticsErrorTypeIndex = (
  state = initialDiagnosticsErrorTypeIndex,
  action
) => {
  switch (action.type) {
    case "DiagnosticsErrorTypeIndex":
      return {
        value: action.index,
      };
    default:
      return state;
  }
};

const DiagnosticsErrorTypeCode = (
  state = initialDiagnosticsErrorTypeCode,
  action
) => {
  switch (action.type) {
    case "DiagnosticsErrorTypeCode":
      return {
        value: action.code,
      };
    default:
      return state;
  }
};

const DiagnosticsTotal = (state = initialTotal, action) => {
  switch (action.type) {
    case "DiagnosticsTotal":
      return {
        value: action.total,
      };
    default:
      return state;
  }
};
const DiagnosticsStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "DiagnosticsStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const DiagnosticsEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "DiagnosticsEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};
const DiagnosticsErrorTypeList = (
  state = initialDiagnosticsErrorTypeList,
  action
) => {
  switch (action.type) {
    case "DiagnosticsErrorTypeList":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};
const DiagnosticsListOfBatteries = (
  state = initialDiagnosticsListOfBatteries,
  action
) => {
  switch (action.type) {
    case "DiagnosticsListOfBatteries":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};

const DiagnosticsStack = (state = initialUserState, action) => {
  switch (action.type) {
    case "DiagnosticsStack":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const DiagnosticsSort = (state = initialDiagnosticsColumnSort, action) => {
  switch (action.type) {
    case "DiagnosticsColumnSort":
      return {
        ...state,
        arr: action.sort,
      };
    default:
      return state;
  }
};

const DiagnosticsPageNumber = (state = initialPageNumber, action) => {
  switch (action.type) {
    case "DiagnosticsPageNumber":
      return {
        value: action.page,
      };
    default:
      return state;
  }
};

const DiagnosticsRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "DiagnosticsRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};

const DiagnosticsOpenAddSelectedBatteries = (state = initialOpenSelectedBatteries, action) => {
  switch (action.type) {
    case "DiagnosticsOpenAddSelectedBatteries":
      return {
        value: action.open,
      };
    default:
      return state;
  }
};

export {
  DiagnosticsColumns,
  DiagnosticsHiddenColumns,
  IsBatteryIsPartnerIsNullDiagnostics,
  DiagnosticsBatteryPartnerSelect,
  DiagnosticsBatteryID,
  DiagnosticsPartner,
  DiagnosticsVolt,
  DiagnosticsCity,
  DiagnosticsZone,
  DiagnosticsIOT,
  DiagnosticsPack,
  DiagnosticsBuildNo,
  DiagnosticsVersion,
  DiagnosticsStatus,
  DiagnosticsTabValue,
  DiagnosticsErrorTypeIndex,
  DiagnosticsErrorTypeCode,
  DiagnosticsTotal,
  DiagnosticsStartDate,
  DiagnosticsEndDate,
  DiagnosticsErrorTypeList,
  DiagnosticsListOfBatteries,
  DiagnosticsStack,
  DiagnosticsSort,
  DiagnosticsPageNumber,
  DiagnosticsRowsPerPage,
  DiagnosticsOpenAddSelectedBatteries
};
