import React, { useEffect, useState } from "react";
import {
  Chart,
  Tooltip,
  Interval,
  Legend,
  Axis,
  registerShape,
  LineAdvance,
  Interaction,
} from "bizcharts";
import { Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CODE from "../../Static/Constants/StatusCodes";
import moment from "moment";
import Loader from "../../Helper/Loaders/Loader";
import { FindNextHighestMultipleOfNum } from "../../Helper/Operations/FindNearestMaxAnalytics";
import NoDataChart from "../NoDataChart";

function generateCustomOutput(min, max, numSteps) {
  const stepSize = (max - min) / (numSteps - 1);
  const result = [min];

  for (let i = 1; i < numSteps - 1; i++) {
    const value = min + i * stepSize;
    result.push(value);
  }

  result.push(max);

  return result;
}



registerShape("interval", "border-radius", {
  draw(cfg, container) {
    const { points } = cfg;
    let path = [];
    path.push(["M", points[0].x, points[0].y]);
    path.push(["L", points[1].x, points[1].y]);
    path.push(["L", points[2].x, points[2].y]);
    path.push(["L", points[3].x, points[3].y]);
    path.push("Z");
    path = this.parsePath(path); // 将 0 - 1 转化为画布坐标

    const group = container.addGroup();
    group.addShape("rect", {
      attrs: {
        x: path[1][1], // 矩形起始点为左上角
        y: path[1][2],
        width: path[2][1] - path[1][1],
        height: path[0][2] - path[1][2],
        fill: cfg.color,
        radius: 0,
      },
    });

    return group;
  },
});

function BizStacked(props) {
  const [chartState, setChartState] = useState({
    data: [],
  });

  const activeStatus = findStatusActive(props.statusClicked);

  

  function findStatusActive(status) {
    if (!status.NumberOfBatteries && !status.BatWithInvalidSignal) {
      return 1000;
    } else if (status.NumberOfBatteries && !status.BatWithInvalidSignal) {
      return props.data.maxTotal;
    } else if (!status.NumberOfBatteries && status.BatWithInvalidSignal) {
      return props.data.maxInvalid;
    } else return props.data.maxTotal;
  }


  const scale = {
    "Total": {
      sync: "value",
      alias: props.total,

      min: 0,
      max: FindNextHighestMultipleOfNum(activeStatus, 1000),
      ticks: generateCustomOutput(
        0,
        FindNextHighestMultipleOfNum(activeStatus, 1000),
        5
      ),
    },
    "Total Part": {
      sync: "value",
      alias: props.partOfTotal,
      min: 0,
    },
    "Line": {
      min: 0,
      max: FindNextHighestMultipleOfNum(props.data.maxPercent, 20),
      ticks: generateCustomOutput(
        0,
        FindNextHighestMultipleOfNum(props.data.maxPercent, 20),
        5
      ),
      alias: props.line,
    },
    Date: {
      tickCount: 150,
    },
  };
  function mountTheChart(data) {
    setChartState({
      data: data,
    });
  }

  useEffect(() => {
    setChartState({ data: [] });
    if (
      props.data !== undefined &&
      (props.data?.responseStatus.code === CODE.SUCCESS ||
        props.data?.responseStatus.code === 1999)
    ) {
      const myData = props.data.data;
      let tempData = [];
      myData.map((item) => {
        tempData.push({
          Date: item.Date,
          ...(props.statusClicked.NumberOfBatteries && {
            "Total": item["Total Devices"],
          }),
          ...(props.statusClicked.BatWithInvalidSignal && {
            "Total Part":
              item["invalid signals"],
          }),
          ...(props.statusClicked.InvalidSignalPercent && {
            "Line": item["Invalid Signal Percentage"],
          }),
        });
      });
      mountTheChart(tempData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.statusClicked]);

  useEffect(
    () => () => {
      setChartState({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {props.data?.responseStatus.code === CODE.SUCCESS ? (
        <div style={{ height: "400px" }}>
          <div
            style={{
              position: "relative",
              height: "calc(100% - 30px)",
              width: "calc(100%)",
              paddingTop: "20px",
            }}
          >
            <Chart height={"97%"} scale={scale} data={chartState.data} autoFit>
              <Interaction type="active-region" />{" "}
              <Interval
                color={props.totalColor}
                shape="border-radius"
                position="Date*Total"
                label={[
                  "Total",
                  (yValue) => {
                    return {
                      content: null,
                      offsetY: 3,
                      style: {
                        fill: "black",
                        fontWeight: 600,
                      },
                    };
                  },
                ]}
              />
              <Interval
                color={props.partOfTotalColor}
                shape="border-radius"
                position="Date*Total Part"
                label={[
                  "Total Part",
                  (yValue) => {
                    return {
                      content: null,
                      offsetY: 25,
                      style: {
                        fill: "white",
                        fontWeight: 600,
                      },
                    };
                  },
                ]}
              />
              <LineAdvance
                shape="straight"
                position="Date*Line"
                color={props.lineColor}
              />

              <Legend position="bottom" />

              <Axis
                name="Date"
                visible={true}
                line={{
                  style: {
                    stroke: "#000",
                    lineWidth: 1.2,
                  },
                }}
                label={{
                  offsetY: 5,
                  style: {
                    fontSize: 12,
                    textAlign: "center",
                    fill: "#000",
                    fontWeight: 400,
                  },
                  formatter: (val) => {
                    if (props.xaxisType === "Weekly" && props.data?.data?.length>5) {
                      let arr = val.split("-");
                      return `${arr[0]} - \n${arr[1]}`;
                    } else {
                      return val;
                    }
                  },
                }}
                tickLine={{
                  style: {
                    lineWidth: 1, // 刻度线宽
                    stroke: "#000", // 刻度线的颜色
                  },
                  length: 6,
                }}
              />
              <Axis
                name="Total"
                position="left"
                line={{
                  style: {
                    stroke: "#000",
                    lineWidth: 1.2,
                  },
                }}
                tickLine={true}
                grid={true}
                label={{
                  style: {
                    fontSize: 12,
                    //   textAlign: 'left',
                    fontFamily: "Roboto",
                    fill: "#000",
                    fontWeight: 400,
                  },
                  autoHide: false,
                  formatter: (val) => {
                    if (val === "1200") {
                      return "";
                    }
                    return val;
                  },
                }}
                title={{
                  style: {
                    fontSize: 14,
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fill: ThemeProperties.black,
                    fontWeight: 500,
                  },
                }}
              />
              <Axis name="Total Part" visible={false} />
              <Axis
                name="Line"
                position="right"
                line={{
                  style: {
                    stroke: "#000",
                    lineWidth: 1.2,
                  },
                }}
                tickLine={true}
                grid={false}
                label={{
                  style: {
                    fontSize: 12,
                    //   textAlign: 'left',
                    fill: "#000",
                    fontWeight: 400,
                  },
                  autoHide: false,
                  formatter: (val) => {
                    if (val === "1200") {
                      return "";
                    }
                    return val;
                  },
                }}
                title={{
                  style: {
                    fontSize: 14,
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fill: ThemeProperties.black,
                    fontWeight: 500,
                  },
                }}
              />
              <Tooltip shared={true}>
                {(title, items) => {
                  let numOfBatteries = items.findIndex(
                    (i) => i.name === props.total
                  );
                  let invalidSignalDevices = items.findIndex(
                    (i) => i.name === props.partOfTotal
                  );
                  let invalidPingsPercent = items.findIndex(
                    (i) => i.name === props.line
                  );

                  return (
                    <div style={{ width: "max-content" }}>
                      <div
                        className="bizcharts-tooltip-title
"
                      >
                        {props.xaxisType === "Daily"
                          ? moment(new Date(title)).format("DD MMM")
                          : title}
                      </div>

                      {numOfBatteries !== -1 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                        >
                          <Brightness1
                            style={{
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              color: items[numOfBatteries].color,
                              marginLeft: "10px",
                            }}
                          />
                          <div className="bizcharts-tooltip-series-group">
                            <b>{"Total Batteries"} : </b>
                            {items[numOfBatteries].value}
                          </div>
                        </div>
                      )}

                      {invalidSignalDevices !== -1 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                        >
                          <Brightness1
                            style={{
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              color: items[invalidSignalDevices].color,
                              marginLeft: "10px",
                            }}
                          />
                          <div className="bizcharts-tooltip-series-group">
                            <b>{items[invalidSignalDevices].name} : </b>
                            {items[invalidSignalDevices].value}
                          </div>
                        </div>
                      )}

                      {invalidPingsPercent !== -1 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                        >
                          <Brightness1
                            style={{
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              color: items[invalidPingsPercent].color,
                              marginLeft: "10px",
                            }}
                          />
                          <div className="bizcharts-tooltip-series-group">
                            <b>{items[invalidPingsPercent].name} : </b>
                            {items[invalidPingsPercent].value}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
              </Tooltip>
            </Chart>
          </div>
        </div>
      ) : (
<div style={{ height: "400px", position: "relative" }}>
          {props.data.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <NoDataChart text={"No data found"} />
          )}
        </div>
      )}
    </div>
  );
}

export default BizStacked;
