import React, { useEffect, useMemo } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import { OuterBox, InnerBox } from "../../../../Components/CustomBox";
import { PaperHighlight } from "../../../../Components/CustomPaper";
import GenericSelectTest from "../../../../Features/Menu/GenericSelectKeyValue";

import {
  SetReportsDQGranularity,
  SetReportsDQStartDate,
  SetReportsDQEndDate,
  SetReportsDQIOT,
  SetReportsDQPack,
} from "../../../../Actions/Reports/DataQuality";
import { SetSessionExpired } from "../../../../Actions";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Events from "../../../../Analytics/Events";
import {
  decryptTheParams,
  encryptTheParams,
  setTheFilterValues,
} from "../../../../Helper/QueryParams/EncryptDecrypt";
import { notification } from "antd";
import { getConnectedBatteries } from "../../../../Api/Api";

import PageHeaderKPI from "../../../../Features/PageHeader/PageHeaderKPI";
import "../../../../Features/Toast/NewNotification.css";
import { MapNonClickablekpis } from "../../../../Static/Data/Map/MapKPIS";
import AntDatePickerReports from "../../../../Helper/DatePicker/AntDatePickerReports";

import { DateDiffInDays } from "../../../../Helper/DatePicker/DateFormatters";
import DataVolume from "./Sections/DataVolume";
import Validity from "./Sections/Validity";
import Timeliness from "./Sections/Timeliness";

const Context = React.createContext({
  name: "Default",
});

function CheckGranularity (diff)
{
  if(diff>0 && diff<=60)
  return "Daily"
  else if(diff>60 && diff<=182)
  return "Weekly"
  else
  return "Monthly"
}

function DataQualityMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [resetButton, setResetButton] = useState(true);

  const iotVendor = useSelector((state) => state.ReportsDQIOT.value);
  const pack = useSelector((state) => state.ReportsDQPack.value);
  const reduxStartDate = useSelector((state) => state.ReportsDQStartDate.value);
  const reduxEndDate = useSelector((state) => state.ReportsDQEndDate.value);
  const granular = useSelector((state) => state.ReportsDQGranularity.value);

  const [connectedBatteries, setConnectedBatteries] = useState(0);

  const filters = {
    IOT: iotVendor,
    Pack: pack,
  };

  function resetTheFilters() {
    Events("Clicked Alert Analytics Reset Filter");
    dispatch(dispatch(SetReportsDQIOT("")));
    dispatch(dispatch(SetReportsDQPack("")));
  }

  function changeTheDate(startDate, endDate) {
    let temp;
    const queryParams = decryptTheParams();

    let diff = DateDiffInDays(startDate, endDate);

    console.log(diff);

    dispatch(SetReportsDQGranularity("Daily"));

    if (startDate === null && endDate === null) {
      dispatch(SetReportsDQStartDate(""));
      dispatch(SetReportsDQEndDate(""));
      temp = {
        ...queryParams,
        startDate: "",
        endDate: "",
        Granularity: "Daily",
      };
    } else if (endDate === null) {
      dispatch(SetReportsDQStartDate(startDate));
      dispatch(SetReportsDQEndDate(startDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: startDate,
        Granularity: "Daily",
      };
    } else if (startDate === null) {
      dispatch(SetReportsDQStartDate(endDate));
      dispatch(SetReportsDQEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: endDate,
        endDate: endDate,
        Granularity: "Daily",
      };
    } else {
      dispatch(SetReportsDQStartDate(startDate));
      dispatch(SetReportsDQEndDate(endDate));

      let tempGran =
      CheckGranularity(diff);
      dispatch(SetReportsDQGranularity(tempGran));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: endDate,
        Granularity: tempGran,
      };
    }

    encryptTheParams(temp, navigate);
  }

  function showResetButton() {
    if (iotVendor !== "" || pack !== "") {
      setResetButton(true);
    } else {
      let temp;
      const queryParams = decryptTheParams();

      setResetButton(false);
      temp = {
        ...queryParams,
        IOT: "",
        Pack: "",
      };
      encryptTheParams(temp, navigate);
    }
  }

  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);

    if (filterName === "IOT") {
      dispatch(SetReportsDQIOT(value));
    } else if (filterName === "Pack") {
      dispatch(SetReportsDQPack(value));
    } else if (filterName === "Granularity") {
      dispatch(SetReportsDQGranularity(value));
    }
  }

  function FilterBox() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {resetButton && (
            <Button
              sx={{ fontSize: "12px", height: "30px", mt: 0, mr: 0.5 }}
              onClick={() => {
                Events("clicked reset button fleet battery health");
                resetTheFilters();
                // openNotification("bottomLeft","All filters are removed. Page might become heavy to function. Consider selecting some relevant filters for smooth experience")
              }}
            >
              Reset
            </Button>
          )}
          {props.pagesContent.filtersWithData.map((item, index) => {
            return (
              <Box key={index}>
                <GenericSelectTest
                  filter={item}
                  handleSetFilter={handleSetFilter}
                  selected={filters[item["itemName"]]} // All,48V,60V
                  maxWidth={"100px"}
                  showClearButton={true}
                  id="overflow-text-1"
                  pageName={"Alert Analytics"}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        // dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    showResetButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotVendor, pack]);

  return (
    <OuterBox>
      <InnerBox>
        <Context.Provider value={contextValue}>
          {contextHolder}
          <Box sx={{ mt: 0.5 }}></Box>
          <PageHeaderKPI
            name={props.pagesContent.pageHeader}
            nonClickableKpis={MapNonClickablekpis}
            connectedBatteries={connectedBatteries}
            showBackButton={true}
          />
          <Box sx={{ mt: 1 }}></Box>

          <div className="stickyDiv">
            <PaperHighlight elevation={0} sx={{ pl: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <GenericSelectTest
                    filter={props.pagesContent.granularityFilter}
                    handleSetFilter={handleSetFilter}
                    selected={granular} // All,48V,60V
                    maxWidth={"80px"}
                    date={[reduxStartDate, reduxEndDate]}
                    showClearButton={false}
                    // id="overflow-text-1"
                    pageName={"DataQuality"}
                  />
                  <AntDatePickerReports
                    changeTheDate={changeTheDate}
                    date={[reduxStartDate, reduxEndDate]}
                    allowClear={false}
                  />
                </Box>
                <Box>{FilterBox()}</Box>
              </Box>
            </PaperHighlight>
          </div>

          {/* <Box sx={{ mt: 2 }}></Box>

          <ThumbNail
            date={[reduxStartDate, reduxEndDate]}
            granular={granular}
            iotVendor={iotVendor}
            pack={pack}
          />

           */}
           <Box sx={{ mt: 2 }}></Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ m: 1 }}>
                <Typography variant="h4">
                  <b>Data Volume</b>
                </Typography>
              </Box>
            </Grid>

            <DataVolume
              date={[reduxStartDate, reduxEndDate]}
              granular={granular}
              iotVendor={iotVendor}
              pack={pack}
            />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ m: 1 }}>
                <Typography variant="h4">
                  <b>Validity</b>
                </Typography>
              </Box>
            </Grid>
            <Validity
              date={[reduxStartDate, reduxEndDate]}
              granular={granular}
              iotVendor={iotVendor}
              pack={pack}
            />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ m: 1 }}>
                <Typography variant="h4">
                  <b>Timeliness</b>
                </Typography>
              </Box>
            </Grid>
            <Timeliness
              date={[reduxStartDate, reduxEndDate]}
              granular={granular}
              iotVendor={iotVendor}
              pack={pack}
            />
          </Grid>
        </Context.Provider>
      </InnerBox>
    </OuterBox>
  );
}

export default DataQualityMain;
