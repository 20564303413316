import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const PaperHighlight = styled(Paper)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: `1.5px solid ${ThemeProperties.grey}`,
  "&:hover": {
    border: `1.5px solid ${ThemeProperties.purple}`,
  },
});

const PaperWithBottomRadius = styled(Paper)({
  backgroundColor: ThemeProperties.white,
  borderRadius: "0px 0px 12px 12px",
  padding:"8px"
});

export { PaperHighlight, PaperWithBottomRadius };
