import React, { useEffect, useMemo } from "react";
import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  OuterBox,
  InnerBox,
  KPIBoxWithoutBorder,
} from "../../Components/CustomBox";

import TabWithColorDiagnostics from "../../Features/Tabs/TabWithColorDiagnostics";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import ListWithSelectionDiagnostics from "../../Features/Lists/ListWithSelectionDiagnostics";

// import DiagnosticsContainer from "../../Features/Table/TableContainer/DiagnosticsContainer";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";

import BasicTableDiagnostics from "../../Features/Table/AntDesign/BasicTableDiagnostics";
import DiagnosticsTabs from "../../Static/Data/Diagnostics/DiagnosticsTabs";
import Events from "../../Analytics/Events";

import { notification } from "antd";

import { useDispatch, useSelector } from "react-redux";

import {
  newCity,
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../../Static/Data/Dashboard/DashboardFilters";

import {
  getErrorTypeList,
  getTableBatteries,
  getCSVOfBatteries,
} from "../../Api/DiagnosticsApi";

import {
  SetDiagnosticsColumns,
  SetDiagnosticsPageNumber,
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsTabValue,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsErrorTypeCode,
  SetDiagnosticsTotal,
  SetDiagnosticsStartDate,
  SetDiagnosticsEndDate,
  SetDiagnosticsStack,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsBatteryPartnerSelect,
  SetDiagnosticsBatteryID,
  SetDiagnosticsPartner,
  SetDiagnosticsOpenAddSelectedBatteries,
} from "../../Actions/Diagnostics";
import { SetSessionExpired } from "../../Actions";

import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";
import { dashboardNonClickablekpis } from "../../Static/Data/Dashboard/DashboardKPIS";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import {
  decryptTheParams,
  encryptTheParams,
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import GenericMultipleSelect from "../../Features/Menu/GenericMultipleSelect";
import { areArraysEqual } from "../../Helper/Operations/ArrayOps";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";
import RemoveSelectedBatteriesWarranty from "../../Features/Dialog/RemoveSelectedBatteriesWarranty";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import SecondsFormatter from "../../Helper/Formatters/SecondsFormatter";
import { getConnectedBatteries } from "../../Api/Api";

const Context = React.createContext({
  name: "Default",
});
// const { useToken } = theme;

function DiagnosticsMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const { token } = useToken();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const universal = useSelector((state) => state.DiagnosticsStack);
  const orderChangedTo = useSelector((state) => state.CustomOrder.orderBy);
  const sortChangedTo = useSelector((state) => state.DiagnosticsSort.arr);

  const [resetButton, setResetButton] = useState(false);

  const tabvalue = useSelector((state) => state.DiagnosticsTabValue.value);
  const errorCode = useSelector(
    (state) => state.DiagnosticsErrorTypeCode.value
  );
  const total = useSelector((state) => state.DiagnosticsTotal.value);
  const indexDiagType = useSelector(
    (state) => state.DiagnosticsErrorTypeIndex.value
  );
  const [connectedBatteries, setConnectedBatteries] = useState(0);
  const [errorTypeList, setErrorTypeList] = useState({
    list: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });

  const batteryPartnerSelect = useSelector(
    (state) => state.DiagnosticsBatteryPartnerSelect.value
  );
  const batteryID = useSelector((state) => state.DiagnosticsBatteryID.value);
  const partner = useSelector((state) => state.DiagnosticsPartner.value);
  const [mounted, setMounted] = useState(false);
  const [mountedCSVLoader, setMountedCSVLoader] = useState(false);

  const city = useSelector((state) => state.DiagnosticsCity.value);
  const zone = useSelector((state) => state.DiagnosticsZone.value);
  const batterystatus = useSelector((state) => state.DiagnosticsStatus.value);
  const iotVendor = useSelector((state) => state.DiagnosticsIOT.value);
  const packVendor = useSelector((state) => state.DiagnosticsPack.value);
  const reduxStartDate = useSelector(
    (state) => state.DiagnosticsStartDate.value
  );
  const reduxEndDate = useSelector((state) => state.DiagnosticsEndDate.value);
  const newColumns = useSelector((state) => state.DiagnosticsColumns.value);

  const filters2 = {
    City: city,
    Zone: zone,
    Status: batterystatus,
    IOT: iotVendor,
    Pack: packVendor,
  };
  const pageNumber = useSelector((state) => state.DiagnosticsPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.DiagnosticsRowsPerPage.value
  );

  const openExportCSVConfirm = useSelector(
    (state) => state.DiagnosticsOpenAddSelectedBatteries.value
  );
  const handleOpenExportCSVConfirm = (event) => {
    dispatch(SetDiagnosticsOpenAddSelectedBatteries(true));
  };
  const handleCloseExportCSVConfirm = () => {
    dispatch(SetDiagnosticsOpenAddSelectedBatteries(false));
  };

  const ChangeTheTab = (newValue) => {
    if (universal.arr.length > 0) {
      dispatch(SetDiagnosticsOpenAddSelectedBatteries(true));
    } else {
      let temp;
      setMounted(false);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
      setErrorTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
      dispatch(SetDiagnosticsTabValue(newValue));
      dispatch(SetDiagnosticsPageNumber(1));

      if (newValue === 0) {
        Events(`clicked ${props.pagesContent.pageHeader} Active`);
      } else if (newValue === 1) {
        Events(`clicked ${props.pagesContent.pageHeader} Resolved`);
      }
      const queryParams = decryptTheParams();
      temp = {
        ...queryParams,
        tabValue: newValue,
      };
      encryptTheParams(temp, navigate);
    }
  };

  function handleSetSelect(name, value) {
    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      batteryPartnerSelect: value,
      batteryID: "",
      partner: "",
    };
    dispatch(SetDiagnosticsBatteryPartnerSelect(value));
    dispatch(SetDiagnosticsBatteryID(""));
    dispatch(SetDiagnosticsPartner(""));
    if (batteryID !== "" || partner !== "") resetTheFilters();

    encryptTheParams(temp, navigate);
  }

  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);
    dispatch(SetDiagnosticsPageNumber(1));

    if (filterName === "City") {
      dispatch(SetDiagnosticsCity(value));
    } else if (filterName === "Zone") {
      dispatch(SetDiagnosticsZone(value));
    } else if (filterName === "Status") {
      dispatch(SetDiagnosticsStatus(value));
    } else if (filterName === "IOT") {
      dispatch(SetDiagnosticsIOT(value));
    } else if (filterName === "Pack") {
      dispatch(SetDiagnosticsPack(value));
    }
  }

  function resetTheFilters() {
    dispatch(SetDiagnosticsCity(newCity));
    dispatch(SetDiagnosticsZone(newZone));
    dispatch(SetDiagnosticsStatus(newStatus));
    dispatch(SetDiagnosticsIOT(newIOT));
    dispatch(SetDiagnosticsPack(newPack));

    dispatch(SetDiagnosticsStartDate(""));
    dispatch(SetDiagnosticsEndDate(""));
    dispatch(SetDiagnosticsPageNumber(1));
    resetTheFilterValues(props.pagesContent.filteredArray, navigate);
  }

  function changeTheDate(startDate, endDate) {
    let temp;
    const queryParams = decryptTheParams();
    dispatch(SetDiagnosticsPageNumber(1));

    if (startDate === null && endDate === null) {
      dispatch(SetDiagnosticsStartDate(""));
      dispatch(SetDiagnosticsEndDate(""));
      temp = {
        ...queryParams,
        startDate: "",
        endDate: "",
      };
    } else if (endDate === null) {
      dispatch(SetDiagnosticsStartDate(startDate));
      dispatch(SetDiagnosticsEndDate(startDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: startDate,
      };
    } else if (startDate === null) {
      dispatch(SetDiagnosticsStartDate(endDate));
      dispatch(SetDiagnosticsEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: endDate,
        endDate: endDate,
      };
    } else {
      dispatch(SetDiagnosticsStartDate(startDate));
      dispatch(SetDiagnosticsEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: endDate,
      };
    }
    encryptTheParams(temp, navigate);
  }

  function getErrorType() {
    let queryParams = decryptTheParams();
    errorTypeList.responseStatus.code !== -1 &&
      setErrorTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
    getErrorTypeList(
      tabvalue,
      city,
      zone,
      batterystatus,
      packVendor,
      iotVendor,

      reduxStartDate,
      reduxEndDate,
      batteryID,
      partner,
      queryParams
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        let tempAll = [],
          totalCount = 0;
        res.response.errorList.map((item) => {
          totalCount = totalCount + item["errorCount"];
        });

        tempAll.push({
          errorCode: "",
          errorCount: totalCount,
          errorDescription: "All",
          orgErrorCode: "",
        });

        tempAll.push(...res.response.errorList);
        setErrorTypeList({
          list: tempAll,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        if (errorCode !== "") {
          console.log("indexDiagType", indexDiagType);
          if (indexDiagType === 0) {
            console.log("yess index is zero", indexDiagType);
            if (tempAll[0]["errorCode"] !== errorCode) {
              dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            } else {
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            }
          } else {
            console.log("no index is not zero", indexDiagType);
            var result = tempAll.find((item) => item.errorCode === errorCode);
            var index = tempAll.findIndex(
              (item) => item.errorCode === errorCode
            );
            if (result === undefined) {
              dispatch(SetDiagnosticsErrorTypeIndex(0));
              dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            } else {
              dispatch(SetDiagnosticsTotal(result["warrantyCount"]));
              dispatch(SetDiagnosticsErrorTypeIndex(index));
            }
          }
        } else {
          dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
          dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
        }
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setErrorTypeList({
          list: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        dispatch(SetDiagnosticsErrorTypeCode(""));
        dispatch(SetDiagnosticsErrorTypeIndex(0));
        dispatch(SetDiagnosticsTotal(0));
      }
    });
  }

  function getListOfBatteries() {
    let queryParams = decryptTheParams();
    listOfBatteries.responseStatus.code !== -1 &&
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
    // if (errorCode === "") {
    //   setListOfBatteries({
    //     batteries: [],
    //     responseStatus: {
    //       code: errorTypeList.responseStatus.code,
    //       msg: "No Data Found",
    //     },
    //   });
    // } else {
    errorTypeList.responseStatus.code !== -1 &&
      getTableBatteries(
        tabvalue,
        city,
        zone,
        batterystatus,
        packVendor,
        iotVendor,

        errorCode,
        reduxStartDate,
        reduxEndDate,
        batteryID,
        partner,

        pageNumber,
        sortChangedTo[tabvalue]["sortBy"],
        orderChangedTo,
        rowsPerPage,
        queryParams
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          setListOfBatteries({
            batteries: res.response.errorList,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setListOfBatteries({
            batteries: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    //}
    setMounted(true);
  }

  function FilterBox() {
    return (
      <>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GenericSelectRedux
                filter={props.pagesContent.searchTypeFilter}
                handleSetFilter={handleSetSelect}
                selected={batteryPartnerSelect}
                maxWidth={"max-content"}
                showClearButton={false}
                id="batteryState"
              />
              <AutocompleteDashboard
                width={300}
                resetTheFilters={resetTheFilters}
                tabValue={tabvalue}
                alertCode={""}
                page={"Diagnostics"}
                openNotification={openNotification}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-end",
                xl: "flex-end",
              },
            }}
          >
            <Box className="filterDateRange">
              <Box sx={{ display: "flex" }}>
                {resetButton && batteryID === "" && (
                  <Button
                    sx={{ fontSize: "12px", height: "30px", mt: 1.5, mr: 0.5 }}
                    onClick={() => resetTheFilters()}
                  >
                    Reset
                  </Button>
                )}

                {props.pagesContent.filtersWithData.map(
                  ({ itemName, items }, index) => {
                    return (
                      <Box key={index}>
                        <GenericMultipleSelect
                          label={itemName}
                          options={items}
                          maxWidth={"100px"}
                          optionValue={filters2[itemName]}
                          handleSetFilter={handleSetFilter}
                          id="overflow-text-3"
                        />
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  function showResetButton() {
    if (
      !areArraysEqual(city, newCity) ||
      !areArraysEqual(batterystatus, newStatus) ||
      !areArraysEqual(zone, newZone) ||
      !areArraysEqual(iotVendor, newIOT) ||
      !areArraysEqual(packVendor, newPack)
    ) {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }

  function CreateCSV() {
    const newBatteries = [];
    let newInsertionTime, newLastPingedTime, newResolvedOn, tab;
    setMountedCSVLoader(true);
    getCSVOfBatteries(universal.arr).then((res) => {
      if (res.responseStatus.code === 200) {
        res.response.errorList.map((row, index) => {
          if ("errorTime" in row)
            newInsertionTime = row.errorTime ? convertUTCtoZone(row.errorTime).replace(/,/g, "") : "";
          if ("lastPingedTime" in row)
            newLastPingedTime = row.lastPingedTime ? convertUTCtoZone(row.lastPingedTime).replace(/,/g, "") : "";
          if ("resolutionTime" in row)
            newResolvedOn = row.resolutionTime ? convertUTCtoZone(row.resolutionTime).replace(/,/g, "") : "";

          if (tabvalue === 0) {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Error Code": row.errorCode,
              Live:
                (new Date().getTime() -
                  new Date(convertUTCtoZone(row.lastPingedTime)).getTime()) /
                  (1000 * 60) <
                5
                  ? "Live"
                  : "Not Live",
              "SOC (%)": row.socBMS,
              "Current (A)": row.currentPack?.toFixed(1),
              "Volt (V)": (row.voltagePack/1000)?.toFixed(1),
              "Temperature (°C)": row.temperature,
              "Alert First Seen": newInsertionTime,
              "Last Pinged": newLastPingedTime,
              "Occupant": row.partner,
              "Lat/Long": row.latitude?.toFixed(6)+"/"+row.longitude?.toFixed(6) ,
              "City": row.location,
              "Zone": row.zone,
              "Status": row.batteryStatus,
              "IOT": row.iotVendor,
              "Pack": row.batteryVendor
            });
          } else if (tabvalue === 1) {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Error Code": row.errorCode,
              Live:
                (new Date().getTime() -
                  new Date(convertUTCtoZone(row.lastPingedTime)).getTime()) /
                  (1000 * 60) <
                5
                  ? "Live"
                  : "Not Live",
              "SOC (%)": row.socBMS,
              "Current (A)": row.currentPack?.toFixed(1),
              "Volt (V)": (row.voltagePack/1000)?.toFixed(1),
              "Temperature (°C)": row.temperature,
              "Resolved On": newResolvedOn,
              "Alert First Seen": newInsertionTime,
              "Resolution Time": SecondsFormatter(row.deltaTime),
              "Last Pinged": newLastPingedTime,
              "Occupant": row.partner,
              "Lat/Long": row.latitude?.toFixed(6)+"/"+row.longitude?.toFixed(6) ,
              "City": row.location,
              "Zone": row.zone,
              "Status": row.batteryStatus,
              "IOT": row.iotVendor,
              "Pack": row.batteryVendor
            });
          }
        });
        if (tabvalue === 0) {
          tab = "Active";
        } else {
          tab = "Resolved";
        }
        DownloadCSV(NameTheCSV("Diagnostics", tab), newBatteries);
        setMountedCSVLoader(false);
        dispatch(SetDiagnosticsStack([]));
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setMountedCSVLoader(false);
        dispatch(SetDiagnosticsStack([]));
      }
    });
  }

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    showResetButton();
    if (
      batterystatus.length > 0 &&
      city.length > 0 &&
      zone.length > 0 &&
      iotVendor.length > 0 &&
      packVendor.length > 0
    )
      getErrorType();
    else {
      setErrorTypeList({
        list: [],
        responseStatus: {
          code: 404,
          msg: "No data found",
        },
      });
    }
  }, [
    tabvalue,
    city,
    zone,
    batterystatus,
    iotVendor,
    packVendor,
    reduxStartDate,
    reduxEndDate,
    batteryID,
    partner,
  ]);

  useEffect(() => {
    setMounted(false);
    const tempColumns = [...newColumns];
    const newOrderChangedTo = orderChangedTo === "desc" ? "descend" : "ascend";
    tempColumns[tabvalue]["columns"].forEach((column, i) => {
      if (
        column.key === sortChangedTo[tabvalue]["sortBy"] &&
        column.sorter === true
      ) {
        column.defaultSortOrder = newOrderChangedTo;
      } else {
        column.defaultSortOrder = "";
      }
    });

    dispatch(SetDiagnosticsColumns(tempColumns));
    // errorCode !=="" cant be done; since all type code has errorCode = ""
    if (
      batterystatus.length > 0 &&
      city.length > 0 &&
      zone.length > 0 &&
      iotVendor.length > 0 &&
      packVendor.length > 0
    )
      getListOfBatteries();
    else {
      setMounted(true);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: 404, msg: "" },
      });
    }
  }, [
    tabvalue,
    errorTypeList,
    errorCode,
    city,
    zone,
    batterystatus,
    iotVendor,
    packVendor,
    reduxStartDate,
    reduxEndDate,
    sortChangedTo[tabvalue]["sortBy"],
    orderChangedTo,
    batteryID,
    partner,
    pageNumber,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (batteryID !== "" || partner !== "") {
      openNotification("bottomLeft", "Cleared all filters if selected");
    }
  }, [batteryID, partner]);

  // useEffect(()=>{
  //   if (universal.arr.length > 0) {
  //     handleOpenExportCSVConfirm();
  //   }
  // },[tabvalue])

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <PageHeaderKPI
            name={"Diagnostics"}
            nonClickableKpis={dashboardNonClickablekpis}
            connectedBatteries={connectedBatteries}
          />

          <Box sx={{ mt: 2, position: "relative" }}>
            <Box sx={{ position: "relative" }}>
              <Box sx={{ position: "absolute", right: -12, zIndex: 1 }}>
                {universal.arr.length > 0 ? (
                  <>
                    {mountedCSVLoader ? (
                      <KPIBoxWithoutBorder // move styles
                        sx={{
                          width: "max-content",
                          pl: 2,
                          pr: 2,
                          opacity: 0.5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size="1rem" />
                        <Typography
                          variant="textSubtitle"
                          sx={{ ml: 1, mr: 1 }}
                        >
                          Loading...
                        </Typography>
                      </KPIBoxWithoutBorder>
                    ) : (
                      <KPIBoxWithoutBorder
                        sx={{
                          width: "max-content",
                          pl: 2,
                          pr: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          Events("Diagnostics download csv");
                          CreateCSV();
                        }}
                      >
                        <Typography variant="textSubtitle">
                          Export csv : <b>{universal.arr.length}</b> batteries
                        </Typography>
                      </KPIBoxWithoutBorder>
                    )}
                  </>
                ) : (
                  <KPIBoxWithoutBorder
                    sx={{ width: "max-content", pl: 2, pr: 2, opacity: 0.5 }}
                  >
                    <Typography variant="textSubtitle">
                      Export csv : <b>{universal.arr.length}</b> batteries
                    </Typography>
                  </KPIBoxWithoutBorder>
                )}
              </Box>

              <Box sx={{ position: "absolute", right: 200, zIndex: 1, top: 5 }}>
                <AntDatePicker
                  changeTheDate={changeTheDate}
                  date={[reduxStartDate, reduxEndDate]}
                  allowClear={true}
                />
              </Box>

              <TabWithColorDiagnostics
                ChangeTheTab={ChangeTheTab}
                tabs={DiagnosticsTabs}
              />

              {DiagnosticsTabs.map((item, index) => (
                <BasicTabPanel value={1} index={index} key={index}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      borderRadius: "0px 12px 12px",
                      p: 1,
                      border: "1px solid #ececec",
                    }}
                  >
                    {FilterBox()}
                    <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                    <Box sx={{ mt: 1.5 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                          <ListWithSelectionDiagnostics data={errorTypeList} />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                          {mounted && (
                            <BasicTableDiagnostics
                              data={listOfBatteries}
                              total={total}
                              columns={newColumns}
                              rowSelection={true}
                              city={city}
                              zone={zone}
                              batterystatus={batterystatus}
                              iot={iotVendor}
                              pack={packVendor}
                              tabvalue={tabvalue}
                              errorCode={errorCode}
                              batteryState={""}
                              date={[reduxStartDate, reduxEndDate]}
                              batteryID={batteryID}
                              partner={partner}
                              existingBatteries={[]}
                              openNotification={openNotification}
                              handleOpenExportCSVConfirm={
                                handleOpenExportCSVConfirm
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </BasicTabPanel>
              ))}
            </Box>
          </Box>
        </InnerBox>
      </OuterBox>
      <RemoveSelectedBatteriesWarranty
        openExportCSVConfirm={openExportCSVConfirm}
        handleCloseExportCSVConfirm={handleCloseExportCSVConfirm}
        CreateCSV={CreateCSV}
        page={"Diagnostics"}
        heading={"Attention! Moving to different Tab/Page"}
      />
    </div>
  );
}

export default React.memo(DiagnosticsMain);

//   const columnHide = useSelector(
//     (state) => state.DiagnosticsHiddenColumns.value
//   );

//   const contentStyle = {
//     backgroundColor: token.colorBgElevated,
//     borderRadius: token.borderRadiusLG,
//     boxShadow: token.boxShadowSecondary,
//   };
//   const menuStyle = {
//     boxShadow: "none",
//   };
//   console.log("hidden columns going into items ", columnHide);
//   const items = [
//     ...newColumns[tabvalue]["columns"].map((d, i) => {
//       if (d.name !== "")
//         if (d.dataIndex === "batteryID" || d.dataIndex === "soc") {
//           return {
//             key: i + 1,
//             label: (
//               <AntCheckbox
//                 // color="primary"
//                 key={i + 1}
//                 checked={true}
//                 disabled
//                 // onChange={() => onFilterCheckboxClick(d.uniqueKey)}
//               >
//                 {d.name}
//               </AntCheckbox>
//             ),
//           };
//         } else {
//           return {
//             key: i + 1,
//             label: (
//               <AntCheckbox
//                 // color="primary"
//                 key={i + 1}
//                 checked={!columnHide.includes(d.uniqueKey)}
//                 onChange={() => onFilterCheckboxClick(d.uniqueKey)}
//               >
//                 {d.name}
//               </AntCheckbox>
//             ),
//           };
//         }
//     }),
//   ];
//   const onClickApply = () => {
//     const tempColumns = [...newColumns];
//     const tempHiddenColumns = [...columnHide];

//     tempColumns[tabvalue]["columns"].forEach((column, i) => {
//       if (column.name !== "") {
//         if (columnHide.includes(column.uniqueKey)) {
//           column.hidden = true;
//           // tempHiddenColumns.push(column.uniqueKey)
//         } else {
//           column.hidden = false;
//         }
//       }
//     });
//     console.log("onClickApply called");
//     console.log("columns are ", tempColumns);
//     dispatch(SetDiagnosticsColumns([...tempColumns]));
//     // dispatch(SetDiagnosticsHiddenColumns([...tempHiddenColumns]));
//     setOpen(false);
//   };

//   const onClickReset = () => {
//     const tempColumns = [...newColumns];
//     const tempHiddenColumns = [...columnHide];

//     tempColumns[tabvalue]["columns"].forEach((column, i) => {
//       if (column.name !== "") {
//         column.hidden = false;
//         const index = tempHiddenColumns.indexOf(column.uniqueKey);
//         if (index > -1) {
//           // only splice array when item is found
//           tempHiddenColumns.splice(index, 1); // 2nd parameter means remove one item only
//         }
//       }
//     });
//     // const arr = newColumns[tabvalue]["columns"];
//     // setColumns([...arr]);
//     console.log("onClickReset called");
//     console.log("columns are ", tempColumns);
//     console.log("hiden columns are ", tempHiddenColumns);
//     dispatch(SetDiagnosticsColumns([...tempColumns]));

//     dispatch(SetDiagnosticsHiddenColumns([...tempHiddenColumns]));
//     setOpen(false);
//   };

//   const handleOpenChange = () => {
//     setOpen(!open);
//   };

//   const onFilterCheckboxClick = (colName) => {
//     if (columnHide.includes(colName)) {
//       // setColumnHide([...columnHide.filter((ele) => ele != colName)]);
//       dispatch(
//         SetDiagnosticsHiddenColumns([
//           ...columnHide.filter((ele) => ele != colName),
//         ])
//       );
//     } else {
//       // setColumnHide([...columnHide, colName]);
//       dispatch(SetDiagnosticsHiddenColumns([...columnHide, colName]));
//     }
//   };

//   const handleOnOpenChange = () => {
//     console.log("handleOnOpenChange called");
//     console.log("column Hide includes ", columnHide);

//     const arr = [];

//     newColumns[tabvalue]["columns"].forEach((col) => {
//       if (col.hidden === true) {
//         arr.push(col.uniqueKey);
//       }
//     });
//     // setColumnHide([...arr])
//     dispatch(SetDiagnosticsHiddenColumns([...arr]));

//     setOpen(!open);
//   };

// function handleRequestPage(pageNumber) {
//   setPageNumber(pageNumber + 1);
// }
{
  /* <ConfigProvider
            theme={{
              token: {
                colorPrimary: ThemeProperties.purple,
              },
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              className="dropDowndDiv"
              // disabled
              open={open}
              onOpenChange={handleOnOpenChange}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div>
                  <div className="dropDown" style={contentStyle}>
                    <div className="dropDownTop">
                      <Typography variant="tableHead">
                        Select columns to show
                      </Typography>
                    </div>

                    <div className="dropDownMiddle">
                      {React.cloneElement(menu, {
                        style: menuStyle,
                      })}
                    </div>

                    <div className="dropDownBottom">
                      <AntButton onClick={() => onClickReset()}>
                        Reset
                      </AntButton>
                      <AntButton type="primary" onClick={() => onClickApply()}>
                        Apply
                      </AntButton>
                    </div>
                  </div>
                </div>
              )}
            >
              <Space>
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #cecece",
                    borderRadius: "4px",
                    width: 35,
                    height: 35,
                    mt: 0.5,
                    ml: 1,
                  }}
                >
                  <ViewWeekOutlinedIcon
                    onClick={handleOpenChange}
                    sx={{
                      cursor: "pointer",
                      fontSize: "25px",
                      color:
                        newColumns[tabvalue]["columns"].filter(
                          (item) => item.hidden === true
                        ).length > 0
                          ? ThemeProperties.purple
                          : ThemeProperties.greyTextColor,
                    }}
                  />
                </Avatar>
              </Space>
            </Dropdown>
          </ConfigProvider> */
}
