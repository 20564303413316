import React, { useEffect, useState } from "react";
import "./datePicker.css";
import { DatePicker } from "antd";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import {formatDateNumeric} from "../DatePicker/DateFormatters";
import Events from "../../Analytics/Events";

const AntDatePicker = (props) => {
  const { RangePicker } = DatePicker;
  // const [status, setStatus] = useState("");

  const [dates, setDates] = useState(null);
  const [value,setValue] = useState(null);

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 45 Days",
      value: [dayjs().add(-45, "d"), dayjs()],
    },
  ];

  const onOpenChange = (open) => {
    if(dates)
    {
        if(dates[0] && !dates[1])
        {
            setDates([dates[0],dates[0]])
        }
        else if(!dates[0] && dates[1])
        {
            setDates([dates[1],dates[1]])
        }
    }

  };

useEffect(() => {
     const start = props.date[0] === undefined || props.date[0] === "" ? null : dayjs(props.date[0],'YYYY-MM-DD');
     const end = props.date[1] === undefined || props.date[1] === "" ? null : dayjs(props.date[1],'YYYY-MM-DD');
     setValue([start,end])
     if(start===null && end ===null)
     {
        setDates(null)
     }
     else
     {
      setDates([start,end])
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date[0],props.date[1]]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4F44E0",
        },
      }}
    >
      {/* <Space direction="vertical" size={12}> */}
        <RangePicker
        
        //   defaultValue={[dayjs('2015-01-01', dateFormat), dayjs('2015-01-01', dateFormat)]}
          format={"DD MMM, YYYY"}
          value={dates || value}
          presets={rangePresets}
          separator={false}
          // status={status}
          inputReadOnly={true}
          allowClear = {props.allowClear}
          onCalendarChange={(val) => {
            Events("changed alerts page date")

            setDates(val);
            if(val)
            {
                if(val[0] && val[1])
                {
                    // console.log("both exists",val[0]["$d"],val[1]["$d"])
                    // console.log(formatDateNumeric(val[0]["$d"]),formatDateNumeric(val[1]["$d"]))
                    props.changeTheDate(formatDateNumeric(val[0]["$d"]),formatDateNumeric(val[1]["$d"]))
                }
                else if(!val[0] && val[1]){
                    // console.log("end exists",val[1]["$d"])
                    props.changeTheDate(formatDateNumeric(val[1]["$d"]),formatDateNumeric(val[1]["$d"]))

                }
                else if(val[0] && !val[1])
                {
                    props.changeTheDate(formatDateNumeric(val[0]["$d"]),formatDateNumeric(val[0]["$d"]))
                }
                else
                {
                    props.changeTheDate("","")
                }
            }
            else
            {
                props.changeTheDate("", "");
                setValue(null)
                setDates(null)
            }
          }}
          // onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
          disabledDate={(current) => {
            // return (
            //   (current && current < dayjs().add(-45, "d")) ||
            //   current > dayjs().add(0, "d")
            // );
            return current && current > dayjs().add(0, "d")
          }}
          // onCalendarChange={(val) => {
          //     console.log(val)
          //   if ( val &&
          //     val[0] < dayjs().add(-2, "month") ||
          //     val && val[1] > dayjs().add(2, "month")
          //   )
          //    {
          //     setStatus("error");
          //   }
          //    else setStatus("");
          // }}
        />
      {/* </Space> */}
    </ConfigProvider>
  );
};

export default AntDatePicker;
