import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid, Box, Typography } from "@mui/material";
import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";

import { useDispatch, useSelector } from "react-redux";
import { SetSessionExpired } from "../../../../../Actions";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";

function Timeliness(props) {
  const dispatch = useDispatch();

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const [latency, setLatency] = useState({
    data: [],
    max:0,
    responseStatus: { code: -1, msg: "" },
  });

  const SetTheLatency = (data) => {
    let tempData = [
      {
        key: "0s",
        value: "0",
        data: 0,
      },
      {
        key: "30s",
        value: "30",
        data: 0,
      },
      {
        key: "1m",
        value: "60",
        data: 0,
      },
      {
        key: "5m",
        value: "300",
        data: 0,
      },
      {
        key: "15m",
        value: "900",
        data: 0,
      },
      {
        key: "30m",
        value: "1800",
        data: 0,
      },
      {
        key: "1h",
        value: "3600",
        data: 0,
      },
      {
        key: "> 1h",
        value: "86400",
        data: 0,
      },
    ];
    if (data.responseStatus.code === 200) {
      tempData.map((item, index1) => {
        let index = data.response.pingList.findIndex(
          (i) => i.frequency === item.value
        );

        if (index !== -1) {
          tempData[index1]["data"] = data.response.pingList.find(
            (i) => i.frequency === item.value
          )["pingPercentage"];
        }
      });
      let temp = [];
      tempData.map((item) => {
        temp.push(item.data);
      });
      setLatency({
        data: temp,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setLatency({
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setLatency({
      data: [],
      max:0,
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetTheLatency = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/ping_frequency`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheLatency(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setLatency({
            ...latency,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setLatency({
            ...latency,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/ping_frequency`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheLatency(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setLatency({
                  ...latency,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };

    GetTheLatency();

    return () => {
      controller.abort();
    };


  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
  ]);
  ScrollToTheElement();

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Latency")}
        >
          <Header
            heading="Latency"
            description="Latency"
            showIButton={true}
            showThreeDots={false}
            id="Latency"
            page="_Reports/DataQuality"
          />
          <div
            className="section"
            id="Latency"
            style={{ height: "calc(100% - 70px)" }}
          >
            {/* <BizStacked /> */}
            <TestApexBar
              data={latency}
              horizontal={false}
              barHeight={"40%"}
              yaxisTitle={"Total pings (%)"}
              xaxisTitle={"Time"}
              tickAmount={10}
              granularity={""}
              // ymax={FindNextHighestMultipleOfNum(
              //   FindNearestMaxAnalytics(Math.max(...props.data.count)),
              //   10000
              // )}
              columnWidth={"70%"}
              stacked={false}
              showDataLabels={true}
              formatter={false}
              categories={["0s", "30s", "1m", "5m", "15m", "30m", "1h", "> 1h"]}
              colors={[ThemeProperties.c_yellow]}
              offsetX={0}
              offsetY={-20}
              series={[
                {
                  name: "Total pings (%)",
                  type: "column",
                  data: latency.data,
                },
              ]}
              customLegend={false}
              width={"100%"}
            />
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default Timeliness;
