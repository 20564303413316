import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box
} from "@mui/material";
import { PaperHighlight } from "../../Components/CustomPaper";
import Header from "../Header/Header";
import { Avatar } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";
import Events from "../../Analytics/Events";

function FindIcon(item) {
  const alerts = [
    {
      alertCode: "sft_temp_chrg",
      alertDescription: "High temperature during charging",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_temp_drv",
      alertDescription: "High temperature inside vehicle",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_temp_idl",
      alertDescription: "High temperature in warehouse",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_volt",
      alertDescription: "Over voltage protection breach",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_cc",
      alertDescription: "Cold charging",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_cur_chrg",
      alertDescription: "Abnormally high current during charging",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_cur_drv",
      alertDescription: "Abnormally high current during driving",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_cur_fluc_chrg",
      alertDescription: "High current fluctuation during charging",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },
    {
      alertCode: "sft_cur_high_soc",
      alertDescription: "High current at full voltage during charging",
      type: "Safety Risk",
      color: "#CF0000",
      textColor: "#fff",
    },

    {
      alertCode: "ops_low_volt_vehicle",
      alertDescription: "Low voltage in vehicle",
      type: "Operational Issue",
      color: "#FFD168",
      textColor: "#000",
    },
    {
      alertCode: "ops_low_volt_warehouse",
      alertDescription: "Low voltage in warehouse",
      type: "Operational Issue",
      color: "#FFD168",
      textColor: "#000",
    },
    {
      alertCode: "ops_ml_dd",
      alertDescription: "Potential deep discharge",
      type: "Operational Issue",
      color: "#FFD168",
      textColor: "#000",
    },
    {
      alertCode: "ops_not_ping",
      alertDescription: "Battery not pinging",
      type: "Operational Issue",
      color: "#FFD168",
      textColor: "#000",
    },
    {
      alertCode: "vln_fc",
      alertDescription: "Field charging",
      type: "Violations",
      color: "#A1E9FF",
      textColor: "#000",
    },
    {
      alertCode: "vln_nc",
      alertDescription: "Night charging",
      type: "Violations",
      color: "#A1E9FF",
      textColor: "#000",
    },
    {
      alertCode: "vln_uc",
      alertDescription: "Unauthorised charging",
      type: "Violations",
      color: "#A1E9FF",
      textColor: "#000",
    },
    {
      alertCode: "vln_ufc",
      alertDescription: "Unauthorised field charging",
      type: "Violations",
      color: "#A1E9FF",
      textColor: "#000",
    },

  ];
  return (
    <Box sx={{ m: 0.8 }}>
      <Avatar
        sx={{
          bgcolor: alerts.find((x) => x.alertCode === item.alertCode)?.color,
          border: `1px solid ${ThemeProperties.grey}`,
          width: { xs: 12, sm: 16, md: 20, lg: 20 },
          height: { xs: 12, sm: 16, md: 20, lg: 20 },
        }}
      >
        {alerts.find((x) => x.alertCode === item.alertCode) === undefined ? (
          <Typography variant={"textDescription"}>-</Typography>
        ) : (
          <Typography
            variant={"textDescription"}
            sx={{
              color: alerts.find((x) => x.alertCode === item.alertCode)
                ?.textColor,
            }}
          >
            {alerts.find((x) => x.alertCode === item.alertCode).type.charAt(0)}
          </Typography>
        )}
      </Avatar>
    </Box>
  );
}
export default function CardWithList(props) {
  const [dense] = React.useState(false);
  // const [alerts, setAlerts] = React.useState([
  //   {
  //     alertCode: "sft_temp_chrg",
  //     alertDescription: "High Temperature during charging",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_temp_drv",
  //     alertDescription: "High Temperature while driving",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_temp_idl",
  //     alertDescription: "High Temperature in warehouse",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_volt",
  //     alertDescription: "Over Voltage Protection Breach",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_cc",
  //     alertDescription: "Cold Charging",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_cur_chrg",
  //     alertDescription: "Abnormally High Current during Charging",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_cur_drv",
  //     alertDescription: "Abnormally high Current during Driving",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_rapid_vt",
  //     alertDescription: "Rapid Voltage and Temperature Rise",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_cur_fluc_chrg",
  //     alertDescription: "High Current Fluctuation during Charging",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },
  //   {
  //     alertCode: "sft_cur_high_soc",
  //     alertDescription: "High Current at full voltage during Charging",
  //     type: "Safety Risk",
  //     color: "#CF0000",
  //     textColor: "#fff",
  //   },

  //   {
  //     alertCode: "ops_low_volt_vehicle",
  //     alertDescription: "Low Voltage in vehicle",
  //     type: "Operational Issue",
  //     color: "#FFD168",
  //     textColor: "#000",
  //   },
  //   {
  //     alertCode: "ops_low_volt_warehouse",
  //     alertDescription: "Low Voltage in warehouse",
  //     type: "Operational Issue",
  //     color: "#FFD168",
  //     textColor: "#000",
  //   },
  //   {
  //     alertCode: "ops_dd",
  //     alertDescription: "Potential Deep Discharge",
  //     type: "Operational Issue",
  //     color: "#FFD168",
  //     textColor: "#000",
  //   },
  //   {
  //     alertCode: "ops_no_utl",
  //     alertDescription: "Underutilized Battery",
  //     type: "Operational Issue",
  //     color: "#FFD168",
  //     textColor: "#000",
  //   },
  //   {
  //     alertCode: "ops_not_ping",
  //     alertDescription: "Battery not pinging",
  //     type: "Operational Issue",
  //     color: "#FFD168",
  //     textColor: "#000",
  //   },
  // ]);
  // React.useEffect(() => {
  //   let newArr = [...alerts];
  //   if (props.data.alerts.length > 0) {
  //     newArr.map((item, index) => {
  //       let tempObj = props.data.alerts.find(
  //         (o) => o.alertCode === item.alertCode
  //       );
  //       item.alertCount = tempObj === undefined ? 0 : tempObj.alertCount;
  //     });
  //     setAlerts(newArr);
  //   }

  // }, [props.data.alerts]);

  return (
    <PaperHighlight
      elevation={0}
      sx={{ height: "100%" }}
      onMouseEnter={() => Events("hovered Current active alerts in fleet")}
    >
      <Header
        heading="Current active alerts in your fleet"
        description="What's Happening in Fleet"
        showIButton={false}
        showThreeDots={false}
        style={{ position: "fixed" }}
      />
      <Box className="section" sx={{ height: "80%", overflow: "auto" }}>
        {props.data.responseStatus.code === 200 ? (
          <List dense={dense}>
            {props.data.alerts.map((item, index) => ( //alerts.map
              <ListItem key={index}>
                <>{FindIcon(item)}</>
                <ListItemText
                  primary={
                    <Typography variant="textSubtitle">
                      {item.alertDescription}
                    </Typography>
                  }
                  sx={{ width: "100%" }}
                />
                <ListItemText
                  primary={
                    <Typography variant="tableRow" sx={{
                      // ml: -1,
                      bgcolor: ThemeProperties.light_yellow,
                      p: 0.5,
                      borderRadius: "10px",
                      fontWeight:400
                    }}>
                      {item.alertCount}
                    </Typography>
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "20%",
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <>{props.data.responseStatus.code === -1 ? <Loader /> : <NoData />}</>
        )}
      </Box>
    </PaperHighlight>
  );
}
