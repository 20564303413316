const Filters = 
{
    "Volt":"Volt",
    "City":"City",
    "Zone":"Zone",
    "Status":"Status",
    "State":"State",
    "Select":"Select",
    "Eq. Cycle":"Eq. Cycle",
    "SOH Level":"SOH Level",
    "Cell":"Cell",
    "Business":"Business",
    "Category":"Category",
    "All":"All"
}
export default Filters