import React, { useEffect } from "react";
import useState from "react-usestateref";
// import "./table.css";
import { Table } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";

import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import moment from "moment";
import { maxDate, formatDateNumeric } from "../../../Helper/DatePicker/DateFormatters";
import NoData from "../../../Helper/Loaders/NoData";
import { convertUTCtoZone, convertUTCtoZoneYYYYMMDD } from "../../../Helper/DatePicker/DateConverter";
import { decryptTheParams, encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";

export default function BasicTableNoPagination(props) {
  const navigate = useNavigate();

  const [columns, setColumns] = useState(props.columns);

  const [list, setList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  }); ////vvvvvvImp
  const loader = false;
  // const [pageNumber, setPageNumber] = useState(1);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total:
        props.data.data !== undefined && props.data.data.length > 0
          ? props.data.data.length
          : 0,
      showTotal: (total) => `Total ${total} items`,
    },
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newColumns = [...columns];
      const item = props.rowSelection
        ? newColumns.splice(fromIndex - 1, 1)[0]
        : newColumns.splice(fromIndex, 1)[0];
      props.rowSelection
        ? newColumns.splice(toIndex - 1, 0, item)
        : newColumns.splice(toIndex, 0, item);

      setColumns(newColumns);
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  function ComputeComparision(finalDate) {
    var dateCheck = moment(finalDate).format("DD/MM/YYYY");
    var dateFrom = moment(new Date(new Date().setDate(new Date().getDate() - 4500))).format("DD/MM/YYYY");
    var dateTo = moment(new Date(maxDate)).format("DD/MM/YYYY");

    var d1 = dateFrom.split("/");
    var d2 = dateTo.split("/");
    var c = dateCheck.split("/");

    var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
    var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
    var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
    if (check >= from && check <= to) return true;
    else return false;
  }

  const handleTableChange = (pagination, filters, sorter, extras) => {
    if (sorter.column) {
    }
    setTableParams({
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        showTotal: (total) => `Total ${total} items`,
      },
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };

  useEffect(() => {
    if (props.data.responseStatus.code === 200) {
      let newData = props.data.data.map((row) => ({
        ...row,
        tableData: {
          checked: false,
        },
      }));
      setList({
        data: newData,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
          total: props.data.data.length,
          showTotal: (total) => `Total ${total} items`,
        },
      });
    } else {
      setList({
        data: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(
    () => () => {
      setList({ data: [], responseStatus: { code: -1, msg: "" } });

      if (props.data.responseStatus.code === 200) {
        let newData = props.data.data.map((row) => ({
          ...row,
          tableData: {
            checked: false,
          },
        }));
        setList({
          data: newData,
          responseStatus: {
            code: props.data.responseStatus.code,
            msg: props.data.responseStatus.msg,
          },
        });

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
            total: props.data.data.length,
            showTotal: (total) => `Total ${total} items`,
          },
        });
      } else {
        setList({
          data: [],
          responseStatus: {
            code: props.data.responseStatus.code,
            msg: props.data.responseStatus.msg,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dateString[0], props.dateString[1]]
  );

  useEffect(
    () => () => {
      setList({ data: [], responseStatus: { code: -1, msg: "" } });
    },
    []
  );

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          {list.responseStatus.code === 200 ? (
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (props.id === "CanBeClicked") {
                      var newDate = convertUTCtoZoneYYYYMMDD(record[props.onClickColumn])
                      // var oldDate = moment(record[props.onClickColumn]).format(
                      //   "DD MMM YYYY"
                      // );
                      if (ComputeComparision(newDate)) {
                        props.changeTheDate(
                          formatDateNumeric(new Date(newDate)),
                          formatDateNumeric(new Date(newDate)) 
                        );
                        props.openNotification("bottomLeft",`Date changed to ${moment(new Date(newDate)).format("DD MMM YYYY")}`);
                        props.ChangeTheTab("Battery Vitals");
                        let temp;
                        let queryParams = decryptTheParams();
                        temp={
                          ...queryParams,
                          batteryPackMetrics : moment(new Date(newDate)).format("YYYY-MM-DD"),
                          startDateSB : moment(new Date(newDate)).format("YYYY-MM-DD"),
                          endDateSB : moment(new Date(newDate)).format("YYYY-MM-DD"),
                          alertFirstSeenOn: convertUTCtoZone(record.insertionTime),
                          // alertFirstSeen : new Date(newDate),
                          // alertType: record.alertDescription,
                          // alertStatus: record.alertStatus,
                        }
                        encryptTheParams(temp,navigate,true)
                        
                      } else {
                        props.openNotification("bottomLeft",`Date is falling out of range`);
                      }
                    }
                  }, // click row
                };
              }}
              className="SpeificBatteryPageTable"
              columns={columns}
              rowSelection={false}
              rowClassName="rowClass"
              dataSource={list.data}
              pagination={tableParams.pagination}
              loading={loader}
              onChange={handleTableChange}
              style={{ height: "360px" }}
              scroll={{ y: 300 }}
              bordered
              size="middle"
            />
          ) : (
            <Table
              className="SpeificBatteryPageTable"
              locale={{
                emptyText: (
                  <div style={{ minHeight: "300px", height: "300px" }}>
                    {list.responseStatus.code === 404 ||
                    list.responseStatus.code === 1999 ||
                    list.responseStatus.code === -2 ? (
                      <div style={{ height: "100%" }}>
                        <NoData text={props.noDataMsg} />
                      </div>
                    ) : (
                      <p style={{ height: "100%", color: "white" }}>
                        
                      </p>
                    )}
                  </div>
                ),
              }}
              columns={columns}
              rowSelection={false}
              rowClassName="rowClass"
              dataSource={[]}
              pagination={false}
              loading={
                list.responseStatus.code === 404 ||
                list.responseStatus.code === 1999 ||
                list.responseStatus.code === -2
                  ? false
                  : true
              }
              style={{ height: "360px" }}
              scroll={{ y: 300 }}
              bordered
              size="middle"
            />
          )}
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
