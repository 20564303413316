import React, { useEffect, useState } from "react";
// import useState from "react-usestateref";
// import "./table.css";
import { Table, theme, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDiagnosticsStack,
  SetDiagnosticsColumnSort,
  SetDiagnosticsPageNumber,
  SetDiagnosticsRowsPerPage,
  SetDiagnosticsColumns,
} from "../../../Actions/Diagnostics";
import { SetSessionExpired } from "../../../Actions";
import { SetTableOrder } from "../../../Actions";
import { getSelectAllBatteries } from "../../../Api/DiagnosticsApi";
import { useNavigate, useLocation } from "react-router-dom";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import { handleScroll } from "./TableFunctions";
import { decryptTheParams, encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";

const { useToken } = theme;

export default function BasicTableDiagnostics(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // ############  Search  ###########
  //   const preColumns = props.columns.map((column) => {
  //     return { ...column };
  //   });
  const [columns, setColumns] = useState(
    props.columns[props.tabvalue]["columns"]
  );

  const queryParams = decryptTheParams();

  const [list, setList] = useState({
    batteries: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const universal = useSelector((state) => state.DiagnosticsStack.arr);
  // const existingBatteries = useSelector(
  //   (state) => state.DashboardExistingBatteries.arr
  // );
  const pageNumber = useSelector((state) => state.DiagnosticsPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.DiagnosticsRowsPerPage.value
  );
  const sortChangedTo = useSelector((state) => state.DiagnosticsSort.arr);


  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = props.rowSelection ? fromIndex > 1 && toIndex > 1 : fromIndex > 0 && toIndex > 0;
      if (dragIndex) {
        const newColumns = [...columns];
        const item = props.rowSelection
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        props.rowSelection
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);

        const finalColumns = [...props.columns];
        finalColumns[props.tabvalue]["columns"] = newColumns;

        dispatch(SetDiagnosticsColumns(finalColumns));
    }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const rowSelection = {
    selectedRowKeys: universal,

    onSelectAll: function (selected, selectedRows, changeRows) {
      clickedSelectAllCheckbox(selected);
    },

    onSelect: function (selected, selectedRows, changeRows) {
      if (selectedRows) {
        let uniqueChars = [...universal, selected.itemID];
        dispatch(SetDiagnosticsStack(uniqueChars));
      } else {
        let uniqueChars = universal.filter((key) => key != selected.itemID);
        dispatch(SetDiagnosticsStack(uniqueChars));
      }
    },
    renderCell: (a, b, c, d) =>
      d.props.disabled ? (
        <IndeterminateCheckBoxIcon
          sx={{ fontSize: "20px", color: "#d8d8d8", cursor: "no-drop" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ) : (
        d
      ),
    getCheckboxProps: (record) => ({
      // disabled: existingBatteries.includes(record.itemID),
      // Column configuration not to be checked
    }),
  };

  function hideColumns(columns) {
    return columns.filter((item) => !item.hidden);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      const tempSort = [...sortChangedTo];
      tempSort[props.tabvalue]["sortBy"] = sorter.columnKey;
      
      dispatch(SetDiagnosticsColumnSort(tempSort));
      dispatch(SetTableOrder(order));
    }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(SetDiagnosticsPageNumber(page));
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    dispatch(SetDiagnosticsPageNumber(1));

    dispatch(SetDiagnosticsRowsPerPage(pageSize));
  };

  function clickedSelectAllCheckbox(selectAll) {
    let queryParams = decryptTheParams();
    let newtempIds = [];
    getSelectAllBatteries(
      props.tabvalue,
      props.city,
      props.zone,
      props.batterystatus,
      props.pack,
      props.iot,

      props.errorCode,
      props.date[0],
      props.date[1],
      props.batteryID,
      props.partner,
      queryParams
    ).then((res) => {
      if(res.responseStatus.code === 200)
      {
      newtempIds = selectAll ? res.response.errorList : [];

      let tempSelected = res.response.errorList;

      let finalArr = tempSelected.filter((n) => !newtempIds.includes(n));

      let final2 = universal.filter((n) => !finalArr.includes(n));

      let final3 = final2.concat(newtempIds);
      let final4 = final3.filter((n) => !props.existingBatteries.includes(n));

      let uniqueChars = [...new Set(final4)];
      dispatch(SetDiagnosticsStack(uniqueChars));
      }
      else
      {
        if(res.responseStatus.code === -2)
        dispatch(SetSessionExpired(true));
        dispatch(SetDiagnosticsStack([]));
      }
    });
  }


  useEffect(() => {

    if (props.data && props.data.batteries.length > 0) {
      // props.data.responseStatus.code !== 404/404
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        batteries: newData,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        batteries: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (
                    event.target.classList[1] ===
                      "ant-table-selection-column" ||
                    event.target.classList[0] === "ant-checkbox-inner" ||
                    event.target.classList[2] === "ant-checkbox-checked" ||
                    event.target.classList[0] === "ant-checkbox-wrapper" ||
                    event.target.classList[0] === "ant-checkbox"
                  ) {
                  } else
                  {
                    if(universal.length>0)
                    {
                      event.preventDefault();
                      props.handleOpenExportCSVConfirm();
                    }
                    else
                    {
                    let temp;
                      temp = {
                        ...queryParams,
                        batteryID: record.batteryID,
                        batterySearchedGlobal: "false",
                        tabValue: 0,
                        tabName: "Alerts",
                        prevLink: location.pathname + location.search,
                      };
                      encryptTheParams(temp, navigate, true);
                    }
                  }
                }, // click row
              };
            }}
            className={
              props.rowSelection
                ? "LandingPageTable Selection"
                : "LandingPageTable NoSelection"
            }
            locale={{
              emptyText: (
                <div style={{ minHeight: "500px", height: "500px" }}>
                  {list.responseStatus.code === 404 ? (
                    <div style={{ height: "100%" }}>
                        <NoData text={"No alerts found"} />
                    </div>
                  ) : (
                    <p style={{ height: "100%", color: "white" }}></p>
                  )}
                </div>
              ),
            }}
            columns={hideColumns(columns)}
            rowSelection={props.rowSelection && rowSelection}
            rowKey={(record) => record.itemID}
            rowClassName="rowClass"
            dataSource={
              list.responseStatus.code === CODE.LOADING
                ? []
                : list.responseStatus.code === 404
                ? []
                : list.batteries
            }
            pagination={false}
            loading={
              list.responseStatus.code === CODE.LOADING
                ? true
                : list.responseStatus.code === CODE.NODATA
                ? false
                : false
            }
            onChange={handleTableChange}
            style={{ height: "575px" }}
            scroll={handleScroll([...columns])}
            bordered
            size="middle"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={rowsPerPage}
              pageSizeOptions={[10, 20, 30, 100]}
              current={pageNumber}
              onChange={handlePageChange}
              total={props.total}
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </ReactDragListView.DragColumn>
      </ConfigProvider>
  );
}
