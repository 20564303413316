import React from "react";
import { Typography, Box } from "@mui/material";
import NoData from "../../Assets/Images/Common/NotFound.svg";

function Error(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <div >
        <Typography style={{ color: "#909090", fontSize: "16px",marginLeft:"20px" }}>
          {props.text}
        </Typography>
        <Box sx={{mt:1}}>
          <img src={NoData} alt="not found" height={150}></img>
        </Box>
        </div>
      </div>
    </div>
  );
}

export default Error;
