import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import SlickCarouselLanding from "../Carousel/SlickCarouselLanding";


function CardWithNavigation(props) {
  
  const NavigationCard2 = [];

  function findTo(to, queryParam) {
    if (queryParam && Object.keys(queryParam).length !== 0) {
      const queryParamNew = Object.entries(queryParam);
      const path = to + "/" + queryParamNew[0][0] + "=" + queryParamNew[0][1];
      return path;
    } else {
      const path = to;
      return path;
    }
  }

  props.data.forEach((item) => {
    let newTabs = [];
    item["tabs"].map((tab) =>
      newTabs.push({
        src: tab["src"],
        text: tab["text"],
        to: findTo(tab["to"], tab["queryParam"]),
        mykey: tab["mykey"],
        isActive: tab["isActive"],
      })
    );

    NavigationCard2.push({
      title: item["title"],
      tabs: newTabs,
    });
  });

  return (
    <Grid container spacing={3}>
      {NavigationCard2.map((d, index) => (
        <Grid item xs={4} key={index}>
          <Box
            sx={{
              borderRadius: `${ThemeProperties.borderRadius}`,
              border:"1.5px solid #ececec",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: `${ThemeProperties.bluePurpleGradient}`,
                padding: "2rem 0rem",
                borderRadius: `${ThemeProperties.borderRadius} ${ThemeProperties.borderRadius} 0 0`,
                width: "100%",
              }}
            >
              <Typography
                variant="paperHeaderWhite"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {d.title}
              </Typography>
            </Box>
            <Box
              sx={{
                flexWrap: "wrap",
                padding: "1rem 0rem",
                width: "100%",
                backgroundColor: `${ThemeProperties.white}`,
                borderRadius: `0 0 ${ThemeProperties.borderRadius} ${ThemeProperties.borderRadius}`,
              }}
            >
              <SlickCarouselLanding tabs={d.tabs}/>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
export default CardWithNavigation;
