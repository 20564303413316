import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Tooltip } from "antd";
import Events from "../../../Analytics/Events";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";
import FindTheSeverity from "../../../Helper/Operations/FindTheSeverity";

function renderBatteryIDCopy(item,name) {
  return (
    <>
    {item && <>
      <Typography variant="tableRow">{item}</Typography>
      <Tooltip title={"Click to copy"} zIndex={30000}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            copy(item);
            Events(`Copied ${name}`);
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: "13px",
              cursor: "pointer",
              "&:hover": {
                color: ThemeProperties.purple,
              },
            }}
          />
        </IconButton>
      </Tooltip>
      </>}
    </>
  );
}

const newColumns = [

  {
    title: (
      <Typography
        variant="tableHeadBold"
      >
        Device ID
      </Typography>
    ),
    name: "Device ID",
    dataIndex: "batteryID",
    key: "batteryID",
    fixed: "left",
    sorter: false,
    filterSearch: false,
    width: 170,
    className: "undragableColumn",
    // maxWidth:"18px",
    render: (batteryID) => (
      <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
    ),
  },
  {
    title: (
      <Typography
        variant="tableHeadBold"
      >
        Battery ID
      </Typography>
    ),
    name: "Battery ID",
    dataIndex: "assetID",
    key: "assetID",
    sorter: false,
    showSorterTooltip: false,
    checkedOptions: [],
    width: 120,
    render: (assetID) => (
      <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
    ),
  },
  {
    title: (
      <Typography variant="tableHeadBold">Operating State</Typography>
    ),
    name: "Operating State",
    dataIndex: "batteryFlag",
    key: "batteryFlag",
    sorter: false,
    showSorterTooltip: false,
    checkedOptions: [],
    width: 140,
    uniqueKey: "batteryFlag",
    render: (batteryFlag) => (
      <div className="renderID">
        <Typography variant="tableRow">{batteryFlag === 0 ?"Operational" : batteryFlag === 1 ?  "Out of Service" : batteryFlag === 2 ? "Under Maintenance": "Isolated" }</Typography>
      </div>
    ),
  },
  {
    // title: "SOC (%)",
    title: (titleProps) => getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
    name: "SOC (%)",
    dataIndex: "socBMS",
    key: "socBMS",

    align: "center",
    width: 110,
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "",
    showSorterTooltip: false,

    filterSearch: false,
    uniqueKey: "socActive",
    render: (socBMS) => <Typography variant="tableRow">{socBMS}</Typography>,
  },
  {
    // title: "Severity",
    title: (
      <Typography variant="tableHeadBold">
        Severity
      </Typography>
    ),
    dataIndex: "severity",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 130,
    ellipsis: {
      showTitle: false,
    },
    render: (severity) => (
      <Typography variant="tableRow">{FindTheSeverity(severity)}</Typography>
    ),
  },
  {
    // title: "Alert Type",
    title: (
      <Typography variant="tableHeadBold">
        Alert Type
      </Typography>
    ),
    dataIndex: "alertType",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 170,
    ellipsis: {
      showTitle: false,
    },
    render: (alertType) => (
      <Typography variant="tableRow">{alertType}</Typography>
    ),
  },
  {
    // title: "Alert Type",
    title: (
      <Typography variant="tableHeadBold">
        Alert Status
      </Typography>
    ),
    dataIndex: "alertStatus",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (alertStatus) => (
      <Typography variant="tableRow">{alertStatus}</Typography>
    ),
  },
  {
    // title: "Alert Type",
    title: (
      <Typography variant="tableHeadBold">
        Alert Description
      </Typography>
    ),
    dataIndex: "alertDescription",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 200,
    ellipsis: {
      showTitle: false,
    },
    render: (alertDescription) => (
      <Tooltip placement="top" title={alertDescription} zIndex={30000}>
      <Typography variant="tableRow">{alertDescription}</Typography>
      </Tooltip>
    ),
  },
  {
    // title: "Alert First Seen",
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "alertTime", "Alert First Seen"),
    name: "Alert First Seen",
    dataIndex: "alertTime",
    key: "alertTime",

    sorter: (a, b) => new Date(a.alertTime) - new Date(b.alertTime),
    defaultSortOrder: "descend",
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "insertionTimeActive",
    width: 170,
    render: (alertTime) => (
      <Typography variant="tableRow">{convertUTCtoZone(alertTime)}</Typography>
    ),
  },
  {
    // title: "Last Pinged",
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),

    dataIndex: "lastPingedTime",
    key: "lastPingedTime",
    sorter: (a, b) => new Date(a.lastPingedTime) - new Date(b.lastPingedTime),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: true,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "lastPingedTimeActive",
    width: 170,
    render: (lastPingedTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(lastPingedTime)}
      </Typography>
    ),
  },
  {
    // title: "Partner",
    title: (
      <Typography variant="tableHeadBold">
        Occupant
      </Typography>
    ),
    dataIndex: "partner",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 170,
    ellipsis: {
      showTitle: false,
    },
    render: (partner) => (
      <Tooltip placement="top" title={partner}>
        <Typography variant="tableRow">{partner}</Typography>
      </Tooltip>
    ),
  },
  {
    // title: "Lat/Long",
    title: (
      <Typography variant="tableHeadBold">
        Lat/Long
      </Typography>
    ),
    dataIndex: "latitude",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "latitudeActive",
    width: 170,
    render: (text, record) => (
      <Typography variant="tableRow">
        {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
      </Typography>
    ),
  },
];

export { newColumns };
