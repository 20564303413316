import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid } from "@mui/material";

import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetRefreshAPICalled, SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import moment from "moment";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";
import { FindNearestMaxAnalytics, FindNextHighestMultipleOfNum } from "../../../../../Helper/Operations/FindNearestMaxAnalytics";

function ThumbNail(props) {
  const dispatch = useDispatch();

  const [devicesDistribution, setDevicesDistribution] = useState({
    count: [],
    categories: [],
    responseStatus: { code: -1, msg: "" },
  });

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const SetTheDevicesDistribution = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempAlertList = [],
          tempCategories = [],
          tempCount = [];
        data.response.alertList.map((item) => {
          tempAlertList.push({
            deviceCount: item.deviceCount,
            alertCount: item.alertCount,
            severity: item.severity,
            startDate: item.range.startDate,
            endDate: item.range.endDate,
          });
        });

        const gy = groupByKey(tempAlertList, "startDate");
        console.log(gy);

        let newData = [];
        gy.map((item) => {
          let tempCount = 0;
          item["values"].map((item2) => {
            tempCount += item2["deviceCount"];
          });
          newData.push({
            key: item["key"],
            values: item["values"],
            count: tempCount,
          });
        });

      newData.map((item) => {
        // console.log(item["values"][0])
        if (props.granular === "Weekly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format(
              "DD MMM"
            ) +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
        } else if (props.granular === "Monthly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("MMM")
          );
        } else
          tempCategories.push(
            moment(new Date(item["key"] + " GMT")).format("DD MMM")
          );
        tempCount.push(item["count"]);
      });

      setDevicesDistribution({
        count: tempCount,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setDevicesDistribution({
        count: [],
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };


  useEffect(() => {
    setDevicesDistribution({
      count: [],
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetTheDevicesDistribution = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        alertType:"",
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/severity_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheDevicesDistribution(data);
      } catch (error) {
        console.log("yess error",error.response)
        if (error.response?.status === CODE.URL_NODATA) {
          setDevicesDistribution({
            ...devicesDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setDevicesDistribution({
            ...devicesDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          dispatch(SetRefreshAPICalled(false));
          console.log("yes refresh api is called")
          try {
            let refresh = await axios.get("/api/refresh");
            if (refresh && refresh.status === CODE.SUCCESS) {
              dispatch(SetRefreshAPICalled(true));
              try {
                let res = await axios.get(`/api/severity_alerts`, {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                });
                const data = callTheAPI(res, true);
                SetTheDevicesDistribution(data);
              } catch (error) {
                if (error.response?.status === CODE.URL_NODATA) {
                  setDevicesDistribution({
                    ...devicesDistribution,
                    responseStatus: {
                      code: CODE.NODATA,
                      msg: "No Data",
                    },
                  });
                } else if (error.response?.status === CODE.UNAUTHORIZED) {
                  return null;
                }
              }
            }
          } catch (error) {
            // Logout();
            SetSessionExpired();
          }
        }
      }
    };

    GetTheDevicesDistribution();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack,
    isRefreshAPICalled
  ]);
  ScrollToTheElement();

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Non pinging devices")}
        >
          <Header
            heading="Total batteries which generated alerts"
            description="Non pinging devices"
            showIButton={true}
            showThreeDots={false}
            id="Non_pinging_devices"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Non_pinging_devices"
            style={{ height: "calc(100% - 70px)" }}
          >
            <TestApexBar
              data={devicesDistribution}
              horizontal={false}
              barHeight={"90%"}
              xaxisTitle={undefined}
              yaxisTitle={"Number of batteries"}
              tickAmount={8}
              granularity={props.granular}
              ymax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                Math.max(...devicesDistribution.count)
              ),1000)}
              columnWidth={devicesDistribution.categories.length>=1 && devicesDistribution.categories.length<9 ? 10 * devicesDistribution.categories.length + "%" : "80%"}
              stacked={false}
              showDataLabels = {false}
              categories={devicesDistribution.categories}
              series={[
                {
                  name: "Number of batteries",
                  data: devicesDistribution.count,
                },
              ]}
              offsetX={20}
              offsetY={0}
              customLegend={false}
              colors={[ThemeProperties.purple, ThemeProperties.Purple4]}
              width={"100%"}
            />
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default ThumbNail;
