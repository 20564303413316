import { encryptQueryParams, decryptQueryParams } from "query-string-hash";

const decryptTheParams = () => {
  let query = window.location.search.slice(1);
  const queryParams = decryptQueryParams(query);
  return queryParams;
};

const encryptTheParams = (temp, navigate, specificBattery) => {
  const que = new URLSearchParams(temp).toString();
  const hash = encryptQueryParams(que);
  if(specificBattery)
  {
    navigate(`/specificBattery?${hash}`)
  }
  else
  {
    navigate(`${window.location.pathname}?${hash}`);
  }
};

const setTheFilterValues = (filterName, value, navigate) => {
  let temp;
  const queryParams = decryptTheParams();

  if (value !== "") {
    temp = {
      ...queryParams,
      [filterName]: value,
    };
  } else {
    temp = Object.fromEntries(
      Object.entries(queryParams).filter(([key, val]) => key !== filterName)
    );
  }
  delete temp["id"]
  if (Object.keys(temp).length === 0) {
    navigate(`${window.location.pathname}`);
  } else {
    encryptTheParams(temp, navigate);
  }
};

const resetTheFilterValues = (arr, navigate) => {
  const queryParams = decryptTheParams();
  arr.forEach((filterName) => {
    for (let key in queryParams) {
      if (key === filterName || key === "endDate" || key === "startDate") {
        delete queryParams[key];
      }
    }
  });
  encryptTheParams(queryParams, navigate);
};
export {
  decryptTheParams,
  encryptTheParams,
  setTheFilterValues,
  resetTheFilterValues,
};
