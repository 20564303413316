import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import helloEmojiIcon from "../../Assets/Icons/Landing/helloemoji.svg";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";

function WelcomeContainer(props) {
  const cookies = new Cookies();

  const name = 
  cookies.get("name") === undefined
  ? removeAccessToken()
  : cookies.get("name");

  const firstName = name ? name.split(" ")[0] : "";

  return (
    <Box>
      <Typography component="h5" variant="textSubtitleBold" align="center">
        Hello {firstName} <img src={helloEmojiIcon} alt="Hello" />
      </Typography>

      <Typography component="h5" variant="textSubtitle" align="center" sx={{mt:1.5}}>
        How can we help you today?
      </Typography>
    </Box>
  )
}

export default WelcomeContainer;
