import React, { useEffect, useState, useRef } from "react";
import DiagnosticsMain from "./DiagnosticsMain";
import Filters from "../../Static/Data/_Common/Filters";
import { getPagesComponents } from "../../Api/Api";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

import {
  socFilter,
  socFilter1,
  partner,
  partner1
} from "../../Static/Data/_Common/TempFilters";
import copy from "copy-to-clipboard";
import { Box, Typography, IconButton } from "@mui/material";
import { Button, Input, Space, Checkbox } from "antd";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsBatteryID,
  SetDiagnosticsBatteryPartnerSelect,
  SetDiagnosticsColumns,
  SetDiagnosticsEndDate,
  SetDiagnosticsHiddenColumns,
  SetDiagnosticsStartDate,
  SetDiagnosticsTabValue,
  SetIsBatteryIsPartnerIsNullDiagnostics,
  SetDiagnosticsPartner,
} from "../../Actions/Diagnostics";

import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import Events from "../../Analytics/Events";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import SecondsFormatter from "../../Helper/Formatters/SecondsFormatter";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";


function getActualArrayValues(data)
{
  let arr =
  data.length === 0 ? [] : data.split(",");
  return arr
}

function Diagnostics(props) {
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });
  const searchInput = useRef(null);
  const tempSocFilter = [...socFilter],
    tempPartnerFilter = [...partner],
    tempSocFilter1 = [...socFilter1],
    tempPartnerFilter1 = [...partner1];
    
  tempSocFilter.map((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter.map((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempSocFilter1.map((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter1.map((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });

  function renderBatteryIDCopy(batteryID) {
    return (
      <>
        <Typography variant="tableRow">{batteryID}</Typography>
        <Tooltip title={"Click to copy"}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              copy(batteryID);
              Events("Copied battery id");
            }}
          >
            <ContentCopyIcon
              sx={{
                fontSize: "13px",
                cursor: "pointer",
                "&:hover": {
                  color: ThemeProperties.purple,
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  useEffect(() => {
    setTimeout(() => {
    //   getPagesComponents("Diagnostics").then((res) => {
    //     if (res.responseStatus.code === 200) {
          const filters = [
            {
              itemName: "Status",
              items: [
                {
                  value: "Idle",
                  id: "0",
                },
                {
                  value: "Charging",
                  id: "4",
                },
                {
                  value: "Discharging",
                  id: "2",
                },
              ],
            },
            {
              itemName: "City",
              items: [
                {
                  value: "Delhi",
                  id: "delhi",
                },
                {
                  value: "Faridabad",
                  id: "faridabad",
                },
                {
                  value: "Ghaziabad",
                  id: "ghaziabad",
                },
                {
                  value: "Gurgaon",
                  id: "gurgaon",
                },
                {
                  value: "Jaipur",
                  id: "jaipur",
                },
                {
                  value: "Kanpur",
                  id: "Kanpur",
                },
                {
                  value: "Karnal",
                  id: "Karnal",
                },
                {
                  value: "Lucknow",
                  id: "Lucknow",
                },
                {
                  value: "Meerut",
                  id: "Meerut",
                },
                {
                  value: "Noida",
                  id: "Noida",
                },
                {
                  value: "Panipat",
                  id: "Panipat",
                },
                {
                  value: "Sonipat",
                  id: "Sonipat",
                },
              ],
            },
            {
              itemName: "Zone",
              items: [
                {
                  value: "Mayur Vihar",
                  id: "Mayur Vihar",
                },
                {
                  value: "Noida",
                  id: "Noida",
                },
                {
                  value: "South Delhi",
                  id: "South Delhi",
                },
                {
                  value: "Jankapuri Kirtinagar",
                  id: "Jankapuri Kirtinagar",
                },
                {
                  value: "Rohini",
                  id: "Rohini",
                },
                {
                  value: "Jahagirpuri",
                  id: "Jahagirpuri",
                },
                {
                  value: "Bawana",
                  id: "Bawana",
                },
                {
                  value: "Central Delhi",
                  id: "Central Delhi",
                },
                {
                  value: "Faridabad",
                  id: "Faridabad",
                },
                {
                  value: "Shahadra",
                  id: "Shahadra",
                },
                {
                  value: "Anand Vihar",
                  id: "Anand Vihar",
                },
                {
                  value: "Gurgaon",
                  id: "Gurgaon",
                },
              ],
            },
            {
              itemName: "IOT",
              items: [
                {
                  value: "Wynncom",
                  id: "Wynncom",
                },
                {
                  value: "Intellicar",
                  id: "Intellicar",
                },
              ],
            },
            {
              itemName: "Pack",
              items: [
                {
                  value: "Livguard",
                  id: "livguard",
                },
              ],
            },
          ];
          let newFilterWithData = [];
          filters.map(
            (
              item //res.response.response.filter.filtersWithData
            ) => newFilterWithData.push(item["itemName"])
          );
          const filteredArray = newFilterWithData;

          const queryParams = decryptTheParams();

          console.log(queryParams)

          for (var value of Object.keys(queryParams)) {
            if (filteredArray.includes(value)) {
              if (value === "City") {
                dispatch(SetDiagnosticsCity(getActualArrayValues(queryParams[value])));
              } else if (value === "Zone") {
                dispatch(SetDiagnosticsZone(getActualArrayValues(queryParams[value])));
              } else if (value === "Status") {
                dispatch(SetDiagnosticsStatus(getActualArrayValues(queryParams[value])));
              } else if (value === "IOT") {
                dispatch(SetDiagnosticsIOT(getActualArrayValues(queryParams[value])));
              } else if (value === "Pack") {
                dispatch(SetDiagnosticsPack(getActualArrayValues(queryParams[value])));
              }
            } else {
              if (value === "startDate") {
                dispatch(SetDiagnosticsStartDate(queryParams[value]));
              } else if (value === "endDate") {
                dispatch(SetDiagnosticsEndDate(queryParams[value]));
              } else if (value === "tabValue") {
                dispatch(SetDiagnosticsTabValue(+queryParams[value]));
              } else if (value === "batteryPartnerSelect") {
                dispatch(SetDiagnosticsBatteryPartnerSelect(queryParams[value]));
              } else if (value === "batteryID") {
                dispatch(SetDiagnosticsBatteryID(queryParams[value]));
              } else if (value === "partner") {
                dispatch(SetDiagnosticsPartner(queryParams[value]));
              } else if (value === "IsBatteryIsPartnerIsNull") {
                queryParams[value] === "null"
                  ? dispatch(SetIsBatteryIsPartnerIsNullDiagnostics(null))
                  : dispatch(SetIsBatteryIsPartnerIsNullDiagnostics(queryParams[value]));
              }
            }
          }

          setPagesContent({
            ...pagesContent,
            pageHeader: "Diagnostics", //sizeOfTextinfo>1? res.response.response.textInfo[props.batteryType]["pageHeader"]:res.response.response.textInfo["pageHeader"]
            filtersWithData: filters, //res.response.response.filter.filtersWithData
            searchTypeFilter: {
                //res.response.response.filter.searchTypeFilter
                items: ["Battery ID", "Occupant"],
                itemName: "Select",
              },
            filteredArray: filteredArray,
            batteryType: props.batteryType,
            mounted: true,
          });
        // }
        // else if(res.responseStatus.code === -2)
        // {
        //   dispatch(SetSessionExpired(true))
        // }
      //});

    //   const handleSearch = (
    //     selectedKeys,
    //     confirm,
    //     dataIndex,
    //     tabvalue,
    //     close
    //   ) => {
    //     const tempColumns = [...columns];
    //     tempColumns[tabvalue]["columns"].forEach((col) => {
    //       if (col.dataIndex === dataIndex) {
    //         col.checkedOptions = [...selectedKeys];
    //       }
    //     });
    //     dispatch(SetDiagnosticsColumns([...tempColumns]));
    //     close();
    //   };
    //   const handleReset = (
    //     setSelectedKeys,
    //     confirm,
    //     filters,
    //     dataIndex,
    //     tabvalue,
    //     close
    //   ) => {
    //     const tempColumns = [...columns];
    //     filters.forEach((ele) => {
    //       ele.isChecked = false;
    //     });
    //     tempColumns[tabvalue]["columns"].forEach((col) => {
    //       if (col.dataIndex === dataIndex) {
    //         col.checkedOptions = [];
    //       }
    //     });
    //     setSelectedKeys([]);
    //     dispatch(SetDiagnosticsColumns([...tempColumns]));
    //     close();
    //   };
    //   const onChangeFilter = (
    //     ele,
    //     dataIndex,
    //     selectedKeys,
    //     setSelectedKeys,
    //     filters
    //   ) => {
    //     if (ele.isChecked) {
    //       filters.forEach((element) => {
    //         if (element == ele) {
    //           ele.isChecked = false;
    //         }
    //       });

    //       const arr =
    //         selectedKeys.length > 0
    //           ? selectedKeys.filter((item) => item != ele.value)
    //           : [];

    //       setSelectedKeys(arr);
    //     } else {
    //       filters.forEach((element) => {
    //         if (element == ele) {
    //           ele.isChecked = true;
    //         }
    //       });

    //       if (selectedKeys[0] == undefined) {
    //         setSelectedKeys([(selectedKeys[0] = "")]);
    //       }
    //       setSelectedKeys([...selectedKeys, ele.value]);
    //     }
    //     var isFilterEmpty = true;
    //     filters.forEach((ele) => {
    //       if (ele.isChecked == true) {
    //         isFilterEmpty = false;
    //       }
    //     });

    //     if (isFilterEmpty) {
    //       setSelectedKeys([]);
    //     }
    //   };

    //   const handleSelectAllFilters = (
    //     e,
    //     filters,
    //     selectedKeys,
    //     setSelectedKeys,
    //     dataIndex
    //   ) => {
    //     if (e.target.checked) {
    //       const arr = [];

    //       if (selectedKeys[0] == undefined) {
    //         setSelectedKeys([(selectedKeys[0] = "")]);
    //       }
    //       filters
    //         .filter((ele) => ele.value.includes(selectedKeys[0]))
    //         .forEach((ele, key) => {
    //           ele.isChecked = true;
    //           arr.push(ele.value);
    //         });

    //       setSelectedKeys([...selectedKeys, ...arr]);
    //     } else {
    //       filters.forEach((ele) => {
    //         ele.isChecked = false;
    //       });
    //       setSelectedKeys([]);
    //     }
    //   };

    //   let checker = (arr) => arr.every((v) => v === true);

    //   const getColumnSearchProps = (dataIndex, tabvalue) => ({
    //     filterDropdown: ({
    //       setSelectedKeys,
    //       selectedKeys,
    //       confirm,
    //       clearFilters,
    //       close,
    //       filters,
    //     }) => (
    //       <div
    //         style={{
    //           padding: 8,
    //         }}
    //         // onKeyDown={(e) => e.stopPropagation()}
    //         onClick={(e) => e?.stopPropagation()}
    //       >
    //         <Input
    //           ref={searchInput}
    //           placeholder={`Search ${dataIndex}`}
    //           value={selectedKeys[0]}
    //           onChange={(e) =>
    //             setSelectedKeys([
    //               (selectedKeys[0] = e.target.value),
    //               ...selectedKeys.splice(1),
    //             ])
    //           }
    //           style={{
    //             marginBottom: 8,
    //             display: "block",
    //           }}
    //         />

    //         {filters.filter((ele) =>
    //           ele.value.includes(
    //             selectedKeys[0] === undefined ? "" : selectedKeys[0]
    //           )
    //         ).length != 0 ? (
    //           <div>
    //             <Checkbox
    //               onClick={(e) =>
    //                 handleSelectAllFilters(
    //                   e,
    //                   filters,
    //                   selectedKeys,
    //                   setSelectedKeys,
    //                   dataIndex
    //                 )
    //               }
    //               checked={
    //                 filters.filter((ele) =>
    //                   ele.value.includes(
    //                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
    //                   )
    //                 ).length != 0
    //                   ? checker(
    //                       filters
    //                         .filter((ele) =>
    //                           ele.value.includes(
    //                             selectedKeys[0] === undefined
    //                               ? ""
    //                               : selectedKeys[0]
    //                           )
    //                         )
    //                         .map((ele) => ele.isChecked == true)
    //                     )
    //                   : false
    //               }
    //             >
    //               Select all Items
    //             </Checkbox>

    //             <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
    //               {console.log(filters, selectedKeys)}
    //               {filters
    //                 .filter((ele) =>
    //                   ele.value.includes(
    //                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
    //                   )
    //                 )
    //                 .map((ele, key) => {
    //                   return (
    //                     <>
    //                       <div>
    //                         <Checkbox
    //                           key={key}
    //                           checked={ele.isChecked}
    //                           onChange={(e) =>
    //                             onChangeFilter(
    //                               ele,
    //                               dataIndex,
    //                               selectedKeys,
    //                               setSelectedKeys,
    //                               filters
    //                             )
    //                           }
    //                         >
    //                           {ele.value}
    //                         </Checkbox>
    //                         <br></br>
    //                       </div>
    //                     </>
    //                   );
    //                 })}
    //             </div>

    //             <div className="dropDownBottom">
    //               <Button
    //                 onClick={() =>
    //                   handleReset(
    //                     setSelectedKeys,
    //                     confirm,
    //                     filters,
    //                     dataIndex,
    //                     tabvalue,
    //                     close
    //                   )
    //                 }
    //               >
    //                 Reset
    //               </Button>
    //               <Button
    //                 type="primary"
    //                 // disabled={selectedKeys.length>1?false:true}
    //                 onClick={() =>
    //                   handleSearch(
    //                     selectedKeys,
    //                     confirm,
    //                     dataIndex,
    //                     tabvalue,
    //                     close
    //                   )
    //                 }
    //               >
    //                 Apply
    //               </Button>
    //             </div>
    //           </div>
    //         ) : (
    //           <p style={{ textAlign: "center" }}>No Options Found</p>
    //         )}
    //       </div>
    //     ),

    //     filterMultiple: true,

    //     onFilter: (value, record) => {
    //       if (value.length != 0) {
    //         return record[dataIndex]
    //           .toString()
    //           .toLowerCase()
    //           .includes(value.toLowerCase());
    //       }
    //     },

    //     onFilterDropdownOpenChange: (visible) => {
    //       const tempColumns = [...columns];
    //       console.log(tempColumns[tabvalue]["columns"]);
    //       if (!visible) {
    //         tempColumns[tabvalue]["columns"].forEach((col) => {
    //           if (col.dataIndex === dataIndex) {
    //             col.filters.forEach((item) => {
    //               if (col.checkedOptions.includes(item.text)) {
    //                 item.isChecked = true;
    //               } else {
    //                 item.isChecked = false;
    //               }
    //             });
    //           }
    //         });
    //         dispatch(SetDiagnosticsColumns([...tempColumns]));
    //       } else {
    //         setTimeout(() => searchInput.current?.select(), 100);
    //       }
    //     },
    //     filterResetToDefaultFilteredValue: true,
    //     filterIcon: (filtered) => (
    //       <FilterAltIcon
    //         style={{
    //           color:
    //             columns[tabvalue]["columns"].filter(
    //               (element) => element.dataIndex === dataIndex
    //             )[0]["checkedOptions"].length > 0
    //               ? ThemeProperties.purple
    //               : undefined,
    //         }}
    //       />
    //     ),
    //     render: (text) => text,
    //   });

      const columns = [
        {
          name: "Active Columns",
          columns: [
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },
            {
              title: (titleProps) => getColumnTitleSort(titleProps,"errorCode","Error Code"),
              name: "Error Code",
              dataIndex: "errorCode",
              key: "errorCode",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "errorCodeActive",
              // width: "30%",
              width: 120,
              render: (errorCode) => (
                <Typography variant="tableRow">{errorCode}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeLiActive",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                  sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) => getColumnTitleSort(titleProps,"socBMS","SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterSearch: false,
              // filterMode: "tree",
              filters: false,
              // ...getColumnSearchProps("soc", 0),
              checkedOptions: [],
              width: 120,
              uniqueKey: "socActive",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },

            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentActive",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackActive",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureActive",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "errorTime",
              key: "errorTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "errorTimeActive",
              width: 170,
              render: (errorTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(errorTime)}
                </Typography>
              ),
            },
            

            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeActive",
              width: 170,
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Occupant",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filters: tempPartnerFilter,
              //...getColumnSearchProps("partner", 0),
              checkedOptions: [],
              //filterSearch: true,
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerActive",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "latitudeActive",
              width: 170,
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityActive",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneActive",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusActive",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotActive",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packActive",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              name: ""
            },
          ],
        },

        {
          name: "Resolved Columns",
          columns:[
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              sorter: false,
              uniqueKey: "batteryIDResolved",
              filterSearch: false,
              fixed: "left",
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDResolved",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },

            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedLiResolved",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "resolvedActive",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackResolved",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureResolved",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "resolutionTime",
                  "Resolved On"
                ),
              name: "Resolved On",
              dataIndex: "resolutionTime",
              key: "resolutionTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "resolutionTimeResolved",
              render: (resolutionTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(resolutionTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "errorTime",
              key: "errorTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "errorTimeActive",
              render: (errorTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(errorTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "deltaTime", "Resolution Time"),
              name: "Resolution Time",
              dataIndex: "deltaTime",
              key: "deltaTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 150,
              uniqueKey: "deltaTimeResolved",
              render: (deltaTime) => (
                <Typography variant="tableRow">
                  {SecondsFormatter(deltaTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Partner",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempPartnerFilter2,
              //...getColumnSearchProps("partner", 2),
              checkedOptions: [],
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerClosed",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityResolved",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneResolved",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusResolved",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotResolved",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packResolved",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
      ];

      dispatch(SetDiagnosticsColumns(columns));
      dispatch(SetDiagnosticsHiddenColumns([]));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
  }, [props.batteryType]);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <DiagnosticsMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default Diagnostics;
