const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthsNumbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

var maxd = new Date();
var maxutc = maxd.getTime() + maxd.getTimezoneOffset() * 60000;
var maxnd = new Date(maxutc + 3600000 * +5.5);

const minDate = new Date().setDate(new Date().getDate() - 60);//45
const maxDate = maxnd;
const minDateMinusOne = new Date().setDate(new Date().getDate() - 46);

const todayDate = new Date();
const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

let day = todayDate.getDate();
let month = todayDate.getMonth() + 1;
let year = todayDate.getFullYear();

let tday = tomorrowDate.getDate();
let tmonth = tomorrowDate.getMonth() + 1;
let tyear = tomorrowDate.getFullYear();

const todayDateNumeric = `${year}-${month}-${day}`
const tomorrowDateNumeric = `${tyear}-${tmonth}-${tday}`

function DateFormatString(date) {
  const year = date.substring(0, 4);
  const month = months[monthsNumbers.indexOf(date.substring(5, 7))];
  const day = date.substring(8, 10);

  const stringDate = day + " " + month + " " + year;
  return stringDate;
}

function DateFormatStringCompleteString(mydate) {
  var date = mydate.toString();
  const year = date.substring(11, 15);
  const month = date.substring(4, 7);
  const day = date.substring(8, 10);

  const stringDate = day + " " + month + " " + year;
  return stringDate;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    date.toLocaleString("en-us", { month: "short" }),
    date.getFullYear(),
  ].join(" ");
}
function formatDateNumeric(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

function DateDiffInDays (start,end)
{
  const date1 = new Date(start);
  const date2 = new Date(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  // console.log(diffDays + " days");
  return diffDays
}

function returnLastSunday()
{
  var now = new Date();
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));

  return lastSunday
}


export {
  DateFormatString,
  DateFormatStringCompleteString,
  minDate,
  maxDate,
  minDateMinusOne,
  formatDate,
  formatDateNumeric,
  DateDiffInDays,
  returnLastSunday,
  todayDateNumeric,
  tomorrowDateNumeric
};
