import React, { useEffect, useState } from "react";
import {
  Chart,
  Tooltip,
  Axis,
  LineAdvance,
  Line,
  Legend,
  Point,
  Geom,
} from "bizcharts";
import { Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CODE from "../../Static/Constants/StatusCodes";
import moment from "moment";
import Loader from "../../Helper/Loaders/Loader";
import { FindNextHighestMultipleOfNum } from "../../Helper/Operations/FindNearestMaxAnalytics";
import NoDataChart from "../NoDataChart";

function generateCustomOutput(min, max, numSteps) {
  const stepSize = (max - min) / (numSteps - 1);
  const result = [min];

  for (let i = 1; i < numSteps - 1; i++) {
    const value = min + i * stepSize;
    result.push(value);
  }

  result.push(max);

  return result;
}

function BizLine(props) {
  const [chartState, setChartState] = useState({
    data: [],
  });

  const scale = {
    Avg_ping_interval: {
      alias: "Average ping interval (s)",
      min: 0,
      max: FindNextHighestMultipleOfNum(props.data.max, 100),
      ticks: generateCustomOutput(
        0,
        FindNextHighestMultipleOfNum(props.data.max, 100),
        5
      ),
      sync: "value",
    },
  };

  function mountTheChart(data) {
    setChartState({
      data: data,
    });
  }

  useEffect(() => {
    setChartState({ data: [] });
    if (
      props.data !== undefined &&
      (props.data?.responseStatus.code === CODE.SUCCESS ||
        props.data?.responseStatus.code === 1999)
    ) {
      console.log(props.data);
      mountTheChart(props.data.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(
    () => () => {
      setChartState({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {props.data?.responseStatus.code === CODE.SUCCESS ? (
        <div style={{ height: "400px" }}>
          <div
            style={{
              position: "relative",
              height: "calc(100% - 30px)",
              width: "calc(100% - 25px)",
              paddingTop: "20px",
            }}
          >
            <Chart
              //   padding={[10, 10, 60, 50]}
              scale={scale}
              autoFit
              height={"97%"}
              data={chartState.data}
            >
              {/* <Line
                shape="straight"
                // area
                position="Date*Avg_ping_interval"
                color={ThemeProperties.c_yellow}
              ></Line> */}

              <LineAdvance
                shape="straight"
                position="Date*Avg_ping_interval"
                size={2}
                color={ThemeProperties.c_yellow}
                label="first"
              />



               <Axis
                name="Avg_ping_interval"
                position="left"
                line={{
                  style: {
                    stroke: "#000",
                    lineWidth: 1.2,
                  },
                }}
                tickLine={true}
                grid={true}
                label={{
                  style: {
                    fontSize: 12,
                    //   textAlign: 'left',
                    fill: "#000",
                    fontWeight: 400,
                  },
                  autoHide: false,
                  formatter: (val) => {
                    if (val === "1200") {
                      return "";
                    }
                    return val;
                  },
                }}
                title={{
                  style: {
                    fontSize: 14,
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fill: ThemeProperties.black,
                    fontWeight: 500,
                  },
                }}
              />
              <Axis
                name="Date"
                visible={true}
                line={{
                  style: {
                    stroke: "#000",
                    lineWidth: 1.2,
                  },
                }}
                label={{
                  offsetY: 5,
                  style: {
                    fontSize: 12,
                    textAlign: "center",
                    fill: "#000",
                    fontWeight: 400,
                  },
                  formatter: (val) => {
                    if (props.xaxisType === "Weekly" && props.data?.data?.length>5) {
                      let arr = val.split("-");
                      return `${arr[0]} - \n${arr[1]}`;
                    } else {
                      return val;
                    }
                  },
                }}
                tickLine={{
                  style: {
                    lineWidth: 1, // 刻度线宽
                    stroke: "#000", // 刻度线的颜色
                  },
                  length: 6,
                }}
              />
              <Tooltip
                showCrosshairs={true}
                showMarkers={true}
                crosshairs={{
                  type: "x", // Show a vertical guide line (guide annotation) for y-axis,
                  line: {
                    style: {
                      lineDash: [3],
                    },
                  },
                }}
              >
                {(title, items) => {
                  return (
                    <div style={{ width: "max-content" }}>
                      <div
                        className="bizcharts-tooltip-title
"
                      >
                        {props.xaxisType === "Daily"
                          ? moment(new Date(title)).format("DD MMM")
                          : title}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          margin: "5px",
                        }}
                      >
                        <Brightness1
                          style={{
                            fontSize: "14px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            color: items[0].color,
                            marginLeft: "10px",
                          }}
                        />
                        <div className="bizcharts-tooltip-series-group">
                          <b>{items[0].name} : </b>
                          {items[0].value}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Tooltip>
            </Chart>
          </div>
        </div>
      ) : (
        <div style={{ height: "400px", position: "relative" }}>
          {props.data.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <NoDataChart text={"No data found"} />
          )}
        </div>
      )}
    </div>
  );
}

export default BizLine;
