import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const OuterBox = styled(Box)({
  flexGrow: 1,
  // backgroundColor: `${ThemeProperties.backgroundPurpleDarker}`,
  paddingTop: "70px",
  paddingLeft: "65px",
  paddingBottom: "100px",
  minWidth:"865px",
  height: "100%",
});

const InnerBox = styled(Box)({
  // backgroundColor: `${ThemeProperties.backgroundPurpleDarker}`,
  maxWidth: "1650px",
  minWidth:"800px",
  padding: "0px 20px",
  margin: "auto",
});

const SimpleBox = styled("div")({});

const DownloadCSVBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "12px",
  width: "150px",
  // marginTop: "8px",
  height: "28px",
  backgroundColor: "#fff",
  cursor: "pointer",
});

const DownloadCSVPointer = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "1px solid rgb(189,189,189)",
  borderRadius: "4px",
  width: "120px",
  mr: 1,
  mt: 1,
  mb: 1,
  height: "35px",
  bgcolor: "#fff",
  cursor: "pointer",
});

const DownloadCSVNoPointer = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "1px solid rgb(189,189,189)",
  borderRadius: "4px",
  width: "120px",
  mr: 1,
  mt: 1,
  mb: 1,
  height: "35px",
  bgcolor: "#fff",
});

const BoxWithOverflow = styled(Box)({
  height: "400px",
  maxHeight: "440px",
  overflowY: "auto",
  overflowX: "hidden",
});

const KPIBox = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  // width: "100%",
  // marginRight: "2rem",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
  boxShadow: "rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px",
});

const KPIBoxSmallWidth = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  width: "16rem",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
  boxShadow: "rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px",
});

const KPIBoxCustomWidth = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  width: "max-content",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
});

const KPIBoxSmallWidthWithoutHover = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: "1px solid #ececec",
  padding: "1rem",
  width: "16rem",
  cursor: "default",
  "&:hover": {
    border: "1px solid #ececec",
  },
});

const KPIBoxWithoutBorder = styled(Box)({
  backgroundColor: ThemeProperties.white,
  borderRadius: ThemeProperties.borderRadius,
  border: `1px solid ${ThemeProperties.purple}`,
  padding: "1rem",
  width: "18rem",
  marginRight: "2rem",
  "&:hover": {
    border: `1px solid ${ThemeProperties.purple}`,
  },
});

export {
  OuterBox,
  InnerBox,
  SimpleBox,
  BoxWithOverflow,
  DownloadCSVBox,
  DownloadCSVPointer,
  DownloadCSVNoPointer,
  KPIBox,
  KPIBoxWithoutBorder,
  KPIBoxSmallWidth,
  KPIBoxCustomWidth,
  KPIBoxSmallWidthWithoutHover,
};
