import React, { useEffect, useState } from "react";
import {  Grid } from "@mui/material";

import CardWithNavigation from "../Features/Cards/CardWithNavigation";
import CardWithNumbers from "../Features/Cards/CardWithNumbers";
import CardWithList from "../Features/Cards/CardWithList";
import WelcomeContainer from "../Features/Container/WelcomeContainer";
import { OuterBox, InnerBox, SimpleBox } from "../Components/CustomBox";
import { columnsNewLanding } from "../Static/Data/Landing/LandingTable";
// import AlertCardData from "../Static/Data/_Testing/AlertCardData";
import { groupByKey } from "../Helper/Operations/GroupByKey";
import {
  getTotalBatteries,
  getActiveAlerts,
  getOpenAlerts,
  getResolvedAlerts,
  getSuperCriticalAlerts,
  getDevicePingStatus,
  getDevicePingStatusCSV
} from "../Api/Api";
import { SetSessionExpired } from "../Actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageNotFound from "../Features/Dialog/PageNotFound";
import {
  todayDateNumeric,
  tomorrowDateNumeric,
} from "../Helper/DatePicker/DateFormatters";
import BasicTableNoPagination from "../Features/Table/AntDesign/BasicTableNoPagination";
import { PaperHighlight } from "../Components/CustomPaper";
import Header from "../Features/Header/Header";
import Events from "../Analytics/Events";
import ScrollToTheElement from "../Helper/Operations/ScrollToTheElement";
import DownloadCSV from "../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../Helper/CSVOperations/NameTheCSV";

export default function Landing(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notFound, setNotFound] = useState(false);

  const [activeAlerts, setActiveAlerts] = useState("...");
  const [openAlerts, setOpenAlerts] = useState("...");
  const [resolvedAlerts, setResolvedAlerts] = useState("...");
  const [total, setTotal] = useState("...");

  const [alertsList, setAlertsList] = useState({
    alerts: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryAlertsList, setBatteryAlertsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const getCSVData = (status) =>{
    getDevicePingStatusCSV(status).then((res) => {
      let temp = [];
      if (res.responseStatus.code === 200) {
        
    if (res.response.data !==null && res.response.data !== undefined && res.response.data.length>0) {
      res.response.data.map((item)=>{
        temp.push({
          "Battery ID":item.assetID,
          "Device ID":item.batteryID,
          "Battery Manufacturer":item.batteryVendor,
          "IOT Manufacturer":item.iotVendor,
          "Operational Status":item.status === "Offline" ? "Not pinged since 1 day" : item.status === "Non-Operational" ? "Not pinged since 7 days" : item.status,
         
          
        })
      })
      DownloadCSV(NameTheCSV("CSV", `Landing_${status}`), temp);
    }
        // const gy = groupByKey(res.response.data, "iotVendor");

        // gy.map((item) => temp.push({
        //   "Battery Vendor": item["values"][0]["batteryVendor"],
        //   "IOT Vendor": item["key"],
        //   Live: item["values"].find((i)=> i.status === "Live")?item["values"].find((i)=> i.status === "Live")["count"]:0,
        //   Offline: item["values"].find((i)=> i.status === "Offline")?item["values"].find((i)=> i.status === "Offline")["count"]:0,
        //   NonOperational: item["values"].find((i)=> i.status === "Non-Operational")?item["values"].find((i)=> i.status === "Non-Operational")["count"]:0
        // }));

        // setBatteryAlertsList({
        //   data: temp,
        //   responseStatus: {
        //     code: res.responseStatus.code,
        //     msg: res.responseStatus.msg,
        //   },
        // });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
  }

  useEffect(() => {
    ScrollToTheElement();

    if (props.routeNotFound) {
      setNotFound(true);
      navigate("/");
    }
    getSuperCriticalAlerts("Active", "4").then((res) => {
      if (res.responseStatus.code === 200) {
        setActiveAlerts(res.response.issues.count);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setActiveAlerts(0);
      }
    });
    getOpenAlerts("Open").then((res) => {
      if (res.responseStatus.code === 200) {
        setOpenAlerts(res.response.issues.count);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setOpenAlerts(0);
      }
    });
    getResolvedAlerts(todayDateNumeric, tomorrowDateNumeric).then((res) => {
      if (res.responseStatus.code === 200) {
        setResolvedAlerts(res.response.issues.count);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setResolvedAlerts(0);
      }
    });
    getTotalBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setTotal(res.response.data.count);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setTotal(0);
      }
    });
    getActiveAlerts().then((res) => {
      if (res.responseStatus.code === 200) {
        setAlertsList({
          alerts: res.response.alertList,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setAlertsList({
          alerts: res.response.alertList,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    getDevicePingStatus().then((res) => {
      let temp = [];
      if (res.responseStatus.code === 200) {
        const gy = groupByKey(res.response.data, "iotVendor");

        gy.map((item) => temp.push({
          "Battery Vendor": item["values"][0]["batteryVendor"],
          "IOT Vendor": item["key"],
          Live: item["values"].find((i)=> i.status === "Live")?item["values"].find((i)=> i.status === "Live")["count"]:0,
          Offline: item["values"].find((i)=> i.status === "Offline")?item["values"].find((i)=> i.status === "Offline")["count"]:0,
          NonOperational: item["values"].find((i)=> i.status === "Non-Operational")?item["values"].find((i)=> i.status === "Non-Operational")["count"]:0
        }));

        setBatteryAlertsList({
          data: temp,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setBatteryAlertsList({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OuterBox>
      <InnerBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <WelcomeContainer />
          </Grid>

          <Grid item xs={12}>
            <SimpleBox>
              <CardWithNavigation
                data={
                  props.pagesLinks
                  //             [
                  //             {
                  //               tabs: [
                  //                 {
                  //                   to: "/Dashboard",
                  //                   src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Alerts_blue.svg",
                  //                   text: "Bounce LTR",
                  //                   mykey: "Dashboard",
                  //                   isActive: true,
                  //                   queryParam: { batteryType: "Share" },
                  //                 },
                  //                 {
                  //                   to: "/Dashboard",
                  //                   src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Alerts_blue.svg",
                  //                   text: "Bounce Infinity",
                  //                   mykey: "Dashboard",
                  //                   isActive: true,
                  //                   queryParam: { batteryType: "Infinity" },
                  //                 },
                  //                 {
                  //                   to: "/Map",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Map_blue.svg",
                  //                   text: "Map",
                  //                   mykey: "Map",
                  //                   isActive: true,
                  //                   queryParam: {},
                  //                 },
                  //               ],
                  //               title: "Real-Time Monitoring",
                  //             },
                  //             {
                  //               tabs: [
                  //                 {
                  //                   to: "/BatteryHealth",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/BatteryHealth_blue.svg",
                  //                   text: "Battery Health",
                  //                   mykey: "BatteryHealth",
                  //                   isActive: true,
                  //                   queryParam: {},
                  //                 },
                  //                 {
                  //                   to: "/Diagnostics",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Diagnostics_blue.svg",
                  //                   text: "Diagnostics",
                  //                   mykey: "Diagnostics",
                  //                   isActive: true,
                  //                   queryParam: {},
                  //                 },
                  //                 {
                  //                   to: "/Anomaly",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Anomaly_blue.svg",
                  //                   text: "Anomaly",
                  //                   mykey: "Anomaly",
                  //                   isActive: true,
                  //                   queryParam: {},
                  //                 },
                  //                 {
                  //                   to: "/Warranty",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Warranty_blue.svg",
                  //                   text: "Warranty",
                  //                   mykey: "Warranty",
                  //                   isActive: true,
                  //                   queryParam: {},
                  //                 },
                  //               ],
                  //               title: "Diagnosis & Management",
                  //             },
                  //             {
                  //               tabs: [
                  //                 {
                  //                   to: "/OperationalStrategies",
                  //                   "src": "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Operational_outline.png",
                  //                   text: "Operational Stra.",
                  //                   mykey: "OperationalStrategies",
                  //                   isActive: false,
                  //                   queryParam: {},
                  //                 },
                  //                 {
                  //                   to: "/Lifetime",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/LifeTime_blue.svg",
                  //                   text: "Lifetime",
                  //                   mykey: "Lifetime",
                  //                   isActive: false,
                  //                   queryParam: {},
                  //                 },
                  //                 {
                  //                   to: "/SecondLife",
                  // src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/SecondLife_blue.svg",
                  //                   text: "Second Life",
                  //                   mykey: "SecondLife",
                  //                   isActive: false,
                  //                   queryParam: {},
                  //                 },
                  //               ],
                  //               title: "Predictive Analytics",
                  //             },
                  //           ]
                }
              />
            </SimpleBox>
          </Grid>

          <Grid item xs={6}>
            <SimpleBox sx={{ height: "470px" }}>
              <CardWithNumbers
                total={total}
                active={activeAlerts}
                open={openAlerts}
                resolved={resolvedAlerts}
              />
            </SimpleBox>
          </Grid>

          <Grid item xs={6}>
            <SimpleBox sx={{ height: "470px" }}>
              <CardWithList data={alertsList} />
            </SimpleBox>
          </Grid>

          <Grid item xs={6}>
            <SimpleBox sx={{ height: "470px" }}>
              <PaperHighlight elevation={0} sx={{ height: "100%" }} onMouseEnter={()=>Events("hovered Device Ping Status")}>
                <Header
                  heading="Device Ping Status"
                  description="What's Happening in Fleet"
                  showIButton={true}
                  showThreeDots={false}
                  getCSVData={getCSVData}
                  showCSVDevicePingLanding={true}
                  csvData ={batteryAlertsList.data}
                  id="Device_Ping_Status"
                  page=""
                />
                <div className="section" id="Device_Ping_Status">
                  
                  <BasicTableNoPagination
                    data={batteryAlertsList}
                    columns={columnsNewLanding}
                    noDataMsg={"No information found"}
                    dateString={["", ""]}
                    setBatteryConfigList={setBatteryAlertsList}
                    changeTheDate={""}
                    ChangeTheTab={""}
                    handleOpenToast={""}
                    openNotification={""}
                    onClickColumn={"insertionTime"}
                    height={340}
                    pagePerSize={8}
                    id="CannotBeClicked"
                  />
                </div>
              </PaperHighlight>
            </SimpleBox>
          </Grid>

         
        </Grid>
        {notFound && (
          <PageNotFound notFound={notFound} setNotFound={setNotFound} />
        )}
      </InnerBox>
    </OuterBox>
  );
}
