import qs from "qs";
import { GetData } from "./Functions/SettingData";

async function getMapBatteries(
  volt,
  city,
  zone,
  batterystatus,
  multipePartners,
  userInputBatteries,
  alertType,
  severity,
  iotVendor,
  batteryVendor
) {
  var rawJson = {
    batteryID: [],
    assetID:userInputBatteries,
    partner: multipePartners,
    voltConf: volt === "" ? [] : [volt],
    batteryStatus: batterystatus === "" ? [] : [batterystatus],
    zone: zone === "" ? [] : [zone],
    location: city === "" ? [] : [city],
    alertType: alertType === "" ? [] : [alertType],
    severity: severity === "" ? [] : [severity],
    batteryFlag:[0],
    iotVendor:iotVendor === "" ? [] : [iotVendor],
    batteryVendor: batteryVendor === "" ? [] : [batteryVendor]
  };
  var config = {
    method: "get",
    url: `/api/locations`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getPartnerOEM(tag) {
  var rawJson = { tag: tag };
  var config = {
    method: "get",
    url: `/api/partner_locations`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getMapInfoWindow(type,value) {
  var rawJson = {
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/info_window`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getFoundNotFoundBatteries(batteryID) {
  var rawJson = {
    batteryID: [],
    assetID: batteryID
  };
  var config = {
    method: "get",
    url: `/api/not_found_assetID`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

export {
  getMapBatteries,
  getMapInfoWindow,
  getFoundNotFoundBatteries,
  getPartnerOEM,
};
