import React, { useEffect } from "react";
import SlickCarousel from "../../Features/Carousel/SlickCarousel";
import useState from "react-usestateref";
import ApexMultiYAxis from "../../Charts/Line/ApexMultiYAxis";
import { formatDateNumeric,formatDate } from "../../Helper/DatePicker/DateFormatters";
import { decryptTheParams, encryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";

const BatteryPackCarousel = (props) => {
  const navigate = useNavigate();
  const [mountTheSlick, setMountTheSlick] = useState(false);

  const [arrayOfDates, setArrayOfDates] = useState([]);
  const [arrayOfDatesNumeric, setArrayOfDatesNumeric] = useState([]);

  const [startDate, setStartDate, refStartDate] = useState([]);
  const queryParams = decryptTheParams();

  const setTheDate = (date) =>
  {
    setStartDate(date);
    let temp;
    temp={
      ...queryParams,
      batteryPackMetrics : date,
    }
    encryptTheParams(temp,navigate,true);
  }

  function handleSetDates() {
    var myStart = formatDate(new Date(props.dateString[0]));
    var myEnd = formatDate(new Date(props.dateString[1]));

    var myStartNumeric = formatDate(new Date(props.dateString[0]));
    // var myEndNumeric = formatDate(new Date(props.dateString[1]));

    var arr,
      dt = new Date(myStart),
      arr1,
      dt1 = new Date(myStartNumeric);
      
    for (
      arr = [], arr1 = [];
      dt <= new Date(myEnd);
      dt.setDate(dt.getDate() + 1), dt1.setDate(dt1.getDate() + 1)
    ) {
      arr.push(formatDate(new Date(dt)));
      arr1.push(formatDateNumeric(new Date(dt1)));
    }
    setArrayOfDates(arr);
    setArrayOfDatesNumeric(arr1);

    let queryParams = decryptTheParams();
    if(queryParams && queryParams["batteryPackMetrics"])
    setStartDate(queryParams["batteryPackMetrics"])
    else
    setStartDate(props.dateString[0]);
  } 

  function SetCSVData (data)
  {
    props.SetCSVData(data)
  }
  useEffect(() => {
    
    if (props.dateString[0] !== "" || props.dateString[1] !== "") {
      handleSetDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateString]);

  useEffect(() => () =>{

    setMountTheSlick(false)

    if (props.dateString[0] !== "" || props.dateString[1] !== "") {
      setMountTheSlick(true);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <>
      <div style={{ marginTop: "-10px" }}>
        {mountTheSlick && (
          <>
          <div className="section">
            <SlickCarousel
              Data={arrayOfDates}
              actualDates={arrayOfDatesNumeric}
              setTheDate={setTheDate}
              startDate={refStartDate.current}
              slidesToShow={10}
            />
            </div>
            <ApexMultiYAxis
            startDate={refStartDate.current}
            alertFirstSeenOn={props.alertFirstSeenOn}
            SetCSVData={SetCSVData}
            />
          </>
        )}
      </div>
      
    </>
  );
};
export default BatteryPackCarousel;
