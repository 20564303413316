import axios from 'axios';
import { postLogout } from '../../Api/Api';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Logout(callTheAPI,handleNavigate) {
  if(callTheAPI)
  {
    axios({
        method: "POST",
        url: postLogout(),
      }).then((res) => {
        if (res.status === 200) {
          // window.location.reload();
          cookies.remove("name",{ path: '/' });
          cookies.remove("sessionId",{ path: '/' });
          cookies.remove("access_token",{ path: '/' });
          cookies.remove("pages",{ path: '/' });
          cookies.remove("pagesMeta",{ path: '/' });
          cookies.remove("navigationCard",{ path: '/' });
          cookies.remove("orgName",{ path: '/' });
          cookies.remove("amplitudeID",{ path: '/' });
          cookies.remove("timeZone",{ path: '/' });
          handleNavigate();
          // window.location.reload();
        }
      })
    }
    else
    {
      cookies.remove("name",{ path: '/' });
          cookies.remove("sessionId",{ path: '/' });
          cookies.remove("access_token",{ path: '/' });
          cookies.remove("pages",{ path: '/' });
          cookies.remove("pagesMeta",{ path: '/' });
          cookies.remove("navigationCard",{ path: '/' });
          cookies.remove("orgName",{ path: '/' });
          cookies.remove("amplitudeID",{ path: '/' });
          cookies.remove("timeZone",{ path: '/' });
          window.location.reload();
    }
}

export default Logout;