import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Events from "../../Analytics/Events";

const ApexBasicDonut = (props) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  const handleSetBatteryHealthSOH = (value) => {
    props.handleSohValue(value);
  };

  function mountTheChart(series, labels, colors, dataLabelColors, minAngle,decimalPlaces) {
    setChartState({
      series: series,
      options: {
        grid: {
          padding: {
            top: 30,
            bottom: -10,
          },
        },
        chart: {
          width: 380,
          type: "donut",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          events: {
            
            dataPointSelection: (event, chartContext, config) => {
              Events("Fleet Battery Health Distribution selected category");
              handleSetBatteryHealthSOH(
                config.w.config.labels[config.dataPointIndex]
              );
            },
            legendClick: (chartContext, seriesIndex, config) => {
              Events("Fleet Battery Health Distribution selected category");
              handleSetBatteryHealthSOH(config.globals.labels[seriesIndex]);
            },
          },
        },
        labels: labels,
        plotOptions: {
          pie: {
            expandOnClick: false,
            dataLabels: {
              offset: 35,
              minAngleToShowLabel: minAngle,
            },
            donut: {
              size: "75%",
              labels: {
                show: false,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Roboto",
                  color: "#000",
                  offsetY: 20,
                  // offsetY: -10
                },
                value: {
                  show: false,
                  fontSize: "42px",
                  fontFamily: "Roboto",
                  color: "#000",
                  fontWeight: 600,
                  offsetY: -16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: false,
                  // label: undefined,
                  // color: "#000",
                  // fontSize: "14px",
                  // formatter: function (w) {
                  //   let queryParams = decryptTheParams();
                  //   if (queryParams["SOH"] && queryParams["SOH"] !== "") {
                  //     let index = w.globals.labels.indexOf(queryParams["SOH"]);
                  //     return w.globals.seriesTotals[index];
                  //   } else {
                  //     return w.globals.seriesTotals.reduce((a, b) => {
                  //       return a + b;
                  //     }, 0);
                  //   }
                  // },
                },
              },
            },
          },
        },
        colors: colors,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "13px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            colors: dataLabelColors,
          },
          dropShadow: {
            enabled: false,
          },
          formatter: function (val, opts) {
            return val?.toFixed(decimalPlaces) + "%"
        },
        },
        tooltip: {
          enabled: false,
        },

        legend: {
          show: props.showLegend,
          fontSize: "12px",
          color: "#22105C",
          fontFamily: "Roboto",
          fontWeight: 400,
          // width:200,
          offsetY: 10,
          position: "bottom",
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          markers: {
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 14,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
          },
          onItemHover: {
            highlightDataSeries: false
        },
        },
        states: {
          hover: {
              filter: {
                  type: 'none',
              }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
            }
        },
      }
      },
      // series: series,
      // options: {
      //   chart: {
      //     type: "donut",
      //     events: {
      //             animationEnd: (ctx) => {
      //               ctx.toggleDataPointSelection(0);
      //             }
      //           }
      //   },
      //   // labels:labels,
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // },
    });
  }
  useEffect(() => {
    const newColors = [
      "#00B159",
      "#7AC36A",
      "#B9E769",
      "#F2A54B",
      "#F9724C",
      "#D22B2B",
    ],
    newLightColors = [
      "#ccefde",
      "#e4f3e1",
      "#f1fae1",
      "#fceddb",
      "#fee3db",
      "#faccc4",
    ],

    tempColors=[],tempDataColors=[];

    // if (props.data.labels !== undefined && props.data.labels.length > 0) {
    if (props.sohLevel === "") {
      mountTheChart(props.data.series, props.data.labels, newColors, ['#000'],2,1);
    } else {
      let itemIndex = props.data.labels.indexOf(props.sohLevel);

      newColors.forEach((item, index) => {
        if (index === itemIndex) {
          tempColors.push(newColors[itemIndex]);
          tempDataColors.push("#000");
        } else {
          tempColors.push(newLightColors[index]);
          tempDataColors.push("transparent");
        }
      });




      mountTheChart(props.data.series, props.data.labels, tempColors, tempDataColors,0,2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.sohLevel]);

  useEffect(
    () => () => {
      setChartState({});
      props.setData({
        series: [],
        labels: [],
        totalLabel: props.totalLabel,
        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div style={{ height: "100%" }}>
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="donut"
        height="100%"
        id=""
      />
    </div>
  );
};
export default ApexBasicDonut;
