import React, { useEffect, useState } from "react";
// import { Button } from "antd";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { OutlinedButton } from "../../Components/CustomButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import CODE from "../../Static/Constants/StatusCodes";

function FlagTheBattery(props) {
  const [value, setValue] = React.useState(props.flag.value);

  const [flag, setFlag] = useState({
    value: -1,
    response: {
      code: CODE.LOADING,
      msg: "Loading... ",
    },
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setFlag({
      value: props.flag.value,
      response: {
        code: props.flag.response.code,
        msg: props.flag.response.msg,
      },
    });
  }, [props.flag.response.code]);

  useEffect(
    () => () => {
      setFlag({
        value: props.flag.value,
        response: {
          code: -1,
          msg: props.flag.response.msg,
        },
      });
    },
    []
  );

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.modal}
      maxWidth="xs"
      sx={{ zIndex: 100001 }}
      onClose={() => {
        props.handleModalClose();
      }}
    >
      <div
        style={{
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="paperHeader">
            {"Select the category you want to flag the battery"}
          </Typography>
          <IconButton
            onClick={() => {
              props.handleModalClose();
            }}
          >
            <CloseIcon
              fontSize="medium"
              sx={{ color: "grey", fontSize: "18.5px" }}
            />
          </IconButton>
        </Box>
        <br />
        <br />
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={0}
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={
                <Typography variant="textSubtitle">Operational</Typography>
              }
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={
                <Typography variant="textSubtitle">
                  Non-Operational: Out of Service
                </Typography>
              }
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={
                <Typography variant="textSubtitle">
                  Non-Operational: Under Maintenance
                </Typography>
              }
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label={
                <Typography variant="textSubtitle">
                  Non-Operational: Isolated
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
        <br></br>

        {flag.response.code === CODE.FORBIDDEN ? (
          <>
            <hr style={{ borderTop: "1px solid #ececec" }}></hr>
            <br></br>
            <Typography variant="textSubtitleRed">
              {
                "You don't have access to edit this selection. Please contact admin."
              }
            </Typography>
          </>
        ) : null}
        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <OutlinedButton
            onClick={() => {
              props.handleModalClose();
            }}
            variant="outlined"
          >
            <Typography variant="textSubtitlePurple">Cancel</Typography>
          </OutlinedButton>
          <OutlinedButton
            variant="contained"
            onClick={() => {
              props.confirmBatteryFlag(value);

              // props.flag.response.code === 201 && props.handleModalClose();
            }}
            sx={{ ml: 2 }}
          >
            <Typography variant="textSubtitleWhite">Confirm</Typography>
          </OutlinedButton>
        </div>
      </div>
    </Dialog>
  );
}

export default FlagTheBattery;
