import React from "react";
import { Avatar, Typography } from "@mui/material";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import bsIcon from "../../Assets/Icons/bsIcon2.svg";

function ProfileAvatar(props) {
  const cookies = new Cookies();

  return (
    <>
      {!props.showIcon ? (
        <Avatar
          style={{
            height: "27px",
            width: "27px",
            backgroundColor: "#FF977A",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              textAlign: "center",
              color: "#fff",
              fontSize: "14px",
            }}
          >
            {cookies.get("name") === undefined
              ? removeAccessToken()
              : cookies.get("name").charAt(0)}
          </Typography>
        </Avatar>
      ) : (
        <div
          className="profile_Icon"
          
        >
          <img src={bsIcon} alt="not-found" height={"35px"} style={{
            backgroundColor: props.openProfilePopup ? "#4037ca" : "transparent",padding: "4px",borderRadius:"4px"
          }}/>
        </div>
      )}
    </>
  );
}

export default ProfileAvatar;
