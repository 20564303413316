import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { getMapInfoWindow } from "../../Api/MapApi";
import CODE from "../../Static/Constants/StatusCodes";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import FlagIcon from "@mui/icons-material/Flag";
import FlagTheBattery from "../Dialog/FlagTheBattery";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Tooltip } from "antd";
import Events from "../../Analytics/Events";

function SimplePageHeader(props) {
  const navigate = useNavigate();

  const { value, batteryID, assetID, partner, lat, long, lastPinged } = props;

  const [modal, setModal] = useState(false);
  const handleModalClose = () => {
    setModal(false);
  };
  const handleModalOpen = () => {
    setModal(true);
    Events("clicked SB flag edit icon")
  };

  const [windoInfo, setWindowInfo] = useState({
    data: {
      severity: "",
      color: "",
    },
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  const [flag, setFlag] = useState({
    value: -1,
    response: {
      code: CODE.LOADING,
      msg: "Loading... ",
    },
  });

  const confirmBatteryFlag = (tag) => {
    props.confirmBatteryFlag(tag);
  };

  useEffect(() => {
    setWindowInfo({
      data: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    });
    let value = props.searchType === "Device" ? assetID : batteryID;
    if (value) {
      let queryParams = decryptTheParams(),
        temp;
      temp = {
        ...queryParams,
        batteryID: assetID,
        // batterySearchedGlobal: "false",
      };
      let finalType, finalValue;

      if (
        queryParams["batteryID"] &&
        queryParams["batteryID"] !== "undefined"
      ) {
        finalType = "Device";
        finalValue = queryParams["batteryID"];
      } else if (
        queryParams["batteryIDGlobal"] &&
        queryParams["batteryIDGlobal"] !== "undefined"
      ) {
        finalType = "Battery";
        finalValue = queryParams["batteryIDGlobal"];
      }

      encryptTheParams(temp, navigate);
      getMapInfoWindow(finalType, finalValue).then((res) => {
        if (res.responseStatus.code === CODE.SUCCESS) {
          let severity = "No Alerts",
            color = ThemeProperties.purple;
          let temp = [
            {
              id: 4,
              name: "Super Critical",
              color: ThemeProperties.map_critical,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 3,
              name: "Critical",
              color: ThemeProperties.map_high,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 2,
              name: "High",
              color: ThemeProperties.map_medium,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 1,
              name: "Low",
              color: ThemeProperties.map_low,
              value: 0,
              selected: false,
              alerts: [],
            },
          ];

          if (res.response.alerts.length > 0) {
            res.response.alerts.forEach((item) => {
              temp[temp.findIndex((o) => o.id === item.severity)].alerts.push(
                item.alertDescription
              );
            });
          }

          for (var i = 0; i < temp.length; i++) {
            if (temp[i].alerts.length > 0) {
              severity = temp[i].name;
              color = temp[i].color;
              break;
            }
          }

          setWindowInfo({
            data: {
              severity: severity,
              color: color,
            },
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          // if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setWindowInfo({
            data: {
              severity: "",
              color: "",
            },
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID]);

  useEffect(() => {
    setFlag({
      value: props.flag.value,
      response: {
        code: props.flag.response.code,
        msg: props.flag.response.msg,
      },
    });
  }, [props.flag.response.code]);

  useEffect(
    () => () => {
      setFlag({
        value: props.flag.value,
        response: {
          code: -1,
          msg: props.flag.response.msg,
        },
      });
    },
    []
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "inline" }}>
            {value === "SpecificBattery" ? (
              <Box sx={{ width: "300px" }}>
                <Box sx={{ display: "flex", mb: 2 }}>
                  <>
                    {/* {assetID && ( */}
                    <Box sx={{ display: "block" }}>
                      <Box>
                        <Typography
                          variant="textDescriptionActive"
                          sx={{ bgcolor: "white", borderRadius: "6px" }}
                        >
                          Battery ID
                        </Typography>
                      </Box>

                      <Typography variant="textSubtitleBold">
                        {batteryID ? batteryID : "------"}
                      </Typography>
                    </Box>
                    {/* )} */}
                  </>
                  <>
                    {/* {partner && ( */}
                    <Box sx={{ display: "block", ml: 5 }}>
                      <Box>
                        <Typography
                          variant="textDescriptionActive"
                          sx={{ bgcolor: "white", borderRadius: "6px" }}
                        >
                          Occupant
                        </Typography>
                      </Box>

                      <Typography variant="textSubtitleBold">
                        {partner ? partner : "------"}
                      </Typography>
                    </Box>
                    {/* )} */}
                  </>
                </Box>

                <Box sx={{ display: "block", mb: 2 }}>
                  <Box>
                    <Typography
                      variant="textDescriptionActive"
                      sx={{ bgcolor: "white", borderRadius: "6px" }}
                    >
                      Device ID
                    </Typography>
                  </Box>

                  <Typography variant="textSubtitleBold">
                    {assetID ? assetID : "------"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Typography variant="textDescriptionActive">
                    <b>Lat/Long :</b> &nbsp;
                  </Typography>
                  <Typography variant="textDescriptionActive">
                    {lat?.toFixed(4)}/{long?.toFixed(4)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <CircleRoundedIcon
                    sx={{ color: windoInfo.data.color, mr: 0.5 }}
                  />
                  <Typography variant="textDescriptionActive" sx={{ mr: 2 }}>
                    {windoInfo.data.severity}
                  </Typography>
                  <CircleRoundedIcon
                    sx={{
                      color:
                        (new Date().getTime() -
                          new Date(convertUTCtoZone(lastPinged)).getTime()) /
                          (1000 * 60) <
                        5
                          ? "green"
                          : "#A9A9A9",
                      mr: 0.5,
                    }}
                  />
                  <Typography variant="textDescriptionActive">
                    {(new Date().getTime() -
                      new Date(convertUTCtoZone(lastPinged)).getTime()) /
                      (1000 * 60) <
                    5
                      ? "Live"
                      : "Not Pinging"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FlagIcon
                    sx={{
                      fontSize: "20px",
                      mr: 0.8,
                      color:
                        flag.value == -1
                          ? "grey"
                          : flag.value == 0
                          ? "green"
                          : "red",
                    }}
                  />

                  <Box sx={{display:"block",mr:0.2}}>
                    <Typography variant="textDescriptionActive">
                      {flag.value === 0
                        ? "Operational"
                        : flag.value === 1
                        ? "Non-Operational: Out of Service"
                        : flag.value === 2
                        ? "Non-Operational: Under Maintenance"
                        : flag.value === 3
                        ? "Non-Operational: Isolated"
                        : "Loading..."}
                    </Typography>
                    {flag.value !== 0 && <Box>
                    <Typography variant="textDescriptionActive"><b>since: </b>{convertUTCtoZone(props.batteryFlagTime)}</Typography>
                    </Box>}
                  </Box>

                      {props.editFlag ? <IconButton onClick={()=>handleModalOpen()}>
                  <BorderColorOutlinedIcon color="primary" sx={{fontSize:"18px"}}/>
                  </IconButton> : null}

                  

                </Box>
              </Box>
            ) : (
              <>
                <Box>
                  <Typography variant="textSubtitleBold">{assetID}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {modal ? (
        <>
          <FlagTheBattery
            modal={modal}
            handleModalClose={handleModalClose}
            flag={flag}
            confirmBatteryFlag={confirmBatteryFlag}
          />
        </>
      ) : null}
    </>
  );
}

export default SimplePageHeader;
