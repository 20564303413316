export const SetDiagnosticsColumns = (columns) =>
{
    return {
        type: "DiagnosticsColumns",
        value: columns
    }
}
export const SetDiagnosticsHiddenColumns = (hidden) =>
{
    return {
        type: "DiagnosticsHiddenColumns",
        value: hidden
    }
}
export const SetIsBatteryIsPartnerIsNullDiagnostics = (value) =>
{
    return {
        type: "IsBatteryIsPartnerIsNullDiagnostics",
        value: value
    }
}
export const SetDiagnosticsBatteryPartnerSelect = (value) =>
{
    return {
        type: "DiagnosticsBatteryPartnerSelect",
        select: value
    }
}
export const SetDiagnosticsBatteryID = (batteryID) =>
{
    return {
        type: "DiagnosticsBatteryID",
        batteryID: batteryID
    }
}
export const SetDiagnosticsPartner = (partner) =>
{
    return {
        type: "DiagnosticsPartner",
        partner: partner
    }
}

export const SetDiagnosticsVolt = (volt) =>
{
    return {
        type: "DiagnosticsVolt",
        volt: volt
    }
}
export const SetDiagnosticsCity = (city) =>
{
    return {
        type: "DiagnosticsCity",
        city: city
    }
}
export const SetDiagnosticsZone = (zone) =>
{
    return {
        type: "DiagnosticsZone",
        zone: zone
    }
}

export const SetDiagnosticsIOT = (Iot) =>
{
    return {
        type: "DiagnosticsIOT",
        Iot: Iot
    }
}

export const SetDiagnosticsPack = (pack) =>
{
    return {
        type: "DiagnosticsPack",
        pack: pack
    }
}

export const SetDiagnosticsVersion = (version) =>
{
    return {
        type: "DiagnosticsVersion",
        version: version
    }
}
export const SetDiagnosticsBuildNo = (buildNo) =>
{
    return {
        type: "DiagnosticsBuildNo",
        payload: buildNo
    }
}
export const SetDiagnosticsStatus = (status) =>
{
    return {
        type: "DiagnosticsStatus",
        status: status
    }
}

export const SetDiagnosticsTabValue = (tab) =>
{
    return {
        type: "DiagnosticsTabValue",
        tab: tab
    }
}

export const SetDiagnosticsErrorTypeIndex = (index) =>
{
    return {
        type: "DiagnosticsErrorTypeIndex",
        index: index
    }
}

export const SetDiagnosticsErrorTypeCode = (code) =>
{
    return {
        type: "DiagnosticsErrorTypeCode",
        code: code
    }
}

export const SetDiagnosticsTotal = (total) =>
{
    return {
        type: "DiagnosticsTotal",
        total: total
    }
}

export const SetDiagnosticsStartDate = (start) =>
{
    return {
        type: "DiagnosticsStartDate",
        start: start
    }
}

export const SetDiagnosticsEndDate = (end) =>
{
    return {
        type: "DiagnosticsEndDate",
        end: end
    }
}

export const SetDiagnosticsErrorTypeList = (list) =>
{
    return {
        type: "DiagnosticsErrorTypeList",
        list: list
    }
}

export const SetDiagnosticsListOfBatteries = (list) =>
{
    return {
        type: "DiagnosticsListOfBatteries",
        list: list
    }
}

export const SetDiagnosticsStack = (batteries) =>
{
    return{
        type: "DiagnosticsStack",
        batteries:batteries
    }
}

export const SetDiagnosticsColumnSort = (sort) =>
{
    return{
        type: "DiagnosticsColumnSort",
        sort:sort
    }
}

export const SetDiagnosticsPageNumber = (page) =>
{
    return {
        type: "DiagnosticsPageNumber",
        page: page
    }
}

export const SetDiagnosticsRowsPerPage = (rows) =>
{
    return {
        type: "DiagnosticsRowsPerPage",
        rows: rows
    }
}

export const SetDiagnosticsOpenAddSelectedBatteries = (open) =>
{
    return {
        type: "DiagnosticsOpenAddSelectedBatteries",
        open: open
    }
}