import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useDispatch, useSelector } from "react-redux";
import { SetMapClusterClick, SetMapSearchedBattery } from "../../Actions/Map";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Events from "../../Analytics/Events";
import FindTheColor from "../../Helper/Operations/FindTheColor";

function ListMapFoundBatteries(props) {
  const dispatch = useDispatch();
  const mapSearchedBattery = useSelector(
    (state) => state.MapSearchedBattery.value
  );

  const goToViolation = (id) => {
    var myElement = document.getElementById(id);
    var topPos = myElement.offsetTop - 62;
    document.getElementById("scrolling_div").scrollTop = topPos;
  };
  useEffect(() => {
    props.clickedMapBattery &&
      mapSearchedBattery !== "" &&
      goToViolation(mapSearchedBattery);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSearchedBattery]);

  return (
    <Box
      sx={{
        border:
          mapSearchedBattery === props.data.batteryID
            ? `1px solid ${ThemeProperties.purple}`
            : "1px solid lightGrey",
        m: 1,
        p: 1,
        borderRadius: "4px",
        "&:hover": {
          border: `1px solid ${ThemeProperties.purple}`,
          cursor: "pointer",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      id={`${props.data.batteryID}`}
      onClick={() => {
        props.setClickedMapBattery(false);
        dispatch(SetMapSearchedBattery(props.data.batteryID));
        dispatch(SetMapClusterClick(true));
        Events("Map clicked a battery inside expanded panel");
      }}
    >

      <CircleRoundedIcon sx={{ color: FindTheColor(props.data.severity),fontSize:"13px",mr:2.5 }} />
      <Typography
        variant={
          mapSearchedBattery === props.data.batteryID
            ? "textSubtitlePurple"
            : "textSubtitle"
        }
      >
        {props.data.assetID}
      </Typography>
    </Box>
  );
}

export default ListMapFoundBatteries;
