import React, { useEffect, useState } from "react";
import MultipleSearchAutocomplete from ".";
import {
  SetAllBatteriesUserInputBatteries,
  SetAllBatteriesPageNumber,
  SetAllBatteriesFoundBatteries,
  SetAllBatteriesNotFoundBatteries,
} from "../../../Actions/AllBatteries";
import { SetMapFoundBatteries, SetMapNotFoundBatteries, SetMapUserInputBatteries } from "../../../Actions/Map";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MultipleSearchState(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [foundBatteries, setFoundBatteries] = useState([]);
  const [notFoundBatteries, setNotFoundBatteries] = useState([]);

  const foundBatteriesRedux = useSelector(
    (state) => state.AllBatteriesFoundBatteries.value
  );
  const notFoundBatteriesRedux = useSelector(
    (state) => state.AllBatteriesNotFoundBatteries.value
  );

  const foundBatteriesMapRedux = useSelector(
    (state) => state.MapFoundBatteries.value
  );
  const notFoundBatteriesMapRedux = useSelector(
    (state) => state.MapNotFoundBatteries.value
  );

  function handleInputBatteries(batteries) {
    let temp;
    let queryParams = decryptTheParams();

    dispatch(SetAllBatteriesPageNumber(1));
    if (props.type === "Advance Search") {
      dispatch(SetAllBatteriesUserInputBatteries(batteries));
      if(batteries.length===0)
      {
        dispatch(SetAllBatteriesFoundBatteries([]));
        dispatch(SetAllBatteriesNotFoundBatteries([]))
      }
    } else {
      dispatch(SetMapUserInputBatteries(batteries));
      if(batteries.length===0)
      {
        dispatch(SetMapFoundBatteries([]));
        dispatch(SetMapNotFoundBatteries([]))
      }

      temp = {
        ...queryParams,
        userInputMapBatteries: batteries,
      };
      encryptTheParams(temp, navigate);
    }
  }

  useEffect(() => {
    if (props.type === "Advance Search") {
      setFoundBatteries(foundBatteriesRedux);
      setNotFoundBatteries(notFoundBatteriesRedux);
    } else {
      setFoundBatteries(foundBatteriesMapRedux);
      setNotFoundBatteries(notFoundBatteriesMapRedux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    foundBatteriesRedux,
    notFoundBatteriesRedux,
    foundBatteriesMapRedux,
    notFoundBatteriesMapRedux,
  ]);

  return (
    <MultipleSearchAutocomplete
      handleInputBatteries={handleInputBatteries}
      foundBatteries={foundBatteries}
      notFoundBatteries={notFoundBatteries}
      type={props.type}
      readOnly={props.readOnly}
      limitTags={props.limitTags}
      maxHeight={props.maxHeight}
      openNotification={props.openNotification}
    />
  );
}

export default MultipleSearchState;

// batteriesNotFound={props.batteriesNotFound}
// setMultipleBatteries={props.setMultipleBatteries}
