import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import IconContainer from "../../Helper/Containers/IconContainer";

export default class MultipleItems extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    var settings = {
      dots: false,
      infinite: false,
      //speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
      //autoplay: false,
      //autoplaySpeed: 2000,
      centerMode: false,
    };
    return (
      <div style={{ margin: "0px 35px 0px 35px" }}>
        <Slider {...settings}>
          {this.props.tabs.map((data, index) => (
            <div key={index}>
              <>
                {data.isActive ? (
                  <Box>
                    <IconContainer
                      // type={data.type}
                      key={index}
                      src={data.src}
                      text={data.text}
                      to={data.to}
                    />
                  </Box>
                ) : (
                  <Box sx={{ opacity: 0.4 }}>
                    <IconContainer
                      // type={data.type}
                      key={index}
                      src={data.src}
                      text={data.text}
                      to={"/"}
                    />
                  </Box>
                )}
              </>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
