import Cookies from "universal-cookie";

function NameTheCSV(page,component) {
  
  const cookies = new Cookies();

  const userName = cookies.get("name").split(" ");

  const mynewTime = Math.round((new Date().getTime())/1000);

  const name = component.split(" ");

  const name2 = name.join("_");

  return [mynewTime,userName[0],page,name2].join("_");
}

export default NameTheCSV;

