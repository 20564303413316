import GlobalBatterySearch from "./GlobalBattery/GlobalBatterySearch";
import GlobalBatteryTypedValue from "./GlobalBattery/GlobalBatteryTypedValue";
import DeletedFromCart from "./DeletedFromCart";
import CustomOrder from "./CustomSortOrder/CustomOrder";

import { PassChangedSuccess } from "./Login";

import {
  StackTableOrder,
  StackTableSort,
  StackTableRowsPerPage,
} from "./BatteriesInStack";
import {
  AllBatteriesColumns,
  AllBatteriesHiddenColumns,
  AllBatteriesRowsPerPage,
  AllBatteriesState,
  AllBatteriesVolt,
  AllBatteriesSeverity,
  AllBatteriesCity,
  AllBatteriesZone,
  AllBatteriesStatus,
  AllBatteriesIOT,
  AllBatteriesPack,
  AllBatteriesBusiness,
  AllBatteriesMultiplePartners,
  AllBatteriesMultiplePartnersDropdown,
  AllBatteriesMultiplePartnersSelectAll,
  AllBatteriesUserInputBatteries,
  AllBatteriesFoundBatteries,
  AllBatteriesNotFoundBatteries,
  AllBatteriesBatteryPartnerSelect,
  AllBatteriesPageNumber,
  AllBatteriesStack,
  KPISort,
} from "./AllBatteries";

import {
  SessionExpired,
  RefreshAPICalled,
  DashboardType,
  DashboardColumns,
  DashboardHiddenColumns,
  IsBatteryIsPartnerIsNull,
  DashboardBatteryPartnerSelect,
  DashboardBatteryID,
  DashboardDeviceID,
  DashboardPartner,
  DashboardVolt,
  DashboardCategory,
  DashboardCity,
  DashboardZone,
  DashboardStatus,
  DashboardSeverity,
  DashboardIOT,
  DashboardPack,
  DashboardTabValue,
  DashboardAlertTypeIndex,
  DashboardAlertTypeCode,
  DashboardTotal,
  DashboardStartDate,
  DashboardEndDate,
  DashboardAlertTypeList,
  DashboardListOfBatteries,
  DashboardStack,
  DashboardExistingBatteries,
  DashboardColumnSort,
  DashboardPageNumber,
  DashboardRowsPerPage,
  DashboardOpenAddSelectedBatteries,
  DashboardSelectedBatteriesAction,
} from "./Dashboard";

import {
  IsBatteryIsPartnerIsNullDiagnostics,
  DiagnosticsBatteryPartnerSelect,
  DiagnosticsColumns,
  DiagnosticsHiddenColumns,
  DiagnosticsBatteryID,
  DiagnosticsPartner,
  DiagnosticsVolt,
  DiagnosticsCity,
  DiagnosticsZone,
  DiagnosticsPack,
  DiagnosticsIOT,
  DiagnosticsVersion,
  DiagnosticsBuildNo,
  DiagnosticsStatus,
  DiagnosticsTabValue,
  DiagnosticsErrorTypeIndex,
  DiagnosticsErrorTypeCode,
  DiagnosticsTotal,
  DiagnosticsStartDate,
  DiagnosticsEndDate,
  DiagnosticsErrorTypeList,
  DiagnosticsListOfBatteries,
  DiagnosticsStack,
  DiagnosticsSort,
  DiagnosticsPageNumber,
  DiagnosticsRowsPerPage,
  DiagnosticsOpenAddSelectedBatteries
} from "./Diagnostics";

import {
  AnomalyColumns,
  AnomalyHiddenColumns,
  IsBatteryIsPartnerIsNullAnomaly,
  AnomalyBatteryID,
  AnomalyPartner,
  AnomalyVolt,
  AnomalyCell,
  AnomalyCity,
  AnomalyZone,
  AnomalyBuildNo,
  AnomalyVersion,
  AnomalyStatus,
  AnomalyTabValue,
  AnomalyIndex,
  AnomalyCode,
  AnomalyTotal,
  AnomalyStartDate,
  AnomalyEndDate,
  AnomalyList,
  AnomalyListOfBatteries,
  AnomalyStack,
  AnomalyColumnSort,
  AnomalyPageNumber,
  AnomalyRowsPerPage,
} from "./Anomaly";

import {
  IsBatteryIsPartnerIsNullWarranty,
  WarrantyColumns,
  WarrantyHiddenColumns,
  WarrantyBatteryID,
  WarrantyPartner,
  WarrantyVolt,
  WarrantyCity,
  WarrantyCell,
  WarrantyBusiness,

  WarrantyIOT,
  WarrantyAge,
  WarrantyCycle,
  WarrantyPack,
  WarrantySOH,

  WarrantyBuildNo,
  WarrantyVersion,
  WarrantyStatus,
  WarrantyTabValue,
  WarrantyIndex,
  WarrantyCode,
  WarrantyTotal,
  WarrantyStartDate,
  WarrantyEndDate,
  WarrantyList,
  WarrantyListOfBatteries,
  WarrantyStack,
  WarrantyColumnSort,
  WarrantyColumnSortOrder,
  WarrantyPageNumber,
  WarrantyRowsPerPage,
  WarrantyOpenAddSelectedBatteries
} from "./Warranty";

import {
  AlertAnalyticsIOT,
    AlertAnalyticsPack,
    AlertAnalyticsStartDate,
    AlertAnalyticsEndDate,
    AlertAnalyticsGranularity
} from "./Reports/AlertAnalytics";

import {
  ReportsDQIOT,
  ReportsDQPack,
  ReportsDQStartDate,
  ReportsDQEndDate,
  ReportsDQGranularity
} from "./Reports/DataQuality";

import {
  BatteryHealthCycleAge,
  BatteryHealthToggleTable,
  BatteryHealthEqCycle,
  BatteryHealthSOH,
  BatteryHealthCell,
  BatteryHealthVolt,
  BatteryHealthBusiness,
  BatteryHealthIOT,
  BatteryHealthPack,
  BatteryHealthRUL,
  BatteryHealthBatteryPartnerSelect,
  BatteryHealthBatteryID,
  BatteryHealthDeviceID,
  BatteryHealthPartner,
  BatteryHealthColumns,
  BatteryHealthSort,
  BatteryHealthSortOrder,
  BatteryHealthPageNumber,
  BatteryHealthRowsPerPage
} from "./BatteryHealth";

import {
  SpecificBatteryDateDisable,
  SpecificBatteryCarouselDate,
  SpecificBatteryDateInvalid,
} from "./SpecificBattery";

import {
  MapBatteryPartnerSelect,
  MapVolt,
  MapCity,
  MapStatus,
  MapSeverity,
  MapZone,
  MapAlertType,
  MapIotVendor,
  MapBatteryVendor,
  MapUserInputBatteries,
  MapFoundBatteries,
  MapNotFoundBatteries,
  MapMultiplePartners,
  MapFullScreen,
  MapSearch,
  MapSearchedBattery,
  MapClusterClick,
} from "./Map";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  PassChangedSuccess,

  GlobalBatterySearch,
  GlobalBatteryTypedValue,

  DeletedFromCart,
  DashboardStack,
  DashboardExistingBatteries,
  DiagnosticsStack,
  AllBatteriesStack,
  CustomOrder,
  DiagnosticsSort,
  DashboardColumnSort,
  KPISort,

  StackTableOrder,
  StackTableSort,
  StackTableRowsPerPage,

  AllBatteriesColumns,
  AllBatteriesHiddenColumns,
  AllBatteriesRowsPerPage,
  AllBatteriesState,
  AllBatteriesVolt,
  AllBatteriesCity,
  AllBatteriesSeverity,
  AllBatteriesZone,
  AllBatteriesStatus,
  AllBatteriesIOT,
  AllBatteriesPack,
  AllBatteriesMultiplePartners,
  AllBatteriesMultiplePartnersDropdown,
  AllBatteriesMultiplePartnersSelectAll,
  AllBatteriesUserInputBatteries,
  AllBatteriesFoundBatteries,
  AllBatteriesNotFoundBatteries,
  AllBatteriesBatteryPartnerSelect,
  AllBatteriesPageNumber,
  AllBatteriesBusiness,

  MapBatteryPartnerSelect,
  MapVolt,
  MapCity,
  MapStatus,
  MapSeverity,
  MapZone,
  MapAlertType,
  MapIotVendor,
  MapBatteryVendor,
  MapUserInputBatteries,
  MapFoundBatteries,
  MapNotFoundBatteries,
  MapMultiplePartners,
  MapFullScreen,
  MapSearch,
  MapSearchedBattery,
  MapClusterClick,

  SessionExpired,
  RefreshAPICalled,
  DashboardType,
  DashboardColumns,
  DashboardHiddenColumns,
  IsBatteryIsPartnerIsNull,
  DashboardBatteryPartnerSelect,
  DashboardBatteryID,
  DashboardDeviceID,
  DashboardPartner,
  DashboardVolt,
  DashboardCategory,
  DashboardCity,
  DashboardSeverity,
  DashboardIOT,
  DashboardPack,
  DashboardZone,
  DashboardStatus,
  DashboardTabValue,
  DashboardAlertTypeIndex,
  DashboardAlertTypeCode,
  DashboardTotal,
  DashboardStartDate,
  DashboardEndDate,
  DashboardAlertTypeList,
  DashboardListOfBatteries,
  DashboardPageNumber,
  DashboardRowsPerPage,
  DashboardOpenAddSelectedBatteries,
  DashboardSelectedBatteriesAction,

  IsBatteryIsPartnerIsNullDiagnostics,
  DiagnosticsBatteryPartnerSelect,
  DiagnosticsColumns,
  DiagnosticsHiddenColumns,
  DiagnosticsBatteryID,
  DiagnosticsPartner,
  DiagnosticsVolt,
  DiagnosticsCity,
  DiagnosticsZone,
  DiagnosticsPack,
  DiagnosticsIOT,
  DiagnosticsVersion,
  DiagnosticsBuildNo,
  DiagnosticsStatus,
  DiagnosticsTabValue,
  DiagnosticsErrorTypeIndex,
  DiagnosticsErrorTypeCode,
  DiagnosticsTotal,
  DiagnosticsStartDate,
  DiagnosticsEndDate,
  DiagnosticsErrorTypeList,
  DiagnosticsListOfBatteries,
  DiagnosticsPageNumber,
  DiagnosticsRowsPerPage,
  DiagnosticsOpenAddSelectedBatteries,

  AnomalyColumns,
  AnomalyHiddenColumns,
  IsBatteryIsPartnerIsNullAnomaly,
  AnomalyBatteryID,
  AnomalyPartner,
  AnomalyVolt,
  AnomalyCell,
  AnomalyCity,
  AnomalyZone,
  AnomalyBuildNo,
  AnomalyVersion,
  AnomalyStatus,
  AnomalyTabValue,
  AnomalyIndex,
  AnomalyCode,
  AnomalyTotal,
  AnomalyStartDate,
  AnomalyEndDate,
  AnomalyList,
  AnomalyListOfBatteries,
  AnomalyStack,
  AnomalyColumnSort,
  AnomalyPageNumber,
  AnomalyRowsPerPage,

  WarrantyColumns,
  WarrantyHiddenColumns,
  IsBatteryIsPartnerIsNullWarranty,
  WarrantyBatteryID,
  WarrantyPartner,
  WarrantyVolt,
  WarrantyCity,
  WarrantyCell,

  WarrantyIOT,
  WarrantyAge,
  WarrantyCycle,
  WarrantyPack,
  WarrantySOH,
  
  WarrantyBusiness,
  WarrantyBuildNo,
  WarrantyVersion,
  WarrantyStatus,
  WarrantyTabValue,
  WarrantyIndex,
  WarrantyCode,
  WarrantyTotal,
  WarrantyStartDate,
  WarrantyEndDate,
  WarrantyList,
  WarrantyListOfBatteries,
  WarrantyStack,
  WarrantyColumnSort,
  WarrantyColumnSortOrder,
  WarrantyPageNumber,
  WarrantyRowsPerPage,
  WarrantyOpenAddSelectedBatteries,

  AlertAnalyticsIOT,
  AlertAnalyticsPack,
  AlertAnalyticsStartDate,
  AlertAnalyticsEndDate,
  AlertAnalyticsGranularity,

  ReportsDQIOT,
  ReportsDQPack,
  ReportsDQStartDate,
  ReportsDQEndDate,
  ReportsDQGranularity,

  BatteryHealthCycleAge,
  BatteryHealthToggleTable,
  BatteryHealthEqCycle,
  BatteryHealthSOH,
  BatteryHealthCell,
  BatteryHealthVolt,
  BatteryHealthBusiness,
  BatteryHealthIOT,
  BatteryHealthPack,
  BatteryHealthRUL,
  BatteryHealthBatteryPartnerSelect,
  BatteryHealthBatteryID,
  BatteryHealthDeviceID,
  BatteryHealthPartner,
  BatteryHealthColumns,
  BatteryHealthPageNumber,
  BatteryHealthRowsPerPage,

  SpecificBatteryDateDisable,
  SpecificBatteryCarouselDate,
  SpecificBatteryDateInvalid,
  BatteryHealthSort,
  BatteryHealthSortOrder
});

export default rootReducer;
