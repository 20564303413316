import React, { useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "universal-cookie";
import { OutlinedTextField } from "../Components/CustomTextField";
import { getOrgInfo } from "../Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CoulombLogo from "../Assets/Icons/CoulombLogo.svg";

import "./Login.css";
import { ThemeProperties } from "../Theme/ThemeProperties";

function Copyright(props) {
  return (
    <Typography align="center" {...props} sx={{ fontSize: "12px", mt: 2 }}>
      {"Copyright © "}
      <Link color="inherit" href="https://coulomb.ai/">
        Coulomb AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login(props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const isPassReset = useSelector((state) => state.PassChangedSuccess.value);

  const formatInputUserName = (event) => {
    setUsername(event.target.value.trim());
  };

  const formatInputPassword = (event) => {
    setPassword(event.target.value.trim());
  };

  const [errorText, setErrorText] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    var credentials = JSON.stringify({
      username: data.get("email").trim(),
      password: data.get("password").trim(),
    });

    var config = {
      method: "post",
      url: "/api/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: credentials,
    };

    axios(config)
      .then((res) => {
        if (res.data.responseStatus.code === 200) {
          cookies.remove("finalCall", { path: "/" });
          cookies.set("name", res.data.loginData.name, { path: "/" });
          setErrorText("");
          // Events(res.data.loginData.name+" Logged In")
        } else {
          setErrorText("Invalid Login Id or password");
        }
        return res.data.responseStatus.code;
      })
      .then((code) => {
        if (code === 200) {
          axios
            .get("/api/page_access")
            .then((res) => {
              if (res.data.responseStatus.code === 200) {
                const myPages = [
                  {
                    tabs: [
                      {
                        to: "/Alerts",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Alerts_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Alerts_white.svg",
                        text: "Alerts",
                        mykey: "Alerts",
                        isActive: true,
                        queryParam: {},
                      },
                      {
                        to: "/Map",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Map_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Map_white.svg",
                        text: "Map",
                        mykey: "Map",
                        isActive: true,
                        queryParam: {},
                      },
                    ],
                    title: "Real-Time Monitoring",
                  },
                  {
                    tabs: [
                      {
                        to: "/BatteryHealth",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/BatteryHealth_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/BatteryHealth_white.svg",
                        text: "Battery Health",
                        mykey: "BatteryHealth",
                        isActive: true,
                        queryParam: {},
                      },
                      {
                        to: "/Reports",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Reports_Blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Reports_White.svg",
                        text: "Reports",
                        mykey: "Reports",
                        isActive: true,
                        queryParam: {},
                      },
                      {
                        to: "/Diagnostics",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Diagnostics_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Diagnostics_white.svg",
                        text: "Diagnostics",
                        mykey: "Diagnostics",
                        isActive: true,
                        queryParam: {},
                      },
                      // {
                      //   to: "/Anomaly",
                      //   src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Anomaly_blue.svg",
                      //   src_white:
                      //     "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Anomaly_white.svg",
                      //   text: "Anomaly",
                      //   mykey: "Anomaly",
                      //   isActive: false,
                      //   queryParam: {},
                      // },
                      {
                        to: "/Warranty",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Warranty_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Warranty_white.svg",
                        text: "Warranty",
                        mykey: "Warranty",
                        isActive: true,
                        queryParam: {},
                      },
                    ],
                    title: "Diagnosis & Management",
                  },
                  {
                    tabs: [
                      {
                        to: "/OperationalStrategies",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Operational_outline.png",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Operational_outline.png",
                        text: "Operational stra.",
                        mykey: "OperationalStrategies",
                        isActive: false,
                        queryParam: {},
                      },
                      {
                        to: "/Lifetime",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/LifeTime_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/LifeTime_blue.svg",
                        text: "Lifetime",
                        mykey: "Lifetime",
                        isActive: false,
                        queryParam: {},
                      },
                      {
                        to: "/SecondLife",
                        src: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/SecondLife_blue.svg",
                        src_white:
                          "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/SecondLife_blue.svg",
                        text: "Second Life",
                        mykey: "SecondLife",
                        isActive: false,
                        queryParam: {},
                      },
                    ],
                    title: "Predictive Analytics",
                  },
                ];

                const newPagesMeta = [];
                myPages !== undefined && //res.data.response.details.pageLinks
                  myPages.forEach((item) => {
                    //res.data.response.details.pageLinks
                    item["tabs"].map((page) => newPagesMeta.push(page));
                  });
                cookies.set(
                  "pages",
                  [
                    "Landing",
                    "Alerts",
                    "SpecificBattery",
                    "AllBatteries",
                    "Map",
                    "BatteryHealth",
                    "Reports",
                    "Warranty",
                    "Diagnostics"
                  ],
                  { path: "/" }
                ); //res.data.response.pages
                cookies.set("pagesMeta", newPagesMeta, { path: "/" });
                cookies.set("navigationCard", myPages, { path: "/" }); //res.data.response.details.pageLinks
              }
            })
            .then(() => {
              getOrgInfo().then((res) => {
                if (res.responseStatus.code === 200) {
                  cookies.set("orgName", res.response.orgName, { path: "/" });
                  cookies.set("sessionId", new Date().getTime() / 1000, {
                    path: "/",
                  });
                  cookies.set("amplitudeID", res.response.amplitudeID, {
                    path: "/",
                  });
                  cookies.set("timeZone", res.response.timeZone, { path: "/" });
                  props.setLoggedIn(!props.LoggedIn);
                }
              });
            });
        }
      })

      .catch(function (error) {});
  };

  return (
    <Box
      sx={{
        display: "table",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        bgcolor: "#F2F2F4",
      }}
    >
      <Box
        sx={{
          display: "table-cell",
          verticalAlign: "middle",
        }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "500px",
            mb: 2,
            //   padding: "0px 56px ",
          }}
        >
          {isPassReset && (
            <div className={`passChangedSuccess`}>
              <Typography variant="textPasswordReset" align="center">
                Password is changed successfully!
              </Typography>
            </div>
          )}
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "center", mb: 2, ml: -0.8 }}
        >
          <img src={CoulombLogo} alt="not found" height={"40px"} />
        </Box>

        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "450px",
            border: "1px solid #DDD7FF",
            borderRadius: "1.2rem",
            backgroundColor: "#fff",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Typography
              variant="paperHeader"
              sx={{
                fontSize: "20px",
              }}
            >
              Log in to Atom
            </Typography>
            <br></br>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <OutlinedTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Login ID"
                value={username}
                onChange={(e) => formatInputUserName(e)}
                name="email"
                autoComplete="email"
                autoFocus
                error={errorText.length === 0 ? false : true}
              />
              <OutlinedTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                value={password || null}
                onChange={(e) => formatInputPassword(e)}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                error={errorText.length === 0 ? false : true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

                // helperText={errorText}
              />
              <Box sx={{ m: "5px 0px 10px" }}>
                <Typography variant="textSubtitleRed">{errorText}</Typography>
              </Box>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography
                    variant="textSubtitle"
                    onClick={() => navigate("/password_reset_2")}
                    sx={{ cursor: "pointer", color: ThemeProperties.purple }}
                  >
                    Reset Password
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="textSubtitle"
                    onClick={() => navigate("/password_reset")}
                    sx={{ cursor: "pointer", color: ThemeProperties.purple }}
                  >
                    Forgot Password?
                  </Typography>
                </div>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Box>
    </Box>
  );
}
