import { ThemeProperties } from "../../../Theme/ThemeProperties"
const Tabs = [
    {
        id:1,
        name:"Active",
        color:ThemeProperties.red_400,
        description: "Needs attention",
        placement:"topLeft"
    },
    {
        id:2,
        name:"Open",
        color:ThemeProperties.blue_400,
        description: "Action taken",
        placement:"topLeft"
    },
    {
        id:3,
        name:"Resolved",
        color:ThemeProperties.green_400,
        description: "Alert recovered",
        placement:"topLeft"
    },
    {
        id:4,
        name:"Ignored",
        color:ThemeProperties.green_400,
        description: "Open for >7 days",
        placement:"topLeft"
    },
    {
        id:5,
        name:"Expired",
        color:ThemeProperties.green_400,
        description: "No more alerting",
        placement:"topLeft"
    }
]

export default Tabs