import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { PTypography } from "../../Components/CustomP";
import moment from "moment";
// import classes from "./Clintele.module.css";
// import TagAndHeading from "../TextArea/TagAndHeading";
// import dots from "../../../assets/images/Home/Clintele/Dots.svg";

export default class SlickCarouselPackMetrics extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    var settings = {
      dots: false,
      infinite: false,
      // speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      //   responsive: [
      //     {
      //       breakpoint: 800,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1,
      //         infinite: true,
      //         dots: true,
      //       },
      //     },
      //   ],
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
      //   autoplay: false,
      //   autoplaySpeed: 2000,
      centerMode: false,
    };
    return (
      <div
        style={{
          margin: this.props.modalData.length > 1 ? "0px 20px 0px 20px" : "0px",
        }}
        className="BatteryPack"
      >
        <Slider {...settings}>
          {this.props.modalData.map((data, index) => (
            <div key={index}>
              
                <Box sx={{ display: "block" }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="tableHead">Alert Seen :</Typography>
                    <Typography variant="textSubtitleSmall">
                      &nbsp;{moment(new Date(data.insertionTime)).format("DD MMM'YY, hh:mm A")}
                    </Typography>
                  </Box>
                  <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                  <Box sx={{ mb: 2,mt:2 }}>
                    <Typography variant="tableHead">Alert Type :</Typography>
                    <Typography variant="textSubtitleSmall">
                      &nbsp;{data.alertDescription}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="tableHead">Severity :</Typography>
                    <Typography variant="textSubtitleSmall">
                      &nbsp;{data.severity}
                    </Typography>
                  </Box>
                  <Box sx={{display:"flex",alignItems:"center"}}>
                    <Typography variant="tableHead">Alert Status :&nbsp;</Typography>
                    <PTypography
                      mycolor={
                        data.alertStatus === "Active"
                          ? ThemeProperties.red_100
                          : data.alertStatus === "Open"
                          ? ThemeProperties.blue_100
                          : data.alertStatus === "Resolved"
                          ? ThemeProperties.green_100
                          : data.alertStatus === "Ignored"
                          ? ThemeProperties.pink_100
                          : ThemeProperties.blueGrey_100
                      }
                    >
                      <Typography variant="tableRow">
                        {data.alertStatus}
                      </Typography>
                    </PTypography>
                  </Box>
                </Box>
              
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
