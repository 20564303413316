import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Box, Typography, Grid } from "@mui/material";
import { Brightness1, HorizontalRule } from "@mui/icons-material";

import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import moment from "moment";
import {
  FindNearestMaxAnalytics,
  FindNextHighestMultipleOfNum,
} from "../../../../../Helper/Operations/FindNearestMaxAnalytics";
import BizStackedLine from "../../../../../Charts/Column/BizStackedLine";

function convertInputToOutput(inputList) {
  const outputList = [];
  for (const item of inputList) {
    const [start, end] = item.split(" - ");
    outputList.push([start + " - ", end]);
  }
  return outputList;
}

function Validity(props) {
  const dispatch = useDispatch();
  const [invalidPingPercent, setInvalidPingPercent] = useState({
    data: [],
    maxTotal: 0,
    maxInvalid: 0,
    maxPercent: 0,
    responseStatus: { code: -1, msg: "" },
  });
  const [statusClickedSignals, setStatusClickedSignals] = useState({
    NumberOfBatteries: true,
    BatWithInvalidSignal: true,
    InvalidSignalPercent: true,
  });
  function handleStatusClickedSignals(status) {
    if (
      statusClickedSignals.NumberOfBatteries &&
      statusClickedSignals.BatWithInvalidSignal &&
      statusClickedSignals.InvalidSignalPercent
    ) {
      if (status === "Total Batteries") {
        setStatusClickedSignals({
          NumberOfBatteries: statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedSignals.InvalidSignalPercent,
        });
      } else if (status === "Batteries with invalid signals") {
        setStatusClickedSignals({
          NumberOfBatteries: !statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedSignals.InvalidSignalPercent,
        });
      } else if (status === "Invalid signals (%)") {
        setStatusClickedSignals({
          NumberOfBatteries: !statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedSignals.InvalidSignalPercent,
        });
      }
    } else {
      if (status === "Total Batteries") {
        if (
          statusClickedSignals.BatWithInvalidSignal ||
          statusClickedSignals.InvalidSignalPercent
        )
        setStatusClickedSignals({
          NumberOfBatteries: !statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedSignals.InvalidSignalPercent,
        });
      } else if (status === "Batteries with invalid signals") {
        if (
          statusClickedSignals.NumberOfBatteries ||
          statusClickedSignals.InvalidSignalPercent
        )
        setStatusClickedSignals({
          NumberOfBatteries: statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedSignals.InvalidSignalPercent,
        });
      } else if (status === "Invalid signals (%)") {
        if (
          statusClickedSignals.NumberOfBatteries ||
          statusClickedSignals.BatWithInvalidSignal
        )
        setStatusClickedSignals({
          NumberOfBatteries: statusClickedSignals.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedSignals.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedSignals.InvalidSignalPercent,
        });
      }
    }
  }
  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Total Batteries",
            color: ThemeProperties.darkerGrey,
            target: statusClickedSignals.NumberOfBatteries,
          },
          {
            status: "Batteries with invalid signals",
            color: "#EF5350",
            target: statusClickedSignals.BatWithInvalidSignal,
          },
          {
            status: "Invalid signals (%)",
            color: "#9D240A",
            target: statusClickedSignals.InvalidSignalPercent,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedSignals(item.status)}
          >
            {item.status === "Invalid signals (%)" ? (
              <HorizontalRule
                style={{
                  fontSize: "24px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: item.color,
                  opacity: item.target ? 1 : 0.4,
                }}
              />
            ) : (
              <Brightness1
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  color: item.color,
                  opacity: item.target ? 1 : 0.4,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  const [invalidLocation, setInvalidLocation] = useState({
    data: [],
    maxTotal: 0,
    maxInvalid: 0,
    maxPercent: 0,
    responseStatus: { code: -1, msg: "" },
  });
  const [statusClickedLocations, setStatusClickedLocations] = useState({
    NumberOfBatteries: true,
    BatWithInvalidSignal: true,
    InvalidSignalPercent: true,
  });

  function handleStatusClickedLocations(status) {
    if (
      statusClickedLocations.NumberOfBatteries &&
      statusClickedLocations.BatWithInvalidSignal &&
      statusClickedLocations.InvalidSignalPercent
    ) {
      if (status === "Total Batteries") {
        setStatusClickedLocations({
          NumberOfBatteries: statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedLocations.InvalidSignalPercent,
        });
      } else if (status === "Batteries with invalid locations") {
        setStatusClickedLocations({
          NumberOfBatteries: !statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedLocations.InvalidSignalPercent,
        });
      } else if (status === "Invalid locations (%)") {
        setStatusClickedLocations({
          NumberOfBatteries: !statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedLocations.InvalidSignalPercent,
        });
      }
    } else {
      if (status === "Total Batteries") {
        if (
          statusClickedLocations.BatWithInvalidSignal ||
          statusClickedLocations.InvalidSignalPercent
        )
        setStatusClickedLocations({
          NumberOfBatteries: !statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedLocations.InvalidSignalPercent,
        });
      } else if (status === "Batteries with invalid locations") {
        if (
          statusClickedLocations.NumberOfBatteries ||
          statusClickedLocations.InvalidSignalPercent
        )
        setStatusClickedLocations({
          NumberOfBatteries: statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: !statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: statusClickedLocations.InvalidSignalPercent,
        });
      } else if (status === "Invalid locations (%)") {
        if (
          statusClickedLocations.NumberOfBatteries ||
          statusClickedLocations.BatWithInvalidSignal
        )
        setStatusClickedLocations({
          NumberOfBatteries: statusClickedLocations.NumberOfBatteries,
          BatWithInvalidSignal: statusClickedLocations.BatWithInvalidSignal,
          InvalidSignalPercent: !statusClickedLocations.InvalidSignalPercent,
        });
      }
    }
  }
  function LegendsLocations() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Total Batteries",
            color: ThemeProperties.darkerGrey,
            target: statusClickedLocations.NumberOfBatteries,
          },
          {
            status: "Batteries with invalid locations",
            color: "#3f51b5",
            target: statusClickedLocations.BatWithInvalidSignal,
          },
          {
            status: "Invalid locations (%)",
            color: "#283593",
            target: statusClickedLocations.InvalidSignalPercent,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedLocations(item.status)}
          >
            {item.status === "Invalid locations (%)" ? (
              <HorizontalRule
                style={{
                  fontSize: "24px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: item.color,
                  opacity: item.target ? 1 : 0.4,
                }}
              />
            ) : (
              <Brightness1
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  color: item.color,
                  opacity: item.target ? 1 : 0.4,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const SetTheInvalidSignals = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempCategories = [];

      let tempData = [];
      data.response.alertList.map((item) => {
        tempData.push({
          invalidSignalPercentage: item.invalidSignalPercentage,
          invalidSignalDevices: item.invalidSignalDevices,
          totalDevices: item.totalDevices,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempData, "startDate");
      let tempMaxTotal = 0,
        tempMaxInvalid = 0,
        tempMaxPercent = 0;

      gy.map((item) => {
        let tempDate = "";
        if (props.granular === "Weekly") {
          tempDate =
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
            " - " +
            moment(new Date(item["values"][0]["endDate"])).format("DD MMM");
        } else if (props.granular === "Monthly") {
          tempDate = moment(new Date(item["values"][0]["startDate"])).format(
            "MMM"
          );
        } else tempDate = moment(new Date(item["key"])).format("DD MMM");

        tempCategories.push({
          Date: tempDate,
          "Invalid Signal Percentage":
            item["values"][0]["invalidSignalPercentage"],
          "invalid signals":
            item["values"][0]["invalidSignalDevices"],
          "Total Devices": item["values"][0]["totalDevices"],
        });

        item["values"].map((item) => {
          if (item["totalDevices"] > tempMaxTotal)
            tempMaxTotal = item["totalDevices"];

          if (item["invalidSignalDevices"] > tempMaxInvalid)
            tempMaxInvalid = item["invalidSignalDevices"];

          if (item["invalidSignalPercentage"] > tempMaxPercent)
            tempMaxPercent = item["invalidSignalPercentage"];
        });
      });

      setInvalidPingPercent({
        data: tempCategories,
        maxTotal: tempMaxTotal,
        maxInvalid: tempMaxInvalid,
        maxPercent: tempMaxPercent,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setInvalidPingPercent({
        data: [],
        maxTotal: 0,
        maxInvalid: 0,
        maxPercent: 0,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheInvalidLocations = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempCategories = [];

      let tempData = [];
      data.response.alertList.map((item) => {
        tempData.push({
          invalidSignalPercentage: item.invalidSignalPercentage,
          invalidSignalDevices: item.invalidSignalDevices,
          totalDevices: item.totalDevices,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempData, "startDate");
      let tempMaxTotal = 0,
        tempMaxInvalid = 0,
        tempMaxPercent = 0;

      gy.map((item) => {
        let tempDate = "";
        if (props.granular === "Weekly") {
          tempDate =
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
            " - " +
            moment(new Date(item["values"][0]["endDate"])).format("DD MMM");
        } else if (props.granular === "Monthly") {
          tempDate = moment(new Date(item["values"][0]["startDate"])).format(
            "MMM"
          );
        } else tempDate = moment(new Date(item["key"])).format("DD MMM");

        tempCategories.push({
          Date: tempDate,
          "Invalid Signal Percentage":
            item["values"][0]["invalidSignalPercentage"],
          "invalid signals":
            item["values"][0]["invalidSignalDevices"],
          "Total Devices": item["values"][0]["totalDevices"],
        });

        item["values"].map((item) => {
          if (item["totalDevices"] > tempMaxTotal)
            tempMaxTotal = item["totalDevices"];

          if (item["invalidSignalDevices"] > tempMaxInvalid)
            tempMaxInvalid = item["invalidSignalDevices"];

          if (item["invalidSignalPercentage"] > tempMaxPercent)
            tempMaxPercent = item["invalidSignalPercentage"];
        });
      });

      setInvalidLocation({
        data: tempCategories,
        maxTotal: tempMaxTotal,
        maxInvalid: tempMaxInvalid,
        maxPercent: tempMaxPercent,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setInvalidLocation({
        data: [],
        maxTotal: 0,
        maxInvalid: 0,
        maxPercent: 0,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setInvalidPingPercent({
      data: [],
      maxTotal: 0,
      maxInvalid: 0,
      maxPercent: 0,
      responseStatus: { code: -1, msg: "" },
    });

    setInvalidLocation({
      data: [],
      maxTotal: 0,
      maxInvalid: 0,
      maxPercent: 0,
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetTheInvalidSignals = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/invalid_signals`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheInvalidSignals(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setInvalidPingPercent({
            ...invalidPingPercent,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setInvalidPingPercent({
            ...invalidPingPercent,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/invalid_signals`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res, true);
              SetTheInvalidSignals(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setInvalidPingPercent({
                  ...invalidPingPercent,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };

    const GetTheInvalidLocations = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/invalid_locations`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheInvalidLocations(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setInvalidLocation({
            ...invalidLocation,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setInvalidLocation({
            ...invalidLocation,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/invalid_locations`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res, true);
              SetTheInvalidLocations(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setInvalidLocation({
                  ...invalidLocation,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };

    GetTheInvalidSignals();
    GetTheInvalidLocations();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
  ]);
  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Invalid Signals")}
        >
          <Header
            heading="Invalid signals"
            description="Invalid Signals"
            showIButton={true}
            showThreeDots={false}
            id="Invalid_Signals"
            page="_Reports/DataQuality"
          />
          <div
            className="section"
            id="Invalid_Signals"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
              <BizStackedLine
                data={invalidPingPercent}
                xaxisType={props.granular}
                statusClicked={statusClickedSignals}
                total={"Number of batteries"}
                partOfTotal={"Bateries with invalid signals"}
                totalColor={ThemeProperties.darkerGrey}
                partOfTotalColor={"#EF5350"}
                line={"Invalid signals (%)"}
                lineColor={"#9D240A"}
              />
            </div>
            <div style={{ paddingLeft: "20px" }}>
              {invalidPingPercent.responseStatus.code === 200 && <Legends />}
            </div>
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Invalid Location")}
        >
          <Header
            heading="Invalid location"
            description="Invalid Location"
            showIButton={true}
            showThreeDots={false}
            id="Invalid_Location"
            page="_Reports/DataQuality"
          />
          <div
            className="section"
            id="Invalid_Location"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
            <BizStackedLine
                data={invalidLocation}
                xaxisType={props.granular}
                statusClicked={statusClickedLocations}
                total={"Number of batteries"}
                partOfTotal={"Bateries with invalid locations"}
                totalColor={ThemeProperties.darkerGrey}
                partOfTotalColor={"#3f51b5"}
                line={"Invalid locations (%)"}
                lineColor={"#283593"}
              />
            </div>
            <div style={{ paddingLeft: "20px" }}>
              {invalidLocation.responseStatus.code === 200 && <LegendsLocations />}
            </div>
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default Validity;
