import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./notification.css";
import notificationIcon from "../../Assets/Icons/NotificationIcon.png";

function NotificationData(props) {
  const [deleted, setDeleted] = useState("");
  const [nonDeleted, setNonDeleted] = useState(10000);
  const [data, setData] = useState([{ id: 1 }]);

  const handleNotificationClose = async (key, document, e) => {
    setDeleted(document.id);
    setNonDeleted(key);

    let arr;
    setTimeout(() => {
      arr = data.filter((ele, i) => i !== key);
      props.setData(arr);
      setData(() => {
        e.stopPropagation();
        return arr;
      });
      setNonDeleted(100000);
    }, 200);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      {data.length > 0 && (
        <div className="notiItemContainer">
          {[
            ...data.map((ele, i) => {
              return (
                <div
                  className={
                    deleted === ele.id
                      ? "notiItemDiv deleted"
                      : nonDeleted < i
                      ? "notiItemDiv non_deleted"
                      : "notiItemDiv  "
                  }
                  key={i}
                >
                  <div className="notiIcon">
                    <img src={notificationIcon} alt="not found"></img>
                  </div>
                  <div className="notiContent">
                    <div className="notiContentHeading">
                      <p>index is {ele.id}</p>
                    </div>

                    <div className="notiContentBody">
                      <p>lorem ipsum</p>
                    </div>
                  </div>

                  <div className="notiIcon">
                    <CloseIcon
                      className="notiCloseIcon"
                      onClick={(e) => {
                        handleNotificationClose(i, ele, e);
                      }}
                    />
                  </div>
                </div>
              );
            }),
          ]}
        </div>
      )}
    </>
  );
}

export default NotificationData;
