import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)({
    "& .MuiInputLabel-outlined": {
      fontSize: 13,
    },
    "& .MuiMenuItem-root": {
      fontSize: "1.3rem",
    },
  });

  const CustomTextFieldToolbar = styled(TextField)({
    "& .MuiInputLabel-outlined": {
      fontSize: 13,
    },
    "& .MuiMenuItem-root": {
      fontSize: "1.3rem",
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#463dbb',
      },
      '&:hover fieldset': {
        borderColor: '#463dbb',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#463dbb',
      },
    },
  });

const OutlinedTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      overflow: "hidden",
      borderRadius: "1rem",
      backgroundColor: "#fff",
      fontSize: 16,
      fontFamily:"Roboto",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&.Mui-focused": {},
    },
    "& .MuiInputLabel-root": {
      fontSize: 13,
      fontFamily:"Roboto",
    },
    "& .MuiFormHelperText-root":
    {
      fontSize: 13,
      fontFamily:"Roboto",
    }
  }));

    const OutlinedTextFieldCalendar = styled((props) => <TextField {...props} />)(
      ({ theme,borderradius }) => ({
    "& .MuiOutlinedInput-root": {
      overflow: "hidden",
      borderRadius: borderradius,
      backgroundColor: "#fff",
      fontSize: 12,
      fontWeight:500,
      width:130,
      fontFamily:"Roboto",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&.Mui-focused": {
        borderColor:"white"
      },
      
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor:"white"
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
        border:"none",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "white",
      },
    "& .MuiInputLabel-root": {
      fontSize: 13,
      fontFamily:"Roboto",
      marginTop:"0px",
      backgroundColor:"white"
    },
    "& .MuiFormHelperText-root":
    {
      fontSize: 13,
      fontFamily:"Roboto",
    }
  }));

export {CustomTextField, OutlinedTextField, OutlinedTextFieldCalendar,CustomTextFieldToolbar}