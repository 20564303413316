import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid, Box, Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";

import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import Events from "../../../../../Analytics/Events";
import BizStacked from "../../../../../Charts/Column/BizStacked";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetRefreshAPICalled, SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import moment from "moment";
import BizLine from "../../../../../Charts/Line/BizLine";


function DataVolume(props) {
  const dispatch = useDispatch();
  const [pingingDevices, setPingingDevices] = useState({
    data: [],
    maxTotal: 0,
    maxInvalid: 0,
    responseStatus: { code: -1, msg: "" },
  });

  const [statusClicked, setStatusClicked] = useState({
    NumberOfDevices: true,
    NonPingingDevices: true,
  });
  function handleStatusClicked(status) {
    if (statusClicked.NumberOfDevices && statusClicked.NonPingingDevices) {
      if (status === "Total Batteries") {
        console.log("yes Number of batteries clicked");
        setStatusClicked({
          NumberOfDevices: statusClicked.NumberOfDevices,
          NonPingingDevices: !statusClicked.NonPingingDevices,
        });
      } else if (status === "Non pinging batteries") {
        setStatusClicked({
          NumberOfDevices: !statusClicked.NumberOfDevices,
          NonPingingDevices: statusClicked.NonPingingDevices,
        });
      }
    } else {
      if (status === "Total Batteries") {
        if (statusClicked.NonPingingDevices)
          setStatusClicked({
            NumberOfDevices: !statusClicked.NumberOfDevices,
            NonPingingDevices: statusClicked.NonPingingDevices,
          });
      } else if (status === "Non pinging batteries") {
        if (statusClicked.NumberOfDevices)
          setStatusClicked({
            NumberOfDevices: statusClicked.NumberOfDevices,
            NonPingingDevices: !statusClicked.NonPingingDevices,
          });
      }
    }
  }
  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Total Batteries",
            color: ThemeProperties.darkerGrey,
            target: statusClicked.NumberOfDevices,
          },
          {
            status: "Non pinging batteries",
            color: ThemeProperties.map_high,
            target: statusClicked.NonPingingDevices,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClicked(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  const [avgPingInterval, setAvgPingInterval] = useState({
    data: [],
    max: 0,
    responseStatus: { code: -1, msg: "" },
  });

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const SetThePingingDevices = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempCategories = [];

      let tempData = [];
      data.response.alertList.map((item) => {
        tempData.push({
          total: item.totalDevicesCount,
          nonPinging: item.notPingedDevicesCount,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempData, "startDate");
      let tempMax = 0,tempMaxInvalid = 0;

      gy.map((item) => {
        let tempDate = "";
        if (props.granular === "Weekly") {
          tempDate =
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
            " - " +
            moment(new Date(item["values"][0]["endDate"])).format("DD MMM");
        } else if (props.granular === "Monthly") {
          tempDate = moment(new Date(item["values"][0]["startDate"])).format(
            "MMM"
          );
        } else tempDate = moment(new Date(item["key"])).format("DD MMM");

        tempCategories.push({
          Date: tempDate,
          "Non pinging batteries": item["values"][0]["nonPinging"],
          "Number of batteries": item["values"][0]["total"],
        });

        item["values"].map((item) => {
          if (item["total"] > tempMax) tempMax = item["total"];
        });

        item["values"].map((item) => {
          if (item["nonPinging"] > tempMaxInvalid) tempMaxInvalid = item["nonPinging"];
        });
      });

      setPingingDevices({
        data: tempCategories,
        maxTotal: tempMax,
        maxInvalid: tempMaxInvalid,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setPingingDevices({
        data: [],
        maxTotal:0,
        maxInvalid:0,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheAvgPingInterval = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempCategories = [],
        tempOrgDate = [];

      let tempData = [];
      data.response.alertList.map((item) => {
        tempData.push({
          avgPingInterval: item.avgPingInterval,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });
      const gy = groupByKey(tempData, "startDate");
      let max = 0;

      gy.map((item) => {
        let tempDate = "";
        if (props.granular === "Weekly") {
          tempDate =
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
            " - " +
            moment(new Date(item["values"][0]["endDate"])).format("DD MMM");
        } else if (props.granular === "Monthly") {
          tempDate = moment(new Date(item["values"][0]["startDate"])).format(
            "MMM"
          );
        } else tempDate = moment(new Date(item["key"])).format("DD MMM");

        tempCategories.push({
          Date: tempDate,
          Avg_ping_interval: item["values"][0]["avgPingInterval"]
        })

        item["values"].map((item) => {
          if (item["avgPingInterval"] > max) max = item["avgPingInterval"];
        });
      });

      setAvgPingInterval({
        data: tempCategories,
        max: max,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setAvgPingInterval({
        data: [],
        max: 0,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setPingingDevices({
      data: [],
      maxTotal: 0,
      maxInvalid: 0,
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetThePingingDevices = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/not_pinging_devices`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetThePingingDevices(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setPingingDevices({
            ...pingingDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setPingingDevices({
            ...pingingDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          dispatch(SetRefreshAPICalled(false));

          try {
            let refresh = await axios.get("/api/refresh");
            if (refresh && refresh.status === CODE.SUCCESS ) {
              dispatch(SetRefreshAPICalled(true));
              try {
                let res = await axios.get(`/api/not_pinging_devices`, {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                });
                const data = callTheAPI(res, true);
                SetThePingingDevices(data);
              } catch (error) {
                if (error.response?.status === CODE.URL_NODATA) {
                  setPingingDevices({
                    ...pingingDevices,
                    responseStatus: {
                      code: CODE.NODATA,
                      msg: "No Data",
                    },
                  });
                } else if (error.response?.status === CODE.UNAUTHORIZED) {
                  return null;
                }
              }
            }
          } catch (error) {
            // Logout();
            SetSessionExpired();
          }
        }
      }
    };

    GetThePingingDevices();
    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack  
  ]);

  useEffect(() => {

    setAvgPingInterval({
      data: [],
      max: 0,
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    const controller = new AbortController();

    const GetTheAvgPingInterval = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        batteryVendor: props.pack,
        iotVendor: props.iotVendor,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/avg_ping_interval`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheAvgPingInterval(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setAvgPingInterval({
            ...avgPingInterval,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setAvgPingInterval({
            ...avgPingInterval,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/avg_ping_interval`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res, true);
              SetTheAvgPingInterval(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setAvgPingInterval({
                  ...avgPingInterval,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };

    GetTheAvgPingInterval();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.granular,
    props.iotVendor,
    props.pack,
    isRefreshAPICalled
  ]);
  ScrollToTheElement();

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Non pinging batteries")}
        >
          <Header
            heading="Non pinging batteries"
            description="Non pinging batteries"
            showIButton={true}
            showThreeDots={false}
            id="Non_pinging_devices"
            page="_Reports/DataQuality"
          />
          <div
            className="section"
            id="Non_pinging_devices"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
              <BizStacked
                data={pingingDevices}
                xaxisType={props.granular}
                statusClicked={statusClicked}
                total={"Number of batteries"}
                partOfTotal={"Non pinging batteries"}
              />
            </div>
            <div style={{ paddingLeft: "20px" }}>
              {pingingDevices.responseStatus.code === 200 && <Legends />}
            </div>
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("DQ Hovered Average ping interval")}
        >
          <Header
            heading="Average ping interval"
            description="Average ping interval"
            showIButton={true}
            showThreeDots={false}
            id="Average_ping_interval"
            page="_Reports/DataQuality"
          />
          <div
            className="section"
            id="Average_ping_interval"
            style={{ height: "calc(100% - 70px)" }}
          >
              <BizLine
              data={avgPingInterval}
              xaxisType={props.granular}
              />
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default DataVolume;
