import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid, Box, Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import ApexDonutAnalytics from "../../../../../Charts/Donut/ApexDonutAnalytics";
import ApexHorizontalBar from "../../../../../Charts/Bar/ApexHorizontalBar";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import {
  FindNearestMaxAnalytics,
  FindNextHighestMultipleOfNum,
} from "../../../../../Helper/Operations/FindNearestMaxAnalytics";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";
import { decryptTheParams } from "../../../../../Helper/QueryParams/EncryptDecrypt";

function findMaxNumber(arr1, arr2, arr3) {
  const combinedArray = [...arr1, ...arr2, ...arr3];
  return Math.max(...combinedArray);
}

function Safety(props) {
  const dispatch = useDispatch();
  const queryParams = decryptTheParams();

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const [safetyAlertsCount, setSafetyAlertsCount] = useState({
    code: [],
    critical: [],
    superCritical: [],
    max: 0,
    responseStatus: { code: -1, msg: "" },
  });
  const [safetyAlertsDevices, setSafetyAlertsDevices] = useState({
    code: [],
    count: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [safetyAlertsResolution, setSafetyAlertsResolution] = useState({
    first: {
      resolveMins: [],
      code: [],
      max: 0,
      startEnd: "",
      responseStatus: { code: -1, msg: "" },
    },
    second: {
      resolveMins: [],
      code: [],
      max: 0,
      startEnd: "",
      responseStatus: { code: -1, msg: "" },
    },
    third: {
      resolveMins: [],
      code: [],
      max: 0,
      startEnd: "",
      responseStatus: { code: -1, msg: "" },
    },
  });

  const [statusClickedDevices, setStatusClickedDevices] = useState({
    first: true,
    second: true,
    third: true,
  });
  const [safetyAlertsResolutionFinal, setSafetyAlertsResolutionFinal] =
    useState({
      first: [],
      second: [],
      third: [],
      code: [],
      max: 0,
      responseStatus: { code: -1, msg: "" },
    });
  function handleStatusClickedDevices(status) {
    if (
      statusClickedDevices.first &&
      statusClickedDevices.second &&
      statusClickedDevices.third
    ) {
      if (status === "Less than 30 min") {
        setStatusClickedDevices({
          first: statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: !statusClickedDevices.third,
        });
      } else if (status === "Between 30 mins and 1 hour") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: statusClickedDevices.second,
          third: !statusClickedDevices.third,
        });
      } else if (status === "More than 1 hour") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: statusClickedDevices.third,
        });
      }
    } else {
      if (status === "Less than 30 min") {
        if (statusClickedDevices.second || statusClickedDevices.third)
          setStatusClickedDevices({
            first: !statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: statusClickedDevices.third,
          });
      } else if (status === "Between 30 mins and 1 hour") {
        if (statusClickedDevices.first || statusClickedDevices.third)
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: !statusClickedDevices.second,
            third: statusClickedDevices.third,
          });
      } else if (status === "More than 1 hour") {
        if (statusClickedDevices.first || statusClickedDevices.second)
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: !statusClickedDevices.third,
          });
      }
    }
  }
  function handleResolutionTimesClicked() {
    let tempSeries = [],
      tempColors = [],
      max = 0;
    if (statusClickedDevices.first) {
      tempSeries.push({
        name: "Less than 30 min",
        data: safetyAlertsResolutionFinal.first,
      });
      tempColors.push(ThemeProperties.purple);
      if (Math.max(...safetyAlertsResolutionFinal.first) > max)
        max = Math.max(...safetyAlertsResolutionFinal.first);
    }
    if (statusClickedDevices.second) {
      tempSeries.push({
        name: "Between 30 mins and 1 hour",
        data: safetyAlertsResolutionFinal.second,
      });
      tempColors.push(ThemeProperties.Purple4);
      if (Math.max(...safetyAlertsResolutionFinal.second) > max)
        max = Math.max(...safetyAlertsResolutionFinal.second);
    }
    if (statusClickedDevices.third) {
      tempSeries.push({
        name: "More than 1 hour",
        data: safetyAlertsResolutionFinal.third,
      });
      tempColors.push(ThemeProperties.Purple6);
      if (Math.max(...safetyAlertsResolutionFinal.third) > max)
        max = Math.max(...safetyAlertsResolutionFinal.third);
    }

    // setSafetyAlertsResolutionFinal((prevState) => ({
    //   ...prevState,
    //   max: max,
    // }));

    console.log(tempSeries, max);
    return [
      tempSeries,
      tempColors,
      FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(max), 1000),
    ];
  }
  function LegendsDevices() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Less than 30 min",
            color: ThemeProperties.purple,
            target: statusClickedDevices.first,
          },
          {
            status: "Between 30 mins and 1 hour",
            color: ThemeProperties.Purple4,
            target: statusClickedDevices.second,
          },
          {
            status: "More than 1 hour",
            color: ThemeProperties.Purple6,
            target: statusClickedDevices.third,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedDevices(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  const [highTempAlerts, setHighTempAlerts] = useState({
    batteries: [],
    count: [],
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [highVoltageAlerts, setHighVoltageAlerts] = useState({
    batteries: [],
    count: [],
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [maxSafetyAlerts, setMaxSafetyAlerts] = useState({
    batteries: [],
    count: [],
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const SetTheAlertsCountAndDevices = (data) => {
    if (data.responseStatus.code == 200) {
      let tempCode = [],
        tempSuperCritical = [],
        tempCritical = [];

      let tempCode1 = [],
        tempCount1 = [],
        tempData = [];

      let count = 0,
        max = 0;

      data.response.alertList.map((item) => {
        if (item["alertCount"] > max) max = item["alertCount"];
      });

      const newMax = FindNearestMaxAnalytics(max);

      const gy = groupByKey(data.response.alertList, "alertDescription");

      let newData = [];
      gy.map((item) => {
        let tempMax = 0;
        item["values"].map((item2) => {
          if (item2["alertCount"] > tempMax) tempMax = item2["alertCount"];
        });
        newData.push({
          key: item["key"],
          values: item["values"],
          max: tempMax,
        });
      });
      let dataV1 = newData.sort((a, b) => b.max - a.max);

      // console.log(gy)

      // console.log(dataV1)

      dataV1.map((item) => {
        tempCode.push(item["key"]);
        tempCritical.push(
          item["values"].find((i) => i.severity === 3)
            ? item["values"].find((i) => i.severity === 3)["alertCount"]
            : 0
        );
        tempSuperCritical.push(
          item["values"].find((i) => i.severity === 4)
            ? item["values"].find((i) => i.severity === 4)["alertCount"]
            : 0
        );
        let count = 0;
        item["values"].map((i) => {
          count = count + i["deviceCount"];
        });

        tempData.push({
          code: item["key"],
          count: count,
        });
      });
      let dataV = tempData.sort((a, b) => b.count - a.count);
      dataV.map((item) => {
        tempCode1.push(item["code"]);
        tempCount1.push(item["count"]);
      });

      setSafetyAlertsCount({
        code: tempCode,
        critical: tempCritical,
        superCritical: tempSuperCritical,
        max: newMax,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });

      setSafetyAlertsDevices({
        code: tempCode1,
        count: tempCount1,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setSafetyAlertsCount({
        code: [],
        critical: [],
        superCritical: [],
        max: 0,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setSafetyAlertsDevices({
        code: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheSafetyAlertsResolution = (
    data,
    start,
    end,
    position,
    resolveMins,
    code,
    max,
    startEnd,
    responseStatus
  ) => {
    if (data.responseStatus.code === 200) {
      let tempCode = [],
        tempFirst = [];

      const gy = groupByKey(data.response.alertList, "alertDescription");

      let categories = [
        {
          name: "High temperature during charging",
          value: ["High temperature", "during charging"],
        },
        {
          name: "High temperature inside vehicle",
          value: ["High temperature", "inside vehicle"],
        },
        {
          name: "High temperature in warehouse",
          value: ["High temperature", "in warehouse"],
        },
        {
          name: "Over voltage protection breach",
          value: ["Over voltage", "protection breach"],
        },
        { name: "Cold charging", value: "Cold charging" },
        {
          name: "Abnormally high current during charging",
          value: ["Abnormally high current", "during charging"],
        },
        {
          name: "Abnormally high current during driving",
          value: ["Abnormally high current","during driving"],
        },
        {
          name: "High current fluctuation during charging",
          value: ["High current fluctuation", "during charging"],
        },
        {
          name: "High current at full voltage during charging",
          value: ["High current at full", "voltage during charging"],
        },
      ];
      let maxy = 0;
      console.log(gy);
      gy.map((item) => {
        let count = 0;
        let tempKey = categories.find((o) => o.name === item["key"]);
        tempCode.push(tempKey["value"]);

        item["values"].map((i) => {
          count = count + i["deviceCount"];
        });
        tempFirst.push(count);

        if (count > maxy) maxy = count;
      });

      const newMax = FindNearestMaxAnalytics(maxy);

      setSafetyAlertsResolution((prevData) => ({
        ...prevData,
        [position]: {
          ...prevData[position],
          [resolveMins]: [...tempFirst],
          [code]: [...tempCode],
          [max]: newMax,
          [startEnd]: "start : " + start + " and " + "end : " + end,
          [responseStatus]: {
            code: data.responseStatus.code,
            msg: data.responseStatus.msg,
          },
        },
      }));
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setSafetyAlertsResolution({
        first: {
          resolveMins: [],
          code: [],
          max: 0,
          startEnd: "",
          responseStatus: { code: 404, msg: "" },
        },
        second: {
          resolveMins: [],
          code: [],
          max: 0,
          startEnd: "",
          responseStatus: { code: 404, msg: "" },
        },
        third: {
          resolveMins: [],
          code: [],
          max: 0,
          startEnd: "",
          responseStatus: { code: 404, msg: "" },
        },
      });
    }
  };

  const SetTheBatteriesWithAlertsTemp = (data) => {
    if (data.responseStatus.code === 200) {
      let tempBattery = [],
        tempCount = [],
        tempData = [];
      data.response.batteryList.map((item) => {
        tempData.push({
          y: item["alertCount"],
          x: item["batteryID"],
          batteryID: item["assetID"],
        });
        tempBattery.push(item["batteryID"]);
        tempCount.push(item["alertCount"]);
      });
      setHighTempAlerts({
        batteries: tempBattery,
        count: tempCount,
        data: tempData,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setHighTempAlerts({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheBatteriesWithAlertsVolt = (data) => {
    if (data.responseStatus.code === 200) {
      let tempBattery = [],
        tempCount = [],
        tempData = [];
      data.response.batteryList.map((item) => {
        tempData.push({
          y: item["alertCount"],
          x: item["batteryID"],
          batteryID: item["assetID"],
        });
        tempBattery.push(item["batteryID"]);
        tempCount.push(item["alertCount"]);
      });
      setHighVoltageAlerts({
        batteries: tempBattery,
        count: tempCount,
        data: tempData,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setHighVoltageAlerts({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheBatteriesWithAlertsMax = (data) => {
    if (data.responseStatus.code === 200) {
      let tempBattery = [],
        tempCount = [],
        tempData = [];
      data.response.batteryList.map((item) => {
        tempData.push({
          y: item["alertCount"],
          x: item["batteryID"],
          batteryID: item["assetID"],
        });
        tempBattery.push(item["batteryID"]);
        tempCount.push(item["alertCount"]);
      });
      setMaxSafetyAlerts({
        batteries: tempBattery,
        count: tempCount,
        data: tempData,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setMaxSafetyAlerts({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    // console.log(props.date)

    setSafetyAlertsResolution({
      first: {
        resolveMins: [],
        code: [],
        max: 0,
        startEnd: "",
        responseStatus: { code: -1, msg: "" },
      },
      second: {
        resolveMins: [],
        code: [],
        max: 0,
        startEnd: "",
        responseStatus: { code: -1, msg: "" },
      },
      third: {
        resolveMins: [],
        code: [],
        max: 0,
        startEnd: "",
        responseStatus: { code: -1, msg: "" },
      },
    });

    setSafetyAlertsCount({
      code: [],
      critical: [],
      superCritical: [],
      responseStatus: { code: -1, msg: "" },
    });

    setSafetyAlertsDevices({
      code: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    setHighTempAlerts({
      batteries: [],
      count: [],
      data: [],
      responseStatus: { code: -1, msg: "" },
    });

    setHighVoltageAlerts({
      batteries: [],
      count: [],
      data: [],
      responseStatus: { code: -1, msg: "" },
    });

    setMaxSafetyAlerts({
      batteries: [],
      count: [],
      data: [],
      responseStatus: { code: -1, msg: "" },
    });

    const controller = new AbortController();

    const GetTheAlertsCountAndDevices = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Safety Risk",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/alerts_type_severity_distribution`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheAlertsCountAndDevices(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setSafetyAlertsCount({
            ...safetyAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setSafetyAlertsDevices({
            ...safetyAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setSafetyAlertsCount({
            ...safetyAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setSafetyAlertsDevices({
            ...safetyAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          //   let refresh = await axios.get("/api/refresh");
          //   console.log("joii",isRefreshAPICalled)
          if (isRefreshAPICalled) {
            // dispatch(SetRefreshAPICalled(true))
            try {
              let res = await axios.get(
                `/api/alerts_type_severity_distribution`,
                {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                }
              );
              const data = callTheAPI(res);
              SetTheAlertsCountAndDevices(data);
            } catch (error) {
              // console.log(error);
              if (error.response?.status === CODE.URL_NODATA) {
                setSafetyAlertsCount({
                  ...safetyAlertsCount,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
                setSafetyAlertsDevices({
                  ...safetyAlertsDevices,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                // removeAccessToken();
                return null;
              }
            }
          }
          // } catch (error) {
          //   data = SetSessionExpired();
          //   return data;
          // }
        }
      }
    };

    const GetTheSafetyAlertsResolution = async (
      start,
      end,
      position,
      resolveMins,
      code,
      max,
      startEnd,
      responseStatus
    ) => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Safety Risk",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
        resolutionTimeStart: start,
        resolutionTimeEnd: end,
      };

      try {
        let res = await axios.get(`/api/alerts_resolution_time`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheSafetyAlertsResolution(
          data,
          start,
          end,
          position,
          resolveMins,
          code,
          max,
          startEnd,
          responseStatus
        );
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setSafetyAlertsResolution({
            ...safetyAlertsResolution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setSafetyAlertsResolution({
            ...safetyAlertsResolution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          // let refresh = await axios.get("/api/refresh");
          // console.log("joii",isRefreshAPICalled)
          if (isRefreshAPICalled) {
            // dispatch(SetRefreshAPICalled(true))
            try {
              let res = await axios.get(`/api/alerts_resolution_time`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheSafetyAlertsResolution(
                data,
                start,
                end,
                position,
                resolveMins,
                code,
                max,
                startEnd,
                responseStatus
              );
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setSafetyAlertsResolution({
                  ...setSafetyAlertsResolution,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                // removeAccessToken();
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    const GetTheBatteriesWithAlertsTemp = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "",
        alertCode: "sft_temp_chrg",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/batteries_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheBatteriesWithAlertsTemp(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setHighTempAlerts({
            batteries: [],
            count: [],
            data: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setHighTempAlerts({
            batteries: [],
            count: [],
            data: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          // let refresh = await axios.get("/api/refresh");
          // console.log("joii",isRefreshAPICalled)
          if (isRefreshAPICalled) {
            // dispatch(SetRefreshAPICalled(true))
            try {
              let res = await axios.get(`/api/batteries_with_max_alerts`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheBatteriesWithAlertsTemp(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setHighTempAlerts({
                  batteries: [],
                  count: [],
                  data: [],
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                // removeAccessToken();
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    const GetTheBatteriesWithAlertsVolt = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "",
        alertCode: "sft_volt",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/batteries_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheBatteriesWithAlertsVolt(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setHighVoltageAlerts({
            batteries: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setHighVoltageAlerts({
            batteries: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          // let refresh = await axios.get("/api/refresh");
          // console.log("joii",isRefreshAPICalled)
          if (isRefreshAPICalled) {
            // dispatch(SetRefreshAPICalled(true))
            try {
              let res = await axios.get(`/api/batteries_with_max_alerts`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheBatteriesWithAlertsVolt(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setHighVoltageAlerts({
                  batteries: [],
                  count: [],
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                // removeAccessToken();
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    const GetTheBatteriesWithAlertsMax = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Safety Risk",
        alertCode: "",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/batteries_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheBatteriesWithAlertsMax(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setMaxSafetyAlerts({
            batteries: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setMaxSafetyAlerts({
            batteries: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          // let refresh = await axios.get("/api/refresh");
          // console.log("joii",isRefreshAPICalled)
          if (isRefreshAPICalled) {
            // dispatch(SetRefreshAPICalled(true))
            try {
              let res = await axios.get(`/api/batteries_with_max_alerts`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheBatteriesWithAlertsMax(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setMaxSafetyAlerts({
                  batteries: [],
                  count: [],
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                // removeAccessToken();
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    GetTheAlertsCountAndDevices();

    GetTheSafetyAlertsResolution(
      "0",
      "1800",
      "first",
      "resolveMins",
      "code",
      "max",
      "startEnd",
      "responseStatus"
    );

    GetTheSafetyAlertsResolution(
      "1800",
      "3600",
      "second",
      "resolveMins",
      "code",
      "max",
      "startEnd",
      "responseStatus"
    );

    GetTheSafetyAlertsResolution(
      "3600",
      "",
      "third",
      "resolveMins",
      "code",
      "max",
      "startEnd",
      "responseStatus"
    );

    GetTheBatteriesWithAlertsTemp();
    GetTheBatteriesWithAlertsVolt();
    GetTheBatteriesWithAlertsMax();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
  ]);

  useEffect(() => {
    let categories = [
      {
        name: "High temperature during charging",
        value: ["High temperature", "during charging"],
      },
      {
        name: "High temperature inside vehicle",
        value: ["High temperature", "inside vehicle"],
      },
      {
        name: "High temperature in warehouse",
        value: ["High temperature", "in warehouse"],
      },
      {
        name: "Over voltage protection breach",
        value: ["Over voltage", "protection breach"],
      },
      { name: "Cold charging", value: "Cold charging" },
      {
        name: "Abnormally high current during charging",
        value: ["Abnormally high current", "during charging"],
      },
      {
        name: "Abnormally high current during driving",
        value: ["Abnormally high current","during driving"],
      },
      {
        name: "High current fluctuation during charging",
        value: ["High current fluctuation", "during charging"],
      },
      {
        name: "High current at full voltage during charging",
        value: ["High current at full", "voltage during charging"],
      },
    ];

    setSafetyAlertsResolutionFinal({
      first: [],
      second: [],
      third: [],
      code: [],
      max: 0,
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });

    if (
      safetyAlertsResolution.first.responseStatus.code === 200 &&
      safetyAlertsResolution.second.responseStatus.code === 200 &&
      safetyAlertsResolution.third.responseStatus.code === 200
    ) {
      let tempFirst = [],
        tempSecond = [],
        tempThird = [],
        tempCode = [];
      categories.map((item) => {
        let indexFirst = safetyAlertsResolution.first.code.findIndex(
          (i) => i.toString() === item.value.toString()
        );
        if (indexFirst === -1) tempFirst.push(0);
        else
          tempFirst.push(safetyAlertsResolution.first.resolveMins[indexFirst]);

        let secondFirst = safetyAlertsResolution.second.code.findIndex(
          (i) => i.toString() === item.value.toString()
        );
        if (secondFirst === -1) tempSecond.push(0);
        else
          tempSecond.push(
            safetyAlertsResolution.second.resolveMins[secondFirst]
          );

        let thirdFirst = safetyAlertsResolution.third.code.findIndex(
          (i) => i.toString() === item.value.toString()
        );
        if (thirdFirst === -1) tempThird.push(0);
        else
          tempThird.push(safetyAlertsResolution.third.resolveMins[thirdFirst]);

        tempCode.push(item.value);
      });

      const maxNumber = findMaxNumber(tempFirst, tempSecond, tempThird);

      setSafetyAlertsResolutionFinal({
        first: tempFirst,
        second: tempSecond,
        third: tempThird,
        code: tempCode,
        max: maxNumber,
        responseStatus: {
          code: 200,
          msg: "data successfully fetched",
        },
      });
    } else if (
      safetyAlertsResolution.first.responseStatus.code === 404 &&
      safetyAlertsResolution.second.responseStatus.code === 404 &&
      safetyAlertsResolution.third.responseStatus.code === 404
    ) {
      setSafetyAlertsResolutionFinal({
        first: [],
        second: [],
        third: [],
        code: [],
        max: 0,
        responseStatus: {
          code: 404,
          msg: "Not found",
        },
      });
    }
  }, [
    safetyAlertsResolution.first.resolveMins,
    safetyAlertsResolution.second.resolveMins,
    safetyAlertsResolution.third.resolveMins,
  ]);

  if (
    queryParams["id"] === "Top_20_Batteries_with_Maximum_Safety_Alerts" ||
    queryParams["id"] === "Top_20_Batteries_with_Maximum_High_Voltage_Alerts" ||
    queryParams["id"] === "Top_20_Batteries_with_Maximum_High_Temperature_Alerts" ||
    !queryParams["id"]
  )
    ScrollToTheElement();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() =>
            Events("AA Hovered Count of Devices with Safety Alerts")
          }
        >
          <Header
            heading="Total batteries with various safety alerts"
            description="Count of Devices with Safety Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Count_of_Batteries_with_Safety_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Count_of_Batteries_with_Safety_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              data={safetyAlertsDevices}
              horizontal={true}
              barHeight={"50%"}
              toggleDataPointSelection={false}
              xaxisTitle={"Number of batteries"}
              xTickAmount={5}
              distributed={false}
              yaxisTitle={undefined}
              formatter={true}
              tickAmount={4}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                Math.max(...safetyAlertsDevices.count)
              ),500)}
              categories={safetyAlertsDevices.code}
              series={[
                {
                  name: "Number of batteries",
                  data: safetyAlertsDevices.count,
                },
              ]}
              offsetX={20}
              offsetY={0}
              colors={[
                ThemeProperties.safety_1,
                ThemeProperties.safety_2,
                ThemeProperties.safety_3,
                ThemeProperties.safety_4,
                ThemeProperties.safety_5,
                ThemeProperties.safety_6,
                ThemeProperties.safety_7,
                ThemeProperties.safety_8,
                ThemeProperties.safety_9,
              ]}
              hoverFilter={"none"}
              activeFilter={"none"}
            />
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Safety alerts resolution time distribution"
            description="Safety Alerts Resolution Time Distribution"
            showIButton={true}
            showThreeDots={false}
            id="Safety_Alerts_Resolution_Time_Distribution"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Safety_Alerts_Resolution_Time_Distribution"
            style={{ height: "calc(100% - 70px)"}}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
              <TestApexBar
                data={safetyAlertsResolutionFinal}
                horizontal={false}
                barHeight={"50%"}
                yaxisTitle={"Number of batteries"}
                xaxisTitle={undefined}
                tickAmount={10}
                granularity={"Weekly"}
                // xmax={FindNearestMaxAnalytics(
                //   Math.max(...safetyAlertsDevices.count)
                // )}
                series={[
                  {
                    name: "Less than 30 min",
                    data: safetyAlertsResolutionFinal.first,
                  },
                  {
                    name: "Between 30 mins and 1 hour",
                    data: safetyAlertsResolutionFinal.second,
                  },
                  {
                    name: "More than 1 hour",
                    data: safetyAlertsResolutionFinal.third,
                  },
                ]}
                ymax={FindNextHighestMultipleOfNum(
                  FindNearestMaxAnalytics(safetyAlertsResolutionFinal.max),
                  100
                )}
                columnWidth={"80%"}
                stacked={false}
                showDataLabels={true}
                formatter={false}
                customLegend={true}
                statusClicked={statusClickedDevices}
                handleLegendClick={handleResolutionTimesClicked}
                categories={safetyAlertsResolutionFinal.code}
                colors={[
                  ThemeProperties.purple,
                  ThemeProperties.Purple4,
                  ThemeProperties.Purple6,
                ]}
                offsetX={0}
                offsetY={-20}
                width={"100%"}
              />
            </div>
            <div style={{ paddingLeft:"20px"}}>
              
              {safetyAlertsResolutionFinal.responseStatus.code ===
                CODE.SUCCESS && <LegendsDevices />}
                
            </div>
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Top 20 batteries with maximum high temperature alerts"
            description="Top 20 Batteries with Highest number of high temperature Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Top_20_Batteries_with_Maximum_High_Temperature_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Top_20_Batteries_with_Maximum_High_Temperature_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              xaxisTitle={"Number of alerts"}
              yaxisTitle={undefined}
              xTickAmount={5}
              distributed={false}
              data={highTempAlerts}
              horizontal={true}
              formatter={true}
              barHeight={"70%"}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(Math.max(...highTempAlerts.count)),50)}
              series={[
                {
                  name: "Number of alerts",
                  data: highTempAlerts.data,
                },
              ]}
              toggleDataPointSelection={false}
              customTooltip={true}
              rgbColor={ThemeProperties.Max_Temperature}
              batteries={highTempAlerts.batteries}
              clickable={true}
              id="Top_20_Batteries_with_Maximum_High_Temperature_Alerts"
              page="_Reports/AlertAnalytics"
              categories={[]}
              offsetY={0}
              offsetX={20}
              colors={[ThemeProperties.Max_Temperature]}
              hoverFilter={"lighten"}
              activeFilter={"darken"}
            />
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Top 20 batteries with maximum high voltage alerts"
            description="Top 20  Batteries with Highest number of high voltage Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Top_20_Batteries_with_Maximum_High_Voltage_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Top_20_Batteries_with_Maximum_High_Voltage_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              xaxisTitle={"Number of alerts"}
              xTickAmount={5}
              yaxisTitle={undefined}
              data={highVoltageAlerts}
              horizontal={true}
              formatter={true}
              distributed={false}
              barHeight={"70%"}
              toggleDataPointSelection={false}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                Math.max(...highVoltageAlerts.count)
              ),50)}
              series={[
                {
                  name: "Number of alerts",
                  data: highVoltageAlerts.data,
                },
              ]}
              customTooltip={true}
              rgbColor={ThemeProperties.Voltage}
              batteries={highVoltageAlerts.batteries}
              clickable={true}
              id="Top_20_Batteries_with_Maximum_High_Voltage_Alerts"
              page="_Reports/AlertAnalytics"
              categories={[]}
              offsetY={0}
              offsetX={20}
              colors={[ThemeProperties.Voltage]}
              hoverFilter={"lighten"}
              activeFilter={"darken"}
            />
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Top 20 batteries with maximum safety alerts"
            description="Top 20 Batteries with Maximum Safety Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Top_20_Batteries_with_Maximum_Safety_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Top_20_Batteries_with_Maximum_Safety_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              xaxisTitle={"Number of alerts"}
              xTickAmount={5}
              distributed={false}
              yaxisTitle={undefined}
              data={maxSafetyAlerts}
              horizontal={true}
              formatter={true}
              barHeight={"70%"}
              toggleDataPointSelection={false}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(Math.max(...maxSafetyAlerts.count)),50)}
              series={[
                {
                  name: "Number of alerts",
                  data: maxSafetyAlerts.data,
                },
              ]}
              customTooltip={true}
              rgbColor={"#CF0000"}
              batteries={maxSafetyAlerts.batteries}
              clickable={true}
              id="Top_20_Batteries_with_Maximum_Safety_Alerts"
              page="_Reports/AlertAnalytics"
              categories={[]}
              offsetY={0}
              offsetX={20}
              colors={["#CF0000"]}
              hoverFilter={"lighten"}
              activeFilter={"darken"}
            />
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default Safety;
