import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../../../../../Helper/DatePicker/DateConverter";
import { callTheAPI } from "../../../../../Api/Functions/SettingData";
import CODE from "../../../../../Static/Constants/StatusCodes";

import { Grid, Box, Typography } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { PaperHighlight } from "../../../../../Components/CustomPaper";
import Header from "../../../../../Features/Header/Header";
import ApexHorizontalBar from "../../../../../Charts/Bar/ApexHorizontalBar";
import ApexMultiSeries from "../../../../../Charts/Line/ApexMultiSeries";
import { ThemeProperties } from "../../../../../Theme/ThemeProperties";
import Events from "../../../../../Analytics/Events";
import ScrollToTheElement from "../../../../../Helper/Operations/ScrollToTheElement";

import { useDispatch, useSelector } from "react-redux";
import { SetSessionExpired } from "../../../../../Actions";
import { groupByKey } from "../../../../../Helper/Operations/GroupByKey";
import moment from "moment";
import ApexBasicTreemap from "../../../../../Charts/Treemap/ApexBasicTreemap";
import { decryptTheParams } from "../../../../../Helper/QueryParams/EncryptDecrypt";
import TestApexBar from "../../../../../Charts/Bar/TestApexBar";
import { FindNearestMaxAnalytics, FindNextHighestMultipleOfNum } from "../../../../../Helper/Operations/FindNearestMaxAnalytics";


const getPercentWidth = (w, n, x) => {
  let newW;
  if (w >= 1 && w < 5) {
    newW = 1000;
  } else if (w >= 5 && w < 10) {
    newW = 750;
  } else if (w >= 10 && w < 15) {
    newW = 450;
  } else if (w >= 15 && w < 20) {
    newW = 500;
  } else if (w >= 20 && w < 25) {
    newW = 350;
  } else if (w >= 25 && w < 30) {
    newW = 280;
  } else {
    newW = 220;
  }
  const percentWidth = Math.round((x / (newW / n)) * 100);
  return `${percentWidth}%`;
};

function Violations(props) {
  const dispatch = useDispatch();
  const queryParams = decryptTheParams();

  const isRefreshAPICalled = useSelector(
    (state) => state.RefreshAPICalled.value
  );

  const [violationAlertsCount, setViolationAlertsCount] = useState({
    code: [],
    count: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [violationAlertsDevices, setViolationAlertsDevices] = useState({
    code: [],
    count: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [maxUFCAlerts, setMaxUFCAlerts] = useState({
    batteries: [],
    count: [],
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [statusClicked, setStatusClicked] = useState({
    first: true,
    second: true,
    third: true,
  });
  function handleStatusClicked(status) {
    if (statusClicked.first && statusClicked.second && statusClicked.third) {
      if (status === "Field charging") {
        setStatusClicked({
          first: statusClicked.first,
          second: !statusClicked.second,
          third: !statusClicked.third,
        });
      } else if (status === "Unauthorised charging") {
        setStatusClicked({
          first: !statusClicked.first,
          second: statusClicked.second,
          third: !statusClicked.third,
        });
      } else if (status === "Unauthorised field charging") {
        setStatusClicked({
          first: !statusClicked.first,
          second: !statusClicked.second,
          third: statusClicked.third,
        });
      }
    } else {
      if (status === "Field charging") {
        if (statusClicked.second || statusClicked.third)
          setStatusClicked({
            first: !statusClicked.first,
            second: statusClicked.second,
            third: statusClicked.third,
          });
      } else if (status === "Unauthorised charging") {
        if (statusClicked.first || statusClicked.third)
          setStatusClicked({
            first: statusClicked.first,
            second: !statusClicked.second,
            third: statusClicked.third,
          });
      } else if (status === "Unauthorised field charging") {
        if (statusClicked.first || statusClicked.second)
          setStatusClicked({
            first: statusClicked.first,
            second: statusClicked.second,
            third: !statusClicked.third,
          });
      }
    }
  }
  function handleAlertsStatusClicked() {
    let tempSeries = [],
      tempColors = [];
    if (statusClicked.first) {
      tempSeries.push({
        name: "Field charging",
        data: violationAlertsDistribution.vln_fc,
      });
      tempColors.push("#00bcd4");
    }
    if (statusClicked.second) {
      tempSeries.push({
        name: "Unauthorised charging",
        data: violationAlertsDistribution.vln_uc,
      });
      tempColors.push(ThemeProperties.violation_2);
    }
    if (statusClicked.third) {
      tempSeries.push({
        name: "Unauthorised field charging",
        data: violationAlertsDistribution.vln_ufc,
      });
      tempColors.push(ThemeProperties.violation_3);
    }
    return [tempSeries, tempColors];
  }
  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Field charging",
            color: "#00bcd4",
            target: statusClicked.first,
          },
          {
            status: "Unauthorised charging",
            color: ThemeProperties.violation_2,
            target: statusClicked.second,
          },
          {
            status: "Unauthorised field charging",
            color: ThemeProperties.violation_3,
            target: statusClicked.third,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClicked(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
  const [violationAlertsDistribution, setViolationAlertsDistribution] =
    useState({
      vln_fc: [],
      vln_uc: [],
      vln_ufc: [],
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

  const [statusClickedDevices, setStatusClickedDevices] = useState({
    first: true,
    second: true,
    third: true,
  });
  function handleStatusClickedDevices(status) {
    if (
      statusClickedDevices.first &&
      statusClickedDevices.second &&
      statusClickedDevices.third
    ) {
      if (status === "Field charging") {
        setStatusClickedDevices({
          first: statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: !statusClickedDevices.third,
        });
      } else if (status === "Unauthorised charging") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: statusClickedDevices.second,
          third: !statusClickedDevices.third,
        });
      } else if (status === "Unauthorised field charging") {
        setStatusClickedDevices({
          first: !statusClickedDevices.first,
          second: !statusClickedDevices.second,
          third: statusClickedDevices.third,
        });
      }
    } else {
      if (status === "Field charging") {
        if (statusClickedDevices.second || statusClickedDevices.third)
          setStatusClickedDevices({
            first: !statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: statusClickedDevices.third,
          });
      } else if (status === "Unauthorised charging") {
        if (statusClickedDevices.first || statusClickedDevices.third)
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: !statusClickedDevices.second,
            third: statusClickedDevices.third,
          });
      } else if (status === "Unauthorised field charging") {
        if (statusClickedDevices.first || statusClickedDevices.second)
          setStatusClickedDevices({
            first: statusClickedDevices.first,
            second: statusClickedDevices.second,
            third: !statusClickedDevices.third,
          });
      }
    }
  }
  function handleAlertsStatusClickedDevices() {
    let tempSeries = [],
      tempColors = [];
    if (statusClickedDevices.first) {
      tempSeries.push({
        name: "Field charging",
        data: violationDevicesDistribution.vln_fc_devices,
      });
      tempColors.push("#01579b");
    }
    if (statusClickedDevices.second) {
      tempSeries.push({
        name: "Unauthorised charging",
        data: violationDevicesDistribution.vln_uc_devices,
      });
      tempColors.push("#03a9f4");
    }
    if (statusClickedDevices.third) {
      tempSeries.push({
        name: "Unauthorised field charging",
        data: violationDevicesDistribution.vln_ufc_devices,
      });
      tempColors.push("#00bcd4");
    }
    return [tempSeries, tempColors];
  }
  function LegendsDevices() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          ml: 2.5,
        }}
      >
        {[
          {
            status: "Field charging",
            color: "#01579b",
            target: statusClickedDevices.first,
          },
          {
            status: "Unauthorised charging",
            color: "#03a9f4",
            target: statusClickedDevices.second,
          },
          {
            status: "Unauthorised field charging",
            color: "#00bcd4",
            target: statusClickedDevices.third,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStatusClickedDevices(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
  const [violationDevicesDistribution, setViolationDevicesDistribution] =
    useState({
      vln_fc_devices: [],
      vln_uc_devices: [],
      vln_ufc_devices: [],
      categories: [],
      responseStatus: { code: -1, msg: "" },
    });

  const [partners, setPartners] = useState({
    partners: [],
    count: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerBatteries, setSelectedPartnerBatteries] = useState({
    batteries: [],
    count: [],
    data: [],
    responseStatus: {
      code: -1,
      msg: "",
    },
  });
  const handleSelectedPartner = (partner) => {
    setSelectedPartner((prevState) => {
      if (prevState === partner) return null;
      else return partner;
    });
  };

  const SetTheAlertsCountAndDevices = (data) => {
    if (data.responseStatus.code === 200) {
      let tempCode = [],
        tempCount = [],
        tempData = [];

      let tempCode1 = [],
        tempCount1 = [],
        tempData1 = [];

      // data.response.alertList.map((item) => {
      //   if (item["alertCount"] > max) max = item["alertCount"];
      // });

      const gy = groupByKey(data.response.alertList, "alertDescription");
      gy.map((item) => {
        let count = 0;
        item["values"].map((i) => (count = count + i["alertCount"]));
        tempData.push({
          code: item["key"],
          count: count,
        });

        let count1 = 0;
        item["values"].map((i) => (count1 = count1 + i["deviceCount"]));
        tempData1.push({
          code: item["key"],
          count: count1,
        });
      });

      let dataV = tempData.sort((a, b) => b.count - a.count);
      dataV.map((item) => {
        tempCode.push(item["code"]);
        tempCount.push(item["count"]);
      });

      let dataV1 = tempData1.sort((a, b) => b.count - a.count);
      dataV1.map((item) => {
        tempCode1.push(item["code"]);
        tempCount1.push(item["count"]);
      });

      setViolationAlertsCount({
        code: tempCode,
        count: tempCount,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setViolationAlertsDevices({
        code: tempCode1,
        count: tempCount1,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setViolationAlertsCount({
        code: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setViolationAlertsDevices({
        code: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheBatteriesWithAlertsMaxUFC = (data) => {
    if (data.responseStatus.code === 200) {
      let tempBattery = [],
        tempCount = [],
        tempData = [];
      data.response.batteryList.map((item) => {
        tempData.push({
          y: item["alertCount"],
          x: item["batteryID"],
          batteryID: item["assetID"],
        });
        tempBattery.push(item["batteryID"]);
        tempCount.push(item["alertCount"]);
      });
      setMaxUFCAlerts({
        batteries: tempBattery,
        count: tempCount,
        data: tempData,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setMaxUFCAlerts({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheViolationAlertsDistribution = (data) => {
    if (data.responseStatus.code === 200 || data.responseStatus.code === 1999) {
      let tempvln_fc = [],
        tempvln_uc = [],
        tempvln_ufc = [],
        tempvln_fc_devices = [],
        tempvln_uc_devices = [],
        tempvln_ufc_devices = [],
        tempCategories = [];

      let tempAlertList = [];
      data.response.alertList.map((item) => {
        tempAlertList.push({
          deviceCount: item.deviceCount,
          alertCount: item.alertCount,
          alertDescription: item.alertDescription,
          startDate: item.range.startDate,
          endDate: item.range.endDate,
        });
      });

      const gy = groupByKey(tempAlertList, "startDate");
      gy.map((item) => {
        if (props.granular === "Weekly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
        } else if (props.granular === "Monthly") {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("MMM")
          );
        } else tempCategories.push(item["key"] + " GMT");

        tempvln_fc.push(
          item["values"].find((i) => i.alertDescription === "Field charging")
            ? item["values"].find(
                (i) => i.alertDescription === "Field charging"
              )["alertCount"]
            : 0
        );
        tempvln_uc.push(
          item["values"].find(
            (i) => i.alertDescription === "Unauthorised charging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Unauthorised charging"
              )["alertCount"]
            : 0
        );
        tempvln_ufc.push(
          item["values"].find(
            (i) => i.alertDescription === "Unauthorised field charging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Unauthorised field charging"
              )["alertCount"]
            : 0
        );

        tempvln_fc_devices.push(
          item["values"].find((i) => i.alertDescription === "Field charging")
            ? item["values"].find(
                (i) => i.alertDescription === "Field charging"
              )["deviceCount"]
            : 0
        );

        tempvln_uc_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Unauthorised charging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Unauthorised charging"
              )["deviceCount"]
            : 0
        );
        tempvln_ufc_devices.push(
          item["values"].find(
            (i) => i.alertDescription === "Unauthorised field charging"
          )
            ? item["values"].find(
                (i) => i.alertDescription === "Unauthorised field charging"
              )["deviceCount"]
            : 0
        );
      });

      setViolationAlertsDistribution({
        vln_fc: tempvln_fc,
        vln_uc: tempvln_uc,
        vln_ufc: tempvln_ufc,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });

      setViolationDevicesDistribution({
        vln_fc_devices: tempvln_fc_devices,
        vln_uc_devices: tempvln_uc_devices,
        vln_ufc_devices: tempvln_ufc_devices,
        categories: tempCategories,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      setViolationAlertsDistribution({
        vln_fc: [],
        vln_uc: [],
        vln_ufc: [],
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setViolationDevicesDistribution({
        vln_fc_devices: [],
        vln_uc_devices: [],
        vln_ufc_devices: [],
        categories: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheTop20Partners = (data) => {
    if (data.responseStatus.code === 200) {
      let tempPartner = [],
        tempCount = [];

      data.response.partnerList.map((item) => {
        tempPartner.push(item["partner"]);
        tempCount.push(item["alertCount"]);
      });

      setPartners({
        partners: tempPartner,
        count: tempCount,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setSelectedPartner(tempPartner[0]);
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setPartners({
        partners: [],
        count: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      setSelectedPartnerBatteries({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  const SetTheSelectedPartnerBatteries = (data) => {
    if (data.responseStatus.code === 200) {
      let tempBattery = [],
        tempCount = [],
        tempData = [];
      data.response.batteryList.map((item) => {
        tempData.push({
          y: item["alertCount"],
          x: item["batteryID"],
          batteryID: item["assetID"],
        });
        tempBattery.push(item["batteryID"]);
        tempCount.push(item["alertCount"]);
      });
      setSelectedPartnerBatteries({
        batteries: tempBattery,
        count: tempCount,
        data: tempData,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    } else {
      if (data.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
      setSelectedPartnerBatteries({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    setViolationAlertsCount({
      code: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    setViolationAlertsDevices({
      code: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    setMaxUFCAlerts({
      batteries: [],
      count: [],
      data: [],
      responseStatus: { code: -1, msg: "" },
    });

    setPartners({
      partners: [],
      count: [],
      responseStatus: { code: -1, msg: "" },
    });

    setSelectedPartnerBatteries({
      batteries: [],
      count: [],
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    setSelectedPartner(null);

    const controller = new AbortController();

    const GetTheAlertsCountAndDevices = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Violation",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/alerts_type_severity_distribution`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheAlertsCountAndDevices(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setViolationAlertsCount({
            ...violationAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setViolationAlertsDevices({
            ...violationAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setViolationAlertsCount({
            ...violationAlertsCount,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
          setViolationAlertsDevices({
            ...violationAlertsDevices,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(
                `/api/alerts_type_severity_distribution`,
                {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                }
              );
              const data = callTheAPI(res);
              SetTheAlertsCountAndDevices(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setViolationAlertsCount({
                  ...violationAlertsCount,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
                setViolationAlertsDevices({
                  ...violationAlertsDevices,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };

    const GetTheBatteriesWithAlertsMaxUFC = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "",
        alertCode: "vln_ufc",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/batteries_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheBatteriesWithAlertsMaxUFC(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setMaxUFCAlerts({
            batteries: [],
            count: [],
            data: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setMaxUFCAlerts({
            batteries: [],
            count: [],
            data: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/batteries_with_max_alerts`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheBatteriesWithAlertsMaxUFC(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setMaxUFCAlerts({
                  batteries: [],
                  count: [],
                  data: [],
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    const GetTheTop20Partners = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertCode: "vln_ufc",
        granularity: "",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
      };

      try {
        let res = await axios.get(`/api/partner_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheTop20Partners(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setPartners({
            partners: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setPartners({
            partners: [],
            count: [],
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          // try {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(`/api/partner_with_max_alerts`, {
                params: rawJson,
                headers: {
                  "Content-Type": "application/json",
                },
                signal: controller.signal,
              });
              const data = callTheAPI(res);
              SetTheTop20Partners(data);
            } catch (error) {
              if (error.response?.status === CODE.URL_NODATA) {
                setPartners({
                  partners: [],
                  count: [],
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
          // } catch (error) {
          //   SetSessionExpired();
          // }
        }
      }
    };

    GetTheAlertsCountAndDevices();
    GetTheBatteriesWithAlertsMaxUFC();
    GetTheTop20Partners();

    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
  ]);

  useEffect(() => {
    setViolationAlertsDistribution({
      vln_fc: [],
      vln_uc: [],
      vln_ufc: [],
      categories: [],
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });
    setViolationDevicesDistribution({
      vln_fc_devices: [],
      vln_uc_devices: [],
      vln_ufc_devices: [],
      categories: [],
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });
    const controller = new AbortController();
    const GetViolationAlertsDistribution = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),
        alertType: "Violation",
        iotVendor: props.iotVendor,
        batteryVendor: props.pack,
        granularity: props.granular,
      };

      try {
        let res = await axios.get(`/api/alert_type_distribution`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res, true);
        SetTheViolationAlertsDistribution(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setViolationAlertsDistribution({
            ...violationAlertsDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setViolationAlertsDistribution({
            ...violationAlertsDistribution,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          if (isRefreshAPICalled) {
            try {
              let res = await axios.get(
                `/api/alerts_type_severity_distribution`,
                {
                  params: rawJson,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  signal: controller.signal,
                }
              );
              const data = callTheAPI(res, true);
              SetTheViolationAlertsDistribution(data);
            } catch (error) {
              console.log(error);
              if (error.response?.status === CODE.URL_NODATA) {
                setViolationAlertsDistribution({
                  ...violationAlertsDistribution,
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: "No Data",
                  },
                });
              } else if (error.response?.status === CODE.UNAUTHORIZED) {
                return null;
              }
            }
          }
        }
      }
    };
    GetViolationAlertsDistribution();
    return () => {
      controller.abort();
    };
  }, [
    props.date[0],
    props.date[1],
    props.iotVendor,
    props.pack,
    isRefreshAPICalled,
    props.granular,
  ]);

  useEffect(() => {
    setSelectedPartnerBatteries({
      batteries: [],
      count: [],
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    const controller = new AbortController();

    const GetTheSelectedPartnerBatteries = async () => {
      var rawJson = {
        startDate: convertZonetoUTCStart(props.date[0]),
        endDate: convertZonetoUTCEnd(props.date[1]),

        iotVendor: props.iotVendor,
        batteryVendor: props.pack,

        alertCode: "vln_ufc",

        partner: selectedPartner,
      };

      try {
        let res = await axios.get(`/api/partner_batteries_with_max_alerts`, {
          params: rawJson,
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
        const data = callTheAPI(res);
        SetTheSelectedPartnerBatteries(data);
      } catch (error) {
        if (error.response?.status === CODE.URL_NODATA) {
          setSelectedPartnerBatteries({
            ...selectedPartnerBatteries,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.FORBIDDEN) {
          setSelectedPartnerBatteries({
            ...selectedPartnerBatteries,
            responseStatus: {
              code: CODE.NODATA,
              msg: "No Data",
            },
          });
        } else if (error.response?.status === CODE.UNAUTHORIZED) {
          try {
            let refresh = await axios.get("/api/refresh");
            if (refresh && refresh.status === CODE.SUCCESS) {
              try {
                let res = await axios.get(
                  `/api/partner_batteries_with_max_alerts`,
                  {
                    params: rawJson,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    signal: controller.signal,
                  }
                );
                const data = callTheAPI(res);
                SetTheSelectedPartnerBatteries(data);
              } catch (error) {
                if (error.response?.status === CODE.URL_NODATA) {
                  setSelectedPartnerBatteries({
                    ...selectedPartnerBatteries,
                    responseStatus: {
                      code: CODE.NODATA,
                      msg: "No Data",
                    },
                  });
                } else if (error.response?.status === CODE.UNAUTHORIZED) {
                  // removeAccessToken();
                  return null;
                }
              }
            }
          } catch (error) {
            // Logout();
            SetSessionExpired();
          }
        }
      }
    };
    if (selectedPartner) GetTheSelectedPartnerBatteries();
    else {
      setSelectedPartnerBatteries({
        batteries: [],
        count: [],
        data: [],
        responseStatus: {
          code: CODE.NODATA,
          msg: "",
        },
      });
    }
    return () => {
      controller.abort();
    };
  }, [selectedPartner, isRefreshAPICalled]);

  if (queryParams["id"] === "Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts" ||
  // queryParams["id"] === "Top_20_Batteries_with_Maximum_Unauthorized_Field_Charging_Alerts" ||
   !queryParams["id"])
    ScrollToTheElement();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("AA Hovered Safety Alerts ")}
        >
          <Header
            heading="Total batteries with various violation alerts"
            description="Count of Devices with Violation Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Count_of_Batteries_with_Violation_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Count_of_Batteries_with_Violation_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              data={violationAlertsDevices}
              xaxisTitle={"Number of batteries"}
              xTickAmount={5}
              yaxisTitle={undefined}
              distributed={false}
              horizontal={true}
              barHeight={"50%"}
              toggleDataPointSelection={false}
              series={[
                {
                  name: "Number of batteries",
                  data: violationAlertsDevices.count,
                },
              ]}
              formatter={true}
              tickAmount={4}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(
                Math.max(...violationAlertsDevices.count)
              ),500)}
              categories={violationAlertsDevices.code}
              offsetX={20}
              offsetY={0}
              colors={[
                "#01579b",
                "#03a9f4",

                "#00bcd4",

                ThemeProperties.violation_4,
                ThemeProperties.violation_5,
                ThemeProperties.violation_6,
                ThemeProperties.violation_7,
                ThemeProperties.violation_8,
                ThemeProperties.violation_9,
              ]}
              hoverFilter={"none"}
              activeFilter={"none"}
            />
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() =>
            Events("AA Hovered Distribution Of Devices Based on Severity")
          }
        >
          <Header
            heading="Batteries with various violation alerts spread across dates"
            description="Number of Alerts generated"
            showIButton={true}
            showThreeDots={false}
            id="Number_of_batteries_generating_alerts_violation"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Number_of_batteries_generating_alerts_violation"
            style={{ height: "calc(100% - 70px)" }}
          >
            <div style={{ height: "calc(100% - 20px)" }}>
              {/* <ApexMultiSeries
                xaxisType={props.granular === "Daily" ? "datetime" : "category"}
                granularity={props.granular}
                data={violationDevicesDistribution}
                yaxisTitle={"Number of batteries"}
                xaxisTitle={undefined}
                tickAmount={8}
                series={[
                  {
                    name: "Field charging",
                    data: violationDevicesDistribution.vln_fc_devices,
                  },
                  {
                    name: "Unauthorised charging",
                    data: violationDevicesDistribution.vln_uc_devices,
                  },
                  {
                    name: "Unauthorised field charging",
                    data: violationDevicesDistribution.vln_ufc_devices,
                  },
                ]}
                statusClicked={statusClickedDevices}
                handleLegendClick={handleAlertsStatusClickedDevices}
                categories={violationDevicesDistribution.categories}
                colors={[
                  "#00bcd4",

                  "#03a9f4",
                  "#01579b",

                  ThemeProperties.violation_4,
                  ThemeProperties.violation_5,
                  ThemeProperties.violation_6,
                  ThemeProperties.violation_7,
                  ThemeProperties.violation_8,
                  ThemeProperties.violation_9,
                ]}
              /> */}
              <TestApexBar
                data={violationDevicesDistribution}
                horizontal={false}
                barHeight={"90%"}
                yaxisTitle={"Number of batteries"}
                xaxisTitle={undefined}
                tickAmount={8}
                granularity={props.granular}
                ymax={FindNextHighestMultipleOfNum(
                  FindNearestMaxAnalytics(violationDevicesDistribution.max),
                  500
                )}
                columnWidth={
                  violationDevicesDistribution.categories.length >= 1 &&
                  violationDevicesDistribution.categories.length < 9
                    ? 10 * violationDevicesDistribution.categories.length +
                      "%"
                    : "80%"
                }
                stacked={false}
                showDataLabels={false}
                categories={violationDevicesDistribution.categories}
                series={[
                  {
                    name: "Field charging",
                    data: violationDevicesDistribution.vln_fc_devices,
                  },
                  {
                    name: "Unauthorised charging",
                    data: violationDevicesDistribution.vln_uc_devices,
                  },
                  {
                    name: "Unauthorised field charging",
                    data: violationDevicesDistribution.vln_ufc_devices,
                  },
                ]}
                offsetX={20}
                offsetY={0}
                customLegend={true}
                statusClicked={statusClickedDevices}
                handleLegendClick={handleAlertsStatusClickedDevices}
                colors={[
                  ThemeProperties.map_critical,
                  ThemeProperties.map_high,
                  ThemeProperties.map_medium,
                  ThemeProperties.map_low,
                ]}
                width={"100%"}
              />
            </div>
            {violationDevicesDistribution.responseStatus.code ===
              CODE.SUCCESS && <LegendsDevices />}
          </div>
        </PaperHighlight>
      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Top 20 partners with maximum unauthorized field charging alerts"
            description="Top 20 Batteries with Maximum Unauthorized Field Charging Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div
              className="section"
              id="Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts"
              style={{ height: "calc(100% - 70px)", width: "50%" }}
            >
              <ApexHorizontalBar
                data={partners}
                xaxisTitle={"Number of alerts"}
                xTickAmount={5}
                yaxisTitle={undefined}
                distributed={false}
                horizontal={true}
                barHeight={"70%"}
                tickAmount={4}
                toggleDataPointSelection={true}
                xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(Math.max(...partners.count)),50)}
                series={[
                  {
                    name: "Number of alerts",
                    data: partners.count,
                  },
                ]}
                formatter={true}
                selectedPartner={selectedPartner}
                handleSelectedPartner={handleSelectedPartner}
                customTooltip={false}
                rgbColor={"#00bcd4"}
                batteries={maxUFCAlerts.batteries}
                clickable={false}
                categories={partners.partners}
                offsetX={20}
                offsetY={0}
                colors={["#00bcd4"]}
                hoverFilter={"lighten"}
                activeFilter={"darken"}
                id={
                  "Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts"
                }
                page="_Reports/AlertAnalytics"
                partnerClick={true}
              />
            </div>
            <div
              className="section"
              id="Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts"
              style={{ height: "calc(100% - 70px)", width: "50%" }}
            >
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    Selected Partner :{" "}
                    <b>
                      {selectedPartner === null
                        ? "No partner selected"
                        : selectedPartner}
                    </b>
                  </Typography>
                  <Typography variant="textSubtitle">
                    Number of batteries :{" "}
                    <b>{selectedPartnerBatteries.batteries.length}</b>
                  </Typography>
                </div>

                <div style={{ height: "calc(100% - 70px)" }}>
                  <ApexBasicTreemap
                    selectedPartner={selectedPartner}
                    data={selectedPartnerBatteries}
                    batteries={selectedPartnerBatteries.batteries}
                    rgbColor={"#00bcd4"}
                    id={
                      "Top_20_Partners_with_Maximum_Unauthorized_Field_Charging_Alerts"
                    }
                    page="_Reports/AlertAnalytics"
                  />
                </div>
              </div>
            </div>
          </div>
        </PaperHighlight>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <PaperHighlight
          elevation={0}
          sx={{ height: "480px" }}
          onMouseEnter={() => Events("SB Hovered Degradation Curve")}
        >
          <Header
            heading="Top 20 batteries with maximum unauthorized field charging alerts"
            description="Top 20 Batteries with Maximum Unauthorized Field Charging Alerts"
            showIButton={true}
            showThreeDots={false}
            id="Top_20_Batteries_with_Maximum_Unauthorized_Field_Charging_Alerts"
            page="_Reports/AlertAnalytics"
          />
          <div
            className="section"
            id="Top_20_Batteries_with_Maximum_Unauthorized_Field_Charging_Alerts"
            style={{ height: "calc(100% - 70px)" }}
          >
            <ApexHorizontalBar
              data={maxUFCAlerts}
              xaxisTitle={"Number of alerts"}
              xTickAmount={5}
              distributed={false}
              yaxisTitle={undefined}
              horizontal={true}
              barHeight={"70%"}
              toggleDataPointSelection={false}
              tickAmount={4}
              formatter={true}
              xmax={FindNextHighestMultipleOfNum(FindNearestMaxAnalytics(Math.max(...maxUFCAlerts.count)),10)}
              series={[
                {
                  name: "Number of alerts",
                  data: maxUFCAlerts.data,
                },
              ]}
              customTooltip={true}
              rgbColor={"#00bcd4"}
              batteries={maxUFCAlerts.batteries}
              clickable={true}
              categories={[]}
              offsetX={20}
              offsetY={0}
              colors={["#00bcd4"]}
              hoverFilter={"lighten"}
              activeFilter={"darken"}
              id="Top_20_Batteries_with_Maximum_Unauthorized_Field_Charging_Alerts"
            page="_Reports/AlertAnalytics"
            />
          </div>
        </PaperHighlight>
      </Grid>
    </>
  );
}

export default Violations;
