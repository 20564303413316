import { styled } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const StyledListItemButton = styled((props) => <ListItemButton {...props} />)(
    ({ theme,myColor }) => ({
      margin:"10px",
      // borderRadius: "12px",
      padding:"8px 16px 8px 8px",
      backgroundColor:"white",
      border:"1px solid #ececec",
      "&.MuiList-root":
      {
        paddingTop:"0px"
      },
      "&.Mui-selected": {
        color: "#fff",
        border:`1px solid ${myColor}`,
        backgroundColor: myColor,
        "&:hover": {          
          backgroundColor: myColor,
          border:`1px solid ${myColor}`,
        },
      },
      "&:hover": {
        border:`1px solid ${myColor}`,
        backgroundColor: ThemeProperties.white,
      },
    })
  )

export {StyledListItemButton}